import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Select from 'react-select';
import WEIcon, { WEIconFontAwesome } from '../../components/WE-IconButton';
import { ActiveButton } from '../../components/WE-Button';
import icon from '../../assets/icons/add.png';
import addicon from '../../assets/images/icons/add.svg';
import deleteicon from '../../assets/images/icons/delete.svg';
import editicon from '../../assets/images/icons/edit.svg';
import securityicon from '../../assets/images/icons/security.svg';
import checkicon from '../../assets/images/icons/check.svg';
import cancelicon from '../../assets/images/icons/cancel.svg';
import mealIcon from '../../assets/images/icons/fork-plate-knife.svg';
import AddKitchen from './AddKitchen';
import next from '../../assets/images/next.png';
import { Link } from 'react-router-dom';
import KitchenDetails from './KitchenDetails';

const Kitchen = ({
  data,
  handleUpdate,
  handleAddNew,
  handleDelete,
  KitchenStatuses,
  Languages,
  CurrentLanguage,
  getKitchens,
  companyId,
  translations,
  setTranslations,
  handleKitchen,
  addKitchen,
  setModalData,
  terminalNumber,
  setTerminalNumber,
  setEditMode,
  setEditId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const KitchenStatusesMap = KitchenStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.value };
  }, {});
  const [isActive, setIsActive] = useState(
    KitchenStatusesMap[data.kitchenStatusId] === 'active'
  );
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    setTerminalNumber(e.target.value);
  };
  useEffect(() => {
    setTerminalNumber(data?.terminalNumber);
  }, [data?.terminalNumber]);
  const getInputField = ({
    language,
    value,
    placeholder,
    cb,
    type = 'text',
  }) => {
    return (
      <div className="language-input">
        <input
          className={`${type}`}
          name={`${language.name}_${placeholder}`}
          placeholder={t(`${placeholder}PH`)}
          defaultValue={value}
          onChange={(event) => cb(event.target.value)}
          required
          type={type}
        />
      </div>
    );
  };

  const handleMealTypes = async (data) => {
    console.log('testing');
    localStorage.setItem('kitchen', data);
    await getKitchens(companyId);
    history.push({
      pathname: `/mealtypes/${data}`,
      state: { data },
    });
  };

  const handleEditKitchen = (id) => {
    setEditMode(true);
    setEditId(id);
    setTranslations(data.translations);
    setTerminalNumber(data.terminalNumber);
    handleKitchen('edit');
  };

  const handleAction = (data) => {
    console.log('Testing', data);
    history.push({
      pathname: `/kitchen-details`,
      state: { data: data },
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={`item ${isActive ? '' : 'blocked'}`}>
      <form className={`kitchen-form`}>
        <div className="left-listing">
          {Languages.map((lang) =>
            getInputField({
              value: data?.translations[lang.id]?.name || '--',
              language: lang,
              placeholder: 'name',
              name: `name_${lang.id}`,
              cb: (value) =>
                setTranslations((state) => ({
                  ...state,
                  [lang.id]: { languageId: lang.id, name: value },
                })),
            })
          )}
          <section className="terminal-no">
            <div className="language-input">
              <input
                name={`terminalNumber`}
                type="text"
                onChange={(e) => handleChange(e)}
                value={data.terminalNumber ? data.terminalNumber : '--'}
              />
            </div>
          </section>
        </div>
        <div className="right-listing">
          <section className="actions">
            <section>
              <div
                className={`icon-wrapper`}
                onClick={() => handleMealTypes(data.id)}
              >
                <img src={mealIcon} />
              </div>
            </section>
            <img src={editicon} onClick={() => handleEditKitchen(data.id)} />
            <img src={deleteicon} onClick={() => handleDelete(data.id)} />
            <section>
              {' '}
              <div className="view-more-btn">
                <img src={next} alt="next" onClick={handleShow} />
              </div>
            </section>
          </section>
        </div>
      </form>
      <KitchenDetails
        show={show}
        handleClose={handleClose}
        handleMealTypes={handleMealTypes}
        handleDelete={handleDelete}
        handleEditKitchen={handleEditKitchen}
        data={data}
      />
    </div>
  );
};

export default Kitchen;
