import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { getUserConfigByCompanyId } from '../../store/companyConfig/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from '../../services/http';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import { isTabletOrMobile } from '../../services/mediaQueries';
import {
  Alert,
  Dialog,
  SuccessDailog,
} from '../../components/Dialogs/SwalDialogs';
import Employee from './Employee';
import EmployeeToAdd from './EmployeeToAdd';
import Select from 'react-select';
import useDebounce from '../../hooks/UseDebounce';
import WEIcon from '../../components/WE-IconButton';
import { Button } from '@material-ui/core';
import { ActiveButton } from '../../components/WE-Button';
import AddContractorEmployeePopup from './AddContractorEmployee';
import { validateEmployeenew } from '../../services/validators';
import CopyUrlPopup from './copyUrl';
import ConfirmationPopup from './confirmation';
import Tippy from '@tippyjs/react';
import cancel from '../../assets/images/close.svg';
import ViewContractorDetails from './viewContractorDetails';
import more from '../../assets/images/more.svg';
import next from '../../assets/images/next.png';
import adduser from '../../assets/images/add-user.svg';
import search from '../../assets/icons/search.png';
import file from '../../assets/images/file-export.svg';
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';
import { getAllUser, usersTotal } from '../../store/user/actions';
import Toast from 'react-bootstrap/Toast';

const initialiState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: 6,
  kitchensIds: [],
  contractors: [],
  employeeId: '',
  card: '',
  status: 'created',
  contractorsIds: [],
};

const ContractorEmployee = ({
  user,
  kitchen,
  setLayoutOptions,
  UserStatuses,
  Kitchens,
  Roles,
  useLoader,
  useFetchingLoader,
  getAllUser,
  usersTotal,
  allUsers,
  companyName,
  cardNumberLengthForUser,
  employeeNumberLengthForUser,
  getUserConfigByCompanyId,
  companyId,
  isStrippedCard,
  cardGenerationUrlForUser,
  cibusEnabled,
}) => {
  const { t } = useTranslation();
  const [kitchensIds, setKitchensIds] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [payload, setPayload] = useState(initialiState);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [contractorList, setContractorList] = useState();
  const [errors, setErrors] = useState({});

  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [newPageSizeUsers, setPageSizeUsers] = useState(25); // Records per page
  const [totalUserRecords, setTotalUserRecords] = useState(0); // Total records
  const [pageCountsUsers, setpageCountsUsers] = useState(1); // Total pages count
  const [currentPageUsers, setCurrentPageUsers] = useState(1); // Current page
  const [searchKeywordUsers, setSearchKeywordUsers] = useState(''); // Search text
  const [userStatusId, setUserStatusId] = useState(null);
  const [userContractorId, setUserContractorId] = useState(null);
  const [userKitchenId, setUserKitchenId] = useState(null);
  const [forceResetPassword, setForceResetPassword] = useState(false);
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [currentUserKitchens, setCurrentUserKitchesList] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      getUserConfigByCompanyId(companyId);
    };
    fetch();
  }, []);
  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      setPayload({ initialiState, allContractors: user?.contractors });
      setIsUpdate(false);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setKitchensIds([]);
    setErrors({});
  };
  useEffect(() => {
    console.log('employeeNumberLengthForUser', employeeNumberLengthForUser);
    console.log('cardNumberLengthForUser', cardNumberLengthForUser);
  }, [open]);
  const Limit = 2;
  const UserStatusesMap_Status = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status };
  }, {});
  const UserStatusesMap_Id = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(async () => {
    let userKitchen = Kitchens.filter((el) => user?.KitchenId?.includes(el.id));
    setCurrentUserKitchesList(userKitchen);
  }, []);

  const handleReset = () => {
    setColumnAndOrder({});
    setSearchKeywordUsers('');
    setUserStatusId('null');
    setUserContractorId('null');
    setUserKitchenId('null');
    gridApi.setSortModel([]);
  };

  const getLimitUserData = async () => {
    let filter = {
      companyId: user.companyId,
      limit: newPageSizeUsers,
      page: currentPageUsers,
      statusId: userStatusId,
      contractorId: userContractorId,
      kitchenId: userKitchenId,
      orderField:
        'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
      orderTarget: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
      search: searchKeywordUsers ? searchKeywordUsers : undefined,
    };
    await useFetchingLoader(async () => await getAllUser(filter));
  };

  useEffect(async () => {
    await getLimitUserData();
  }, [
    dummy,
    kitchen,
    newPageSizeUsers,
    currentPageUsers,
    columnAndOrder,
    userKitchenId,
    userContractorId,
    userStatusId,
  ]);
  const handleChangeKitchenFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserKitchenId(userTypeId);
  };
  const handleChangeContractorFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserContractorId(userTypeId);
  };
  const handleChangeUserStatusFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserStatusId(userTypeId);
  };
  useEffect(async () => {
    const contractorData = await http.get(`contractors/getAllContractors`);
    setContractorList(contractorData.data);
    console.log('User', user);
    // let filter = {
    //   roleId: [RolesMap.contractorEmployee],
    //   companyId: user.companyId,
    //   contractorId: user.contractorId,
    //   // userStatusId: { ne: UserStatusesMap.deleted },
    // };
    // let filter = {
    //   roleId: [
    //     RolesMap.contractorEmployee
    //   ],
    //   companyId: user.companyId,
    //   limit: newPageSizeUsers,
    //   page: currentPageUsers,
    //   keyword: searchKeywordUsers,
    // };
    // const { data, total } = await useFetchingLoader(
    //   async () => await http.get(`users`, { filter })
    // );
    setPrefetchData(allUsers);
    let serverUsers = allUsers.map((u, i) => ({
      userId: u.id,
      'Employee name': [u.firstName, u.lastName].join(' '),
      firstName: u.firstName,
      lastName: u.lastName,
      employeeId: u?.employeeId ? u?.employeeId : '--',
      cardId: u?.cardId ? u?.cardId : '--',
      Status: UserStatuses.find((el) => el.id === u.userStatusId).display,
      createdAt: u.createdAt
        ? u.createdAt.split('T')[0]
        : undefined /* Format : YYYY-MM-DD */,
      Kitchens: Kitchens?.filter(
        (obj) => u?.kitchens.find((el) => el.id === obj.id) !== undefined
      )
        .map((obj) => obj.name)
        ?.join(','),
      Contractors: u?.contractors ? u?.contractors : '--',
      email: u?.email ? u?.email : '--',
      Action: i,
      phone: u?.phone ? u?.phone : '--',
      creditCard: u.creditCard,
      magneticStripe: u?.magneticStripe,
    }));
    setTotalUserRecords(usersTotal);
    setUsersToDisplay(serverUsers);
    setpageCountsUsers(Math.ceil(usersTotal / newPageSizeUsers));
  }, [dummy, kitchen, allUsers]);

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };
  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  // ag grid data
  const columnDefs = [
    {
      field: 'firstName',
      headerName: t('employeeName'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'employeeId',
      headerName: t('employeeNo'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: 'cardId',
      headerName: t('cardNo'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: 'Status',
      headerName: t('status'),
      suppressMovable: true,
    },
    {
      field: 'createdAt',
      headerName: t('registrationDate'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: 'Kitchens',
      headerName: t('kitchenname'),
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'KitcheCellRender',
    },
    {
      field: 'email',
      headerName: t('email'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: 'Contractors',
      headerName: t('contractors'),
      cellRenderer: 'ContractorCellRender',
      suppressMovable: true,
    },
    {
      field: 'phone',
      headerName: t('phone'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  // Tab view
  const columnDefsTab = [
    {
      field: 'firstName',
      headerName: t('employeeName'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'employeeId',
      headerName: t('employeeNo'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: 'cardId',
      headerName: t('cardNo'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: 'Status',
      headerName: t('status'),
      sortable: true,
      suppressMovable: true,
    },
    {
      field: 'createdAt',
      headerName: t('registrationDate'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'Kitchens',
      headerName: t('kitchenname'),
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'KitcheCellRender',
      hide: true,
    },
    {
      field: 'Contractors',
      headerName: t('contractors'),
      cellRenderer: 'ContractorCellRender',
      sortable: true,
      suppressMovable: true,
    },
    {
      field: 'email',
      headerName: t('email'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'phone',
      headerName: t('phone'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      hide: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'firstName',
      headerName: t('employeeName'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'employeeId',
      headerName: t('employeeNo'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: 'cardId',
      headerName: t('cardNo'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: 'createdAt',
      headerName: t('registrationDate'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'Kitchens',
      headerName: t('kitchenname'),
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'KitcheCellRender',
      hide: true,
    },
    {
      field: 'email',
      headerName: t('email'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'phone',
      headerName: t('phone'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'Contractors',
      headerName: t('contractors'),
      sortable: true,
      suppressMovable: true,
      cellRenderer: 'ContractorCellRender',
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    comparator: () => {},
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 80,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  const getUserData = () => {
    getLimitUserData();
  };
  const onFilterTextBoxChanged = (e) => {
    setCurrentPageUsers(1);
    const value = e.target.value;
    setSearchKeywordUsers(value);
  };
  /******* add New employee */
  const handleAddNewUser = async (userData) => {
    // setDummy(false);
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];
    try {
      //  let userToAdd = userData;
      let userToAdd;
      userData.employeeId =
        userData.employeeId && userData.employeeId !== ''
          ? userData.employeeId
          : null;
      userData.cardId =
        userData.cardId && userData.cardId !== '' ? userData.cardId : null;
      console.log('userData', userData);
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`users/create`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      // handleClose();
      // setToggleAddUser(false);
      Dialog({
        title: t('addedmessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setDummy(!dummy);
          handleClose();
        },
      });
    } catch (err) {
      Alert({ error: err });
    }
  };

  /******** update employee details */
  const handleUpdateUser = async (event) => {
    event.preventDefault();
    console.log('payload', payload);
    try {
      // setDummy(false);
      let details = {
        firstName: payload.fName,
        lastName: payload.lName,
        email: payload.email,
        phone: payload.phone,
        // userStatusId:prefetchData?.find(el => el.id === payload.userId)?.userStatusId,
        userStatusId: UserStatuses.find((el) => el.display === payload.status)
          .id,
        kitchensIds: kitchensIds,
        password: payload.password === '' ? undefined : payload.password,
        contractorsIds:
          payload.contractorsIds && payload.contractorsIds.length !== 0
            ? payload.contractorsIds
            : payload.contractors && payload.contractors.length !== 0
            ? payload.contractors.map((el) => el.id)
            : null,
        contractors: payload.contractors,
        cardId:
          !payload.card || payload.card === '' || payload.card === '--'
            ? null
            : isStrippedCard
            ? payload.card.replace(/^0+/, '')
            : payload.card,
        employeeId:
          !payload.employeeId ||
          payload.employeeId === '' ||
          payload.employeeId === '--'
            ? null
            : payload.employeeId,
        roleId: RolesMap.contracorEmployee,
        contractorId: user.contractorId,
        magneticStripe: payload.magneticStripe,
        // value: payload.value,
      };
      const { isValid, errors } = validateEmployeenew(
        details,
        cardNumberLengthForUser,
        employeeNumberLengthForUser
      );
      console.log('isValid', isValid);
      console.log('errors', errors);
      setErrors({ ...errors });
      if (!isValid) {
        return;
      }
      const userId = payload.userId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`users/${userId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      Dialog({
        title: t('updatedMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setDummy(!dummy);
          setViewDetails(!viewDetails);
          handleClose();
        },
      });

      setKitchensIds([]);
    } catch (err) {
      Alert({ error: err });
    }
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const onBtnExport = async (fileName) => {
    console.log('This');
    try {
      if (disableButton || tableColumn.length === 0) {
        console.log('Disabled or empty table');
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        const arr = [
          'firstNamePH',
          'lastNamePH',
          'user_type',
          'status',
          'cardIdPH',
          'employeeNo',
          'kitchens',
          'phonePH',
          'contractorName',
          'paymentMethod',
          'updatedOn',
          'startDate',
          cibusEnabled ? 'magneticStripe' : undefined,
          'guestLink',
          'registrationDate',
          'endDate',
        ].filter((el) => el !== undefined);
        const resArr = arr.map((el) => t(el));
        const filter = {
          companyId: localStorage.getItem('companyId'),
          limit: totalUserRecords,
          page: 1,
          keyword: searchKeywordUsers,
          statusId: userStatusId,
          contractorId: userContractorId,
          kitchenId: userKitchenId,
          orderField:
            'field' in columnAndOrder ? columnAndOrder?.field : 'firstName',
          orderTarget:
            'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
          search: searchKeywordUsers ? searchKeywordUsers : undefined,
        };
        console.log('This', resArr);
        for (const key in filter) {
          if (
            filter[key] === undefined ||
            filter[key] === null ||
            filter[key] === 'null'
          ) {
            delete filter[key];
          }
        }
        const res = await http.post(
          `users/download`,
          {
            isReport: true,
            headers: resArr,
          },
          { ...filter }
        );
        console.log('res', res);
        const downloadUrl = window.URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv' })
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  const ResendVerification = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/resetPassword`, {
          userData: { email: el.Email, resendLink: true },
          companyId: localStorage.getItem('companyId'),
        })
    );
    if (res?.sent) {
      Dialog({
        title: t('resendMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          //setDummy(true);
        },
      });
    }
  };

  const handleDeleteUser = async () => {
    try {
      let details = {
        userStatusId: 5,
      };
      const response = await http.put(`users/${deleteId}`, { ...details });
      setConfirmation(false);
      if (response) {
        Dialog({
          title: t('deleteMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(true);
          },
        });
      }
      setDummy(false);
      setDeleteId('');
    } catch (err) {
      Alert({ error: err });
    }
  };

  const CopyDataUrl = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/copyUrl`, {
          userData: { email: el.Email },
        })
    );
    setResendData(res);

    setCopyData(true);
  };
  const handleCloseUrl = () => {
    setCopyData(false);
    setCopied(false);
  };
  const handleConfirmation = (userId) => {
    setConfirmation(!confirmation);
    setDeleteId(userId);
  };
  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const lang = localStorage.getItem('language');
  return (
    <div className="user-management-table global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-user-wrapper">
            <div className="search-block">
              <div className="search-input">
                <img src={search} alt="search" />
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={`${t('freeTextSearch')}`}
                  onInput={onFilterTextBoxChanged}
                  value={searchKeywordUsers}
                />
              </div>
              <button onClick={getUserData} className="search-button">
                <img src={search} alt="search" />
              </button>
              <button
                onClick={() => handleReset()}
                className="reset-button-user-management"
              >
                <img src={cancel} alt="reset" />
              </button>
            </div>
            <div className="filter-block">
              <div className="from-date">
                <select
                  name="userType"
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeUserStatusFilter(event.target.value)
                  }
                  value={userStatusId}
                >
                  <option value="null">{t('selectUserStatus')}</option>
                  <option value="1">{t('created')}</option>
                  <option value="2">{t('pending')}</option>
                  <option value="3">{t('active')}</option>
                  <option value="4">{t('blocked')}</option>
                  <option value="5">{t('deleted')}</option>
                </select>
              </div>
              <div className="from-date">
                <select
                  name="userType"
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeContractorFilter(event.target.value)
                  }
                  value={userContractorId}
                >
                  <option value="null">{t('selectContractor')}</option>
                  {user?.contractors
                    ? user?.contractors.map((el) => (
                        <option value={el.id}>{el.name}</option>
                      ))
                    : []}
                </select>
              </div>
              <div className="from-date">
                <select
                  name="userType"
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeKitchenFilter(event.target.value)
                  }
                  value={userKitchenId}
                >
                  <option value="null">{t('selectKitchen')}</option>
                  {Kitchens
                    ? Kitchens.filter((elem) =>
                        user.KitchenId.includes(elem.id)
                      ).map((el) => <option value={el.id}>{el.name}</option>)
                    : []}
                </select>
              </div>
            </div>
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{totalUserRecords}</span>
              </p>
            </div>
            <div className="actions">
              <img
                onClick={() =>
                  onBtnExport(
                    `${companyName}-users-${moment().format('DD-MMM-YYYY')}`
                  )
                }
                src={file}
                alt="add"
              />
              <img src={adduser} alt="add" onClick={() => handleOpen('add')} />
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            columnDefs={tableColumn}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            onSortChanged={() => printSortStateToConsole()}
            frameworkComponents={{
              PopupCellRenderer: (e) => (
                <PopupCellRenderer
                  handleOpen={handleOpen}
                  e={e}
                  setUpdate={setUpdate}
                  setPayload={setPayload}
                  setIsEdit={setIsEdit}
                  t={t}
                  kitchensIds={kitchensIds}
                  setKitchensIds={setKitchensIds}
                  Kitchens={Kitchens}
                  ResendVerification={ResendVerification}
                  handleDeleteUser={handleDeleteUser}
                  CopyDataUrl={CopyDataUrl}
                  handleConfirmation={handleConfirmation}
                  UserStatuses={UserStatuses}
                  contractorList={contractorList}
                  handleViewMore={handleViewMore}
                />
              ),
              ViewMore: (e) => (
                <ViewMore
                  e={e}
                  allContractors={user?.contractors}
                  handleViewMore={handleViewMore}
                />
              ),
              CreditCard: (e) => <CreditCard e={e} />,
              KitcheCellRender: (e) => <KitcheCellRender e={e} />,
              ContractorCellRender: (e) => <ContractorCellRender e={e} />,
            }}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            debug={false}
            overlayLoadingTemplate={'<p></p>'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            pagination={false}
            paginationPageSize={50}
            paginationNumberFormatter={function (params) {
              return '[' + params.value.toLocaleString() + ']';
            }}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <p>
                {newPageSizeUsers * (currentPageUsers - 1) + 1} to{' '}
                {newPageSizeUsers * currentPageUsers} of {totalUserRecords}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers - 1 > 0
                    ? setCurrentPageUsers(currentPageUsers - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageUsers} of{' '}
                {Math.ceil(usersTotal / newPageSizeUsers)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers < Math.ceil(usersTotal / newPageSizeUsers)
                    ? setCurrentPageUsers(currentPageUsers + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(Math.ceil(usersTotal / newPageSizeUsers));
                }}
              />
            </Pagination>
          </div>
        </div>
        {/* <div className="pagination-header">
        {t('pageSize')}:
        <select onChange={() => onPageSizeChanged()} id="page-size">
          <option value="10" selected={true}>
            10
          </option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> */}
        <AddContractorEmployeePopup
          open={open}
          handleClose={handleClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          // userData={{ name: '' }}
          userData={prefetchData}
          newUserFlag={true}
          handleAddNewUser={handleAddNewUser}
          isEdit={true}
          payload={payload}
          setPayload={setPayload}
          handleUpdateUser={handleUpdateUser}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          currentUserKitchens={currentUserKitchens}
          t={t}
          errors={errors}
          setErrors={setErrors}
          user={user}
          contractorList={contractorList}
          cardNumberLengthForUser={cardNumberLengthForUser}
          employeeNumberLengthForUser={employeeNumberLengthForUser}
          isStrippedCard={isStrippedCard}
          cardGenerationUrlForUser={cardGenerationUrlForUser}
          cibusEnabled={cibusEnabled}
        />
        <CopyUrlPopup
          open={copyData}
          handleClose={CopyDataUrl}
          resendData={resendData}
          handleCloseUrl={handleCloseUrl}
          copied={copied}
          setCopied={setCopied}
        />
        <ConfirmationPopup
          open={confirmation}
          handleClose={() => setConfirmation(false)}
          handleDeleteUser={handleDeleteUser}
        />
        <ViewContractorDetails
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
          CopyDataUrl={CopyDataUrl}
          ResendVerification={ResendVerification}
          handleConfirmation={handleConfirmation}
          handleOpen={handleOpen}
          setPayload={setPayload}
          UserStatuses={UserStatuses}
          setKitchensIds={setKitchensIds}
          Kitchens={Kitchens}
          Roles={Roles}
          cibusEnabled={cibusEnabled}
          //setMealTypesIds={setMealTypesIds}
        />
        <Toast
          onClose={() => setOpenDownload(false)}
          show={openDownload}
          style={{
            position: 'sticky',
            right: '20px',
            bottom: '70px',
          }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
              onClick={() => setOpenDownload(false)}
            />
          </Toast.Header>
          <Toast.Body>{t('downloadFile')}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

const ActionButton = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  ResendVerification,
  handleDeleteUser,
  CopyDataUrl,
  handleConfirmation,
  UserStatuses,
}) => {
  setUpdate(e.data);
  setIsEdit(true);
  return (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
    >
      <button
        onClick={() => {
          // e.data['Kitchens'].length
          //   ? setKitchensIds(
          //       Kitchens.map((el) => {
          //         if (e.data['Kitchens'].includes(el.name)) return el.id;
          //       })
          //     )
          //   : setKitchensIds([]);
          let ids = [];
          if (e.data['Kitchens'].length) {
            Kitchens.forEach((el) => {
              if (e.data['Kitchens'].includes(el.name)) {
                ids.push(el.id);
              }
            });
          }
          setKitchensIds(ids);
          console.log('e.data 1', e.data);
          setPayload({
            userId: e.data['userId'],
            card: e.data['cardId'],
            email: e.data['email'],
            fName: e.data['firstName'],
            lName: e.data['lastName'],
            phone: e.data['phone']
              ? e.data['phone'].split('-').join('')
              : e.data['phone'],
            // kitchensIds: e.data['Kitchens'],
            kitchensIds: ids,
            contractors: e.data['Contractors'],
            employeeId: e.data['employeeId'],
            password: e.data['password'],
            status: e.data['Status'],
            contractorsIds: [],
          });
          handleOpen();
        }}
        className="edit-btn"
      >
        {t('editbutton')}
      </button>
      <button
        className="delete-btn"
        // onClick={() => handleDeleteUser(e.data['userId'])}
        onClick={() => handleConfirmation(e.data['userId'])}
      >
        {t('deletebutton')}
      </button>
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button
          className="resend-btn"
          onClick={() => ResendVerification(e.data)}
        >
          Resend
        </button>
      ) : (
        ''
      )}
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button className="copy-btn" onClick={() => CopyDataUrl(e.data)}>
          Copy URL
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

const ViewMore = ({ e, handleViewMore, allContractors, contractors }) => {
  return (
    <div className="view-more-btn">
      <img
        src={next}
        alt="next"
        onClick={() =>
          handleViewMore({ ...e.data, allContractors, contractors })
        }
      />
    </div>
  );
};

const KitcheCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e?.data?.Kitchens?.length > 0 ? e?.data?.Kitchens : '--'}</p>
    </div>
  );
};
const ContractorCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>
        {Array.isArray(e?.data.Contractors) && e?.data.Contractors?.length > 0
          ? e?.data?.Contractors.map((el) => el.name).join(',')
          : '--'}
      </p>
    </div>
  );
};
const CreditCard = ({ e }) => {
  return (
    <div className={`credit-card-option`}>
      <input type="checkbox" checked={e?.data?.creditCard} />
    </div>
  );
};
const PopupCellRenderer = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
  contractorList,
  handleViewMore,
}) => {
  //  console.log('ddd =>');
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const handleClick = (data) => {
    // console.log('ddddddd =>', e);
  };

  const dropDownContent = (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
      style={{ position: 'relative' }}
    >
      <div className="menu-container">
        <div className="menu-item">
          <div
            onClick={() => {
              // e.data['Kitchens'].length
              //   ? setKitchensIds(
              //       Kitchens.map((el) => {
              //         if (e.data['Kitchens'].includes(el.name)) return el.id;
              //       })
              //     )
              //   : setKitchensIds([]);
              let ids = [];
              if (e.data['Kitchens'].length) {
                Kitchens.forEach((el) => {
                  if (e.data['Kitchens'].includes(el.name)) {
                    ids.push(el.id);
                  }
                });
              }
              setKitchensIds(ids);
              console.log('e.data 2', e.data);
              setPayload({
                userId: e.data['userId'],
                card: e.data['cardId'],
                email: e.data['email'],
                fName: e.data['firstName'],
                lName: e.data['lastName'],
                phone: e.data['phone']
                  ? e.data['phone'].split('-').join('')
                  : e.data['phone'],
                // kitchensIds: e.data['Kitchens'],
                kitchensIds: ids,
                contractors: e.data['Contractors'],
                employeeId: e.data['employeeId'],
                password: e.data['password'],
                status: e.data['Status'],
                contractorsIds: [],
              });
              handleOpen();
            }}
            // className="edit-btn"
          >
            {t('editbutton')}
          </div>
        </div>
        <div className="menu-item">
          <div
            // className="delete-btn"
            // onClick={() => handleDeleteUser(e.data['userId'])}
            onClick={() => handleConfirmation(e.data['userId'])}
          >
            {t('deletebutton')}
          </div>
        </div>

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div
              // className="resend-btn"
              onClick={() => ResendVerification(e.data)}
            >
              Resend
            </div>
          </div>
        ) : (
          ''
        )}

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div onClick={() => CopyDataUrl(e.data)}>Copy URL</div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      });
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] });
    }

    if (option === 'edit') {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: 'make',
      });
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <i
        className="fa fa-ellipsis-v"
        aria-hidden="true"
        // onClick={() => handleDropdownClick(e.data?.userId)}
        onClick={visible ? hide : show}
      ></i>
    </Tippy>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  allUsers: state.user.allUsers,
  usersTotal: state.user.usersTotal,
  cardGenerationUrlForUser: state.companyConfig.cardGenerationUrlForUser,
  employeeNumberLengthForUser: state.companyConfig.employeeNumberLengthForUser,
  cardNumberLengthForUser: state.companyConfig.cardNumberLengthForUser,
  companyName: state.companyConfig.companyName,
  companyId: state.companyConfig.companyId,
  isStrippedCard: state.companyConfig.isStrippedCard,
  cibusEnabled: state.companyConfig.paymentsSettings.cibusEnabled,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getUserConfigByCompanyId,
  getAllUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractorEmployee);
