import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { DisabledButton } from '../../components/WE-Button';
import JSONFormatter from 'json-formatter-js';
import moment from 'moment';
import http from '../../services/http';
import { useFetchingLoader } from '../../store/app/actions';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewReservationDetails = ({
  kitchen,
  kitchens,
  reservations,
  MealTypes,
  Languages,
  open,
  details,
  handleClose,
  kitchenParam = '',
  reportType,
  handleOpen,
  reserData,
  setDetails,
  handleCancelOrder,
  currentUser,
  currentLanguage,
  useFetchingLoader,
  setRefresh = {},
  refresh = false,
  fromDate = '',
  toDate = '',
  reservationType = '',
  attendanceEnabled = true,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [viewData, setViewData] = useState(false);
  const [jsonData, setJsondata] = useState();
  const [tranId, setTranId] = useState(undefined);
  const [isExp, setIsExp] = useState(false);
  const [kitchenFilter, setKitchenFilter] = useState({});
  useEffect(() => {
    let filter = reserData?.filter((el) => el.id === details?.id);
    setDetails(filter[0]);
  }, [reserData]);
  useEffect(() => {
    if (kitchenParam) {
      setKitchenFilter(kitchens.options.find((el) => el.id == kitchenParam));
    }
  }, [kitchens, kitchenParam, details]);
  useEffect(() => {
    if (details && Object.keys(details).length !== 0 && details?.mealDish) {
      const dateNow = new Date();
      const expDate = new Date(
        details?.mealDish[0]?.mealDish?.meal?.date +
          'T' +
          details?.mealDish[0]?.mealDish?.meal?.mealType?.to
      );
      setIsExp(expDate < dateNow);
    }
  }, [details]);
  useEffect(() => {
    let data;
    if (
      details?.chargeResponse &&
      'additionalSettlementInfo' in JSON.parse(details?.chargeResponse)
    ) {
      // console.log(JSON.parse(JSON.parse(details?.chargeResponse).additionalSettlementInfo)?.tranId)
      setTranId(
        JSON.parse(JSON.parse(details?.chargeResponse).additionalSettlementInfo)
          ?.tranId
      );
    } else {
      if (details?.chargeResponse) {
        // console.log(JSON.parse(details?.chargeResponse)?.tranId);
        setTranId(JSON.parse(details?.chargeResponse)?.tranId);
      }
    }
    /*console.log('details', details?.chargeResponse ? JSON.parse(details?.chargeResponse): 'miss')
    console.log(
      'kitchens ',
      details?.mealDish
        ? kitchens.options.find(
            (el) => el?.id === details?.mealDish[0]?.mealDish?.dish?.kitchenId
          )
        : null
    );*/
  }, [details]);
  // const getMealDish = (params) => {
  //   if (params) {
  //     const translationsMap = params;
  //     const language = i18n.language;
  //     const languageId = Languages.find((lang) => lang.value === language).id;
  //     return translationsMap[languageId];
  //   }
  //   return '';
  // };

  const getMealDish = (params) => {
    let dataArray = params;
    let finalData = '';
    if (dataArray?.length > 0) {
      finalData = dataArray?.map((ele) => {
        return `${ele.translations[currentLanguage.id]}`;
      });
      return finalData?.toString();
    }
  };

  const handleViewData = (id) => {
    setViewData(!viewData);
    setJsondata(id);
  };

  const filterMealType = MealTypes.filter(
    (el) => el.kitchenId == kitchenParam ?? kitchen.id
  );

  const deleteCreditPoint = async (param) => {
    const getResponseInfo = await useFetchingLoader(
      async () => await http.delete(`users/deleteCreditPointByAdmin`, param)
    );
    if (getResponseInfo.status === 200) {
      SwalDialogs.Dialog({
        title: `${t('cancellOrder')}`,
        type: 'success',
        buttons: {
          showCancelButton: false,
          showConfirmButton: true,
        },
        onConfirm: () => {
          handleClose();
          setRefresh(!refresh);
        },
      });
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-employee-popup"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <div className="reservation-title">
            <h5>
              {t('reservation')}
              {t('reservationNumber')}
              {details?.id}
            </h5>
            <h6>
              {details?.user
                ? details?.user?.firstName && details?.user?.lastName
                  ? details?.user?.firstName + ' ' + details?.user?.lastName
                  : details?.user?.firstName ||
                    details?.user?.lastName ||
                    details?.user?.email ||
                    details?.user?.phone ||
                    details?.user?.employeeId
                : ''}
            </h6>
          </div>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div
            className={`view-more-popup ${
              reportType === 'DetailedReservations'
                ? ''
                : 'view-reservation-page'
            }`}
          >
            {reportType === 'DetailedReservations' && (
              <>
                <div className="user-detail-container reservation-content">
                  <div className="user-details-row">
                    <div className="user-details-block">
                      <h6>{t('orderDate')}:</h6>
                      <p>
                        {moment(details?.createdAt).format('DD/MM/YYYY HH:mm')}
                      </p>
                    </div>
                    {/* <div className="user-details-block">
                      <h6>{t('name')}:</h6>
                      <p>
                        {details?.user
                          ? details?.user?.firstName +
                            ' ' +
                            details?.user?.lastName
                          : '--'}
                      </p>
                    </div> */}
                    <div className="user-details-block">
                      <h6>{t('mealDate')}:</h6>
                      <p>
                        {details?.meal?.date
                          ? details?.meal?.date
                          : details?.mealDish?.[0]?.mealDish?.meal?.date
                          ? details?.mealDish?.[0]?.mealDish?.meal?.date
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('kitchenName')}:</h6>
                      <p>
                        {details?.kitchen?.name
                          ? details?.kitchen?.name
                          : details?.mealDish &&
                            kitchens.options.find(
                              (el) =>
                                el?.id ===
                                details?.mealDish[0]?.mealDish?.dish?.kitchenId
                            )
                          ? kitchens.options.find(
                              (el) =>
                                el?.id ===
                                details?.mealDish[0]?.mealDish?.dish?.kitchenId
                            ).name
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('mealType')}:</h6>
                      {details?.point ? (
                        <p>{details?.mealType?.display}</p>
                      ) : (
                        <p>
                          {MealTypes?.find(
                            (mealType) =>
                              mealType.id ===
                              details?.mealDish?.[0]?.mealDish?.meal?.mealTypeId
                          )?.display || ''}
                        </p>
                      )}
                    </div>
                    <div className="user-details-block">
                      <h6>{t('dishName')}:</h6>
                      <p>
                        {details?.point
                          ? t('pendingCollectedPoint')
                          : getMealDish(
                              details?.mealDish
                                ? details?.mealDish[0]?.mealDish?.dishes
                                : []
                            )}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('price')}:</h6>
                      <p>
                        {details?.point
                          ? details?.point
                          : details?.paymentMethod === 'Wallet'
                          ? details?.orderPrice
                          : '₪' + details?.orderPrice}
                      </p>
                    </div>
                    {reservationType !== 'Credit points' && (
                      <div className="user-details-block">
                        <h6>{t('quantity')}:</h6>
                        <p>
                          {details?.mealDish && Array.isArray(details?.mealDish)
                            ? details?.mealDish[0].mealDish.dishes
                                .map((elem) => elem.quantity)
                                .join(', ')
                            : ' '}
                        </p>
                      </div>
                    )}
                    <div className="user-details-block">
                      <h6>{t('contractorName')}:</h6>
                      <p>
                        {details?.user?.contractors.length !== 0
                          ? details?.user?.contractors
                              .map((elem) => elem.name)
                              .join(', ')
                          : '--'}
                      </p>
                    </div>
                    {(details?.paymentOptionId === 3 ||
                      details?.paymentMethod === 'Credit Card') && (
                      <>
                        <div className="user-details-block">
                          <h6>{t('cardLastDigits')}:</h6>
                          <p>
                            {details?.last4Digits
                              ? details?.last4Digits
                              : details?.chargeResponse &&
                                JSON.parse(
                                  details?.chargeResponse
                                )?.pan?.substr(-4)}
                          </p>
                        </div>
                        {tranId && (
                          <div className="user-details-block">
                            <h6>{t('transactionId')}:</h6>
                            <p>{tranId}</p>
                          </div>
                        )}
                      </>
                    )}
                    {(details?.paymentOptionId === 6 ||
                      details?.paymentMethod === 'Cibus') && (
                      <div className="user-details-block">
                        <h6>{t('cardLastDigits')}:</h6>
                        <p>
                          {details?.user?.magneticStripe
                            ? details?.user?.magneticStripe?.substr(-4)
                            : '--'}
                        </p>
                      </div>
                    )}
                    <div className="user-details-block">
                      <h6>{t('paymentMethod')}:</h6>
                      <p>
                        {' '}
                        {details?.paymentOptionId === 5
                          ? t('wallet')
                          : details?.paymentOptionId === 4
                          ? t('paymentOption-salary')
                          : details?.paymentOptionId === 3
                          ? t('paymentOption-creditCard')
                          : details?.paymentOptionId === 6
                          ? t('paymentOption-cibus')
                          : details?.paymentMethod === 'Invoice'
                          ? t('invoice')
                          : '--'}
                      </p>
                    </div>
                    {details?.paymentOptionId === 6 ? (
                      <div className="user-details-block">
                        <h6>{t('paymentOrderId')}:</h6>
                        <p>
                          {details?.chargeResponse
                            ? JSON.parse(details?.chargeResponse)?.deal_id
                            : '--'}
                          {details?.chargeResponse
                            ? JSON.parse(details?.chargeResponse)?.orderID
                            : '--'}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {details?.paymentOptionId === 6 ? (
                      <div className="user-details-block">
                        <h6>{t('paymentStatus')}:</h6>
                        <p>
                          {details?.paymentStatus ? t('paid') : t('notPaid')}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {details?.phoneNumber ? (
                      <div className="user-details-block">
                        <h6>{t('phonePH')}:</h6>
                        <p>
                          {details?.phoneNumber ? details?.phoneNumber : '--'}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="user-details-block">
                      <h6>{t('realizedDate')}:</h6>
                      {details?.point && details?.status ? (
                        <p>
                          {details?.updatedAt
                            ? moment(details?.updatedAt).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : '--'}
                        </p>
                      ) : (
                        <p>
                          {details?.realizeDate
                            ? moment(details?.realizeDate).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : '--'}
                        </p>
                      )}
                    </div>
                    <div className="user-details-block">
                      <h6>{t('realizedMethod')}:</h6>
                      <p>
                        {details?.realizedMethod
                          ? details?.realizedMethod === 'Manual'
                            ? t('manual')
                            : details?.realizedMethod === 'QR'
                            ? t('qr')
                            : details?.realizedMethod === 'Card'
                            ? t('card')
                            : '--'
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('realizedByUserName')}:</h6>
                      <p>
                        {details?.realizedMethod === 'Card' ||
                        details?.realizedMethod === 'QR'
                          ? '--'
                          : details?.realizedUserName
                          ? details?.realizedUserName
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('realizedByDispenserName')}:</h6>
                      <p>
                        {details?.orderStatusId === 2 &&
                        (details?.realizedMethod === 'Card' ||
                          details?.realizedMethod === 'QR')
                          ? details?.kitchenDevice?.displayName
                            ? details?.kitchenDevice?.displayName
                            : '--'
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('realizedStatus')}:</h6>
                      <p>
                        {details?.point
                          ? t('pendingCollectedPoint')
                          : details?.orderStatusId === 2
                          ? t('realizedOrder')
                          : t('notRealized')}
                      </p>
                    </div>{' '}
                    {details?.point
                      ? ''
                      : details?.cancelledBy && (
                          <>
                            {' '}
                            <div className="user-details-block">
                              <h6>{t('cancelledBy')}:</h6>
                              <p>
                                {details?.cancelledBy
                                  ? details?.cancelledBy
                                  : '--'}
                              </p>
                            </div>
                            <div className="user-details-block">
                              <h6>{t('cancelledOrderTime')}:</h6>
                              <p>
                                {details?.cancelledDate
                                  ? moment(details?.cancelledDate).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  : '--'}
                              </p>
                            </div>
                          </>
                        )}
                    {details?.paymentOptionId === 4 ? (
                      details?.point ? (
                        <div className="user-details-block">
                          <h6>{t('isSalary')}:</h6>
                          <p>
                            {details?.status ? (
                              details?.reported ? (
                                <>
                                  {' '}
                                  <div className="order-status success">
                                    <span>{t('success')}</span>
                                    <span>
                                      (
                                      {moment(details?.updatedAt).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                      )
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <div className="order-status success">
                                  <span>{t('isAproved')}</span>
                                  <span>
                                    {moment(details?.updatedAt).format(
                                      'DD/MM/YYYY'
                                    )}
                                  </span>
                                </div>
                              )
                            ) : details?.orderStatusId !== 3 ? (
                              <div className="order-status pending">
                                {/* <i className="fa fa-circle" /> */}
                                <span>{t('pending')}</span>
                              </div>
                            ) : (
                              <div className="order-status failed">
                                {/* <i className="fa fa-circle" /> */}
                                <span>{t('cancelled')}</span>
                              </div>
                            )}
                          </p>
                        </div>
                      ) : (
                        <div className="user-details-block">
                          <h6>{t('isSalary')}:</h6>
                          <p>
                            {details?.reported &&
                            details?.orderStatusId !== 3 ? (
                              <div className="order-status success">
                                {/* <i className="fa fa-circle" /> */}
                                <span>{t('success')}</span>
                                <span>
                                  (
                                  {moment(details?.updatedAt).format(
                                    'DD/MM/YYYY HH:mm'
                                  )}
                                  )
                                </span>
                              </div>
                            ) : details?.orderStatusId !== 3 && isExp ? (
                              <div className="order-status success">
                                <span>{t('isAproved')}</span>
                                <span>
                                  {moment(details?.updatedAt).format(
                                    'DD/MM/YYYY'
                                  )}
                                </span>
                              </div>
                            ) : details?.orderStatusId !== 3 ? (
                              <div className="order-status pending">
                                {/* <i className="fa fa-circle" /> */}
                                <span>{t('pending')}</span>
                              </div>
                            ) : (
                              <div className="order-status failed">
                                {/* <i className="fa fa-circle" /> */}
                                <span>{t('canceled')}</span>
                              </div>
                            )}
                          </p>
                        </div>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {currentUser.roleId !== 9 &&
                currentUser.roleId !== 7 &&
                details?.point ? (
                  <div className="modal-footer-btn">
                    {!details?.reported && (
                      <button
                        className="edit-btn"
                        onClick={() =>
                          deleteCreditPoint({
                            userId: details?.userId,
                            mealTypeId: details?.mealType?.id,
                            point: details?.mealType?.creditPoints,
                            kitchenId: details?.mealType?.kitchenId,
                            mealId: details?.mealId,
                            date: details?.addDate,
                          })
                        }
                      >
                        <i class="las la-trash"></i>
                        {t('cancelOrderBtnLabel')}
                      </button>
                    )}
                    {reservationType === 'Credit points' &&
                      currentUser.roleId !== 3 &&
                      currentUser.roleId !== 7 &&
                      currentUser.roleId !== 9 &&
                      attendanceEnabled && (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            history.push(
                              `/attendance?user=${
                                details?.userId
                              }&fromDate=${moment(fromDate).format(
                                'YYYY-MM-DD'
                              )}&toDate=${moment(toDate).format('YYYY-MM-DD')}`
                            );
                          }}
                        >
                          <i class="las la-address-card"></i>
                          {t('attendance')}
                        </button>
                      )}
                  </div>
                ) : details?.orderStatusId === 2 ? (
                  <div className="modal-footer-btn">
                    {currentUser.roleId !== 9 && currentUser.roleId !== 7 && (
                      <button
                        className="edit-btn"
                        onClick={() => {
                          handleCancelOrder(details.id, details.userId);
                        }}
                      >
                        <i class="las la-trash"></i>
                        {t('cancelOrderBtnLabel')}
                      </button>
                    )}
                    {reservationType === 'Credit points' &&
                      currentUser.roleId !== 3 &&
                      currentUser.roleId !== 9 &&
                      (window.location.href.includes('localhost') ||
                        window.location.href.includes('vishay') ||
                        window.location.href.includes('develop-app')) && (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            history.push(
                              `/attendance?user=${
                                details?.userId
                              }&fromDate=${moment(fromDate).format(
                                'YYYY-MM-DD'
                              )}&toDate=${moment(toDate).format('YYYY-MM-DD')}`
                            );
                          }}
                        >
                          <i class="las la-address-card"></i>
                          {t('attendance')}
                        </button>
                      )}
                  </div>
                ) : details?.orderStatusId === 3 ? (
                  <div className="modal-footer-btn">
                    <p>{t('canceled')}</p>
                  </div>
                ) : currentUser.roleId !== 9 && currentUser?.roleId !== 7 ? (
                  <div className="modal-footer-btn">
                    {!details?.reported && (
                      <button
                        className="edit-btn"
                        onClick={() => {
                          handleOpen(
                            details.hash,
                            details.mealDish[0].mealDish.mealId,
                            details.id
                          );
                        }}
                      >
                        <i class="las la-hourglass-half"></i>
                        {t('realizeOrder')}
                      </button>
                    )}
                    {currentUser.roleId !== 9 && currentUser.roleId !== 7 && (
                      <button
                        className="edit-btn"
                        onClick={() => {
                          handleCancelOrder(details.id, details.userId);
                        }}
                      >
                        <i class="las la-trash"></i>
                        {t('cancelOrderBtnLabel')}
                      </button>
                    )}
                    {reservationType === 'Credit points' &&
                      currentUser.roleId !== 3 &&
                      currentUser.roleId !== 9 &&
                      (window.location.href.includes('localhost') ||
                        window.location.href.includes('vishay') ||
                        window.location.href.includes('develop-app')) && (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            history.push(
                              `/attendance?user=${
                                details?.userId
                              }&fromDate=${moment(fromDate).format(
                                'YYYY-MM-DD'
                              )}&toDate=${moment(toDate).format('YYYY-MM-DD')}`
                            );
                          }}
                        >
                          <i class="las la-address-card"></i>
                          {t('attendance')}
                        </button>
                      )}
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
            {reportType === 'TotalUser' && (
              <>
                <div className="user-detail-container">
                  <div className="user-details-row">
                    <div className="user-details-block">
                      <h6>{t('orderId')}:</h6>
                      <p>{details?.userId ? details?.userId : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('firstName')}:</h6>
                      <p>{details?.firstName ? details?.firstName : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('lastName')}:</h6>
                      <p>{details?.lastName ? details?.lastName : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('cardId')}:</h6>
                      <p>{details?.cardNumber ? details?.cardNumber : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('employeeId')}:</h6>
                      <p>
                        {details?.employeeNumber
                          ? details?.employeeNumber
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('userType')}:</h6>
                      <p>{details?.userType ? details?.userType : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('contractorName')}:</h6>
                      <p>
                        {details?.order?.user.contractors
                          .map((elem) => elem.name)
                          .join(', ')
                          ? details?.order?.user.contractors
                              .map((elem) => elem.name)
                              .join(', ')
                          : '--'}
                      </p>
                    </div>
                    {filterMealType?.map((el) => (
                      <>
                        <div className="user-details-block">
                          <h6>
                            {el?.display} {t('reservations')}:
                          </h6>
                          <p>
                            {
                              details?.[
                                el?.value?.split(' ').join('') + 'Reservation'
                              ]
                            }
                          </p>
                        </div>
                        <div className="user-details-block">
                          <h6>
                            {el?.display} {t('realized')}:
                          </h6>
                          <p>
                            {
                              details?.[
                                el?.value?.split(' ').join('') + 'Realized'
                              ]
                            }
                          </p>
                        </div>
                      </>
                    ))}
                    <div className="user-details-block">
                      <h6>{t('totalReservation')}:</h6>
                      <p>
                        {details?.totalReservation
                          ? details?.totalReservation
                          : '0'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('totalRealized')}:</h6>
                      <p>
                        {details?.totalRealized ? details?.totalRealized : '0'}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {reportType === 'MealType' && (
              <>
                <div className="user-detail-container">
                  <div className="user-details-row">
                    <div className="user-details-block">
                      <h6>{t('mealTypeName')}:</h6>
                      <p>{details?.display ? details?.display : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('totalOfInventory')}:</h6>
                      <p>{details?.inventory ? details?.inventory : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('ofreservations')}:</h6>
                      <p>{details?.ordered ? details?.ordered : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('ofRealised')}:</h6>
                      <p>{details?.realized ? details?.realized : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('perceReservedInventory')}:</h6>
                      <p>
                        {details?.reservedInventory
                          ? details?.reservedInventory
                          : 0}
                        %
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('ofRealisedInventory')}:</h6>
                      <p>
                        {details?.realizedInventory
                          ? details?.realizedInventory
                          : 0}
                        %
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('ofRealizedReserved')}:</h6>
                      <p>
                        {details?.realizedReserved
                          ? details?.realizedReserved
                          : 0}
                        %
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('totalCreditPointRequest')}:</h6>
                      <p>{details?.creditPoints}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('pendingCreditPointRequest')}:</h6>
                      <p>{details?.pending ? details?.pending : 0}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('approvedCreditPointReqquest')}:</h6>
                      <p>{details?.approval ? details?.approval : 0}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('creditPointPercentage')}:</h6>
                      <p>{details?.percent ? details?.percent : 0}%</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <ConfirmationPopup
              open={viewData}
              jsonData={jsonData}
              handleClose={handleViewData}
              t={t}
              prefetchData={reserData}
            />
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

const ConfirmationPopup = ({
  open,
  handleClose,
  prefetchData,
  jsonData,
  t,
}) => {
  let jsondataValue = {};
  if (!prefetchData) {
    prefetchData = [];
  }

  jsondataValue = prefetchData?.filter((el) => el.id === jsonData);
  const formatter =
    jsondataValue && jsondataValue[0]
      ? new JSONFormatter(JSON.parse(jsondataValue[0]?.chargeResponse || '{}'))
      : '';
  let element = document.getElementById('json');

  if (element && formatter !== '') {
    element.innerHTML = '';
    element.appendChild(formatter.render());
  }

  return (
    // <Modal
    //   open={open}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    //   maxWidth="lg"
    //   className="add-employee-popup delete-popup log-json-data"
    // >
    //   <Box sx={style}>
    <>
      {open ? <div className="bg-shadow" /> : ''}
      <div
        className={`add-employee-popup delete-popup log-json-data reservation-popup ${
          open ? 'open-json-popup' : ''
        }`}
      >
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <h6>{t('updatedData')}</h6>
        <div id="modal-modal-description">
          <div className="copy-url">
            <div className="confirmation-btn" id="json"></div>
            <button className="copy-btn" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </>
    //   </Box>
    // </Modal>
  );
};

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
  currentUser: state.user,
});
const mapDispatchToProps = {
  useFetchingLoader,
};
// export default ReservationManagement;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewReservationDetails);
