import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { getUser } from '../../store/user/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from '../../services/http';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import { isTabletOrMobile } from '../../services/mediaQueries';
import {
  Alert,
  Dialog,
  SuccessDailog,
} from '../../components/Dialogs/SwalDialogs';
import Employee from './Employee';
import EmployeeToAdd from './EmployeeToAdd';
import Select from 'react-select';
import useDebounce from '../../hooks/UseDebounce';
import WEIcon from '../../components/WE-IconButton';
import { Button } from '@material-ui/core';
import { ActiveButton } from '../../components/WE-Button';
import AddGuestUserPopup from './AddGuestUser';
import {
  validateEmployeenew,
  validateGuestUsersNew,
} from '../../services/validators';
//import CopyUrlPopup from './copyUrl';
import CopyGuestUrlPopup from './copyGuestUrl';
import ConfirmationPopup from './confirmation';
import Tippy from '@tippyjs/react';
import ViewGuestDetails from './viewGuestDetails';
import more from '../../assets/images/more.svg';
import next from '../../assets/images/next.png';
import adduser from '../../assets/images/add-user.svg';
import search from '../../assets/images/Search.svg';
import file from '../../assets/images/file-export.svg';
import moment from 'moment';

const initialiState = {
  userId: null,
  // cardId: Math.floor(Math.random() * 5000 + 1),
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: 8,
  hireFrom: '',
  hireTill: '',
  mealTypeId: [],
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'created',
  contractor: '',
  contractorsIds: [],
};

const ContractorEmployee = ({
  user,
  kitchen,
  setLayoutOptions,
  UserStatuses,
  Kitchens,
  Roles,
  useLoader,
  useFetchingLoader,
}) => {
  const { t } = useTranslation();
  const [kitchensIds, setKitchensIds] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [payload, setPayload] = useState(initialiState);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [contractorList, setContractorList] = useState();
  const [getMealTypes, setGetMealTypes] = useState();
  const [getMealTypesKitchenWise, setMealTypesKitchenWise] = useState();
  const [errors, setErrors] = useState({});
  const [mealTypesIds, setMealTypesIds] = useState([]);
  const [currentUserKitchens, setCurrentUserKitchesList] = useState([]);

  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [allUsers, setAllusers] = useState();
  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      setPayload({ initialiState, allContractors: user?.contractors });
      setIsUpdate(false);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setKitchensIds([]);
    setMealTypesKitchenWise([]);
    setPayload(initialiState);
    setErrors({});
  };
  const Limit = 2;
  const UserStatusesMap_Status = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status };
  }, {});
  const UserStatusesMap_Id = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});

  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(async () => {
    let userKitchen = Kitchens.filter((el) => user?.KitchenId?.includes(el.id));
    setCurrentUserKitchesList(userKitchen);
  }, []);
  useEffect(async () => {
    const getMealType = await http.get(`mealTypes`);
    // const mealTypeList = getMealType?.data.filter(
    //   (el) => el?.kitchenId === kitchen.id
    // );
    setGetMealTypes(getMealType?.data);

    const contractorData = await http.get(`contractors/getAllGuestUsers`);

    setContractorList(contractorData.data);
    let filter = {
      roleId: [RolesMap.guestUser],
      //companyId: user.companyId,
      contractorId: user?.contractors.map((el) => el.id),
      userStatusId: { ne: UserStatusesMap.deleted },
    };
    const { data, total } = await useFetchingLoader(
      async () => await http.get(`users/allGuestUsers`, { filter })
    );
    setAllusers(data);
    setPrefetchData(data);
    let serverUsers = data.map((u, i) => ({
      userId: u?.id,
      'Employee name': [u?.firstName, u?.lastName].join(' '),
      firstName: u?.firstName,
      lastName: u?.lastName,
      employeeId: u?.employeeId,
      Status: UserStatuses.find((el) => el.id === u.userStatusId).display,
      'Registration Date': u.createdAt
        ? u.createdAt.split('T')[0]
        : undefined /* Format : YYYY-MM-DD */,
      Kitchens: u?.kitchens.map((k) => k.name),
      Contractors: u?.contractors ? u?.contractors : '--',
      Email: u?.email,
      Action: i,
      phone: u?.phone,
      creditCard: u?.creditCard,
      hireFrom: u?.hireFrom,
      hireTill: u?.hireTill,
      kitchenId: u?.kitchenId,
      mealTypeId: u.guestUser?.mealTypeId,
      generatedLink: u.guestUser?.generatedLink,
      isUsed: u.guestUser?.isUsed,
    }));
    setUsersToDisplay(serverUsers);

    // const mealTypedata = await useFetchingLoader(
    //   async () => await http.get(`getMonthly?kitchenId=${kitchen.id}`)
    // );
    // console.log('mealTypedata===>', mealTypedata);
    //http://localhost:4000/meals/getMonthly?lang=en&kitchenId=2
  }, [dummy]);
  useEffect(() => {}, []);
  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  // ag grid data
  const columnDefs = [
    {
      field: 'Employee name',
      headerName: t('employeeName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'hireFrom',
      headerName: t('startDate'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'hireTill',
      headerName: t('endDate'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'Status',
      headerName: t('status'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'Registration Date',
      headerName: t('registrationDate'),
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressMovable: true,
    },
    {
      field: 'Kitchens',
      headerName: t('kitchenname'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'Email',
      headerName: t('email'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'Contractors',
      headerName: t('contractors'),
      cellRenderer: (params) => {
        return params?.data?.Contractors
          ? params?.data?.Contractors.map((el) => el.name).join(', ')
          : '--';
      },
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'phone',
      headerName: t('phone'),
      cellRenderer: 'Phone',
      sortable: true,
      filter: true,
      suppressMovable: true,
    },

    // {
    //   // field: 'Action',
    //   headerName: t('action'),
    //   cellRenderer: 'PopupCellRenderer',
    //   headerClass: 'resizable-header',
    //   width: 100,
    //   suppressMovable: true,
    // },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  // Tab view
  const columnDefsTab = [
    {
      field: 'Employee name',
      headerName: t('employeeName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'hireFrom',
      headerName: t('startDate'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'Contractors',
      headerName: t('contractors'),
      cellRenderer: (params) => {
        return params?.data?.Contractors
          ? params?.data?.Contractors.map((el) => el.name).join(', ')
          : '--';
      },
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'hireTill',
      headerName: t('endDate'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'Employee name',
      headerName: t('employeeName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: 'hireFrom',
      headerName: t('startDate'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      //pinned: 'left',
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 80,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };
  /******* add New employee */
  const handleAddNewUser = async (userData) => {
    //setDummy(false);
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];
    try {
      //  let userToAdd = userData;
      let userToAdd;
      userData.employeeId =
        userData.employeeId && userData.employeeId !== ''
          ? userData.employeeId
          : null;
      userData.cardId =
        userData.cardId && userData.cardId !== '' ? userData.cardId : null;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`users/createGuestUser`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      // handleClose();
      // setToggleAddUser(false);
      Dialog({
        title: t('addedmessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setDummy(!dummy);
          // window.location.reload();
        },
      });
      //setDummy(false);
      handleClose();
    } catch (err) {
      Alert({ error: err });
    }
  };

  /******** update employee details */
  const handleUpdateUser = async (event) => {
    event.preventDefault();
    try {
      let details = {
        firstName: payload?.fName,
        lastName: payload?.lName,
        email: payload?.email ? payload?.email : null,
        phone: payload?.phone,
        kitchensIds: kitchensIds,
        password: payload.password === '' ? undefined : payload.password,
        roleId: RolesMap?.guestUser,
        contractorsIds: payload.contractorsIds ? payload?.contractorsIds : [],
        contractors: payload?.contractors,
        hireFrom: payload?.hireFrom,
        hireTill: payload?.hireTill,
        mealTypeId: payload?.mealTypesIds,
        magneticStripe: payload.magneticStripe,
        //mealTypeId: mealTypesIds,
      };
      //const { isValid, errors } = validateEmployeenew(details);
      const { isValid, errors } = validateGuestUsersNew(details);
      setErrors({ ...errors });
      if (!isValid) {
        return;
      }

      //setDummy(false);
      const userId = payload.userId;

      const response = await useFetchingLoader(
        async () =>
          await http.put(`users/updateGuestUser/${userId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      if (response) {
        Dialog({
          title: t('updatedMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(!dummy);
            setViewDetails(false);
            setMealTypesKitchenWise([]);
            setPayload(initialiState);
            setKitchensIds([]);
            handleClose();
            // window.location.reload();
          },
        });
        setKitchensIds([]);
      }
    } catch (err) {
      Alert({ error: err });
    }
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const onBtnExport = () => {
    gridApi.exportDataAsCsv({
      processCellCallback: function (cell) {
        let cellVal = cell.value;
        if (cell.column.colId === 'Contractors') {
          cellVal = cell.value.map((val) => val.name);
        }
        return cellVal;
      },
    });
  };
  const ResendVerification = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/resetPassword`, {
          userData: { email: el.Email, resendLink: true },
          companyId: localStorage.getItem('companyId'),
        })
    );
    if (res?.sent) {
      Dialog({
        title: t('resendMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          //setDummy(true);
        },
      });
    }
  };

  const handleDeleteUser = async () => {
    try {
      let details = {
        userStatusId: 5,
      };
      const response = await http.put(`users/${deleteId}`, { ...details });
      setConfirmation(false);
      if (response) {
        Dialog({
          title: t('deleteMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(true);
          },
        });
      }
      setDummy(false);
      setViewDetails(false);
      setDeleteId('');
    } catch (err) {
      Alert({ error: err });
    }
  };

  const CopyDataUrl = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/guestLinkCopy`, {
          userData: { userId: el?.userId },
        })
    );
    setResendData(res);

    setCopyData(true);
  };
  const handleCloseUrl = () => {
    setCopyData(false);
    setCopied(false);
  };
  const handleConfirmation = (userId) => {
    setConfirmation(!confirmation);
    setDeleteId(userId);
  };
  const lang = localStorage.getItem('language');
  return (
    <div className="user-management-table global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-input">
            <img src={search} alt="search" />
            <input
              type="text"
              id="filter-text-box"
              placeholder={`${t('freeTextSearch')}`}
              onInput={onFilterTextBoxChanged}
            />
          </div>
          {/* <div className="active-button">
          <button onClick={() => onBtnExport()} className="download-csv">
            {t('downloadCSV')}
          </button>
          <div onClick={() => handleOpen('add')}>
            <ActiveButton label={t('add')} arrow={true} />
          </div>
        </div> */}

          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{usersToDisplay?.length}</span>
              </p>
            </div>
            {usersToDisplay?.length !== undefined &&
              usersToDisplay?.length !== null && (
                <div className="actions">
                  <img onClick={() => onBtnExport()} src={file} alt="add" />

                  <img
                    src={adduser}
                    alt="add"
                    onClick={() => handleOpen('add')}
                  />
                </div>
              )}
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            columnDefs={tableColumn}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            frameworkComponents={{
              PopupCellRenderer: (e) => (
                <PopupCellRenderer
                  handleOpen={handleOpen}
                  e={e}
                  setUpdate={setUpdate}
                  setPayload={setPayload}
                  setIsEdit={setIsEdit}
                  t={t}
                  kitchensIds={kitchensIds}
                  allUsers={allUsers}
                  setKitchensIds={setKitchensIds}
                  Kitchens={Kitchens}
                  ResendVerification={ResendVerification}
                  handleDeleteUser={handleDeleteUser}
                  CopyDataUrl={CopyDataUrl}
                  handleConfirmation={handleConfirmation}
                  UserStatuses={UserStatuses}
                  contractorList={contractorList}
                  setMealTypesIds={setMealTypesIds}
                  handleViewMore={handleViewMore}
                />
              ),
              Phone: (e) => <Phone e={e} />,
              ViewMore: (e) => (
                <ViewMore
                  e={e}
                  allContractors={user?.contractors}
                  handleViewMore={handleViewMore}
                />
              ),
              CreditCard: (e) => <CreditCard e={e} />,
            }}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            debug={false}
            overlayLoadingTemplate={'<p></p>'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            pagination={false}
            paginationPageSize={10}
            paginationNumberFormatter={function (params) {
              return '[' + params.value.toLocaleString() + ']';
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
          ></AgGridReact>
        </div>
        {/* <div className="pagination-header">
        {t('pageSize')}:
        <select onChange={() => onPageSizeChanged()} id="page-size">
          <option value="10" selected={true}>
            10
          </option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> */}
        <AddGuestUserPopup
          open={open}
          handleClose={handleClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          // userData={{ name: '' }}
          userData={prefetchData}
          newUserFlag={true}
          handleAddNewUser={handleAddNewUser}
          isEdit={true}
          payload={payload}
          setPayload={setPayload}
          handleUpdateUser={handleUpdateUser}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          t={t}
          errors={errors}
          setErrors={setErrors}
          user={user}
          contractorList={contractorList}
          kitchen={kitchen}
          getMealTypes={getMealTypes}
          mealTypesIds={mealTypesIds}
          setMealTypesIds={setMealTypesIds}
          setGetMealTypes={setGetMealTypes}
          currentUserKitchens={currentUserKitchens}
          setMealTypesKitchenWise={setMealTypesKitchenWise}
          getMealTypesKitchenWise={getMealTypesKitchenWise}
        />
        <CopyGuestUrlPopup
          open={copyData}
          handleClose={CopyDataUrl}
          resendData={resendData}
          handleCloseUrl={handleCloseUrl}
          copied={copied}
          setCopied={setCopied}
        />
        <ConfirmationPopup
          open={confirmation}
          handleClose={() => setConfirmation(false)}
          handleDeleteUser={handleDeleteUser}
        />
        <ViewGuestDetails
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
          CopyDataUrl={CopyDataUrl}
          ResendVerification={ResendVerification}
          handleConfirmation={handleConfirmation}
          handleOpen={handleOpen}
          setPayload={setPayload}
          allUsers={allUsers}
          UserStatuses={UserStatuses}
          setKitchensIds={setKitchensIds}
          Kitchens={Kitchens}
          Roles={Roles}
          setMealTypesIds={setMealTypesIds}
          setMealTypesKitchenWise={setMealTypesKitchenWise}
          getMealTypesKitchenWise={getMealTypesKitchenWise}
          setGetMealTypes={setGetMealTypes}
          getMealTypes={getMealTypes}
          //handleWallet={handleWallet}
          //handleOrderLink={handleOrderLink}
        />
      </div>
    </div>
  );
};

const ActionButton = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  allUsers,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  ResendVerification,
  handleDeleteUser,
  CopyDataUrl,
  handleConfirmation,
  UserStatuses,
}) => {
  setUpdate(e.data);
  setIsEdit(true);

  return (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
    >
      <button
        onClick={() => {
          // e.data['Kitchens'].length
          //   ? setKitchensIds(
          //       Kitchens.map((el) => {
          //         if (e.data['Kitchens'].includes(el.name)) return el.id;
          //       })
          //     )
          //   : setKitchensIds([]);
          let ids = [];
          if (e.data['Kitchens'].length) {
            Kitchens.forEach((el) => {
              if (e.data['Kitchens'].includes(el.name)) {
                ids.push(el.id);
              }
            });
          }
          const kitchen =
            allUsers
              ?.find((el) => el.id === details['userId'])
              ?.kitchens?.map((el) => el.id) || ids;
          setKitchensIds(kitchen);
          setPayload({
            userId: e.data['userId'],
            card: e.data['Card Number'],
            email: e.data['Email'],
            fName: e.data['firstName'],
            lName: e.data['lastName'],
            phone: e.data['phone']
              ? e.data['phone'].split('-').join('')
              : e.data['phone'],
            // kitchensIds: e.data['Kitchens'],
            kitchensIds: kitchen,
            contractors: e.data['Contractors'],
            employeeId: e.data['employeeId'],
            password: e.data['password'],
            status: e.data['Status'],
            hireFrom: e.data['hireFrom'],
            hireTill: e.data['hireTill'],
            mealTypeId: e.data['mealTypeId'],
            contractorsIds: [],
            // contractor: e.data['Contractor'],
          });

          handleOpen();
        }}
        className="edit-btn"
      >
        {t('editbutton')}
      </button>
      <button
        className="delete-btn"
        // onClick={() => handleDeleteUser(e.data['userId'])}
        onClick={() => handleConfirmation(e.data['userId'])}
      >
        {t('deletebutton')}
      </button>
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button
          className="resend-btn"
          onClick={() => ResendVerification(e.data)}
        >
          Resend
        </button>
      ) : (
        ''
      )}
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button className="copy-btn" onClick={() => CopyDataUrl(e.data)}>
          Copy URL
        </button>
      ) : (
        ''
      )}

      <div className="menu-item">
        <div
          // onClick={() => handleDeleteUser(e.data['userId'])}
          onClick={handleViewMore}
        >
          {t('viewMore')}
        </div>
      </div>
    </div>
  );
};

const Phone = ({ e }) => {
  return (
    <div className="view-more-btn">
      <p>{e?.data?.phone ? e?.data?.phone : '--'}</p>
    </div>
  );
};
const ViewMore = ({ e, handleViewMore, allContractors, contractors }) => {
  return (
    <div className="view-more-btn">
      <img
        src={next}
        alt="next"
        onClick={() =>
          handleViewMore({ ...e.data, allContractors, contractors })
        }
      />
    </div>
  );
};

const CreditCard = ({ e }) => {
  return (
    <div className={`credit-card-option`}>
      <input type="checkbox" checked={e?.data?.creditCard} />
    </div>
  );
};
const ContractorCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>
        {Array.isArray(e?.data.Contractors) && e?.data.Contractors?.length > 0
          ? e?.data?.Contractors.map((el) => el.name).join(', ')
          : '--'}
      </p>
    </div>
  );
};
const PopupCellRenderer = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  allUsers,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
  contractorList,
  setMealTypesIds,
  handleViewMore,
}) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const handleClick = (data) => {
    // console.log('ddddddd =>', e);
  };

  const dropDownContent = (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
      style={{ position: 'relative' }}
    >
      <div className="menu-container">
        <div className="menu-item">
          <div
            onClick={() => {
              // e.data['Kitchens'].length
              //   ? setKitchensIds(
              //       Kitchens.map((el) => {
              //         if (e.data['Kitchens'].includes(el.name)) return el.id;
              //       })
              //     )
              //   : setKitchensIds([]);
              let ids = [];
              if (e.data['Kitchens'].length) {
                Kitchens.forEach((el) => {
                  if (e.data['Kitchens'].includes(el.name)) {
                    ids.push(el.id);
                  }
                });
              }
              const kitchen =
                allUsers
                  ?.find((el) => el.id === details['userId'])
                  ?.kitchens?.map((el) => el.id) || ids;
              setKitchensIds(kitchen);
              setPayload({
                userId: e.data['userId'],
                card: e.data['Card Number'],
                email: e.data['Email'],
                fName: e.data['firstName'],
                lName: e.data['lastName'],
                phone: e.data['phone']
                  ? e.data['phone'].split('-').join('')
                  : e.data['phone'],
                // kitchensIds: e.data['Kitchens'],
                kitchensIds: kitchen,
                contractors: e.data['Contractors'],
                employeeId: e.data['employeeId'],
                password: e.data['password'],
                status: e.data['Status'],
                hireFrom: e.data['hireFrom'],
                hireTill: e.data['hireTill'],
                kitchenId: e.data['kitchenId'],
                mealTypeId: JSON.parse(e.data['mealTypeId']),
                // contractor: e.data['Contractor'],
                contractorsIds: [],
              });

              let mealTypeIdList = JSON.parse(e.data['mealTypeId']);
              let array = [];
              if (mealTypeIdList.length) {
                let aa = mealTypeIdList.map((el) => array.push(el));
              }

              setMealTypesIds(array);

              handleOpen();
            }}
            // className="edit-btn"
          >
            {t('editbutton')}
          </div>
        </div>
        <div className="menu-item">
          <div
            // className="delete-btn"
            // onClick={() => handleDeleteUser(e.data['userId'])}
            onClick={() => handleConfirmation(e.data['userId'])}
          >
            {t('deletebutton')}
          </div>
        </div>

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div
              // className="resend-btn"
              onClick={() => ResendVerification(e.data)}
            >
              Resend
            </div>
          </div>
        ) : (
          ''
        )}

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div onClick={() => CopyDataUrl(e.data)}>Copy URL</div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      });
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] });
    }

    if (option === 'edit') {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: 'make',
      });
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <i
        className="fa fa-ellipsis-v"
        aria-hidden="true"
        // onClick={() => handleDropdownClick(e.data?.userId)}
        onClick={visible ? hide : show}
      ></i>
    </Tippy>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractorEmployee);
