import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setEnums, setLayoutOptions, showAlert } from '../store/app/actions';
import { register } from '../store/user/actions';
import { getKitchens } from '../store/kitchens/actions';
import { useHistory, useParams } from 'react-router-dom';
import SignupForm from '../components/Forms/SignupForm';
import * as SwalDialogs from '../components/Dialogs/SwalDialogs';
import { Alert } from '../components/Dialogs/SwalDialogs';
import LanguageSelection from '../components/Forms/LanguageSelection';
import enums from '../services/enums';
import { setLanguage } from '../store/languages/actions';
import GuestScreen from './Guests/GuestScreen';

// @ts-ignore
const Signup = ({
  user,
  setLayoutOptions,
  register,
  showAlert,
  getKitchens,
}) => {
  const { t } = useTranslation();
  setLayoutOptions({ title: t('signup'), showKitchen: false });
  const history = useHistory();

  const handleSubmit = async (form) => {
    try {
      let user = await register(form);
      await getKitchens(user.companyId);
      history.push('/lobby');

      await showAlert({
        content: t('profileUpdated'),
        onClose: () => {},
      });
    } catch (err) {
      Alert({ error: err });
    }
  };

  return (
    <GuestScreen
      content={
        <div className="signup">
          <SignupForm
            onSubmit={handleSubmit}
            initialValues={{}}
            isSignup={true}
          />
        </div>
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setLayoutOptions,
  register,
  showAlert,
  getKitchens,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
