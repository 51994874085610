import io from 'socket.io-client';
import AppConfig from '../constants/Config';
console.log('server in socket ', AppConfig.server.url);
const socket = io(AppConfig.server.url, {
  transports: ['websocket'],
  rejectUnauthorized: false,
  query: `token=${JSON.parse(localStorage.getItem('token'))}`,
  //  reconnection: false,
});
socket.on('connect', () => {
  console.log('connected');
});

socket.emit('join', localStorage.getItem('userId'));
socket.on('disconnect', () => {
  console.log('Disconnected');
});

socket.on('latestVersion', (data) => {
  if (data.version !== localStorage.getItem('version')) {
    localStorage.setItem('version', data.version);
    window.location.reload();
  }
});

const tryReconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      }
    });
  }, 2000);
};

socket.io.on('close', tryReconnect);

export { socket };
