import React, { useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '860px',
  width: '100%',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const getInputField = ({ language, value, placeholder, cb, type = 'text' }) => {
  return (
    <div className="input-field">
      <strong className={'label'}>{`${language.displayName}`}</strong>
      <input
        className={`${type}`}
        name={`${language.name}_${placeholder}`}
        placeholder={`${language.displayName}`}
        value={value}
        onChange={(event) => cb(event.target.value)}
        required
        type={type}
      />
    </div>
  );
};

const AddKitchen = ({
  open,
  handleClose,
  Languages,
  translations,
  setTranslations,
  t,
  handleFormSubmit,
  setTerminalNumber,
  terminalNumber,
  editMode,
}) => {
  const handleChange = (e) => {
    setTerminalNumber(e.target.value);
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup add-kitchen-popup"
    >
      <Box sx={style}>
        <div className="close-btn">
          <h6>{editMode ? t('editKitchen') : t('addKitchen')} </h6>
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>

        <div className="item">
          {Languages.map((lang) =>
            getInputField({
              value: (translations && translations[lang.id]?.name) || '',
              language: lang,
              placeholder: 'name',
              name: `name_${lang.id}`,
              cb: (value) =>
                setTranslations((state) => ({
                  ...state,
                  [lang.id]: { languageId: lang.id, name: value },
                })),
            })
          )}
          <div className="input-field">
            <strong className={'label'}>{t('terminalNumber')}</strong>
            <input
              name={`terminalNumber`}
              type="text"
              onChange={(e) => handleChange(e)}
              value={terminalNumber}
              placeholder={t('terminalNumber')}
            />
          </div>
        </div>
        <div className="add-employee-btn">
          <div>
            <button arrow={false} onClick={handleFormSubmit}>
              {editMode ? t('update') : t('add')}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default AddKitchen;
