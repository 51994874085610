import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
  getAllLogs,
} from '../store/app/actions';
import { connect } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../services/http';
import { isTabletOrMobile } from '../services/mediaQueries';
import { sleep } from '../services/utils';
import { Alert, Dialog } from '../components/Dialogs/SwalDialogs';
import { ActiveButton } from '../components/WE-Button';
import cancel from '../assets/images/close.svg';
import moment from 'moment';
import JSONFormatter from 'json-formatter-js';
import search from '../assets/icons/search.png';
import file from '../assets/images/file-export.svg';
import { Button } from 'react-bootstrap';
import more from '../assets/images/more.svg';
import next from '../assets/images/next.png';
import agGridLocales from '../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  maxWidth: '500px',
  wordBreak: 'break-all',
  borderRadius: '5px',
  height: 'auto',
};

const LogViewer = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  languages,
  currentLanguage,
  getAllLogs,
  alllogsToDisplay,
  logsTotal,
  showFetchingLoader,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [logsToDisplay, setLogsToDisplay] = useState(null);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [viewData, setViewData] = useState();
  const [prefetchData, setPrefetchData] = useState(null);
  const [jsonData, setJsondata] = useState();
  // const [fromDate, setFromdate] = useState(
  //   new Date().setDate(new Date().getDate() - 2)
  // );
  const [fromDate, setFromdate] = useState(
    new Date().setDate(new Date().getDate() - 6)
  );
  // const [toDate, setToDate] = useState(
  //   new Date().setDate(new Date().getDate() - 1)
  // );
  const [toDate, setToDate] = useState(
    new Date().setDate(new Date().getDate())
  );
  const [maxDates, setMaxDate] = useState(false);
  const [columns, setcolumns] = useState();
  const [details, setDetails] = useState();
  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs, setPageSizeLogs] = useState(25); // Records per page
  const [totalLogsRecords, setTotalLogsRecords] = useState(0); // Total records
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [searchText, setsearchText] = useState(''); // Search text

  // useEffect(() => {
  //   if (localStorage.getItem('recordsPerPage') !== null) {
  //     setPageSizeLogs(localStorage.getItem('recordsPerPage'));
  //   }
  // }, []);
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});

  /***********fetch log   data */
  const fetchDataFromApi = async () => {
    let filterData = {
      companyId: user.companyId,
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      toDate: moment(toDate).format('YYYY-MM-DD'),
      keyword: searchText,
      page: currentPageAllLogs,
      limit: newPageSizeLogs,
    };
    await useFetchingLoader(async () => {
      await getAllLogs(filterData);
    });
  };
  useEffect(() => {
    fetchDataFromApi();
  }, [currentPageAllLogs, newPageSizeLogs, searchText]);
  useEffect(async () => {
    // const { data } = await useFetchingLoader(
    //   async () =>
    //     await http.get(`activityLogs/getAll`, {
    //       companyId: user.companyId,
    //       fromDate: moment(fromDate).format('YYYY-MM-DD'),
    //       toDate: moment(toDate).format('YYYY-MM-DD'),
    //     })
    // );
    setPrefetchData(alllogsToDisplay);
    let logsData = alllogsToDisplay
      ? alllogsToDisplay.map((u, i) => ({
          id: u.id,
          Role: Roles.find((el) => el.id === u['userroleId'])?.display,
          Name: u['userfirstName'] + ' ' + u['userlastName'],
          Email: u['useremail'],
          description: u?.message,
          date: new Date(u.createdAt).toISOString().slice(0, 10),
          time: moment(u.createdAt).format('hh : mm A'),
          action: u.action,
          controller: u.controller,
          tableName: u.tableName,
          Action: i,
          firstName: u['userfirstName'],
          lastName: u['userlastName'],
        }))
      : [];

    setLogsToDisplay(logsData);
  }, [alllogsToDisplay]);

  useEffect(async () => {
    if (alllogsToDisplay) {
      setTotalLogsRecords(alllogsToDisplay);
      setpageCountsLogs(Math.ceil(logsTotal / newPageSizeLogs));
    }
  }, [alllogsToDisplay]);
  /************global filter funcitionm */
  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    //gridApi.setQuickFilter(value);
    setcurrentPageAllLogs(1);
    setsearchText(value);
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };
  const handleRecordCountChange = (elem) => {
    setcurrentPageAllLogs(1);
    setPageSizeLogs(elem?.target?.value);
    localStorage.setItem('recordsPerPage', elem?.target?.value);
  };

  const createSelectItems = () => {
    let items = [];
    let pageNums = [25, 50, 100, 200, 500];
    for (let j = 0; j < pageNums.length; j++) {
      items.push(
        <option key={pageNums[j]} value={pageNums[j]}>
          {pageNums[j]}
        </option>
      );
    }
    return items;
  };
  // ag grid data
  const columnDefs = [
    {
      // field: 'Name',
      headerName: `${t('userName')}, ${t('role')}`,
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params?.data?.Name}`
          ? `${params?.data?.Name} <br /> <b>${params?.data?.Role}</b>`
          : '--';
      },
    },
    {
      field: 'firstName',
      headerName: t('email'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      hide: true,
      cellRenderer: (params) => {
        return `${params.data.firstName}` ? `${params.data.firstName}` : '--';
      },
    },
    {
      field: 'lastName',
      headerName: t('email'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      hide: true,
      cellRenderer: (params) => {
        return `${params.data.lastName}` ? `${params.data.lastName}` : '--';
      },
    },
    {
      field: 'description',
      headerName: t('description'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.description}`
          ? `${params.data.description}`
          : '--';
      },
    },

    {
      field: 'time',
      headerName: `${t('time')}, ${t('date')}`,
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params?.data?.date}`
          ? `${params?.data?.date} <br /> <b>${params?.data?.time}</b>`
          : '--';
      },
    },
    {
      field: 'action',
      headerName: t('statusPH'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params?.data?.action}` ? `${params?.data?.action}` : '--';
      },
    },
    {
      field: 'controller',
      headerName: t('controller'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params?.data?.controller}`
          ? `${params?.data?.controller}`
          : '--';
      },
    },
    {
      field: 'tableName',
      headerName: t('tableName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params?.data?.tableName}`
          ? `${params?.data?.tableName}`
          : '--';
      },
    },
    {
      field: 'ActionButton',
      headerName: t('action'),
      cellRenderer: 'ActionButton',
      headerClass: 'resizable-header',
      width: 300,
      suppressMovable: true,
    },
  ];

  const tabcolumnDefs = [
    {
      // field: 'Name',
      headerName: `${t('userName')}, ${t('role')}`,
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params?.data?.Name}`
          ? `${params?.data?.Name} <br /> <b>${params?.data?.Role}</b>`
          : '--';
      },
    },

    {
      field: 'description',
      headerName: t('description'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.description}`
          ? `${params.data.description}`
          : '--';
      },
    },

    {
      field: 'time',
      headerName: `${t('time')}, ${t('date')}`,
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params?.data?.date}`
          ? `${params?.data?.date} <br /> <b>${params?.data?.time}</b>`
          : '--';
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.action}` ? `${params.data.action}` : '--';
      },
    },
    {
      field: 'controller',
      headerName: t('controller'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.controller}` ? `${params.data.controller}` : '--';
      },
    },
    {
      field: 'tableName',
      headerName: t('tableName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.tableName}` ? `${params.data.tableName}` : '--';
      },
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
      flex: 1,
    },
  ];

  const mobcolumnDefs = [
    {
      // field: 'Name',
      headerName: `${t('userName')}, ${t('role')}`,
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params?.data?.Name} <br /> <b>${params?.data?.Role}</b>`;
      },
      width: 150,
    },
    {
      field: 'ActionButton',
      headerName: t('More'),
      cellRenderer: 'ActionButton',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
      flex: 1,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  const handleDate = async (name, value) => {
    if (name === 'fromdate') {
      if (!value) {
        return setFromdate(null);
      }
      let maxDate = new Date().setDate(new Date(value).getDate());
      setMaxDate(moment(maxDate).format('YYYY-MM-DD'));
      setFromdate(new Date(value));
    } else if (name === 'todate') {
      if (!value) {
        return setToDate(null);
      }
      const tempToDate = new Date(value);
      setToDate(tempToDate);
    }
  };

  const handleApplyfilter = async (fromDate, toDate) => {
    // const { data } = await useFetchingLoader(
    //   async () =>
    //     await http.get(`activityLogs/getAll`, {
    //       companyId: user.companyId,
    //       fromDate: moment(fromDate).format('YYYY-MM-DD'),
    //       toDate: moment(toDate).format('YYYY-MM-DD'),
    //     })
    // );
    fetchDataFromApi();
    setPrefetchData(alllogsToDisplay);
    let logsFilterData =
      alllogsToDisplay &&
      alllogsToDisplay.map((u, i) => ({
        id: u.id,
        Role: Roles.find((el) => el.id === u['userroleId'])?.display,
        Name: u['userfirstName'] + ' ' + u['userlastName'],
        Email: u['useremail'],
        description: u?.message,
        date: new Date(u.createdAt).toISOString().slice(0, 10),
        time: moment(u.createdAt).format('hh : mm A'),
        action: u.action,
        controller: u.controller,
        tableName: u.tableName,
        Action: i,
      }));

    setLogsToDisplay(logsFilterData);

    if (gridApi) {
      gridApi.redrawRows();
    }
    // setPrefetchData(filterDate);
    //});
  };

  const handleViewData = (data) => {
    setViewData(!viewData);
    setJsondata(data.id);
  };

  /***********Export CSV file */
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const handleViewMore = (data) => {
    // setViewDetails(!viewDetails);
    setDetails(data);
    history.push({
      pathname: `/logviewer-details`,
      state: { data: data },
    });
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setcolumns(tabcolumnDefs);
    } else if (window.innerWidth <= 480) {
      setcolumns(mobcolumnDefs);
    } else {
      setcolumns(columnDefs);
    }
  }, []);

  const lang = localStorage.getItem('language');
  return (
    <div className="global-data-table reservation-management log-viewer-page">
      <div className="container">
        <div className="add-employee-btn">
          <div className="left-filter">
            <div className="filter-section">
              <div className="search-filter">
                <div className="xyz">
                  <div className="from-date">
                    <label>{t('fromDate')}</label>
                    <input
                      type="date"
                      defaultValue={moment(fromDate).format('YYYY-MM-DD')}
                      placeholder="dd/mm/yyyy"
                      name="fromDate"
                      onChange={(event) =>
                        handleDate('fromdate', event.target.value)
                      }
                      max={moment(new Date()).format('YYYY-MM-DD') || ''}
                    />
                  </div>
                  <div className="from-date">
                    <label>{t('toDate')}</label>
                    <input
                      defaultValue={moment(toDate).format('YYYY-MM-DD')}
                      type="date"
                      placeholder="dd/mm/yyyy"
                      name="toDate"
                      onChange={(event) =>
                        handleDate('todate', event.target.value)
                      }
                      max={moment(new Date()).format('YYYY-MM-DD') || ''}
                      // max={maxDates || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="search-input">
              <input
                type="text"
                id="filter-text-box"
                placeholder={t('freeTextSearch')}
                onInput={onFilterTextBoxChanged}
              />
              <img src={search} alt="search" />
            </div>
            {fromDate && toDate ? (
              <button
                onClick={() => handleApplyfilter(fromDate, toDate)}
                className="search-button"
              >
                <img src={search} alt="search" />
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="active-button">
            <div className="actions">
              <img src={file} alt="add" onClick={() => onBtnExport()} />
            </div>
          </div>
        </div>

        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={logsToDisplay}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            columnDefs={columns}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            frameworkComponents={{
              ActionButton: (e) => (
                <ActionButton handleViewData={handleViewData} e={e} />
              ),
              ViewMore: (e) => (
                <ViewMore e={e} handleViewMore={handleViewMore} />
              ),
            }}
            debug={false}
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            overlayLoadingTemplate={'<p></p>'}
            // rowSelection={'multiple'}
            // rowGroupPanelShow={'always'}
            // pivotPanelShow={'always'}
            // enableRangeSelection={true}
            // pagination={true}
            // paginationPageSize={10}
            // paginationNumberFormatter={function (params) {
            //   return '[' + params.value.toLocaleString() + ']';
            // }}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              {/* <select
                id="we_change_record_count"
                onChange={handleRecordCountChange}
                defaultValue={localStorage.getItem('recordsPerPage') !== null ? localStorage.getItem('recordsPerPage'):newPageSizeDishes}
              >
                {createSelectItems()}
              </select> */}
              <p>
                {newPageSizeLogs * (currentPageAllLogs - 1) + 1} to{' '}
                {newPageSizeLogs * currentPageAllLogs} of {logsTotal}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  setcurrentPageAllLogs(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  currentPageAllLogs > 1
                    ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageAllLogs} of{' '}
                {Math.ceil(logsTotal / newPageSizeLogs)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  currentPageAllLogs < Math.ceil(logsTotal / newPageSizeLogs)
                    ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  setcurrentPageAllLogs(Math.ceil(logsTotal / newPageSizeLogs));
                }}
              />
            </Pagination>
          </div>
        </div>
        {/* <div className="pagination-header">
          {t('pageSize')}:
          <select onChange={() => onPageSizeChanged()} id="page-size">
            <option value="10" selected={true}>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div> */}

        <ConfirmationPopup
          open={viewData}
          prefetchData={prefetchData}
          jsonData={jsonData}
          handleClose={handleViewData}
          t={t}
        />
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const ConfirmationPopup = ({
  open,
  handleClose,
  handleDeleteUser,
  prefetchData,
  jsonData,
  t,
}) => {
  let jsondataValue = prefetchData?.filter((el) => el.id === jsonData);
  const formatter1 =
    jsondataValue && jsondataValue[0]
      ? new JSONFormatter(JSON.parse(jsondataValue[0]?.reqBody))
      : '';
  let element1 = document.getElementById('json1');
  if (element1 && formatter1 !== '') {
    element1.innerHTML = '';
    element1.appendChild(formatter1.render());
  }

  const formatter2 =
    jsondataValue && jsondataValue[0]
      ? new JSONFormatter(JSON.parse(jsondataValue[0]?.reqParam))
      : '';
  let element2 = document.getElementById('json2');
  if (element2 && formatter2 !== '') {
    element2.innerHTML = '';
    element2.appendChild(formatter2.render());
  }
  return (
    // <Modal
    //   open={open}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    //   maxWidth="lg"
    //   className="add-employee-popup delete-popup log-json-data"
    // >
    //   <Box sx={style}>
    <>
      {open ? <div className="bg-shadow" /> : ''}
      <div
        className={`add-employee-popup delete-popup log-json-data ${
          open ? 'open-json-popup' : ''
        }`}
      >
        <div className="updated-data-heading">
          <h6>{t('updatedData')}</h6>
          <div className="close-btn">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <div id="modal-modal-description">
          <div className="copy-url">
            <div className="confirmation-btn">
              <h3>Body</h3>
              <div className="confirmation-btn" id="json1"></div>
            </div>
            <div className="confirmation-btn">
              <h3>Param</h3>
              <div className="confirmation-btn" id="json2"></div>
            </div>
            <button className="copy-btn" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </>
    //   </Box>
    // </Modal>
  );
};

const ActionButton = ({ e, handleViewData, t }) => {
  return (
    <div className={`log-btn`}>
      <Button onClick={() => handleViewData(e.data)}>View Data</Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  languages: state.languages.options,
  currentLanguage: state.languages.current,
  alllogsToDisplay: state.app.alllogsToDisplay,
  logsTotal: state.app.logsTotal,
  showFetchingLoader: state.app.showFetchingLoader,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getAllLogs,
};
export default connect(mapStateToProps, mapDispatchToProps)(LogViewer);
