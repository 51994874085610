import React, { useEffect, useRef, useState, useMemo, memo } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
  getAllAuditMess,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../../services/http';
import { isTabletOrMobile } from '../../services/mediaQueries';
import { sleep } from '../../services/utils';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import { ActiveButton } from '../../components/WE-Button';
import cancel from '../../assets/images/close.svg';
import moment from 'moment';
import JSONFormatter from 'json-formatter-js';
import search from '../../assets/icons/search.png';
import file from '../../assets/images/file-export.svg';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import more from '../../assets/images/more.svg';
import next from '../../assets/images/next.png';
import agGridLocales from '../../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  maxWidth: '500px',
  wordBreak: 'break-all',
  borderRadius: '5px',
  height: 'auto',
};

const auditManager = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  languages,
  currentLanguage,
  getAllAuditMess,
  allAuditsToDisplay,
  auditsTotal,
  showFetchingLoader,
}) => {
  const [currPage, setCurrPage] = useState('All');
  const [dropdownValue, setDropdownValue] = useState([]);
  const [dropdownIdx, setDropdownIdx] = useState(0);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [columns, setcolumns] = useState();
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [allAuditToDisplay, setAllAuditToDisplay] = useState(null);
  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs, setPageSizeLogs] = useState(15); // Records per page
  const [totalLogsRecords, setTotalLogsRecords] = useState(0); // Total records
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [searchText, setsearchText] = useState(''); // Search text
  const [viewData, setViewData] = useState();
  const [jsonData, setJsondata] = useState();
  const [allAuditRecord, setAllAuditRecord] = useState(null);
  const [clickedFieldName, setClickedFieldName] = useState(null);
  const [fromDate, setFromdate] = useState(
    new Date().setDate(new Date().getDate() - 6)
  );
  const [toDate, setToDate] = useState(
    new Date().setDate(new Date().getDate())
  );
  /***********fetch log   data */

  const fetchDataFromApi = async () => {
    let filterData = {
      filter: {
        findStr: searchText,
        dropdownIdx,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        order: columnAndOrder,
      },
      page: currentPageAllLogs,
      limit: newPageSizeLogs,
    };
    await useFetchingLoader(async () => {
      await getAllAuditMess(filterData);
    });
  };

  const fetch = async () => {
    const data = await http.get('audit/getAllTables');
    setDropdownValue(data.map((el) => el.display[i18n.language]));
  };

  useEffect(async () => {
    fetchDataFromApi();
    fetch();
  }, []);

  useEffect(() => {
    fetchDataFromApi();
  }, [
    currentPageAllLogs,
    newPageSizeLogs,
    searchText,
    currPage,
    toDate,
    fromDate,
    columnAndOrder,
  ]);

  useEffect(async () => {
    if (allAuditsToDisplay) {
      setpageCountsLogs(Math.ceil(auditsTotal / newPageSizeLogs));
    }
  }, [allAuditsToDisplay]);

  const handleDate = async (name, value) => {
    console.log('handleDate');
    if (name === 'fromdate') {
      if (!value) {
        return setFromdate(null);
      }
      setFromdate(new Date(value));
    } else if (name === 'todate') {
      if (!value) {
        return setToDate(null);
      }
      const tempToDate = new Date(value);
      setToDate(tempToDate);
    }
    fetchDataFromApi();
  };
  /************global filter funcitionm */
  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    //gridApi.setQuickFilter(value);
    setcurrentPageAllLogs(1);
    setsearchText(value);
  };
  const handleRecordCountChange = (elem) => {
    setcurrentPageAllLogs(1);
    setPageSizeLogs(elem?.target?.value);
    localStorage.setItem('recordsPerPage', elem?.target?.value);
  };
  useEffect(async () => {
    let auditData = allAuditsToDisplay.map((u, i) => ({
      id: u.id,
      // Role: Roles.find((el) => el.id === u['userroleId'])?.display,
      fieldName: u?.fieldName,
      action: u?.eventType,
      prevValue: String(u?.prevValue),
      currValue: String(u?.currValue),
      date: new Date(u.createdAt).toISOString().slice(0, 10),
      time: moment(u.createdAt).format('hh : mm A'),
      createdBy: String(u?.createdBy),
    }));
    setAllAuditRecord(auditData);
    setAllAuditToDisplay(auditData);
  }, [allAuditsToDisplay]);

  const columnDefs = [
    {
      field: 'createdAt',
      headerName: `${t('time')}, ${t('date')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return `${params?.data?.date}`
          ? `${params?.data?.date} <br /> <b>${params?.data?.time}</b>`
          : '--';
      },
    },
    {
      field: 'fieldName',
      headerName: `${t('fieldName')}`,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'eventType',
      headerName: `${t('eventType')}`,
      cellRenderer: (params) => {
        return `${params?.data?.action}` ? `${params?.data?.action} ` : '--';
      },
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'prevValue',
      headerName: `${t('prevValue')}`,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'currValue',
      headerName: `${t('currValue')}`,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'createdBy',
      headerName: `${t('userName')}`,
      cellRenderer: (params) => {
        return `${params?.data?.createdBy}`
          ? `${params?.data?.createdBy}`
          : '--';
      },
      sortable: true,
      comparator: () => {},
    },
  ];

  const tabcolumnDefs = [
    {
      field: 'createdAt',
      headerName: `${t('time')}, ${t('date')}`,
      cellRenderer: (params) => {
        return `${params?.data?.date}`
          ? `${params?.data?.date} <br /> <b>${params?.data?.time}</b>`
          : '--';
      },
      width: 100,
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'fieldName',
      width: 100,
      headerName: `${t('fieldName')}`,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      width: 80,
      field: 'eventType',
      headerName: `${t('eventType')}`,
      cellRenderer: (params) => {
        return `${params?.data?.action}` ? `${params?.data?.action} ` : '--';
      },
      sortable: true,
      comparator: () => {},
    },
    {
      width: 150,
      field: 'prevValue',
      headerName: `${t('prevValue')}`,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      width: 150,
      field: 'currValue',
      headerName: `${t('currValue')}`,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      width: 90,
      field: 'createdBy',
      headerName: `${t('userName')}`,
      cellRenderer: (params) => {
        return `${params?.data?.createdBy}`
          ? `${params?.data?.createdBy}`
          : '--';
      },
      sortable: true,
      comparator: () => {},
    },
  ];

  const mobcolumnDefs = [
    {
      field: 'fieldName',
      headerName: `${t('fieldName')}`,
      width: 90,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'eventType',
      headerName: `${t('eventType')}`,
      width: 75,
      cellRenderer: (params) => {
        return `${params?.data?.action}` ? `${params?.data?.action} ` : '--';
      },
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'prevValue',
      headerName: t('prevValue'),
      width: 75,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'currValue',
      headerName: t('currValue'),
      width: 75,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
    {
      field: 'createdBy',
      headerName: `${t('userName')}`,
      width: 80,
      cellRenderer: 'ActionButton',
      sortable: true,
      comparator: () => {},
    },
  ];

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  /***********Export CSV file */

  useEffect(() => {
    if (window.innerWidth <= 1280 && window.innerWidth >= 481) {
      setcolumns(tabcolumnDefs);
    } else if (window.innerWidth <= 480) {
      setcolumns(mobcolumnDefs);
    } else {
      setcolumns(columnDefs);
    }
  }, []);

  const lang = localStorage.getItem('language');

  const printSortStateToConsole = (e) => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const handleViewData = (data, e) => {
    if (e) {
      console.log('Event', e);
      setClickedFieldName(e.colDef.field);
      setJsondata(data.id);
    }
    setViewData(!viewData);
  };

  return (
    <div className="global-data-table reservation-management log-viewer-page">
      <div className="container">
        <div className="add-employee-btn">
          <div className="left-filter">
            {' '}
            <div className="filter-section">
              <div className="search-filter">
                <div className="xyz">
                  <div className="from-date">
                    <label>{t('fromDate')}</label>
                    <input
                      type="date"
                      defaultValue={moment(fromDate).format('YYYY-MM-DD')}
                      placeholder="dd/mm/yyyy"
                      name="fromDate"
                      onChange={(event) =>
                        handleDate('fromdate', event.target.value)
                      }
                      max={moment(new Date()).format('YYYY-MM-DD') || ''}
                    />
                  </div>
                  <div className="from-date">
                    <label>{t('toDate')}</label>
                    <input
                      defaultValue={moment(toDate).format('YYYY-MM-DD')}
                      type="date"
                      placeholder="dd/mm/yyyy"
                      name="toDate"
                      onChange={(event) =>
                        handleDate('todate', event.target.value)
                      }
                      max={moment(new Date()).format('YYYY-MM-DD') || ''}
                      // max={maxDates || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="search-input">
              <input
                type="text"
                id="filter-text-box"
                placeholder={t('freeTextSearch')}
                onInput={onFilterTextBoxChanged}
              />
              <img src={search} alt="search" />
            </div>
            <div className="dropdown-style">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  {currPage}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdownValue.map((lang, index) => (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrPage(lang);
                          setDropdownIdx(index);
                        }}
                        key={index}
                      >
                        {lang}
                      </Dropdown.Item>
                    </>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={allAuditToDisplay}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            columnDefs={columns}
            onSortChanged={(e) => printSortStateToConsole(e)}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            debug={false}
            animateRows={true}
            enableRangeSelection={true}
            suppressRowClickSelection={true}
            frameworkComponents={{
              ActionButton: (e) => (
                <ActionButton
                  handleViewData={(data) => handleViewData(data, e)}
                  e={e}
                />
              ),
            }}
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            overlayLoadingTemplate={'<p></p>'}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <p>
                {newPageSizeLogs * (currentPageAllLogs - 1) + 1} to{' '}
                {newPageSizeLogs * currentPageAllLogs} of {auditsTotal}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  setcurrentPageAllLogs(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  currentPageAllLogs > 1
                    ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageAllLogs} of{' '}
                {Math.ceil(auditsTotal / newPageSizeLogs)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  currentPageAllLogs < Math.ceil(auditsTotal / newPageSizeLogs)
                    ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  setcurrentPageAllLogs(
                    Math.ceil(auditsTotal / newPageSizeLogs)
                  );
                }}
              />
            </Pagination>
          </div>
        </div>
        <ConfirmationPopup
          open={viewData}
          jsonData={jsonData}
          allAuditRecord={allAuditRecord}
          handleClose={handleViewData}
          clickedFieldName={clickedFieldName}
          t={t}
        />
      </div>
    </div>
  );
};

const ConfirmationPopup = ({
  open,
  handleClose,
  handleDeleteUser,
  allAuditRecord,
  jsonData,
  clickedFieldName,
  t,
}) => {
  let jsondataValue = allAuditRecord?.filter((el) => el.id === jsonData);

  const formatter1 =
    jsondataValue &&
    jsondataValue[0] &&
    jsondataValue[0][clickedFieldName].length !== 0
      ? jsondataValue[0][clickedFieldName].split('/')
      : [];

  return (
    <>
      {open ? <div className="bg-shadow" /> : ''}
      <div
        className={`add-employee-popup delete-popup log-json-data ${
          open ? 'open-json-popup' : ''
        }`}
      >
        <div className="updated-data-heading">
          <div className="close-btn">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <div id="modal-modal-description">
          <div className="copy-url">
            <div className="confirmation-btn">
              <h3>{clickedFieldName}</h3>
              <ul className="no-bullets">
                {formatter1.map((el) => (
                  <li className="format">{el}</li>
                ))}
              </ul>
            </div>
            <button className="copy-btn" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </>
    //   </Box>
    // </Modal>
  );
};
const ActionButton = ({ e, handleViewData, t }) => {
  let elInCell = 60;
  if (window.innerWidth <= 1280 && window.innerWidth >= 481) {
    elInCell = 40;
  } else if (window.innerWidth <= 480) {
    elInCell = 10;
  } else {
    elInCell = 60;
  }
  return e.value && e.value.length > elInCell ? (
    <div className={`audit-general-text`}>
      <Button onClick={() => handleViewData(e.data)}>
        {e.value.slice(0, elInCell - 5) + '... '}
        <span className={`audit-view-more`}>view more</span>
      </Button>
    </div>
  ) : !e.value || e.value.length === 0 ? (
    <span>--</span>
  ) : (
    <span>{e.value}</span>
  );
};
const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  languages: state.languages.options,
  currentLanguage: state.languages.current,
  allAuditsToDisplay: state.app.allAuditsToDisplay,
  auditsTotal: state.app.auditsTotal,
  showFetchingLoader: state.app.showFetchingLoader,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getAllAuditMess,
};
export default connect(mapStateToProps, mapDispatchToProps)(auditManager);
