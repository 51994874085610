import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import Box from '@material-ui/core/Box';
import { Modal, Grid, styled, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import enums from '../../services/enums';
import Dates from '../../services/dates.js';
import { isMobile } from '../../services/mediaQueries';
import { sortMeals } from '../../services/sortItems';
import Typography from '@material-ui/core/Typography';
import { ActiveButton, DisabledButton } from '../../components/WE-Button';
import http from '../../services/http';
import { sleep } from '../../services/utils';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { getMealTimesByDate } from '../../constants/MealTimes';
import {
  Alert,
  Dialog,
  DialogWithInput,
} from '../../components/Dialogs/SwalDialogs';
import WEIcon from '../../components/WE-IconButton';
import cancel from '../../assets/images/close.svg';
import SlotPopup from './SlotPopup';

//import 'react-calendar/dist/Calendar.css';

//  Safari & IE browsers do not support the date format “yyyy-mm-dd”
const fixDateForAllBrowsers = (dateString) => dateString?.replace(/-/g, '/');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 15px',
  p: 4,
  borderRadius: '5px',
  Width: '550px',
  minHeight: '250px',
};

// @ts-ignore
const MealTime = ({
  kitchen,
  show,
  setLayoutOptions,
  currentLanguage,
  MealTypes,
  showAlert,
  useLoader,
  useFetchingLoader,
}) => {
  const history = useHistory();
  const { date, mealTypeId } = useParams();
  const [toggleCalender, setToggleCalender] = useState(true);
  const [toggleMealTypeSelector, setToggleMealTypeSelector] = useState(true);
  const [availableMealTypes, setAvailableMealTypes] = useState(null);
  const [slotPopup, setSlotPopup] = useState(false);
  const [allSlot, setAllSlot] = useState(null);
  const [check, setCheck] = useState(false);
  const [slotBooked, setSlotBooked] = useState([]);
  const [availableSlot, setAvailableSlot] = useState(null);
  const [month, setMonth] = useState([]);
  const [mealType, setMealType] = useState(null);
  const [mealData, setMealData] = useState(null);
  const [mealId, setMealId] = useState(null);
  const [mealSlot, setMealSlot] = useState(null);
  const [buttonActive, setButtonActive] = useState(false);
  const [currDate, setCurrDate] = useState(
    moment(fixDateForAllBrowsers(date)).isValid()
      ? moment(fixDateForAllBrowsers(date))
      : moment()
  );

  useEffect(async () => await useLoader(() => sleep()), []);
  useEffect(() => {
    const getMonth = async () => {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const getMonthlyData = await http.get(
        `meals/getMonthly`,
        {
          kitchenId: kitchen?.id,
        }
        // , {
        //   startDate: moment(firstDay).format('YYYY-MM-DD'),
        //   endDate: moment(lastDay).format('YYYY-MM-DD'),
        // }
      );
      let dateArray = [];
      getMonthlyData.filter((el) => dateArray.push(el.date));
      dateArray.sort((a, b) => new Date(a) - new Date(b));
      setMonth(dateArray);
    };
    getMonth();
  }, [kitchen]);

  useEffect(() => {
    if (availableMealTypes?.length === 1) {
      setButtonActive(true);
    }
  });

  useEffect(() => {
    if (setButtonActive.length === 1) {
      setButtonActive(true);
    }
  });

  const onCalenderChange = async (date) => {
    setToggleCalender(!toggleCalender);
    setMealType(null);
    // await getAvailableMealTypes(moment(date).format('YYYY-MM-DD'));
    setButtonActive(false);
    setCurrDate(
      moment(
        fixDateForAllBrowsers(
          moment(new Date(date.setDate(date.getDate()))).format('YYYY-MM-DD')
        )
      )
    );
  };

  const getFutureMeals = (date, MealTypes) => {
    let mealTimes = getMealTimesByDate(date, MealTypes);
    let now = moment();
    let futureMeals = MealTypes.filter(
      (mealType) => mealTimes[mealType.id].ends > now
    ).reduce((acc, type) => {
      return { ...acc, [type.id]: type };
    }, {});
    return futureMeals;
  };
  const [newDate, setNewdate] = useState();
  const getAvailableMealTypes = async (date) => {
    const { data: meals } = await useFetchingLoader(
      async () =>
        await http.get(`meals`, {
          filter: {
            date,
            kitchenId: kitchen?.id,
            mealStatusId: [
              enums.getMap('MealStatus').active,
              enums.getMap('MealStatus').blocked,
            ],
          },
        })
    );
    if (meals?.length > 0) {
      setMealData({ data: meals });
    } else {
      let current = new Date(currDate);

      let newdate = month.find((el) => new Date(el) > current);
      if (newdate) {
        let max = moment(
          new Date(current.setDate(new Date(newdate).getDate()))
        ).format('YYYY-MM-DD');

        if (max) {
          setCurrDate(moment(fixDateForAllBrowsers(max)));
        }
      }
    }

    // if(mealData?.length>0){
    //   setNewdate(mealData.data[0].date)
    // }
    let futureMeals = getFutureMeals(date, MealTypes);

    const availableMealsTypes = MealTypes.map((mt) => {
      return {
        available:
          !!meals.find((m) => m.mealTypeId === mt.id && m.mealStatusId === 1) &&
          futureMeals.hasOwnProperty(mt.id),

        ...mt,
      };
    });
    let sortedAvailableMealTypes = availableMealsTypes
      .filter((m) => m.available)
      .sort((mt1, mt2) => (mt1.orderIndex > mt2.orderIndex ? 1 : -1));
    await setAvailableMealTypes(sortedAvailableMealTypes);
    if (sortedAvailableMealTypes.length === 1) {
    }

    if (sortedAvailableMealTypes.length === 1) {
      let id = sortedAvailableMealTypes[0].id;
      setMealType(id);
      let redirectTo = `/mealTime/${moment(date).format('YYYY-MM-DD')}/${id}`;
      history.push(redirectTo);
      return;
    }

    let redirectTo = `/mealTime/${moment(date).format('YYYY-MM-DD')}/${
      mealTypeId || ''
    }`;
    history.push(redirectTo);
  };

  useEffect(async () => {
    setLayoutOptions({ title: t('choosingMealTime') });
  }, []);

  const getAvailableMeals = useCallback(async () => {
    let d = moment(currDate).format('YYYY-MM-DD');
    if (d) await getAvailableMealTypes(d);
  }, [currDate, month]);

  useEffect(async () => {
    let d = moment(currDate).format('YYYY-MM-DD');
    if (d) await getAvailableMealTypes(d);
  }, [currDate, month]);

  useEffect(async () => {
    if (mealData !== null) {
      let { data } = mealData || {};
      const getMeal = data.filter((el) => el.mealTypeId === mealType);
      setMealId(getMeal && getMeal[0] && getMeal[0].id);
    }
    let getAllSlot;
    if (mealId) {
      await useFetchingLoader(async () => {
        getAllSlot = await http.get(`mealSlots/getByMealId/${mealId}`);
      });
      setAllSlot(getAllSlot);
    }
  }, [mealData, mealType, mealId]);

  const ontMealTypeChange = async (type) => {
    setButtonActive(true);
    history.push(`/mealTime/${currDate.format('YYYY-MM-DD')}/${type}`);
    setMealType(type);
  };
  const { t } = useTranslation();

  /* Toggle on tablet screens */

  setLayoutOptions({ title: t('choosingMealTime') });

  const goToDishSelection = async () => {
    try {
      const { data } = await http.get('orders/getAllOrder', {
        filter: { date, mealTypeId, orderStatusId: { ne: 3 } },
      });
      const { mealDish } = data;
      let meal = {};

      if (mealDish?.length > 0) {
        meal =
          (order &&
            order?.mealDish &&
            order?.mealDish?.length > 0 &&
            order?.mealDish[0]?.mealDish?.meal) ??
          {};
      } else {
        meal = mealDish?.meal ?? {};
      }
      // const data = response.data;
      //const data = _data.sort((d1,d2)=>{return moment(d1.createdAt).valueOf() - moment(d2.createdAt).valueOf()})
      const order = data.find((o) => meal?.id === mealId);
      if (order && (order.orderStatusId === 1 || order.orderStatusId === 2)) {
        if (order.orderStatusId === 1)
          SwalDialogs.Dialog({
            title: t('orderExistsTitle'),
            text: `${t('areYouSureYouWantToSelectAnotherDish')} ${
              order.mealDish.dish.name
            }?`,
            onConfirm: () =>
              // history.push(
              //   `/mealDishes/${moment(currDate).format('YYYY-MM-DD')}/${
              //     mealType || ''
              //   }/${order.mealDish.dish.id}?mealSlotId=${
              //     mealSlot ? mealSlot : ''
              //   }`
              // ),
              history.push(
                `/mealDishes/${moment(currDate).format('YYYY-MM-DD')}/${
                  mealType || ''
                }/${order.mealDish.dish.id}/${order?.id}/${
                  mealSlot ? mealSlot : ''
                }
                }`
              ),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('selectAnotherDishBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        else if (order.orderStatusId === 2) {
          if (order) Alert({ error: { content: t('alreadyRealizedForDate') } });
        }
      } else {
        // history.push(
        //   `/mealDishes/${moment(currDate).format('YYYY-MM-DD')}/${
        //     mealType || ''
        //   }?mealSlotId=${mealSlot ? mealSlot : ''}`
        // );
        history.push(
          `/mealDishes/${moment(currDate).format('YYYY-MM-DD')}/${
            mealType || ''
          }${order?.id ? '/' + order?.id : ''}${mealSlot ? '/' + mealSlot : ''}`
        );
      }
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handleSlot = async () => {
    setSlotPopup(!slotPopup);
    // let getAllSlot;
    // await useFetchingLoader(async () => {
    //   getAllSlot = await http.get(`mealSlots/getByMealId/${mealId}`);
    // });
    // setAllSlot(getAllSlot);
  };

  const selectMealSlot = async (slot) => {
    setMealSlot(slot);
    // setCheck(true);

    const getTotalReservations = await http.get(
      `mealSlots/getByMealSlotId/${slot}`
    );
    setAvailableSlot(getTotalReservations);
    if (getTotalReservations === 0) {
      setSlotBooked([...slotBooked, slot]);
      Dialog({
        title: `${t('maxReservationMessage')}`,
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('ok'),
        },
        onConfirm: () => {
          // setCheck(false);
        },
      });
    }
  };

  function mealTypeName(mealTypeId) {
    if (!mealTypeId || !availableMealTypes) return t('mealType');
    const mealType = availableMealTypes.find((mt) => mt.id == mealTypeId);
    if (!mealType) return t('mealType');
    return mealType.value;
  }

  const handleClose = () => {
    setSlotPopup(false);
  };

  return (
    <div className={`meal-time ${show ? 'active' : ''}`}>
      <div className="content">
        <div className="calendar wrapper">
          {isMobile() && toggleCalender ? (
            <div
              className="title"
              onClick={() => setToggleCalender(!toggleCalender)}
            >
              {
                <>
                  <p className={'placeholder align-text'}>
                    {t('selectMealDate')}
                  </p>
                  <div style={{ display: 'flex' }}>
                    <p className={'meal-type'} style={{ flex: 1 }}>
                      {moment(currDate).format('dddd / D.M.YYYY')}
                    </p>
                    <WEIcon
                      iconSize={'xSmall'}
                      additionalClassname={'show-on-mobile'}
                      icon={'down'}
                    />
                  </div>
                </>
              }
            </div>
          ) : (
            <>
              <div
                className="title"
                onClick={() => setToggleCalender(!toggleCalender)}
              >
                <div style={{ display: 'flex' }}>
                  <p className={'meal-type'} style={{ flex: 1 }}>
                    {t('selectMealDate')}
                  </p>
                  <WEIcon
                    iconSize={'xSmall'}
                    additionalClassname={'show-on-mobile'}
                    icon={'down'}
                  />
                </div>
              </div>
              <Calendar
                minDate={new Date()}
                calendarType={'Hebrew'}
                locale={currentLanguage.shortCode}
                onChange={onCalenderChange}
                value={currDate.toDate()}
                selected={currDate.toDate()}
                tileClassName={({ date, view }) => {
                  if (
                    month.find((x) => x === moment(date).format('YYYY-MM-DD'))
                  ) {
                    return 'bold-date';
                  }
                }}
                // tileDisabled={({ date }) => date.getDay() === 0}
              />
            </>
          )}
        </div>

        {
          <div className="mealtype wrapper">
            {isMobile() && toggleMealTypeSelector ? (
              <>
                {availableMealTypes?.length === 0 ? (
                  <p style={{ fontWeight: 'bold' }}>
                    {t('noMealsForThisDate')}
                  </p>
                ) : (
                  <div
                    className="title"
                    onClick={() =>
                      setToggleMealTypeSelector(!toggleMealTypeSelector)
                    }
                  >
                    <p className={'placeholder align-text'}>
                      {t('selectMealTypeSecond')}
                    </p>
                    {availableMealTypes?.length === 1 ? (
                      availableMealTypes[0].value
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <p className={'meal-type'} style={{ flex: 1 }}>
                          {mealTypeName(mealTypeId)}
                        </p>
                        <WEIcon
                          iconSize={'xSmall'}
                          additionalClassname={'show-on-mobile'}
                          icon={'down'}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                {availableMealTypes?.length === 0 ? (
                  <p style={{ fontWeight: 'bold' }}>
                    {t('noMealsForThisDate')}
                  </p>
                ) : (
                  <>
                    <div
                      className="title"
                      onClick={() =>
                        setToggleMealTypeSelector(!toggleMealTypeSelector)
                      }
                    >
                      <div style={{ display: 'flex' }}>
                        <p className={'meal-type'} style={{ flex: 1 }}>
                          {t('selectMealTypeSecond')}
                        </p>
                        <WEIcon
                          iconSize={'xSmall'}
                          additionalClassname={'show-on-mobile'}
                          icon={'down'}
                        />
                      </div>
                    </div>
                    <MealTypeSelector
                      mealTypes={availableMealTypes}
                      ontMealTypeChange={ontMealTypeChange}
                      mealTypeId={mealTypeId}
                      toggleCB={() => setToggleMealTypeSelector(true)}
                      selectMealSlot={selectMealSlot}
                      mealSlot={mealSlot}
                    />
                  </>
                )}
              </>
            )}
          </div>
        }
      </div>

      <div className="bottom">
        <div className="button-wrapper">
          {/* {mealType ? (
            <ActiveButton
              label={t('dishSelection')}
              cb={goToDishSelection}
              label={t('selectSlot')}
              cb={handleSlot}
              arrow={true}
            />
          ) : (
            <>
              {<DisabledButton label={t('selectSlot')} />}
            </>
          )} */}
          {allSlot?.length > 0 && mealType ? (
            <>
              {mealType && date && buttonActive ? (
                <ActiveButton
                  label={t('dishSelection')}
                  cb={goToDishSelection}
                  label={t('selectSlot')}
                  cb={handleSlot}
                  arrow={true}
                />
              ) : (
                <>{<DisabledButton label={t('selectSlot')} />}</>
              )}
            </>
          ) : (
            <>
              {mealType && date && buttonActive ? (
                <ActiveButton
                  label={t('dishSelection')}
                  cb={goToDishSelection}
                  arrow={true}
                />
              ) : (
                <DisabledButton label={t('dishSelection')} />
              )}
            </>
          )}
        </div>
      </div>
      <SlotPopup
        mealType={mealType}
        slotPopup={slotPopup}
        slotBooked={slotBooked}
        handleSlot={handleSlot}
        mealTypes={availableMealTypes}
        t={t}
        goToDishSelection={goToDishSelection}
        mealTypeId={mealTypeId}
        allSlot={allSlot}
        selectMealSlot={selectMealSlot}
        check={check}
        availableSlot={availableSlot}
        handleClose={handleClose}
      />
    </div>
  );
};

const MealTypeSelector = ({
  mealTypes,
  ontMealTypeChange,
  mealTypeId,
  toggleCB,
  mealSlot,
  selectMealSlot,
}) => {
  return (
    <main className="meal-type">
      {mealTypes?.map((t) => (
        <div
          className={`option-wrapper ${t.available ? '' : 'disabled'}`}
          key={t.id}
        >
          <div
            onClick={() => {
              ontMealTypeChange(t.id);
              toggleCB();
            }}
            className={`option ${mealTypeId == t.id ? 'active' : ''}`}
          >
            {t.value}
          </div>
        </div>
      ))}
    </main>
  );
};

// const SlotPopup = ({
//   slotPopup,
//   handleSlot,
//   t,
//   goToDishSelection,
//   mealTypeId,
//   mealTypes,
//   mealType,
//   allSlot,
//   mealSlot,
//   selectMealSlot,
//   slotBooked,
//   check,
//   availableSlot,
// }) => {
//   console.log(allSlot, 'ppppppppppppppppp');
//   return (
//     <Modal
//       open={slotPopup}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//       maxWidth="lg"
//       className="add-employee-popup slot-popup"
//     >
//       <Box sx={style}>
//         <div className="close-btn">
//           <img src={cancel} alt="close" onClick={handleSlot} />
//         </div>

//         <Typography id="modal-modal-title" variant="h5" component="h2">
//           <div className="slot-section">
//             <h6>{t('preferedTime')} </h6>
//             {allSlot && allSlot.length > 0 ? (
//               <p>
//                 {`${
//                   allSlot &&
//                   allSlot.length > 0 &&
//                   allSlot[0] &&
//                   allSlot[0].mealTypesSlot.mealType.from &&
//                   allSlot[0].mealTypesSlot.mealType.from
//                 } ${'to'}
//                 ${
//                   allSlot &&
//                   allSlot.length > 0 &&
//                   allSlot[0] &&
//                   allSlot[0].mealTypesSlot.mealType.to &&
//                   allSlot[0].mealTypesSlot.mealType.to
//                 }`}
//               </p>
//             ) : (
//               'No slots Available'
//             )}

//             {allSlot &&
//               allSlot.map((el, i) => (
//                 <div className="slot-timing" key={i}>
//                   <div className="slot-timing-listing">
//                     <input
//                       type="radio"
//                       id="1st"
//                       name="slottime"
//                       value={el.id}
//                       onChange={() => {
//                         selectMealSlot(el.id);
//                       }}
//                       disabled={slotBooked.indexOf(el.id) >= 0 ? true : false}
//                     />
//                     <label for="1st">
//                       {/* {t('firstSeat')}:{' '} */}
//                       {el.mealTypesSlot.from && el.mealTypesSlot.to
//                         ? el.mealTypesSlot.from + ' to ' + el.mealTypesSlot.to
//                         : ''}
//                     </label>
//                   </div>
//                 </div>
//               ))}

//             <div className="bottom">
//               <div className="button-wrapper">
//                 {availableSlot === 1 || (allSlot && allSlot.length === 0) ? (
//                   <ActiveButton
//                     label={t('dishSelections')}
//                     cb={goToDishSelection}
//                     arrow={true}
//                   />
//                 ) : (
//                   <DisabledButton
//                     label={t('dishSelections')}
//                     cb={goToDishSelection}
//                     arrow={true}
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         </Typography>
//       </Box>
//     </Modal>
//   );
// };

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  currentLanguage: state.languages.current,
  MealTypes: state.app.enums.MealType,
});

const mapDispatchToProps = {
  setLayoutOptions,
  showAlert,
  useLoader,
  useFetchingLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealTime);
