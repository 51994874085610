import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { frontendUrl } from '../../services/utils';
import http from '../../services/http';
import moment from 'moment';

import ArrowIcon from '../../assets/images/left-arrow.svg';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import OrderSummaryDetails from '../../components/OrderComponents/OrderSummaryDetails.jsx';
import OrderConfirmationPopup from '../../components/OrderComponents/OrderConfirmationPopup.jsx';
import { generateMealDishData } from '../../components/DishComponents/Dish';
import { ActiveButton } from '../../components/WE-Button';
import WEIcon from '../../components/WE-IconButton';
import { sleep } from '../../services/utils';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import ExtrasDish from '../../components/OrderComponents/extrasDish';
import CafeteriaDetails from '../../components/OrderComponents/cafeteriaDetails';

const OrderSummary = ({
  setLayoutOptions,
  showAlert,
  OrderStatuses,
  MealTypes,
  useLoader,
  getPaymentPreferences,
  useFetchingLoader,
  user,
  kitchen,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  const [mealSlotId, setMealSlotId] = useState(null);
  const [mealSlotData, setMealSlotData] = useState(null);
  const [extrasPopup, setExtrasPopup] = useState(false);
  const [extrsData, setExtrasData] = useState();
  const [addedExtras, setAddedExtras] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(0);
  const [details, setDetails] = useState(null);
  const [togglePopup, setTogglePopup] = useState(false);
  const [mealId, setMealId] = useState();
  const [isSkip, setIsSkip] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [mealExtra, setMealExtra] = useState();
  const [cancellationTime, setCancellationTime] = useState();
  const [paymenttypeData, setPaymentTypeData] = useState();
  const [csrfTokenState, setCsrfTokenState] = useState('');
  const [haveWeReceivedPostResponseState, setHaveWeReceivedPostResponseState] =
    useState('not yet');

  const skip = {
    status: false,
  };
  let extraItemTotal = 0;
  useEffect(() => {
    const getData = async () => {
      const data = await getPaymentPreferences(kitchen.id, '');

      setPaymentType(data.paymentType);
      // setPaymentMethod(data.selectedPaymentOptions);
      setPaymentTypeData(data);
    };
    getData();
  }, []);

  setLayoutOptions({ title: t('orderSummaryTitle') });
  useEffect(async () => await useLoader(() => sleep()), []);
  const OrderStatusesMap = OrderStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});
  const refreshPaymentMethods = useCallback(() => {
    useLoader(async () => {
      setPaymentMethods(await http.get('users/paymentMethods'));
      setDefaultPaymentMethod(
        (await http.get('users/defaultPaymentMethod'))?.id
      );
    });
  }, []);
  useEffect(async () => {
    refreshPaymentMethods();
    let url = window.location.href;
    let split = url.split('+')[1];
    if (split) {
      const order = await http.get(`orders/${split}`);
      if (order.id == split) {
        const data = await http.get(
          `mealExtras/getMealExtra/${split}/${user?.id}`,
          {
            filter: {
              orderStatusId: 1,
              date: details?.date,
              mealTypeId: details?.mealTypeId,
            },
          }
        );
        setMealExtra(data);
        setTogglePopup(true);
      }
    }
  }, []);

  const { mealDishId, slotId } = useParams();
  const skipAddOrder = async () => {
    setIsSkip(false);
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId !== 6) {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: details.date,
            mealTypeId: details.mealTypeId,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderExistsTitle'),
            text: `${t('changeOrderText')} ${order.mealDish.dish.name} ${t(
              'cancelOrderTextin'
            )} ${order.mealDish.meal.date} ${t('cancelOrderTextin')} ${
              order.mealDish.dish.kitchen.name
            } ${t('changeOrderTextto')}  ${details.dishName}`,
            onConfirm: () => replaceOrderNew(order.orderId),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          // handleURLABc();
          setPaymentPopup(true);
        }
      } catch (err) {
        Alert({ error: err });
      }
      // setPaymentPopup(true);
    } else {
      setExtrasPopup(false);
      makeOrder();
    }
  };
  const AddOrderNew = async () => {
    setIsSkip(true);
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId !== 6) {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: details.date,
            mealTypeId: details.mealTypeId,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderExistsTitle'),
            text: `${t('changeOrderText')} ${order.mealDish.dish.name} ${t(
              'cancelOrderTextin'
            )} ${order.mealDish.meal.date} ${t('cancelOrderTextin')} ${
              order.mealDish.dish.kitchen.name
            } ${t('changeOrderTextto')}  ${details.dishName}`,
            onConfirm: () => replaceOrderNew(order.orderId),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          // handleURLABc();
          setPaymentPopup(true);
        }
      } catch (err) {
        Alert({ error: err });
      }
      // setPaymentPopup(true);
    } else {
      setExtrasPopup(false);
      makeOrder();
    }
  };
  const skipAddOrderPayment = () => {
    makeOrder();
    setPaymentPopup(false);
  };

  const MakeOrderwithoutExtras = async () => {
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId !== 6) {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: details.date,
            mealTypeId: details.mealTypeId,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderExistsTitle'),
            text: `${t('changeOrderText')} ${order?.mealDish?.dish?.name} ${t(
              'cancelOrderTextin'
            )} ${order?.mealDish?.meal?.date} ${t('cancelOrderTextin')} ${
              order?.mealDish?.dish?.kitchen?.name
            } ${t('changeOrderTextto')}  ${details?.dishName}`,
            onConfirm: () => replaceOrderNew(order.orderId),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          // handleURLABc();
          setPaymentPopup(true);
        }
      } catch (err) {
        Alert({ error: err });
      }
      // setPaymentPopup(true);
    } else {
      makeOrder();
    }
  };

  // const MakeCafeteriaOrder = async () => {
  //   if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId !== 6) {
  //     try {
  //       const { data } = await http.get('orders/getAllOrder', {
  //         filter: {
  //           orderStatusId: 1,
  //           date: details.date,
  //           mealTypeId: details.mealTypeId,
  //         },
  //       });
  //       if (data.length > 0) {
  //         let order = data[0];
  //         SwalDialogs.Dialog({
  //           title: t('orderExistsTitle'),
  //           text: `${t('changeOrderText')} ${order.mealDish.dish.name} ${t(
  //             'cancelOrderTextin'
  //           )} ${order.mealDish.meal.date} ${t('cancelOrderTextin')} ${
  //             order.mealDish.dish.kitchen.name
  //           } ${t('changeOrderTextto')}  ${details.dishName}`,
  //           onConfirm: () => replaceOrderNew(order.id),
  //           buttons: {
  //             showCancelButton: true,
  //             confirmButtonText: t('replaceOrderBtnLabel'),
  //             cancelButtonText: t('back'),
  //           },
  //         });
  //       } else {
  //         // handleURLABc();
  //         setPaymentPopup(true);
  //       }
  //     } catch (err) {
  //       Alert({ error: err });
  //     }
  //     // setPaymentPopup(true);
  //   } else {
  //     makeOrder();
  //   }
  // };
  /* Fetches the MealDishes and updates 'mealDishes' */
  useEffect(async () => {
    if (mealDishId) {
      const mealDishData = await useFetchingLoader(
        async () => await http.get(`mealsDishes/${mealDishId}`)
      );
      let now = mealDishData?.meal?.mealType?.to;
      let hour = now.split(':')[0];
      let minute = now.split(':')[1];
      let dt = new Date(null, null, null, hour, minute);
      if (mealDishData?.meal?.mealType?.cancellationTime) {
        dt.setMinutes(
          dt.getMinutes() - mealDishData?.meal?.mealType?.cancellationTime
        );
        setCancellationTime(moment(dt).format('HH:mm'));
      } else {
        setCancellationTime(mealDishData?.meal?.mealType?.cancellationTime);
      }

      const getMealId = mealDishData ? mealDishData.meal.id : '';
      setMealId(getMealId);
      const mealDishDetails = generateMealDishData(
        MealTypesMap_Id,
        mealDishData
      );
      setDetails(mealDishDetails);
    }
  }, [mealDishId]);

  useEffect(async () => {
    if (slotId) {
      const mealSlot = await useFetchingLoader(
        async () => await http.get(`mealSlots/getById/${slotId}`)
      );
      setMealSlotData(mealSlot);
    }
  }, [slotId]);

  // useEffect(async () => {}, []);

  /********* get extras data  */
  useEffect(async () => {
    if (mealId) {
      const data = await useFetchingLoader(async () => {
        return await http.get(`mealExtras/getMealExtrasMeal/${mealId}`);
      });
      setExtrasData(data);
    }
  }, [mealId]);

  const addOrder = async () => {
    if (isSkip === true) {
      extraItemTotal =
        addedExtras.length > 0
          ? addedExtras
              .map((el) => {
                const findExtraDish = extrsData.find((elem) => elem.id === el);
                if (findExtraDish) {
                  return findExtraDish?.price;
                }
              })
              .reduce((prev, cur) => prev + cur, 0)
          : 0;
    } else {
      extraItemTotal = 0;
    }
    const res = await http.get(`users/getUserKitchen/${user.id}`);
    if (res.KitchenId && res.roleId === 6) {
      try {
        const mealDishData = await useFetchingLoader(
          async () =>
            await http.post(`orders/create`, {
              mealId: details.mealId,
              dishId: details.dishId,
              //orderPrice: details.dishPrice + extraItemTotal,
              slotId: slotId,
              mealExtraId: addedExtras,
            })
        );
        const data = await http.get(
          `mealExtras/getMealExtra/${details.id}/${user.id}`,
          {
            filter: {
              orderStatusId: 1,
              date: details.date,
              mealTypeId: details.mealTypeId,
            },
          }
        );

        setMealExtra(data);
        setExtrasPopup(false);
        setTogglePopup(true);
      } catch (err) {
        Alert({ error: err });
      }
    } else if (res.roleId !== 6) {
      try {
        const mealDishData = await useFetchingLoader(
          async () =>
            await http.post(`orders/create`, {
              mealId: details.mealId,
              dishId: details.dishId,
              // orderPrice: details.dishPrice + extraItemTotal,
              slotId: slotId,
              mealExtraId: addedExtras,
            })
        );
        const data = await http.get(
          `mealExtras/getMealExtra/${details.id}/${user.id}`,
          {
            filter: {
              orderStatusId: 1,
              date: details.date,
              mealTypeId: details.mealTypeId,
            },
          }
        );

        setMealExtra(data);
        setExtrasPopup(false);
        setTogglePopup(true);
      } catch (err) {
        Alert({ error: err });
      }
    } else {
      Alert({ error: t('alertForContractor') });
    }
  };

  // const skipAddOrder = async () => {
  //   try {
  //     const extraItemTotal =
  //       addedExtras.length > 0
  //         ? addedExtras
  //             .map((el) => {
  //               const findExtraDish = extrsData.find((elem) => elem.id === el);
  //               if (findExtraDish) {
  //                 return findExtraDish?.price;
  //               }
  //             })
  //             .reduce((prev, cur) => prev + cur, 0)
  //         : 0;

  //     const mealDishData = await useFetchingLoader(
  //       async () =>
  //         await http.post(`orders/create`, {
  //           mealId: details.mealId,
  //           dishId: details.dishId,
  //           orderPrice: details.dishPrice,
  //           slotId: mealSlotId,
  //           mealExtraId: addedExtras,
  //         })
  //     );
  //     setExtrasPopup(false);
  //     setTogglePopup(true);
  //   } catch (err) {
  //     Alert({ error: err });
  //   }
  // };
  const replaceOrder = async (orderIdToCancel) => {
    await useFetchingLoader(
      async () => await http.put(`orders/${orderIdToCancel}/cancel`)
    );
    const data = await http.get(
      `mealExtras/getMealExtra/${details.id}/${user.id}`,
      {
        filter: {
          orderStatusId: 1,
          date: details.date,
          mealTypeId: details.mealTypeId,
        },
      }
    );

    setMealExtra(data);
    await addOrder();
  };

  const replaceOrderNew = async (orderIdToCancel) => {
    await useFetchingLoader(
      async () => await http.put(`orders/${orderIdToCancel}/cancel`)
    );
    const data = await http.get(
      `mealExtras/getMealExtra/${details.id}/${user.id}`,
      {
        filter: {
          orderStatusId: 1,
          date: details.date,
          mealTypeId: details.mealTypeId,
        },
      }
    );

    setMealExtra(data);
    setPaymentPopup(true);
    // handleURLABc();
  };

  const handleextrasPopup = () => {
    setExtrasPopup(!extrasPopup);
  };

  const makeOrder = async () => {
    try {
      const { data } = await http.get('orders/getAllOrder', {
        filter: {
          orderStatusId: 1,
          date: details.date,
          mealTypeId: details.mealTypeId,
        },
      });
      if (data.length > 0) {
        let order = data[0];

        SwalDialogs.Dialog({
          title: t('orderExistsTitle'),
          text: `${t('changeOrderText')} ${order.mealDish.dish.name} ${t(
            'cancelOrderTextin'
          )} ${order.mealDish.meal.date} ${t('cancelOrderTextin')} ${
            order.mealDish.dish.kitchen.name
          } ${t('changeOrderTextto')}  ${details.dishName}`,
          onConfirm: () => replaceOrder(order.orderId),
          buttons: {
            showCancelButton: true,
            confirmButtonText: t('replaceOrderBtnLabel'),
            cancelButtonText: t('back'),
          },
        });
      } else {
        await addOrder();
      }
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handlePayment = async () => {
    handleURLABc();
    // let url = window.location.href;
    // let orders = url.split('#');
    // const res = await http.post('users/paymentUrl', {
    //   url_redirect: frontendUrl('successfullySavedPaymentMethod'),
    //   paymentType: paymentType,
    //   currentUrl: orders[1],
    //   mealId: details.mealId,
    //   dishId: details.dishId,
    //   orderPrice: details.dishPrice + extraItemTotal,
    //   slotId: mealSlotId,
    //   mealExtraId: addedExtras,
    // });

    // window.location.href = res.url;

    // history.push(`/paymentManagement?type=${paymentType}`);
  };

  const handleURLABc = async () => {
    let url = window.location.href;
    console.log('url', details.mealId);
    let orders = url.split('#');
    const res = await http.post('users/paymentUrl', {
      url_redirect: frontendUrl('successfullySavedPaymentMethod'),
      paymentType: paymentType,
      currentUrl: orders[1],
      mealId: details.mealId,
      dishId: details.dishId,
      orderPrice: details.dishPrice + extraItemTotal,
      slotId: mealSlotId,
      mealExtraId: addedExtras,
    });
    window.location.href = res.url;
  };
  console.log(paymentMethods, 'aaaaaaaaaaaaa');
  return (
    <div className="order-wrapper">
      {details && (
        <>
          <div className="back-button-remove">
            <WEBackButton text={backText} redirectHistory={backLink} />
          </div>
          {/* <WEBackButton text={backText} redirectHistory={backLink} /> */}
          <OrderSummaryDetails
            details={details}
            mealSlotData={mealSlotData}
            setExtrasPopup={setExtrasPopup}
            extrasPopup={extrasPopup}
            extrsData={extrsData}
            setAddedExtras={setAddedExtras}
            addedExtras={addedExtras}
            skipAddOrder={skipAddOrder}
            AddOrderNew={AddOrderNew}
            setIsSkip={setIsSkip}
            paymentPopup={paymentPopup}
            setPaymentPopup={setPaymentPopup}
            paymentMethods={paymentMethods}
            skipAddOrderPayment={skipAddOrderPayment}
            handlePayment={handlePayment}
            cancellationTime={cancellationTime}
            t={t}
            confirmBtn={
              <ActiveButton
                justifyBtn={''}
                cb={
                  extrsData?.length > 0
                    ? handleextrasPopup
                    : MakeOrderwithoutExtras
                }
                label={t('orderConfirmation')}
              />
            }
          />

          <OrderConfirmationPopup
            mealExtra={mealExtra}
            toggle={togglePopup}
            details={details}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  OrderStatuses: state.app.enums.OrderStatus,
  MealTypes: state.app.enums.MealType,
  user: state.user,
  kitchen: state.kitchens.current,
});
const mapDispatchToProps = {
  setLayoutOptions,
  showAlert,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
