import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { StaticStars } from '../../components/RatingStars';
import Tippy from '@tippyjs/react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};
const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  let formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

const ViewCopyPreviousMealDetail = ({
  open,
  handleClose,
  details,
  handleAddDishAll,
  currentLanguage,
  previousMeal,
}) => {
  const { t, i18n } = useTranslation();

  const d = { ...details?.Chefdish };
  const propertyValues = Object.values(d);
  let filter = propertyValues.filter((ele) => ele.isChefMealDish === true);

  const percentToDisplay = (value, outOfValue) =>
    outOfValue > 0 ? Number.parseInt((value / outOfValue) * 100) : 0;
  console.log('details', details);
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-employee-popup view-dish-details"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('previousMealDetail')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup">
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('date')}: </h6>
                  <p>{details?.date}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('day')}:</h6>
                  <p>{details?.day}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('mealTypeName')}:</h6>
                  <p>{details?.mealtype}</p>
                </div>

                <div className="user-details-block">
                  <h6>{t('inventory')}:</h6>
                  <p>{details?.inventory}</p>
                </div>

                <div className="user-details-block">
                  <h6>{t('orders')}:</h6>
                  <p>
                    {previousMeal?.ordered}{' '}
                    {`(${percentToDisplay(
                      details?.order,
                      details?.inventory
                    )}%)`}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('realized')}:</h6>
                  <p>
                    {previousMeal?.ordered}{' '}
                    {`(${percentToDisplay(
                      details?.realized,
                      details?.inventory
                    )}%)`}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('chefDish')}:</h6>
                  <p>
                    <div className={'percent-data'}>
                      <p className={'inventory'}>
                        {`${
                          filter?.length > 0
                            ? filter?.map((el) => el?.name[1])
                            : t('none')
                        }`}
                      </p>
                    </div>
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('noofDishes')}:</h6>
                  <p>
                    {' '}
                    <Tippy
                      content={
                        <>
                          {propertyValues && (
                            <div className="hover-popup">
                              {propertyValues &&
                                propertyValues.map((el) => (
                                  <p>{el?.name[currentLanguage.id]}</p>
                                ))}
                            </div>
                          )}
                        </>
                      }
                    >
                      <p className={'inventory'} id="myButton">
                        {`${propertyValues ? propertyValues?.length : 0}`}
                      </p>
                    </Tippy>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Typography>
        <div className="view-popup-btn">
          <button
            className="close-modal-btn"
            onClick={handleAddDishAll(details)}
          >
            {t('CopyMeal')}
          </button>
        </div>
      </Box>
    </Modal>
  );
};
export default ViewCopyPreviousMealDetail;
