import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import WEIcon from '../../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../../services/http';
import Select from 'react-select';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import User from './User';
import { isTabletOrMobile } from '../../services/mediaQueries';
import { sleep } from '../../services/utils';
import Kitchen from './Kitchen';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import { getKitchens } from '../../store/kitchens/actions';
// import { Button } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import searchimg from '../../assets/images/Search.svg';
import AddKitchen from './AddKitchen';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import agGridLocales from '../../translations/ag-grid';
const KitchenManagement = ({
  kitchen,
  CompanyId,
  setLayoutOptions,
  KitchenStatuses,
  Kitchens,
  Languages,
  CurrentLanguage,
  useLoader,
  useFetchingLoader,
  getKitchens,
}) => {
  const Endpoint = 'kitchens';
  const { t, i18n } = useTranslation();
  const KitchenStatusesMap = KitchenStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  const [kitchens, setKitchens] = useState(null);
  const [kitchenToDisplay, setKitchenToDisplay] = useState(null);
  const [addKitchen, setAddKitchen] = useState(false);
  const [dummy, setdummy] = useState(false);
  useEffect(async () => await useLoader(() => sleep()), []);

  useEffect(async () => {
    setLayoutOptions({ title: t(`${Endpoint}Management`) });
    try {
      let { data } = await useFetchingLoader(
        async () =>
          await http.get(`${Endpoint}`, {
            filter: { kitchenStatusId: KitchenStatusesMap.active },
          })
      );

      data = await Promise.all(
        data.map(
          async (kitchen) =>
            await http.get(`${Endpoint}/${kitchen.id}`, { language: 'all' })
        )
      );
      setKitchens(data);
    } catch (err) {
      Alert({ error: err });
    }
  }, [kitchen?.id, dummy]);

  /* Search section */
  const [search, setSearch] = useState('');
  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const [toggleSearch, setToggleSearch] = useState(false);
  const handleSearchToggle = () => setToggleSearch(!toggleSearch);

  const searchRef = useRef();
  useEffect(() => {
    if (toggleSearch) searchRef.current.focus();
  }, [toggleSearch]);

  useEffect(() => {
    let filteredKitchens = kitchens?.filter(
      (kitchen) =>
        kitchen.kitchenStatusId === KitchenStatusesMap.active &&
        kitchen.translations?.some(
          (translation) =>
            translation.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
    );
    setKitchenToDisplay(filteredKitchens);
  }, [kitchens, search, dummy]);

  const handleDelete = async (id) => {
    SwalDialogs.Dialog({
      title: 'Are you sure you want to delete this Kitchen',
      // text: 'fhdh',
      // type: 'success',
      onConfirm: async () => {
        await useFetchingLoader(
          async () =>
            await http.put(`${Endpoint}/${id}`, {
              kitchenStatusId: KitchenStatusesMap.deleted,
            })
        );
        setKitchens((state) =>
          state.map((kitchen) =>
            kitchen.id === id
              ? {
                  ...kitchen,
                  kitchenStatusId: KitchenStatusesMap.deleted,
                }
              : kitchen
          )
        );
        SwalDialogs.Dialog({
          title: 'Kitchen Deleted Successfully',
          // text: 'fhdh',
          type: 'success',
          onConfirm: () => {
            setEditMode(false);
            handleClose();
          },
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('replaceOrderBtnLabel'),
            // cancelButtonText: t('back'),
          },
        });
      },
      buttons: {
        showCancelButton: true,
        confirmButtonText: t('replaceOrderBtnLabel'),
        cancelButtonText: t('back'),
      },
    });
  };

  const handleUpdate = async (id, data) => {
    try {
      await useFetchingLoader(
        async () => await http.put(`${Endpoint}/${id}`, { ...data })
      );
    } catch (err) {
      Alert({ error: err });
    }
  };

  const [toggleAdd, setToggleAdd] = useState(false);
  const [translations, setTranslations] = useState();
  const handleAddToggle = () => setToggleAdd(true);
  const [terminalNumber, setTerminalNumber] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState();

  const handleAddNew = async (data) => {
    try {
      data = {
        ...data,
        companyId: CompanyId,
        kitchenStatusId: KitchenStatusesMap.active,
      };
      await useFetchingLoader(async () => {
        let result = await http.post(`${Endpoint}/create`, data);
        await setKitchens((state) => [...state, { ...data, id: result.id }]);
      });
      setToggleAdd(false);
    } catch (err) {
      Alert({ error: err });
    }
  };

  const tableLabels = ['name'];

  const handleKitchen = (value) => {
    if (value === 'add') {
      setAddKitchen(!addKitchen);
      setTerminalNumber('');
      setTranslations({});
      setdummy(false);
    } else if (value === 'edit') {
      setAddKitchen(!addKitchen);
      setdummy(false);
    }
  };

  const handleClose = () => {
    setAddKitchen(false);
  };
  const handleFormSubmit = async (event) => {
    if (editMode) {
      event.preventDefault();
      await handleUpdate(editId, {
        translations: Object.values(translations),
        terminalNumber: terminalNumber,
      });
      SwalDialogs.Dialog({
        title: 'Kitchen Updated Successfully',
        // text: 'fhdh',
        type: 'success',
        onConfirm: () => {
          setdummy(true);
          setEditMode(false);
          handleClose();
        },
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('replaceOrderBtnLabel'),
          // cancelButtonText: t('back'),
        },
      });
    } else {
      event.preventDefault();
      await handleAddNew({
        name: translations[CurrentLanguage.id].name,
        terminalNumber: terminalNumber,
        translations: Object.values(translations),
      });
      SwalDialogs.Dialog({
        title: 'Kitchen Added Successfully',
        // text: 'fhdh',
        type: 'success',
        onConfirm: () => {
          setdummy(true);
          handleClose();
        },
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('replaceOrderBtnLabel'),
          // cancelButtonText: t('back'),
        },
      });
    }
  };

  return (
    <div className="kitchen-management global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-input">
            <input
              className={`input ${toggleSearch ? 'toggle' : ''}`}
              ref={searchRef}
              type={'text'}
              defaultValue={search}
              onKeyUp={(e) => handleSearchChange(e.target.value)}
              placeholder={`${t('search')}`}
            />
            <img src={searchimg} alt="search" />
          </div>
          <div className="active-button">
            <Button onClick={() => handleKitchen('add')}>
              <i class="las la-plus"></i> <span>{t('addKitchen')}</span>
            </Button>
          </div>
        </div>
        {isTabletOrMobile() ? (
          <p>{t('systemKitchens')}</p>
        ) : (
          <section className="table-labels">
            {tableLabels.map((label) => (
              <p className={`h-${label}`}>{t(`${label}PH`)}</p>
            ))}
          </section>
        )}

        {kitchenToDisplay && kitchenToDisplay.length > 0 ? (
          <div className={'kitchens'}>
            <div className="item kitchen-header">
              <div className="kitchen-form">
                <div className="left-listing">
                  {Languages.map((el) => (
                    <h6>
                      {el.name === 'hebrew'
                        ? t('hebrew')
                        : el.name === 'english'
                        ? t('english')
                        : el.name === 'russian'
                        ? t('russian')
                        : ''}
                    </h6>
                  ))}
                  {/* <h6>{t('hebrew')}</h6>
                  <h6>{t('english')}</h6>
                  <h6>{t('russian')}</h6> */}
                  <h6>{t('terminalNumber')}</h6>
                </div>
                <div className="right-listing">
                  <h6>{t('action')}</h6>
                </div>
              </div>
            </div>

            {kitchenToDisplay
              .sort((k1, k2) => k1?.name?.localeCompare(k2.name))
              .map((kitchen) => (
                <Kitchen
                  key={kitchen.id}
                  data={{
                    ...kitchen,
                    translations:
                      kitchen.translations?.reduce(
                        (acc, translation) => ({
                          ...acc,
                          [translation.languageId]: translation,
                        }),
                        {}
                      ) || {},
                  }}
                  translations={translations}
                  KitchenStatuses={KitchenStatuses}
                  CurrentLanguage={CurrentLanguage}
                  Kitchens={Kitchens}
                  localeText={agGridLocales[i18n.language]}
                  Languages={Languages}
                  setTranslations={setTranslations}
                  handleDelete={handleDelete}
                  handleUpdate={handleUpdate}
                  companyId={CompanyId}
                  getKitchens={getKitchens}
                  addKitchen={addKitchen}
                  handleKitchen={handleKitchen}
                  terminalNumber={terminalNumber}
                  setTerminalNumber={setTerminalNumber}
                  setEditMode={setEditMode}
                  setEditId={setEditId}
                />
              ))}
          </div>
        ) : (
          <div className="no-kitchens">
            <p>{t('noKitchens')}</p>
          </div>
        )}
      </div>
      <AddKitchen
        setTerminalNumber={setTerminalNumber}
        handleFormSubmit={handleFormSubmit}
        open={addKitchen}
        handleClose={handleClose}
        Languages={Languages}
        translations={translations}
        setTranslations={setTranslations}
        t={t}
        terminalNumber={terminalNumber}
        editMode={editMode}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  KitchenStatuses: state.app.enums.KitchenStatus,
  Kitchens: state.kitchens.options,
  CompanyId: state.user.companyId,
  Languages: state.languages.options,
  CurrentLanguage: state.languages.current,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getKitchens,
};
export default connect(mapStateToProps, mapDispatchToProps)(KitchenManagement);
