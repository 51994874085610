import { AgGridReact } from 'ag-grid-react';
import { useFetchingLoader } from '../../store/app/actions';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMealTypeReservations } from '../../store/kitchens/actions';
import agGridLocales from '../../translations/ag-grid';
import { setLayoutOptions } from '../../store/app/actions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
// import { Dialog } from '../../components/Dialogs/SwalDialogs';
// import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import file from '../../assets/images/file-export.svg';
import next from '../../assets/images/next.png';
import search from '../../assets/icons/search.png';
import ViewReservationdetails from './viewReservationdetails';
import { useLocation } from 'react-router-dom';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import FilterIcon from '../../assets/images/filterIcon.png';
import Pagination from 'react-bootstrap/Pagination';
import http from '../../services/http';
import Toast from 'react-bootstrap/Toast';

const ByMealTypeReservationManagement = ({
  kitchen,
  reservations,
  getMealTypeReservations,
  totalReservationsCount,
  useFetchingLoader,
  // setLayoutOptions,
  currentLanguage,
  getPaymentPreferences,
  companyName,
}) => {
  const { t, i18n } = useTranslation();

  const location = useLocation<any>();
  //const state = location.state as CustomizedState;

  const [reserData, setReserData] = useState([{}]);
  const [, setMaxDate] = useState<any>(false);
  // const [viewData, setViewData] = useState(false);
  const [gridApi, setGridApi] = useState<any>();
  const [, setColumnApi] = useState<any>();
  // const [jsonData, setJsondata] = useState();
  const [tableColumn, setTableColumn] = useState<any>();
  const [details, setDetails] = useState<any>();
  const [viewDetails, setViewDetails] = useState<any>();
  const [dummy] = useState<any>();
  const { mealDate } = location?.state ?? {};

  const [fromDate, setFromdate] = useState<any>(
    mealDate ? new Date(mealDate) : new Date()
  );
  const [fromDateFormat, setfromDateFormat] = useState(
    mealDate ? new Date(mealDate) : new Date()
  );
  const [toDateFormat, settoDateFormat] = useState(
    mealDate ? new Date(mealDate) : new Date()
  );
  const [toDate, setToDate] = useState<any>(
    mealDate ? new Date(mealDate) : new Date()
  );
  const [filterOrder, setFilterData] = useState<any>();
  const [showPaymentOption, setShowPaymentOption] = useState();

  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs] = useState(25); // Records per page
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [columnAndOrder, setColumnAndOrder] = useState<any>({});
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<any>();

  // console.log(columnApi, maxDates, mealDate, mealTypeId);

  useEffect(() => {
    if (kitchen) {
      // useFetchingLoader(async () => {
      const data = getPaymentPreferences(kitchen?.id, '');
      setShowPaymentOption(data.selectedPaymentOptions);
      // });
    }
  }, [kitchen]);

  useEffect(() => {
    if (reservations) {
      setpageCountsLogs(Math.ceil(totalReservationsCount / newPageSizeLogs));
    }
  }, [reservations]);

  useEffect(() => {
    if (reservations) {
      setpageCountsLogs(Math.ceil(totalReservationsCount / newPageSizeLogs));
    }
  }, []);
  useEffect(() => {
    useFetchingLoader(async () => {
      await getMealTypeReservations(
        kitchen?.id,
        new Date().toISOString().split('T')[0],
        new Date().toISOString().split('T')[0],
        currentPageAllLogs,
        newPageSizeLogs,
        'field' in columnAndOrder ? columnAndOrder?.field : '',
        'order' in columnAndOrder ? columnAndOrder?.order : ''
      );
    });
  }, []);

  useEffect(() => {
    if (reservations && !filterOrder) {
      gridApi?.setRowData(reservations || []);
      setReserData(reservations || []);
      if (searchText) {
        gridApi?.setQuickFilter(searchText);
      }
    } else {
      let filter = reservations.filter(
        (el: any) =>
          el?.orderStatusId === 1 ||
          el?.orderStatusId === 2 ||
          el?.orderStatusId === 3
      );
      setReserData(filter);
      gridApi?.setRowData(filter);
      if (searchText) {
        gridApi?.setQuickFilter(searchText);
      }
    }
  }, [reservations, dummy]);

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };

  const handleFilterChanged = (e) => {
    const value = e.target.value;
    setSearchText(value);
    gridApi.setQuickFilter(value);
  };
  const lang = localStorage.getItem('language');

  const columnTotalMealTypeDefs = [
    {
      field: 'display',
      headerName: `${t('mealTypeName')}`,
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'inventory',
      headerName: ` ${t('totalOfInventory')}`,
      sortable: true,
      comparator: () => {},
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'ordered',
      headerName: `${t('ofreservations')}`,
      sortable: true,
      comparator: () => {},
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'realized',
      headerName: `${t('realized')}`,
      sortable: true,
      comparator: () => {},
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'reservedInventory',
      headerName: ` ${t('perceReservedInventory')}`,
      resizable: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.reservedInventory} %`;
      },
    },
    {
      field: 'realizedInventory',
      headerName: ` ${t('ofRealisedInventory')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.realizedInventory} %`;
      },
    },
    {
      field: 'realizedReserved',
      headerName: ` ${t('ofRealizedReserved')}`,
      resizable: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.realizedReserved} %`;
      },
    },
    {
      field: 'creditPoints',
      headerName: ` ${t('totalCreditPointRequest')}`,
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'pending',
      headerName: ` ${t('pendingCreditPointRequest')}`,
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'approval',
      headerName: ` ${t('approvedCreditPointReqquest')}`,
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'percent',
      headerName: ` ${t('creditPointPercentage')}`,
      resizable: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.percent} %`;
      },
    },
  ];

  const mobcolumnTotalMealTypeDefs = [
    {
      field: 'display',
      headerName: `${t('mealTypeName')}`,
      suppressMovable: true,
    },
    {
      field: 'inventory',
      headerName: ` ${t('totalOfInventory')}`,
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [columnAndOrder, currentPageAllLogs]);

  const handleChange = async (name: any, value: any) => {
    setFilterData(false);
    if (name === 'fromdate') {
      if (!value) {
        setFromdate(null);
      }
      let maxDate = new Date();
      setMaxDate(moment(maxDate).format('YYYY-MM-DD'));
      setfromDateFormat(value);
      setFromdate(new Date(value));
      fetchData(
        moment(value).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD')
      );
    } else if (name === 'todate') {
      if (!value) {
        setToDate(null);
      }
      const tempToDate = new Date(value);
      settoDateFormat(value);
      setToDate(tempToDate);
      fetchData(
        moment(fromDate).format('YYYY-MM-DD'),
        moment(tempToDate).format('YYYY-MM-DD')
      );
    }
  };

  const fetchData = async (fromDateParam?: any, toDateParam?: any) => {
    useFetchingLoader(async () => {
      await getMealTypeReservations(
        kitchen?.id,
        fromDateParam ??
          moment(fromDate).format('YYYY-MM-DD') ??
          new Date().toISOString().split('T')[0],
        toDateParam ??
          moment(toDate).format('YYYY-MM-DD') ??
          new Date().toISOString().split('T')[0],
        currentPageAllLogs,
        newPageSizeLogs,
        'field' in columnAndOrder ? columnAndOrder?.field : '',
        'order' in columnAndOrder ? columnAndOrder?.order : ''
      );
    });
  };
  /***********Export CSV file */
  const onBtnExport = async (exportCsvFileNames) => {
    try {
      if (disableButton || reservations.length === 0) {
        console.log('Disabled or empty table', exportCsvFileNames);
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        const arr = [
          'mealTypeName',
          'totalOfInventory',
          'ofreservations',
          'realized',
          'perceReservedInventory',
          'ofRealisedInventory',
          'ofRealizedReserved',
          'totalCreditPointRequest',
          'pendingCreditPointRequest',
          'approvedCreditPointReqquest',
          'creditPointPercentage',
        ];
        const resArr = arr.map((el) => t(el));
        const res = await http.post(
          `kitchens/${kitchen?.id}/getReportMealsTypeReservationsFile/download`,
          {
            isReport: true,
            headers: resArr,
            filename: exportCsvFileNames,
            isFile: false,
          },
          {
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            page: currentPageAllLogs,
            limit: newPageSizeLogs,
          }
        );

        const downloadUrl = window.URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv' })
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', exportCsvFileNames);
        document.body.appendChild(link);
        link.click();
        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  /*const handleViewData = (id: any) => {
    setViewData(!viewData);
    setJsondata(id);
  };*/

  const gridOptions = {
    onFilterChanged: () => {},
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setTableColumn(mobcolumnTotalMealTypeDefs);
    } else {
      setTableColumn(columnTotalMealTypeDefs);
    }
  }, [showPaymentOption]);

  const handleviewmore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  const [mobFilter, showMobFilter] = useState(false);
  const handleHeight = () => {
    showMobFilter(!mobFilter);
  };
  return (
    <>
      <div className="reservation-management global-data-table reservation-updated-page">
        <div className="container">
          <div className="add-employee-btn">
            <div className="left-filter">
              <div className="filter-section">
                <div className="search-filter">
                  <div className="xyz">
                    <div className="from-date">
                      <label>{t('fromDate')}</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={
                          fromDateFormat
                            ? fromDateFormat
                            : mealDate
                            ? new Date(mealDate)
                            : new Date()
                        }
                        name="fromDate"
                        onChange={(event) => handleChange('fromdate', event)}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                    <div className="from-date">
                      <label>{t('toDate')}</label>

                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={
                          toDateFormat
                            ? toDateFormat
                            : mealDate
                            ? new Date(mealDate)
                            : new Date()
                        }
                        name="toDate"
                        onChange={(event) => handleChange('todate', event)}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-input searchBtn">
                <label>{t('search')}</label>
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={t('freeTextSearch')}
                  onInput={handleFilterChanged}
                />
                <img src={search} alt="search" />
              </div>
              <button
                onClick={handleHeight}
                className="search-button filter-btn"
              >
                <img src={FilterIcon} alt="filter" />
              </button>
            </div>

            <div className="active-button">
              <div className="total-data">
                <p>
                  {t('totalData')}: <span>{totalReservationsCount}</span>
                </p>
              </div>
              <div className="actions">
                <img
                  src={file}
                  alt="add"
                  onClick={() =>
                    onBtnExport(
                      `${companyName}-${kitchen.name}-${t(
                        'totalByMeals'
                      )}-${moment(fromDate).format('YYYY-MM-DD')}-${moment(
                        toDate
                      ).format('YYYY-MM-DD')}`
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={
                reserData.length
                  ? reserData.map((reservation) => ({
                      ...reservation,
                      kitchen,
                    }))
                  : []
              }
              onSortChanged={() => printSortStateToConsole()}
              columnDefs={tableColumn}
              localeText={agGridLocales[i18n.language]}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onGridColumnsChanged={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              frameworkComponents={{
                ViewMore: (e) => (
                  <ViewMore handleviewmore={handleviewmore} e={e} />
                ),
              }}
              reactUi={true}
              enableRtl={lang === '"he"' ? true : false}
              pagination={false}
              paginationPageSize={50}
              overlayLoadingTemplate={'<p></p>'}
              paginationNumberFormatter={function (params) {
                return params.value.toLocaleString();
              }}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                <p>
                  {newPageSizeLogs * (currentPageAllLogs - 1) + 1} to{' '}
                  {newPageSizeLogs * currentPageAllLogs} of{' '}
                  {totalReservationsCount}
                </p>
              </div>
              <Pagination>
                <Pagination.First
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(1);
                  }}
                />
                <Pagination.Prev
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs > 1
                      ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                      : '';
                  }}
                />

                <Pagination.Item active>
                  Page {currentPageAllLogs} of{' '}
                  {Math.ceil(totalReservationsCount / newPageSizeLogs)}
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs <
                    Math.ceil(totalReservationsCount / newPageSizeLogs)
                      ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                      : '';
                  }}
                />
                <Pagination.Last
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(
                      Math.ceil(totalReservationsCount / newPageSizeLogs)
                    );
                  }}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <ViewReservationdetails
        open={viewDetails}
        details={details}
        handleClose={handleviewmore}
        reportType={'MealType'}
        handleOpen={() => {}}
        reserData={reserData}
        setDetails={setDetails}
        handleCancelOrder={() => {}}
        currentLanguage={currentLanguage}
      />
      <Toast
        onClose={() => setOpenDownload(false)}
        show={openDownload}
        style={{
          position: 'sticky',
          right: '20px',
          bottom: '70px',
        }}
      >
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
            onClick={() => setOpenDownload(false)}
          />
        </Toast.Header>
        <Toast.Body>{t('downloadFile')}</Toast.Body>
      </Toast>
    </>
  );
};
const ViewMore = ({ e, handleviewmore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleviewmore(e.data)} />
    </div>
  );
};
/*const ConfirmationPopup = ({
  open,
  handleClose,
  prefetchData,
  jsonData,
  t,
}) => {
  let jsondataValue = {};
  if (!prefetchData) {
    prefetchData = [];
  }

  jsondataValue = []?.filter((el: any) => el.id === jsonData);
  const formatter =
    jsondataValue && jsondataValue[0]
      ? new JSONFormatter(JSON.parse(jsondataValue[0]?.chargeResponse || '{}'))
      : '';
  let element = document.getElementById('json');

  if (element && formatter !== '') {
    element.innerHTML = '';
    element.appendChild(formatter.render());
  }

  return (
    <>
      {open ? <div className="bg-shadow" /> : ''}
      <div
        className={`add-employee-popup delete-popup log-json-data reservation-popup ${
          open ? 'open-json-popup' : ''
        }`}
      >
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <h6>{t('updatedData')}</h6>
        <div id="modal-modal-description">
          <div className="copy-url">
            <div className="confirmation-btn" id="json"></div>
            <button className="copy-btn" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};*/

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
  currentLanguage: state.languages.current,
  currentUser: state.user,
  totalReservationsCount: state.kitchens.totalReservations,
  companyName: state.companyConfig.companyName,
});

const mapDispatchToProps = {
  getMealTypeReservations,
  setLayoutOptions,
  useFetchingLoader,
  getPaymentPreferences,
};

// export default ReservationManagement;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ByMealTypeReservationManagement);
