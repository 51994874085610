import React, { useEffect, useState, useRef } from 'react';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { connect } from 'react-redux';

/* Charts */
import { LineChart, PieChart, BarChart } from '../Charts';
import Chart from 'react-google-charts';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import http from '../../services/http';
import CardBackground from '../../assets/images/food_bg.png';
import dates from '../../services/dates';
import { ActiveButton, DisabledButton } from '../../components/WE-Button';
import { StaticStars } from '../../components/RatingStars';
import { sleep } from '../../services/utils';
import WEBackButton from '../../components/WE-BackButton';
import moment from 'moment';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import menus from '../../assets/images/control-panel/menus.svg';
import revenue from '../../assets/images/control-panel/revenue.svg';
import orders from '../../assets/images/control-panel/orders.svg';
import client from '../../assets/images/control-panel/client.svg';
import { Button, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReviewChart } from '../SiteManager/Lobby';

const colorArray = [
  '#FF6633',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#FF99E6',
  '#CCFF1A',
  '#6666FF',
  '#1AB399',
  '#E6FF80',
  '#1AFF33',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
];

const ControlPanel = ({
  user,
  kitchen,
  MealTypes,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(async () => await useLoader(() => sleep()), []);

  const [daysToFetch, setDaysToFetch] = useState(30);
  const [nextMeal, setNextMeal] = useState(null);
  const [lastMeal, setLastMeal] = useState(null);
  const [popularDishes, setPopularDishes] = useState(null);
  const [mealsRatings, setMealsRatings] = useState(null);
  const [fromDate, setFromdate] = useState();
  const [toDate, setToDate] = useState();
  const [fromDatePopular, setFromDatePopular] = useState();
  const [toDatePopular, setToDatePopular] = useState();
  const [mealRatingsData, setMealRatingsData] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [userKitchen, setUserKitchen] = useState();
  const [paymentType, setPaymentType] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const MealTypeMap = {};
  MealTypes.forEach((meal) => (MealTypeMap[meal.id] = meal.value));
  const wrapperRef = useRef(null);

  useEffect(async () => {
    if (!kitchen) return;
    setLayoutOptions({ title: t('controlPanel') });
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const { nextMeal, lastMeal, popularDishes, mealsRatings } =
      await useFetchingLoader(
        async () =>
          await http.get(`kitchens/${kitchen.id}/controlPanel`, {
            fromDate: moment(firstDay).format('YYYY-MM-DD'),
            toDate: moment(lastDay).format('YYYY-MM-DD'),
          })
      );
    setNextMeal(nextMeal);
    setLastMeal(lastMeal);
    let popDishes = {
      days: daysToFetch,
      data: popularDishes.map((dish) => [
        dish.dish,
        Number.parseInt(dish.ordered),
      ]),
    };
    setPopularDishes(popDishes);
    let ratings = {
      days: daysToFetch,
      data: mealsRatings.map((dateInDate) => [
        dates.ddmmFormat(dateInDate.date),
        dateInDate.rating ? Number.parseInt(dateInDate.rating) : 0,
      ]),
    };
    setMealsRatings(ratings);
    setMealRatingsData(ratings?.data);
  }, [kitchen]);

  const Roles = {
    1: 'admin',
    2: 'siteManager',
    3: 'kitchenManager',
    4: 'client',
    9: 'kitchenWorker',
  };
  // const RolesMap =
  const currentRole = Roles[user.roleId];

  const handleDate = (name, value) => {
    if (name === 'fromdate') {
      if (!value) {
        return setFromdate(null);
      }
      setFromdate(new Date(value));
    } else if (name === 'todate') {
      if (!value) {
        return setToDate(null);
      }
      setToDate(new Date(value));
    }
  };

  const handleDatePopular = (name, value) => {
    if (name === 'fromdate') {
      if (!value) {
        return setFromDatePopular(null);
      }
      setFromDatePopular(new Date(value));
    } else if (name === 'todate') {
      if (!value) {
        return setToDatePopular(null);
      }
      setToDatePopular(new Date(value));
    }
  };

  const updateData = async () => {
    const data = await useFetchingLoader(
      async () =>
        await http.get(`kitchens/${kitchen.id}/controlPanelDishRating`, {
          fromDate: moment(fromDate).format('YYYY-MM-DD'),
          toDate: moment(toDate).format('YYYY-MM-DD'),
        })
    );
    let ratings = {
      days: daysToFetch,
      data: data.map((dateInDate) => [
        dates.ddmmFormat(dateInDate.date),
        dateInDate.rating ? Number.parseInt(dateInDate.rating) : 0,
      ]),
    };
    // console.log("ratings?.data",ratings?.data)
    setMealRatingsData(ratings?.data);
  };
  const updateDataPopular = async () => {
    const data = await useFetchingLoader(
      async () =>
        await http.get(`kitchens/${kitchen.id}/controlPanelPopularMeal`, {
          fromDate: moment(fromDatePopular).format('YYYY-MM-DD'),
          toDate: moment(toDatePopular).format('YYYY-MM-DD'),
        })
    );
    let popDishes = {
      days: daysToFetch,
      data: data.map((dish) => [dish.dish, Number.parseInt(dish.ordered)]),
    };
    setPopularDishes(popDishes);
  };
  const handleDropdownClick = () => {
    setOpenDropdown(!openDropdown);
  };
  const handlePayment = async (type) => {
    // call kitchen payment method  data
    if (type === 'hyp') {
      const data = getPaymentPreferences(kitchen.id, type);
      setPaymentMethod(data.selectedPaymentOptions);
      history.push(`/paymentManagement?paymenttype=hyp`);
    }
    if (type === 'nayax') {
      const data = getPaymentPreferences(kitchen.id, type);
      setPaymentMethod(data.selectedPaymentOptions);
      history.push(`/paymentManagement?paymenttype=nayax`);
    }
  };
  return (
    <div className="control-panel-payment">
      <div className="container">
        <div className={'control-panel'}>
          <>
            <div className="grid-layout">
              <div className="box-layout">
                <div className="box-layout-grid">
                  <span>
                    <img src={menus} alt="menus" />
                  </span>
                  <div className="total-items">
                    <h6>27</h6>
                  </div>
                </div>
                <p>Total Menus</p>
              </div>

              <div className="box-layout">
                <div className="box-layout-grid">
                  <span>
                    <img src={revenue} alt="menus" />
                  </span>
                  <div className="total-items">
                    <h6>6k</h6>
                  </div>
                </div>
                <p>Total Revenue</p>
              </div>
              <div className="box-layout">
                <div className="box-layout-grid">
                  <span>
                    <img src={orders} alt="menus" />
                  </span>
                  <div className="total-items">
                    <h6>20</h6>
                  </div>
                </div>
                <p>Total Orders</p>
              </div>
              <div className="box-layout">
                <div className="box-layout-grid">
                  <span>
                    <img src={client} alt="menus" />
                  </span>
                  <div className="total-items">
                    <h6>31</h6>
                  </div>
                </div>
                <p>Total Client</p>
              </div>
            </div>
            <div className="grid-revenue-alyout">
              <div className="grid-revenue-layout-left">
                <div className="order-summary-section">
                  <div className="order-summary">
                    <h6>Orders Summary</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                  <div className="active-month">
                    <Button>Monthly</Button>
                    <Button>Weekly</Button>
                    <Button>Today</Button>
                  </div>
                </div>
                <div className="new-orders-section">
                  <div className="new-orders">
                    <span>25</span>
                    New Orders
                  </div>
                  <div className="manage-orders">
                    <Link to="/">Manage orders</Link>
                  </div>
                </div>
                <div className="order-status-section">
                  <div className="order-status-box">
                    <h6>3</h6>
                    <p>On Delivery</p>
                  </div>
                  <div className="order-status-box">
                    <h6>8</h6>
                    <p>Delivered</p>
                  </div>
                  <div className="order-status-box">
                    <h6>8</h6>
                    <p>Canceled</p>
                  </div>
                </div>
                <div className="dishes-section">
                  <div className="chart-section">
                    <DonutChart />
                  </div>
                  <div className="pregress-bar-section">
                    <div className="chart-list ">
                      Dish (24%)
                      <div className="dish-progressbar">
                        <ProgressBar variant="success" now={80} />
                        <span>10</span>
                      </div>
                    </div>
                    <div className="chart-list ">
                      Order (24%)
                      <div className="order-progressbar">
                        <ProgressBar variant="success" now={40} />
                        <span>10</span>
                      </div>
                    </div>
                    <div className="chart-list ">
                      Dish (24%)
                      <div className="other-progressbar">
                        <ProgressBar variant="success" now={60} />
                        <span>10</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-revenue-layout-right">
                <div className="order-summary-section">
                  <div className="order-summary">
                    <h6>Revenue</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                  <div className="active-month">
                    <Button>Monthly</Button>
                  </div>
                </div>
                <div className="income-section">
                  <div className="income-box">
                    <p>Income</p>
                    <h6>$5,397K</h6>
                  </div>
                  <div className="expense-box">
                    <p>Expense</p>
                    <h6>$5,397K</h6>
                  </div>
                </div>
                <div className="revenue-chart-section">
                  <RevenueChart />
                </div>
              </div>
            </div>
            <div className="control-panel-review-section">
              <div className="control-panel-review-chart">
                <ReviewChart />
              </div>
              <div className="daily-target-section">
                <div className="daily-target-head">
                  <span></span>
                  <div className="daily-target-heading">
                    <h6>Daily Target Income</h6>
                    <p>Lorem ipsum dolor sit</p>
                  </div>
                </div>
                <div className="daily-target-progress">
                  <CircularProgressbar value={70} text={`${0.7 * 100}%`} />
                </div>
                <div className="daily-target-footer">
                  <div className="today-amnt">
                    <p>$749.56</p>
                  </div>
                  <div className="target-amnt">
                    <p>from $1,000</p>
                  </div>
                  <div className="daily-target-status">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do
                    </p>
                  </div>
                  <div className="daily-target-detail">
                    <Link to="#">More Details</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-chart-section">
              <PanelChart
                title={t('averageMeal')}
                handleDate={handleDate}
                toDate={toDate}
                fromDate={fromDate}
                updateData={updateData}
                t={t}
                mainContent={
                  mealRatingsData?.length > 0 ? (
                    <LineChart
                      data={mealRatingsData}
                      colors={['#22D03D']}
                      size={{ h: 300 }}
                    />
                  ) : (
                    <p>{t('noAverageMealReview')}</p>
                  )
                }
              />

              <PanelChart
                title={` ${t('popuplar')} ${popularDishes?.data.length} ${t(
                  'dishes'
                )}`}
                handleDate={handleDatePopular}
                toDate={toDatePopular}
                updateData={updateDataPopular}
                fromDate={fromDatePopular}
                t={t}
                mainContent={
                  popularDishes?.data?.length > 0 ? (
                    <BarChart
                      data={popularDishes?.data
                        ?.sort((m1, m2) => m2[1] - m1[1])
                        .map((meal, idx) => [...meal, colorArray[idx]])}
                      size={{ h: 40 * popularDishes?.data?.length }}
                    />
                  ) : (
                    <p>{t('noPopularDishes')}</p>
                  )
                }
              />
            </div>
            <div className="grid">
              {/* Next Meal */}
              <PanelCard
                title={t('nextMeal')}
                subTitle={
                  nextMeal &&
                  `${dates.format(nextMeal?.date)} / ${
                    MealTypeMap[nextMeal?.mealTypeId]
                  }`
                }
                mainContent={
                  <>
                    {nextMeal?.inventory > 0 ? (
                      <div className={'orders'}>
                        <p className={'chart-content up'}>{`${t(
                          'orders'
                        )} ${Math.round(
                          (nextMeal.ordered / nextMeal.inventory) * 100
                        )}%`}</p>
                        <PieChart
                          data={[
                            [
                              'Ordered',
                              Math.round(
                                (nextMeal.ordered / nextMeal.inventory) * 100
                              ),
                            ],
                            [
                              'Inventory left',
                              100 -
                                Math.round(
                                  (nextMeal.ordered / nextMeal.inventory) * 100
                                ),
                            ],
                          ]}
                          size={{ w: 200, h: 200 }}
                          colors={['#7BED8D']}
                        />
                      </div>
                    ) : (
                      <p>{t('noInventoryInMeal')}</p>
                    )}
                  </>
                }
                actionBtn={
                  nextMeal?.inventory > 0 ? (
                    <ActiveButton
                      wrapperClassname={'control-btn'}
                      label={t('mealSegmentation')}
                      arrow={true}
                      cb={() =>
                        history.push(
                          `/mealReport/${nextMeal.date}/${nextMeal.mealTypeId}`
                        )
                      }
                    />
                  ) : (
                    <DisabledButton label={t('mealSegmentation')} />
                  )
                }
              />

              {/* Previous Meal */}

              <PanelCard
                title={t('previousMeal')}
                subTitle={
                  lastMeal &&
                  `${dates.format(lastMeal?.date)} / ${
                    MealTypeMap[lastMeal?.mealTypeId]
                  }`
                }
                mainContent={
                  <>
                    <div className="part1">
                      <>
                        {lastMeal?.inventory > 0 ? (
                          <>
                            <p className={'chart-content up'}>{`${t(
                              'orders'
                            )} ${Math.round(
                              (lastMeal.ordered / lastMeal.inventory) * 100
                            )}%`}</p>

                            <PieChart
                              data={[
                                [
                                  'Ordered',
                                  Math.round(
                                    (lastMeal.ordered / lastMeal.inventory) *
                                      100
                                  ),
                                ],
                                [
                                  'Inventory left',
                                  100 -
                                    Math.round(
                                      (lastMeal.ordered / lastMeal.inventory) *
                                        100
                                    ),
                                ],
                              ]}
                              size={{ w: 200, h: 200 }}
                              colors={['#2997EB']}
                            />
                            <p className={'chart-content down'}>{`${
                              lastMeal?.ranOutDishes
                            } ${t('dishesRanOutOutOf')} ${
                              lastMeal?.numOfDishes
                            }`}</p>
                          </>
                        ) : (
                          <p>{t('noInventoryInMeal')}</p>
                        )}
                      </>
                    </div>

                    <div className="part2">
                      <p>{`${t('numOfReviews')} ${lastMeal?.numOfRaters}`}</p>
                      {lastMeal?.averageRating ? (
                        <>
                          <p>{`${t(
                            'averageScore'
                          )} ${lastMeal?.averageRating.toFixed(2)}`}</p>
                          <StaticStars
                            rating={lastMeal?.averageRating.toFixed(2)}
                            size={'large'}
                            numOfRaters={lastMeal?.numOfRaters}
                          />
                        </>
                      ) : (
                        <p>{t('noRating')}</p>
                      )}
                    </div>
                  </>
                }
                actionBtn={
                  <ActiveButton
                    wrapperClassname={'control-btn'}
                    label={t('moreMeals')}
                    arrow={true}
                    cb={() => history.push(`/mealManagement`)}
                  />
                }
              />

              {/* Charts */}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

const PanelCard = ({ title, subTitle, mainContent, actionBtn }) => {
  return (
    <div
      className="panel-card"
      style={{ backgroundImage: `url(${CardBackground})` }}
    >
      <h4 className="title">{title}</h4>
      <p className="sub-title">{subTitle}</p>

      <section className={'content'}>{mainContent}</section>

      {actionBtn}
    </div>
  );
};

const PanelChart = ({
  title = '',
  mainContent,
  handleDate,
  fromDate,
  toDate,
  updateData,
  t,
}) => {
  return (
    <div className={'panel-chart'}>
      <h4 className="title">{title}</h4>
      <div className="filter-section new-one">
        <div className="xyz">
          <div className="from-date">
            <label>{t('fromDate')}</label>
            <input
              type="date"
              placeholder="dd/mm/yyyy"
              name="fromDate"
              onChange={(event) => handleDate('fromdate', event.target.value)}
            />
          </div>
          <div className="from-date">
            <label>{t('toDate')}</label>
            <input
              type="date"
              placeholder="dd/mm/yyyy"
              name="toDate"
              onChange={(event) => handleDate('todate', event.target.value)}
            />
          </div>
        </div>

        {fromDate && toDate ? (
          <ActiveButton label={t('apply')} arrow={false} cb={updateData} />
        ) : (
          ''
        )}
      </div>
      <div className={'chart-wrapper'} style={{ backgroundColor: 'white' }}>
        {mainContent}
      </div>
    </div>
  );
};

const DonutChart = () => {
  const data = [
    ['Task', 'Hours per Day'],
    ['Dish', 11],
    ['Order', 8],
    ['Dish', 5],
  ];
  const options = {
    title: '',
    pieHole: 0.4,
    is3D: false,
    legend: 'none',
    colors: ['#FF6D4D', '#2BC156', '#404A56'],
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="100%"
      data={data}
      options={options}
    />
  );
};

const RevenueChart = () => {
  const data = [
    ['x', 'Net Profit', 'Revenue'],
    ['Jan', 0, 0],
    ['Feb', 10, 5],
    ['Mar', 23, 15],
    ['Apr', 17, 9],
    ['May', 18, 10],
    ['Jun', 9, 5],
    ['Jul', 11, 3],
  ];

  const options = {
    colors: ['#2F4CDD', '#B519EC'],
    legend: { position: 'top' },
    series: {
      1: { curveType: 'function' },
    },
  };

  return (
    <Chart
      chartType="AreaChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  MealDishStatuses: state.app.enums.MealDishStatus,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getPaymentPreferences,
};
export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
