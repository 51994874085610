import React from 'react';
import { ActiveButton } from '../../components/WE-Button';
import dates from '../../services/dates';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import moment from 'moment';

const ShowPreviousContainer = ({
  items,
  assignMethod,
  showPreviousLabel = 'showPreviousDates',
  showPrevious,
  showPreviousOnClick,
}) => {
  const { t } = useTranslation();
  const today = dates.formatServer(new Date());
  const futureItems = items;
  // const futureItems1 = items.filter(
  //   (item) => showPrevious || item?.date?.localeCompare(today) <= 0
  // );

  // PAST MEAL ORDERS START
  let d = new Date().toLocaleString('he-IL', {
    timeZone: 'Asia/Jerusalem',
  });
  let getTime = d.split(',')[1];
  let finalHours =
    (getTime.split(':')[0] < 10 ? '0' : '') +
    getTime.split(':')[0].split(' ').join('') +
    ':' +
    getTime.split(':')[1];

  (async function () {
    let startIndex = 0;
    for await (let el of futureItems) {
      if (el?.point > 0) {
        futureItems[startIndex].date = el?.meal?.date;
      }
      startIndex = startIndex + 1;
    }
  })();

  const dataInfo = futureItems?.sort((a, b) => b?.date?.localeCompare(a?.date));

  const newArr = dataInfo?.filter(
    (el) => el?.date == moment(new Date()).format('YYYY-MM-DD')
  );

  let getNextMealInfo = newArr?.filter(
    (ele) =>
      (ele?.mealType?.from <= finalHours && finalHours >= ele?.mealType?.to) ||
      (ele?.mealDish?.[0]?.mealDish?.meal?.mealType?.from <= finalHours &&
        finalHours >= ele?.mealDish?.[0]?.mealDish?.meal?.mealType?.to)
  );
  // let getNextMealInfo = newArr?.filter(
  //   (ele) =>
  //     ele?.mealType?.from <= finalHours && finalHours >= ele?.mealType?.to
  // );
  const filterMeals2 = dataInfo;
  //let len = 3 - getNextMealInfo.length;
  //const slicedArray = filterMeals2?.slice(0, len);
  const filterNextMealsOrder = getNextMealInfo?.concat(filterMeals2);
  // PAST MEAL ORDERS END

  //let itemsInfo = filterNextMealsOrder.slice(0, 3);
  const uniqueData = filterNextMealsOrder.filter((item, index, self) => {
    return index === self.findIndex((i) => i.id === item.id);
  });
  let itemsInfo = uniqueData.filter((el) => !el?.point).slice(0, 3);

  return (
    <>
      {itemsInfo?.length === 0 ? (
        <h3 style={{ marginTop: '100px' }}>{t('noPastDates')}</h3>
      ) : (
        itemsInfo
          .sort((item1, item2) => item2?.date?.localeCompare(item1?.date))
          .map((item) =>
            assignMethod(item, item?.date?.localeCompare(today) <= 0)
          )
      )}
      <>
        {/* {items.length > futureItems.length && (
          <ShowPrevious
            showPrevious={showPrevious}
            showPreviousOnClick={showPreviousOnClick}
            label={t(showPreviousLabel)}
            fakeItem={assignMethod({})}
          />
        )} */}
      </>
    </>
  );
};

const ShowPrevious = ({
  showPrevious,
  showPreviousOnClick,
  label,
  fakeItem,
}) => {
  return (
    !showPrevious && (
      <div className="show-previous">
        <div className={'in-front'}>
          <Button onClick={showPreviousOnClick}>{label}</Button>
        </div>

        {/*<div className={"fake-item"}>*/}
        {/*    {fakeItem}*/}
        {/*</div>*/}
      </div>
    )
  );
};

export default ShowPreviousContainer;
