import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../../services/http';
import Select from 'react-select';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import User from './User';
import { isTabletOrMobile } from '../../services/mediaQueries';
import { sleep } from '../../services/utils';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import AddUserPopup from './AddUser';
import { ActiveButton } from '../../components/WE-Button';
import {
  validateEmployeenew,
  validateGuestUsersNew,
} from '../../services/validators';
import CopyUrlUser from './copyUrlUser';
import ConfirmationPopup from './confirmation';
import { Autocomplete } from '@material-ui/lab';
import more from '../../assets/images/more.svg';
import file from '../../assets/images/file-export.svg';
import adduser from '../../assets/images/add-user.svg';
import search from '../../assets/icons/search.png';
import Tippy from '@tippyjs/react';
import next from '../../assets/images/next.png';
import ViewDetails from './viewDetails';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import agGridLocales from '../../translations/ag-grid';
import { getAllUser, usersTotal } from '../../store/user/actions';
import { getUserConfigByCompanyId } from '../../store/companyConfig/actions';
import Pagination from 'react-bootstrap/Pagination';
import WESpinner from '../../../src/components/WE-Spinner';
import Toast from 'react-bootstrap/Toast';
import cancel from '../../assets/images/close.svg';
const initialState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: [],
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'active',
  contractorId: '',
  contractors: [],
  magneticStripe: '',
  mealTypeId: [],
  hireTill: '',
  hireFrom: '',
  isPasswordTemporary: false,
};

const UserManagement = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  languages,
  CurrentLanguage,
  getAllUser,
  usersTotal,
  allUsers,
  allUserData,
  cibusEnabled,
  companyId,
  getUserConfigByCompanyId,
  cardGenerationUrlForUser,
  cardNumberLengthForUser,
  employeeNumberLengthForUser,
  companyName,
  isStrippedCard,
  attendanceEnabled,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [kitchensIds, setKitchensIds] = useState([]);
  const [payload, setPayload] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [rolesIds, setRolesIds] = useState();
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [contractorList, setContractorList] = useState();
  const [showContractor, setShowContractor] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectContractor, setSelectContractor] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [tableColumn, setTableColumn] = useState();
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const [showGuestUser, setshowGuestUser] = useState(false);
  const [selectGuestUser, setselectGuestUser] = useState();
  const [loading, setLoading] = useState(false);
  const [mealTypesIds, setMealTypesIds] = useState([]);
  const [getMealTypes, setGetMealTypes] = useState();
  const [rowCount, setRowCount] = useState();
  const [searchText, setSearchText] = useState();
  const [getMealTypesKitchenWise, setMealTypesKitchenWise] = useState();

  const [newPageSizeUsers, setPageSizeUsers] = useState(25); // Records per page
  const [totalUserRecords, setTotalUserRecords] = useState(0); // Total records
  const [pageCountsUsers, setpageCountsUsers] = useState(1); // Total pages count
  const [currentPageUsers, setCurrentPageUsers] = useState(1); // Current page
  const [searchKeywordUsers, setSearchKeywordUsers] = useState(''); // Search text
  const [userRoleId, setUserRoleId] = useState('null');
  const [userStatusId, setUserStatusId] = useState('null');
  const [userContractorId, setUserContractorId] = useState('null');
  const [userKitchenId, setUserKitchenId] = useState('null');
  const [forceResetPassword, setForceResetPassword] = useState(false);
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  /***********fetch User data */
  useEffect(() => {
    const fetch = async () => {
      getUserConfigByCompanyId(companyId);
    };
    fetch();
  }, []);

  const getLimitUserData = async () => {
    let filter = {
      companyId: user.companyId,
      limit: newPageSizeUsers,
      page: currentPageUsers,
      roleId: userRoleId,
      statusId: userStatusId,
      contractorId: userContractorId,
      kitchenId: userKitchenId,
      orderField:
        'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
      orderTarget: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
      search: searchKeywordUsers ? searchKeywordUsers : undefined,
    };
    await useFetchingLoader(async () => {
      const getMealType = await http.get(`mealTypes`);
      setGetMealTypes(getMealType?.data);
      const contractorData = await http.get(`contractors/getAllContractors`);
      setContractorList(contractorData.data);
      await getAllUser(filter);
    });
  };
  useEffect(async () => {
    await getLimitUserData();
  }, [
    dummy,
    kitchen,
    newPageSizeUsers,
    currentPageUsers,
    columnAndOrder,
    userKitchenId,
    userContractorId,
    userStatusId,
    userRoleId,
  ]);
  useEffect(async () => {
    setPrefetchData(allUsers);
    let serverUsers = allUsers?.map((u, i) => {
      const paymentMethodArr = [
        ...new Set(
          u?.kitchens.map((el) => el.kitchenPayment.paymentOption.name)
        ),
      ];
      const userStatus = UserStatuses.find((el) => el.id === u.userStatusId);
      const userRole = Roles.find((el) => el.id === u.roleId);
      const contractor = contractorList.find(
        (el) => el.id === u.contractors[0]?.id
      );
      const contractors = contractorList.filter((obj1) =>
        u.contractors.some((obj2) => obj2.id === obj1.id)
      );
      return {
        userId: u.id,
        Name: [u.firstName, u.lastName].join(' '),
        firstName: u.firstName,
        lastName: u.lastName,
        employeeId: u.employeeId,
        'Card Number': u.cardId,
        Status: userStatus?.display,
        'Registration Date': u.createdAt?.split('T')[0] ?? undefined,
        Kitchens: Kitchens?.filter((obj) =>
          u?.kitchens.some((el) => el.id === obj.id)
        )
          .map((obj) => obj.name)
          ?.join(','),
        Email: u.email,
        Action: i,
        Role: userRole?.display,
        RoleId: userRole?.id,
        Contractor: contractor?.id,
        allContractorInfo: contractor,
        allContractors: contractors,
        contractors: u.contractors || null,
        phone: u.phone?.split(' ').join(''),
        contractorName: u?.contractorName,
        StatusVal: userStatus?.value,
        creditCard: u.creditCard,
        connectTeam: u.connectTeam,
        paymentMethod: paymentMethodArr,
        amount: u?.userWallet?.wallet?.amount,
        walletId: u?.userWallet?.walletId,
        totalReservation: u.totalReservations,
        updatedAt: u.updatedAt?.split('T')[0] ?? undefined,
        magneticStripe: u?.magneticStripe,
        hireFrom: u?.hireFrom,
        hireTill: u?.hireTill,
        mealTypeId:
          u?.guestUser?.mealTypeId && u?.guestUser?.mealTypeId !== 'undefined'
            ? JSON.parse(u?.guestUser?.mealTypeId)
            : [],
        generatedLink: u?.guestUser?.generatedLink,
        isUsed: u?.isUsed,
        isPasswordTemporary: u?.isPasswordTemporary,
      };
    });
    setUsersToDisplay(serverUsers);
    setRowCount(serverUsers?.length);
    setTotalUserRecords(usersTotal);
    setpageCountsUsers(Math.ceil(usersTotal / newPageSizeUsers));
    setLoading(true);
  }, [dummy, kitchen, allUsers, allUserData]);

  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      setPayload(initialState);
      setIsUpdate(false);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsUpdate(false);
    setshowGuestUser(false);
    setMealTypesKitchenWise([]);
    setKitchensIds([]);
    setErrors({});
  };
  const handleChangeRoleFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserRoleId(userTypeId);
  };
  const handleChangeKitchenFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserKitchenId(userTypeId);
  };
  const handleChangeContractorFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserContractorId(userTypeId);
  };
  const handleChangeUserStatusFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserStatusId(userTypeId);
  };
  /******* add New User */
  const handleAddNewUser = async (userData) => {
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];

    try {
      let userToAdd;
      userData.employeeId =
        userData.employeeId !== '' ? userData.employeeId : null;
      userData.cardId = userData.cardId !== '' ? userData.cardId : null;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`users/create`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      if (userToAdd.errorMessage === 'passwordPolicy') {
        Dialog({
          text:
            t('character') +
            ' ' +
            userToAdd?.errorLetter +
            ' ' +
            t('isNotVaild') +
            ' ' +
            t('passwordSecurity'),
          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('passwordUpdatedConfirmButtonText'),
          },
        });
      } else {
        Dialog({
          title: t('addedmessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(true);
            window.location.reload();
          },
        });
      }
      handleClose();
    } catch (err) {
      Alert({ error: err });
    }
  };

  // ADD GUEST USER INFO
  const handleAddNewGuestUser = async (userData) => {
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];
    try {
      //  let userToAdd = userData;
      let userToAdd;
      userData.employeeId =
        userData.employeeId !== '' ? userData.employeeId : null;
      userData.cardId = userData.cardId !== '' ? userData.cardId : null;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`users/createGuestUser`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      handleClose();
      if (userToAdd?.id) {
        Dialog({
          title: t('addedmessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(true);
            window.location.reload();
          },
        });
      }
      handleClose();
    } catch (err) {
      Alert({ error: err });
    }
  };
  //details?.roleId[0]

  const handleUpdateGuestUser = async () => {
    //event.preventDefault();
    try {
      let details = {
        firstName: payload.fName,
        lastName: payload.lName,
        email: payload?.email ? payload?.email : null,
        phone: payload.phone,
        kitchensIds: kitchensIds,
        password: payload.password === '' ? undefined : payload.password,
        roleId: RolesMap.guestUser,
        contractorId: payload?.contractorId ? payload?.contractorId : null,
        contractorsIds: payload?.contractorsIds
          ? payload?.contractorsIds
          : payload?.contractors
          ? payload?.contractors.map((el) => el.id)
          : [],
        magneticStripe: payload.magneticStripe,
        hireFrom: payload.hireFrom,
        hireTill: payload.hireTill,
        mealTypeId: payload.mealTypeId,
        isPasswordTemporary: false,
      };
      console.log('Details', details);
      const { isValid, errors } = validateGuestUsersNew(details);
      setErrors({ ...errors });
      if (!isValid) {
        return;
      }
      const userId = payload.userId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`users/updateGuestUser/${userId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      if (response) {
        Dialog({
          title: t('updatedMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(true);
            setViewDetails(false);
            handleClose();
            setshowGuestUser(false);
            window.location.reload();
          },
        });
        setKitchensIds([]);
      }
    } catch (err) {
      console.log('Error');
      Alert({ error: err });
    }
  };

  const handleUpdateUser = async () => {
    // userData.employeeId = userData.employeeId!==''?userData.employeeId:null;
    // userData.cardId = userData.cardId!==''?userData.cardId:null;
    try {
      let details = {
        firstName: payload.fName,
        lastName: payload.lName,
        email: payload.email,
        phone: payload.phone,
        // userStatusId:prefetchData?.find(el => el.id === payload.userId)?.userStatusId,
        userStatusId: UserStatuses.find((el) => el.display === payload.status)
          .id,
        kitchensIds: payload.kitchensIds,
        password: payload.password === '' ? undefined : payload.password,
        cardId: payload?.card
          ? isStrippedCard
            ? payload.card.replace(/^0+/, '')
            : payload.card
          : null,
        employeeId: payload.employeeId ? payload.employeeId : null,
        roleId: payload.role,
        value: payload.status,
        contractorsIds:
          payload.role == 4
            ? null
            : payload.contractorsIds || payload.contractors
            ? payload.contractorsIds || payload.contractors.map((el) => el.id)
            : null,
        magneticStripe: payload.magneticStripe,
        isPasswordTemporary: payload.isPasswordTemporary,
      };
      const { isValid, errors } = validateEmployeenew(
        details,
        cardNumberLengthForUser,
        employeeNumberLengthForUser
      );
      setErrors({ ...errors });
      if (details?.kitchensIds?.length == 0) {
        return setErrors({ ...errors, kitchensIds: 'Kitchen is required' });
      }
      if (!details?.roleId?.[0]) {
        return setErrors({ ...errors, role: 'Role is required' });
      }
      if (
        details?.roleId &&
        (details?.roleId?.[0] == 6 || details?.roleId?.[0] == 7)
      ) {
        if (!details?.contractorsIds || details?.contractorsIds.length === 0) {
          return setErrors({
            ...errors,
            contractorId: 'Contractor is required',
          });
        }
      }
      if (!isValid) {
        return;
      }
      const userId = payload.userId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`users/${userId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      Dialog({
        title: t('updatedMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setDummy(true);
          handleClose();
          setViewDetails(false);
          setshowGuestUser(false);
          window.location.reload();
        },
      });
      setKitchensIds([]);
    } catch (err) {
      // console.log('User', err);
      Alert({ error: err });
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      let details = {
        userStatusId: 5,
      };
      const response = await useFetchingLoader(
        async () => await http.put(`users/${deleteId}`, { ...details })
      );
      await setConfirmation(false);
      if (viewDetails) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
      await setViewDetails(!viewDetails);
      if (response) {
        await Dialog({
          title: t('deleteMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy((prev) => !prev);
            setshowGuestUser(false);
          },
        });
      }
      // setDummy(false);
      setDeleteId('');
    } catch (err) {
      Alert({ error: err });
    }
  };
  /************global filter funcitionm */
  // const onFilterTextBoxChanged = (e) => {
  //   const value = e.target.value;
  //   gridApi.setQuickFilter(value);
  // };
  const handleReset = () => {
    setColumnAndOrder({});
    setSearchKeywordUsers('');
    setUserRoleId('null');
    setUserStatusId('null');
    setUserContractorId('null');
    setUserKitchenId('null');
    gridApi.setSortModel([]);
    setCurrentPageUsers(1);
  };
  const getUserData = () => {
    getLimitUserData();
  };

  const handleFilterChanged = (e) => {
    setCurrentPageUsers(1);
    const value = e.target.value;
    setSearchKeywordUsers(value);
    //setSearchText(value);
    //gridApi.setQuickFilter(value);
    // const rowCount = gridApi.getModel().rowsToDisplay.length;
    // setRowCount(rowCount);
  };

  const gridOptions = {
    onFilterChanged: handleFilterChanged,
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  /***********Export CSV file */
  const onBtnExport = async (fileName) => {
    try {
      if (disableButton || usersToDisplay.length === 0) {
        console.log('Disabled or empty table');
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        const arr = [
          'firstNamePH',
          'lastNamePH',
          'user_type',
          'status',
          'cardIdPH',
          'employeeNo',
          'kitchens',
          'phonePH',
          'contractorName',
          'paymentMethod',
          'updatedOn',
          'startDate',
          cibusEnabled ? 'magneticStripe' : undefined,
          'guestLink',
          'registrationDate',
          'endDate',
        ].filter((el) => el !== undefined);
        const resArr = arr.map((el) => t(el));
        const filter = {
          companyId: localStorage.getItem('companyId'),
          limit: totalUserRecords,
          page: 1,
          keyword: searchKeywordUsers,
          roleId: userRoleId,
          statusId: userStatusId,
          contractorId: userContractorId,
          kitchenId: userKitchenId,
          orderField:
            'field' in columnAndOrder ? columnAndOrder?.field : 'firstName',
          orderTarget:
            'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
          search: searchKeywordUsers ? searchKeywordUsers : undefined,
        };
        for (const key in filter) {
          if (
            filter[key] === undefined ||
            filter[key] === null ||
            filter[key] === 'null'
          ) {
            delete filter[key];
          }
        }
        const res = await http.post(
          `users/download`,
          {
            isReport: true,
            headers: resArr,
          },
          { ...filter }
        );
        const downloadUrl = window.URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv' })
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  // ag grid data
  const columnDefs = [
    {
      field: 'firstName',
      headerName: t('userName'),
      sortable: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'NameCellRender',
      comparator: () => {},
      resizable: true,
    },
    {
      field: 'email',
      headerName: t('email'),
      sortable: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      comparator: () => {},
      resizable: true,
      hide: true,
    },
    {
      field: 'employeeId',
      headerName: t('employeeNo'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'EmployeeIdCellRender',
    },
    {
      field: 'roleId',
      headerName: t('userType'),
      suppressMovable: true,
      cellRenderer: 'StatusCellRender',
      resizable: true,
    },
    {
      field: 'cardId',
      headerName: t('cardId'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'CardIdCellRender',
    },
    {
      field: 'kitchens',
      headerName: t('kitchenname'),
      comparator: () => {},
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'KitcheCellRender',
    },
    {
      field: 'phone',
      headerName: t('phone'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'PhoneCellRender',
    },
    {
      field: 'contractorName',
      headerName: t('contractorName'),
      comparator: () => {},
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'ContractorCellRender',
    },

    {
      field: 'Payment Method',
      headerName: t('paymentMethod'),
      comparator: () => {},
      cellRenderer: 'PaymentMethod',
      suppressMovable: true,
    },
    {
      field: 'totalReservations',
      headerName: t('reservations'),
      cellRenderer: 'OrderLink',
      suppressMovable: true,
    },
    {
      field: 'magneticStripe',
      headerName: t('magneticStripe'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      hide: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];
  // Tab view
  const columnDefsTab = [
    {
      field: 'firstName',
      headerName: t('userName'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'NameCellRender',
      resizable: true,
    },
    {
      field: 'employeeId',
      headerName: t('employeeNo'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'EmployeeIdCellRender',
    },
    {
      field: 'userStatusId',
      headerName: t('userType'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      cellRenderer: 'StatusCellRender',
      resizable: true,
    },
    {
      field: 'cardId',
      headerName: t('cardId'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'CardIdCellRender',
    },

    {
      field: 'kitchens',
      headerName: t('kitchenname'),
      comparator: () => {},
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'KitcheCellRender',
    },
    {
      field: 'phone',
      headerName: t('phone'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'PhoneCellRender',
    },
    {
      field: 'magneticStripe',
      headerName: t('magneticStripe'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      hide: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'firstName',
      headerName: t('userName'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'NameCellRender',
      resizable: true,
    },
    {
      field: 'email',
      headerName: t('email'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      // cellRenderer: 'NameCellRender',
      resizable: true,
      hide: true,
    },
    {
      field: 'employeeId',
      headerName: t('employeeNo'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'EmployeeIdCellRender',
      hide: true,
    },
    {
      field: 'userStatusId',
      headerName: t('userType'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      cellRenderer: 'StatusCellRender',
      resizable: true,
      hide: true,
    },
    {
      field: 'cardId',
      headerName: t('cardId'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'CardIdCellRender',
    },
    {
      field: 'kitchens',
      headerName: t('kitchenname'),
      comparator: () => {},
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'KitcheCellRender',
      hide: true,
    },
    {
      field: 'phone',
      headerName: t('phone'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'PhoneCellRender',
      hide: true,
    },
    {
      field: 'contractorName',
      headerName: t('contractorName'),
      comparator: () => {},
      suppressMovable: true,
      resizable: true,
      cellRenderer: 'ContractorCellRender',
      hide: true,
    },

    {
      field: 'Payment Method',
      headerName: t('paymentMethod'),
      cellRenderer: 'PaymentMethod',
      comparator: () => {},
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'totalReservations',
      headerName: t('reservations'),
      cellRenderer: 'OrderLink',
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'magneticStripe',
      headerName: t('magneticStripe'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
      hide: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    suppressMovable: true,
    // sizeColumnsToFit: true,
    suppressSizeToFit: true,
    width: 'auto',
  };

  const onFirstDataRendered = (params) => {
    // arbitrarily expand a row for presentational purposes
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1).setExpanded(true);
    }, 0);
  };

  // const gridOptions = {
  //   rowHeight: 60,
  //   groupHeaderHeight: 60,
  //   headerHeight: 60,
  //   rowStyle: {
  //     'border-bottom': 'white 10px solid',
  //     'border-top': 'white 0px solid',
  //     'border-radius': '5px',
  //     padding: '0px 5px',
  //   },
  // };

  const ResendVerification = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/resetPassword`, {
          userData: { email: el.Email, resendLink: true },
          companyId: localStorage.getItem('companyId'),
        })
    );
    if (res?.sent) {
      Dialog({
        title: t('resendMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
      });
    }
  };

  const CopyDataUrl = async (el) => {
    if (el?.RoleId === 8) {
      const res = await useFetchingLoader(
        async () =>
          await http.post(`users/guestLinkCopy`, {
            userData: { userId: el?.userId },
          })
      );
      res.resetPasswordUrl = res?.guestUrl;
      setResendData(res);
    } else {
      const res = await useFetchingLoader(
        async () =>
          await http.post(`users/copyUrl`, {
            userData: { email: el.Email },
          })
      );
      setResendData(res);
    }
    setCopyData(true);
  };
  const handleCloseUrl = () => {
    setCopyData(false);
    setCopied(false);
  };
  const handleConfirmation = (userId) => {
    setConfirmation(!confirmation);
    setDeleteId(userId);
  };
  const lang = localStorage.getItem('language');
  const [newRoles, setNewRoles] = useState();
  useEffect(() => {
    if (user.roleId === 1) {
      setNewRoles(Roles);
    } else if (user.roleId !== 1) {
      let aa = Roles.filter((el) => el.id !== 1);
      setNewRoles(aa);
    }
  }, [user]);

  const handleViewMore = (data) => {
    if (viewDetails) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    setViewDetails(!viewDetails);
    setDetails(data);
  };
  const handleWallet = (walletId) => {
    history.push(`/credit?${walletId}`);
  };
  const handleOrderLink = (userId, createdAt) => {
    history.push(
      `/reservationManagement?user=${userId}&createdAt=${createdAt}`
    );
  };
  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  return (
    <div className="user-management-table global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-user-wrapper">
            <div className="search-block">
              <div className="search-input">
                <img src={search} alt="search" />
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={`${t('freeTextSearch')}`}
                  onInput={handleFilterChanged}
                  value={searchKeywordUsers}
                />
              </div>
              <button onClick={getUserData} className="search-button resize">
                <img src={search} alt="search" />
              </button>
              <button
                onClick={() => handleReset()}
                className="reset-button-user-management"
              >
                <img src={cancel} alt="reset" />
              </button>
            </div>

            <div className="filter-block">
              <div className="from-date">
                <select
                  name="userType"
                  placeholder={t('filter')}
                  value={userRoleId}
                  onChange={(event) =>
                    handleChangeRoleFilter(event.target.value)
                  }
                >
                  <option value="null">{t('selectUserType')}</option>
                  {user.roleId === 1 && <option value="1">{t('admin')}</option>}
                  <option value="2">{t('siteManager')}</option>
                  <option value="3">{t('kitchenManager')}</option>
                  <option value="4">{t('employee')}</option>
                  <option value="6">{t('contractorEmployee')}</option>
                  <option value="7">{t('contractorAdmin')}</option>
                  <option value="8">{t('guestUsers')}</option>
                  <option value="9">{t('kitchenWorker')}</option>
                </select>
              </div>
              <div className="from-date">
                <select
                  name="userType"
                  value={userStatusId}
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeUserStatusFilter(event.target.value)
                  }
                >
                  <option value="null">{t('selectUserStatus')}</option>
                  <option value="1">{t('created')}</option>
                  <option value="2">{t('pending')}</option>
                  <option value="3">{t('active')}</option>
                  <option value="4">{t('blocked')}</option>
                  <option value="5">{t('deleted')}</option>
                </select>
              </div>
              <div className="from-date">
                <select
                  name="userType"
                  value={userContractorId}
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeContractorFilter(event.target.value)
                  }
                >
                  <option value="null">{t('selectContractor')}</option>
                  {contractorList
                    ? contractorList.map((el) => (
                        <option value={el.id}>{el.name}</option>
                      ))
                    : []}
                </select>
              </div>
              <div className="from-date">
                <select
                  name="userType"
                  value={userKitchenId}
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeKitchenFilter(event.target.value)
                  }
                >
                  <option value="null">{t('selectKitchen')}</option>
                  {Kitchens
                    ? Kitchens.map((el) => (
                        <option value={el.id}>{el.name}</option>
                      ))
                    : []}
                </select>
              </div>
            </div>
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{totalUserRecords}</span>
              </p>
            </div>
            {loading == 0 ? (
              <div className="loading_excel" title="Loading">
                <WESpinner size={15} color={'#14cd31'} />
              </div>
            ) : (
              ''
            )}
            <div className={`actions ${loading == 1 ? '' : 'hidden'}`}>
              {
                <img
                  src={file}
                  alt="add"
                  onClick={() =>
                    onBtnExport(
                      `${companyName}-users-${moment().format('DD-MMMM-YYYY')}`
                    )
                  }
                />
              }
              <img src={adduser} alt="add" onClick={() => handleOpen('add')} />
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            columnDefs={tableColumn}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            onSortChanged={() => printSortStateToConsole()}
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            frameworkComponents={{
              PopupCellRenderer: (e) => (
                <PopupCellRenderer
                  handleOpen={handleOpen}
                  e={e}
                  setUpdate={setUpdate}
                  setPayload={setPayload}
                  setIsEdit={setIsEdit}
                  handleDeleteUser={handleDeleteUser}
                  t={t}
                  kitchensIds={kitchensIds}
                  setKitchensIds={setKitchensIds}
                  Kitchens={Kitchens}
                  Roles={Roles}
                  ResendVerification={ResendVerification}
                  UserStatuses={UserStatuses}
                  CopyDataUrl={CopyDataUrl}
                  handleConfirmation={handleConfirmation}
                  setShowContractor={setShowContractor}
                  contractorList={contractorList}
                  handleViewMore={handleViewMore}
                />
              ),
              ViewMore: (e) => (
                <ViewMore e={e} handleViewMore={handleViewMore} />
              ),
              NameCellRender: (e) => <NameCellRender e={e} />,
              CardIdCellRender: (e) => <CardIdCellRender e={e} />,
              EmployeeIdCellRender: (e) => <EmployeeIdCellRender e={e} />,
              PhoneCellRender: (e) => <PhoneCellRender e={e} />,
              ContractorCellRender: (e) => <ContractorCellRender e={e} />,
              KitcheCellRender: (e) => <KitcheCellRender e={e} />,
              StatusCellRender: (e) => <StatusCellRender e={e} />,
              CreditCard: (e) => <CreditCard e={e} />,
              PaymentMethod: (e) => (
                <PaymentMethod e={e} handleWallet={handleWallet} t={t} />
              ),
              OrderLink: (e) => (
                <OrderLink e={e} handleOrderLink={handleOrderLink} />
              ),
            }}
            debug={false}
            rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            pagination={false}
            paginationPageSize={50}
            overlayLoadingTemplate={'<p></p>'}
            paginationNumberFormatter={function (params) {
              return params.value.toLocaleString();
            }}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <p>
                {newPageSizeUsers * (currentPageUsers - 1) + 1} to{' '}
                {newPageSizeUsers * currentPageUsers} of {totalUserRecords}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers - 1 > 0
                    ? setCurrentPageUsers(currentPageUsers - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageUsers} of{' '}
                {Math.ceil(usersTotal / newPageSizeUsers)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers < Math.ceil(usersTotal / newPageSizeUsers)
                    ? setCurrentPageUsers(currentPageUsers + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(Math.ceil(usersTotal / newPageSizeUsers));
                }}
              />
            </Pagination>
          </div>
        </div>
        <AddUserPopup
          open={open}
          handleClose={handleClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          // userData={{ name: '' }}
          userData={prefetchData}
          newUserFlag={true}
          handleAddNewUser={handleAddNewUser}
          handleAddNewGuestUser={handleAddNewGuestUser}
          isEdit={true}
          payload={payload}
          setPayload={setPayload}
          handleUpdateUser={handleUpdateUser}
          handleUpdateGuestUser={handleUpdateGuestUser}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          rolesIds={rolesIds}
          setRolesIds={setRolesIds}
          t={t}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          cibusEnabled={cibusEnabled}
          setErrors={setErrors}
          errors={errors}
          user={user}
          newRoles={newRoles}
          contractorList={contractorList}
          showContractor={showContractor}
          setShowContractor={setShowContractor}
          setSelectContractor={setSelectContractor}
          selectContractor={selectContractor}
          setshowGuestUser={setshowGuestUser}
          showGuestUser={showGuestUser}
          setselectGuestUser={setselectGuestUser}
          selectGuestUser={selectGuestUser}
          setMealTypesIds={setMealTypesIds}
          mealTypesIds={mealTypesIds}
          setGetMealTypes={setGetMealTypes}
          getMealTypes={getMealTypes}
          forceResetPassword={forceResetPassword}
          setForceResetPassword={setForceResetPassword}
          setMealTypesKitchenWise={setMealTypesKitchenWise}
          getMealTypesKitchenWise={getMealTypesKitchenWise}
          cardGenerationUrlForUser={cardGenerationUrlForUser}
          cardNumberLengthForUser={cardNumberLengthForUser}
          employeeNumberLengthForUser={employeeNumberLengthForUser}
        />
        <CopyUrlUser
          open={copyData}
          handleClose={CopyDataUrl}
          resendData={resendData}
          handleCloseUrl={handleCloseUrl}
          copied={copied}
          setCopied={setCopied}
        />
        <ConfirmationPopup
          open={confirmation}
          handleClose={() => setConfirmation(false)}
          handleDeleteUser={handleDeleteUser}
        />
        <ViewDetails
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
          CopyDataUrl={CopyDataUrl}
          ResendVerification={ResendVerification}
          handleConfirmation={handleConfirmation}
          handleOpen={handleOpen}
          setPayload={setPayload}
          UserStatuses={UserStatuses}
          setKitchensIds={setKitchensIds}
          Kitchens={Kitchens}
          Roles={Roles}
          handleWallet={handleWallet}
          handleOrderLink={handleOrderLink}
          setShowContractor={setShowContractor}
          setMealTypesIds={setMealTypesIds}
          getMealTypes={getMealTypes}
          setMealTypesKitchenWise={setMealTypesKitchenWise}
          getMealTypesKitchenWise={getMealTypesKitchenWise}
          cibusEnabled={cibusEnabled}
          attendanceEnabled={attendanceEnabled}
        />
        <Toast
          onClose={() => setOpenDownload(false)}
          show={openDownload}
          style={{
            position: 'sticky',
            right: '20px',
            bottom: '70px',
          }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
              onClick={() => setOpenDownload(false)}
            />
          </Toast.Header>
          <Toast.Body>{t('downloadFile')}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};
const CreditCard = ({ e }) => {
  return (
    <div className={`credit-card-option`}>
      <input type="checkbox" checked={e?.data?.creditCard} />
    </div>
  );
};
const PaymentMethod = ({ e, handleWallet, t }) => {
  let paymentMethodsForCA = '';
  if (e.data?.RoleId === 7) {
    if (
      e.data.allContractors &&
      e.data.allContractors.length !== 0 &&
      Boolean(e.data.allContractors.find((elem) => elem?.isInvoice === 1))
    ) {
      const methods = e.data.paymentMethod;
      methods.push('invoice');
      const uniqueArray = Array.from(new Set(methods));
      paymentMethodsForCA = uniqueArray.map((el) => t(el)).join(', ');
    } else {
      paymentMethodsForCA = e.data.paymentMethod.map((el) => t(el)).join(', ');
    }
  }
  return (
    <div
      className={`payment-method ${
        e.data['walletId'] ? 'without-link' : 'add-link'
      }`}
    >
      {e.data.paymentMethod.length
        ? (e.data?.RoleId !== 6 &&
            e.data?.RoleId !== 7 &&
            e.data?.RoleId !== 8) ||
          (e.data?.RoleId === 8 &&
            e.data?.allContractorInfo &&
            e.data?.allContractorInfo?.isInvoice === 0) ||
          (e.data?.RoleId === 6 &&
            e.data?.allContractorInfo &&
            e.data?.allContractorInfo?.isInvoice === 0)
          ? e.data.paymentMethod.map((el, i) =>
              el === 'Wallet' ? (
                e.data['walletId'] ? (
                  <p
                    role="presentation"
                    onClick={() => handleWallet(e.data['walletId'])}
                  >
                    {t('wallet')}
                    <>
                      ({e.data.amount ? e.data.amount : 0})
                      {e.data.paymentMethod.length === 1 ||
                      e.data.paymentMethod.length - 1 === i
                        ? ' '
                        : ', '}
                    </>
                  </p>
                ) : el === 'Wallet' ? (
                  t('wallet') +
                  '(0)' +
                  (e.data.paymentMethod.length === 1 ||
                  e.data.paymentMethod.length - 1 === i
                    ? ' '
                    : ', ')
                ) : (
                  ' '
                )
              ) : el === 'Salary' ? (
                t('paymentOption-salary') +
                (e.data.paymentMethod.length === 1 ||
                e.data.paymentMethod.length - 1 === i
                  ? ''
                  : ', ')
              ) : el === 'Cibus' ? (
                t('paymentOption-cibus') +
                (e.data.paymentMethod.length === 1 ||
                e.data.paymentMethod.length - 1 === i
                  ? ''
                  : ', ')
              ) : el === 'Credit Card' ? (
                t('paymentOption-creditCard') +
                (e.data.paymentMethod.length === 1 ||
                e.data.paymentMethod.length - 1 === i
                  ? ''
                  : ', ')
              ) : el === 'Invoice' ? (
                t('invoice')
              ) : (
                '--'
              )
            )
          : e.data.allContractors &&
            e.data.allContractors.length !== 0 &&
            Boolean(e.data.allContractors.find((elem) => elem?.isInvoice === 0))
          ? paymentMethodsForCA
          : t('invoice')
        : '--'}
    </div>
  );
};
const OrderLink = ({ e, handleOrderLink }) => {
  return (
    <div
      className={`payment-method ${
        e.data['userId'] != undefined ? 'add-link' : 'without-link'
      }`}
    >
      <p
        role="presentation"
        onClick={() =>
          handleOrderLink(e.data['userId'], e.data['Registration Date'])
        }
      >
        {/* Order Link */}
        {e.data['totalReservation']}
      </p>
    </div>
  );
};

const ActionButton = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
}) => {
  setUpdate(e.data);
  setIsEdit(true);
  return (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
    >
      <button
        onClick={() => {
          let ids = [];
          if (e.data['Kitchens'].length) {
            Kitchens.forEach((el) => {
              if (e.data['Kitchens'].includes(el.name)) {
                ids.push(el.id);
              }
            });
          }
          setKitchensIds(ids);
          setPayload({
            userId: e.data['userId'],
            card: e.data['Card Number'],
            email: e.data['Email'],
            fName: e.data['firstName'],
            lName: e.data['lastName'],
            phone: e.data['phone']
              ? e.data['phone'].split('-').join('')
              : e.data['phone'],
            kitchensIds: ids,
            employeeId: e.data['employeeId'],
            role: [Roles.find((el) => el.display === e.data['Role'])?.id],
            contractors: e.data['contractors'] ? e.data['contractors'] : null,
            contractorId:
              Roles.find((el) => el.display === e.data['Role'])?.id == 4
                ? null
                : e.data['Contractor'],
            // status: UserStatuses?.find(el => el.display ===e.data["Status"])?.id
            status: e.data['Status'],
            magneticStripe: e.data['magneticStripe'],
            hireFrom: e?.data['hireFrom'],
            hireTill: e?.data['hireTill'],
            mealTypeId: e?.data['mealTypeId'],
            isPasswordTemporary: e.data['isPasswordTemporary'],
          });
          let mealTypeIdList = JSON.parse(e.data['mealTypeId']);
          let array = [];
          if (mealTypeIdList.length) {
            let aa = mealTypeIdList.map((el) => array.push(el));
          }
          setMealTypesIds(array);
          handleOpen();
        }}
        className="edit-btn"
      >
        {t('editbutton')}
      </button>
      <button
        className="delete-btn"
        // onClick={() => handleDeleteUser(e.data['userId'])}
        onClick={() => handleConfirmation(e.data['userId'])}
      >
        {t('deletebutton')}
      </button>
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button
          className="resend-btn"
          onClick={() => ResendVerification(e.data)}
        >
          {t('resend')}
        </button>
      ) : (
        ''
      )}
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button className="copy-btn" onClick={() => CopyDataUrl(e.data)}>
          {t('copyUrl')}
        </button>
      ) : (
        ''
      )}
      <div className="menu-item">
        <div
          // onClick={() => handleDeleteUser(e.data['userId'])}
          onClick={handleViewMore}
        >
          {t('viewMore')}
        </div>
      </div>
    </div>
  );
};

const NameCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <h6>
        <p>{e?.data?.Name ? e?.data?.Name : '--'}</p>
      </h6>
      <p>{e?.data?.Email ? e?.data?.Email : '--'}</p>
    </div>
  );
};

const CardIdCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>
        {e?.data?.['Card Number']
          ? e?.data['Card Number']?.toString()?.padStart(9, '0')
          : '--'}
      </p>
    </div>
  );
};

const EmployeeIdCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e.data.employeeId ? e?.data?.employeeId : '--'}</p>
    </div>
  );
};
const PhoneCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e.data.phone ? e?.data?.phone : '--'}</p>
    </div>
  );
};
const ContractorCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>
        {e.data.contractors
          ? e?.data?.contractors.map((elem) => elem.name).join(', ')
          : '--'}
      </p>
    </div>
  );
};
const KitcheCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e?.data?.Kitchens?.length > 0 ? e?.data?.Kitchens : '--'}</p>
    </div>
  );
};

const StatusCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e?.data?.Role === 'Client' ? 'Employee' : e?.data?.Role}</p>
      <h6
        className={`user-status ${
          e?.data?.StatusVal === 'active' ? 'status-active' : ''
        } ${e?.data?.StatusVal === 'deleted' ? 'status-deleted' : ''}`}
      >
        <i class="fa fa-circle" aria-hidden="true"></i> {e?.data?.Status}
      </h6>
    </div>
  );
};
const PopupCellRenderer = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
  setShowContractor,
  contractorList,
  handleViewMore,
}) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
      style={{ position: 'relative' }}
    >
      <div className="menu-container">
        <div className="menu-item">
          <div
            onClick={() => {
              let ids = [];
              if (e.data['Kitchens'].length) {
                Kitchens.forEach((el) => {
                  if (e.data['Kitchens'].includes(el.name)) {
                    ids.push(el.id);
                  }
                });
              }
              setKitchensIds(ids);
              if (e.data['RoleId'] === 6) {
                setShowContractor(true);
              }

              setPayload({
                userId: e.data['userId'],
                card: e.data['Card Number'],
                email: e.data['Email'],
                fName: e.data['firstName'],
                lName: e.data['lastName'],
                phone: e.data['phone']
                  ? e.data['phone'].split('-').join('')
                  : e.data['phone'],
                kitchensIds: ids,
                employeeId: e.data['employeeId'],
                contractors: e.data['contractors']
                  ? e.data['contractors']
                  : null,
                role: [Roles.find((el) => el.display === e.data['Role'])?.id],
                contractorId:
                  Roles.find((el) => el.display === e.data['Role'])?.id == 4
                    ? null
                    : e.data['Contractor'],
                // status: UserStatuses?.find(el => el.display ===e.data["Status"])?.id
                status: e.data['Status'],
                mealTypeId: e?.data['mealTypeId'],
                magneticStripe: e.data['magneticStripe'],
                isPasswordTemporary: e.data['isPasswordTemporary'],
              });
              handleOpen();
            }}
          >
            {t('editbutton')}
          </div>
        </div>
        <div className="menu-item">
          <div
            // onClick={() => handleDeleteUser(e.data['userId'])}
            onClick={() => handleConfirmation(e.data['userId'])}
          >
            {t('deletebutton')}
          </div>
        </div>

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div
              className="resend-btn"
              onClick={() => ResendVerification(e.data)}
            >
              {t('resend')}
            </div>
          </div>
        ) : (
          ''
        )}

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div onClick={() => CopyDataUrl(e.data)}>{t('copyUrl')}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      });
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] });
    }

    if (option === 'edit') {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: 'make',
      });
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <img src={more} onClick={visible ? hide : show} className="more-info" />
      {/* <i
        className="fa fa-ellipsis-v"
        aria-hidden="true"
        onClick={visible ? hide : show}
      ></i> */}
    </Tippy>
  );
};
const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  languages: state.languages.options,
  CurrentLanguage: state.languages.current,
  allUsers: state.user.allUsers,
  usersTotal: state.user.usersTotal,
  allUserData: state.user.allUserData,
  cibusEnabled: state.companyConfig.paymentsSettings.cibusEnabled,
  attendanceEnabled: state.companyConfig.menuSettings.attendanceEnabled,
  companyId: state.companyConfig.companyId,
  employeeNumberLengthForUser: state.companyConfig.employeeNumberLengthForUser,
  cardNumberLengthForUser: state.companyConfig.cardNumberLengthForUser,
  cardGenerationUrlForUser: state.companyConfig.cardGenerationUrlForUser,
  companyName: state.companyConfig.companyName,
  isStrippedCard: state.companyConfig.isStrippedCard,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getAllUser,
  getUserConfigByCompanyId,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
