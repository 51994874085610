import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActiveButton } from '../WE-Button';
import CheckGreen from '../../assets/images/check_green.svg';

const Popup = ({ toggle, details, mealExtra, MealTypes }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push('/myMeals');
  };
  console.log('mealExtra', mealExtra);
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});
  return (
    <>
      {toggle && (
        <div className="order-popup fadeInClass">
          <div className="popup-block">
            <div className="popup-title">
              <img src={CheckGreen} alt="" />
              <h4>{t('orderReceivedSuccessfully')}</h4>
            </div>
            <div className="order-details">
              <div className="total-ordered-dishes">
                {details.map((el, i) => (
                  <>
                    <h4>
                      {i + 1}. {el.dish.name}
                    </h4>
                  </>
                ))}
              </div>
              {mealExtra && mealExtra.length > 0 ? (
                <h6>Extra: {mealExtra && mealExtra[0].text} </h6>
              ) : (
                ''
              )}
              <p>
                {details[0].meal.date} /{' '}
                {MealTypesMap_Id[details[0]?.meal?.mealTypeId]}
              </p>
            </div>
          </div>
          <ActiveButton cb={handleClick} label={t('confirm')} />
        </div>
      )}
    </>
  );
};

export default Popup;
