import React, { useEffect, useState } from 'react';
import { Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { StaticStars } from '../../components/RatingStars';
import DishImage from '../../screens/client/DishImage';
import TableBtn from '../Newfolder/TableBtn';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewDishDetail = ({
  open,
  handleClose,
  details,
  handleEditdish,
  handleDeleteClick,
  user,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Modal
      show={open}
      onHide={handleClose}
      centered
      maxWidth="lg"
      className="view-more-popup view-employee-popup view-dish-details"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('dishManagement')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup">
            {/* <div className="back">
              <img src={back} alt="back" onClick={handleClose} />
            </div> */}
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('image')}:</h6>
                  <DishImage fileName={details?.dishImage} />
                </div>
                <div className="user-details-block">
                  <h6>{t('name')}:</h6>
                  <p>{details?.dishName ? details?.dishName : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('description')}:</h6>
                  <p>
                    {details?.dishDescription ? details?.dishDescription : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('category')}:</h6>
                  <p>{details?.dishCategory ? details.dishCategory : '--'}</p>
                </div>

                <div className="user-details-block">
                  <h6>{t('price')}:</h6>
                  <p>{details?.dishPrice}</p>
                </div>

                <div className="user-details-block">
                  <h6>{t('rating')}:</h6>
                  <p>
                    <StaticStars
                      rating={details?.dishRating || -1}
                      numOfRaters={details?.numberOfRaters}
                    />
                  </p>
                </div>
                {/* <div className="user-details-block">
                  <h6>{t('action')}:</h6>
                  <p>
                    <div className="actions-btns">
                      <i
                        class="las la-pen"
                        title="edit"
                        onClick={() => handleEditdish(details?.dishId, true)}
                      ></i>
                      <i
                        class="las la-trash"
                        title="delete"
                        onClick={() =>
                          handleDeleteClick(details?.dishId, details?.dishName)
                        }
                      ></i>
                    </div>
                  </p>
                </div> */}
                {user.roleId !== 9 && (
                  <div className="modal-footer-btn">
                    <Button
                      onClick={() => handleEditdish(details?.dishId, true)}
                    >
                      <i class="las la-pen" title="edit"></i>
                      {t('edit')}
                    </Button>
                    <Button
                      onClick={() =>
                        handleDeleteClick(details?.dishId, details?.dishName)
                      }
                    >
                      <i class="las la-trash" title="delete"></i>
                      {t('delete')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Typography>
        {/* <div className="view-popup-btn">
          <button className="close-modal-btn" onClick={handleClose}>
            {t('close')}
          </button>
        </div> */}
      </Box>
    </Modal>
  );
};
export default ViewDishDetail;
