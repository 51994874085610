import React, { useEffect, useState } from 'react';
import { Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { StaticStars } from '../../components/RatingStars';
import WEIcon from '../../components/WE-IconButton';
import {
  Alert,
  Dialog,
  DialogWithInput,
} from '../../components/Dialogs/SwalDialogs';
import WEToggle from '../../components/WE-Toggle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewMenuContructionDetail = ({
  open,
  handleCloseModel,
  data,
  handleDelete,
  handleUpdateChefMealDish,
  useFetchingLoader,
  printPopup,
  handlePrint,
  handleDropdownClick,
  setActionDropdown,
  actionDropdown,
  wrapperRef,
  inventoryValue,
  handleDeleteClick,
  handleAddEdit,
  handleMinusClick,
  handlePlusClick,
  handleInventoryChange,
  setOpenModel,
  user,
  pastFlag,
}) => {
  const { t, i18n } = useTranslation();

  const openInventoryModal = (dishName = '') => {
    DialogWithInput({
      title: `${t('enterInventoryFor')} ${dishName}`,
      type: 'number',
      onConfirm: async (data) =>
        await handleInventoryChange(Number(data.value)),
      buttons: {
        confirmButtonText: t('saveInfo'),
        cancelButtonText: t('back'),
      },
    });
  };
  return (
    <Modal
      show={open}
      onHide={handleCloseModel}
      centered
      maxWidth="lg"
      className="view-more-popup view-c-meal-popup view-dish-details"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('mealManagement')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleCloseModel} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup">
            {actionDropdown === data?.id && (
              <div className="user-detail-container" ref={wrapperRef}>
                <div className="user-details-block">
                  <h6>{t('inventory')}:</h6>
                  <p>
                    <div className={'inventory'} disabled={user.roleId === 9}>
                      <i
                        class="las la-plus"
                        onClick={
                          user.roleId === 9 || (pastFlag && user.roleId === 3)
                            ? () => {}
                            : handlePlusClick
                        }
                      ></i>
                      <input
                        className={'inv-input'}
                        type={'number'}
                        min={0}
                        disabled={
                          user.roleId === 9 || (pastFlag && user.roleId === 3)
                        }
                        value={inventoryValue}
                        onClick={() => openInventoryModal(data?.dish?.name)}
                      />
                      {/* {inventoryValue} */}
                      <i
                        onClick={
                          user.roleId === 9 || (pastFlag && user.roleId === 3)
                            ? () => {}
                            : handleMinusClick
                        }
                        className={`las la-minus ${
                          inventoryValue < 1 ? 'inventroy-disable' : ''
                        }`}
                      ></i>
                    </div>
                  </p>
                  {/* <p
                        onClick={() => openInventoryModal(data?.dish?.name)}
                      >
                        {inventoryValue}{' '}
                      </p> */}
                </div>
                <div className="user-details-block">
                  <h6>{t('orders')}: </h6>
                  <p>
                    <PercentData
                      label={t('orders')}
                      value={data?.ordered}
                      outOfValue={data?.inventory}
                    />
                  </p>
                </div>
                <div className="user-details-block">
                  <h6> {t('realized')}: </h6>
                  <p>
                    <PercentData
                      label={t('realized')}
                      value={data?.realized}
                      outOfValue={data?.inventory}
                    />
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('chefDish')}</h6>
                  <p>
                    <WEToggle
                      onChange={() =>
                        handleUpdateChefMealDish(data.id, !data.isChefMealDish)
                      }
                      label={''}
                      checked={data.isChefMealDish}
                      disabled={
                        user.roleId === 9 || (pastFlag && user.roleId === 3)
                      }
                      onColor={'#14cd31'}
                      size={'small'}
                    />
                  </p>
                </div>

                {/* <div className="user-details-block">
                  <h6>{t('action')}:</h6>
                  <p>
                    <div className="actions-btns">
                      <i
                        class="las la-pen"
                        title="edit"
                        onClick={() => handleAddEdit(data?.dish.id)}
                      ></i>
                      <i
                        class="las la-trash"
                        title="delete"
                        onClick={handleDeleteClick}
                      ></i>
                    </div>
                  </p>
                </div> */}
                <div className="modal-footer-btn">
                  {user.roleId !== 9 && (
                    <>
                      <Button onClick={() => handleAddEdit(data?.dish.id)}>
                        <i class="las la-pen" title="edit"></i>
                        {t('edit')}
                      </Button>
                      <Button onClick={handleDeleteClick}>
                        <i class="las la-trash" title="delete"></i>
                        {t('delete')}
                      </Button>
                    </>
                  )}
                </div>
                {/* 
                  <div
                    className="dropdown-item"
                    onClick={() => handleAddEdit(data?.dish.id)}
                  >
                    {t('edit')}
                  </div>
                  <div className="dropdown-item" onClick={handleDeleteClick}>
                    {t('delete')}
                  </div> */}
              </div>
            )}
          </div>
        </Typography>
        {/* <div className="view-popup-btn">
          <button className="close-modal-btn" onClick={handleCloseModel}>
            {t('close')}
          </button>
        </div> */}
      </Box>
    </Modal>
  );
};
const percentToDisplay = (value, outOfValue) =>
  outOfValue > 0 ? Number.parseInt((value / outOfValue) * 100) : 0;

const PercentData = ({ value, outOfValue, label }) => {
  value = value ?? 0;
  outOfValue = outOfValue ?? 0;
  return (
    <>
      <div className={'percent-data'}>
        <p>
          {value} {`(${percentToDisplay(value, outOfValue)}%)`}
        </p>
      </div>
    </>
  );
};
export default ViewMenuContructionDetail;
