import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function AlertDialog({ isOpen, title, content, onClose }) {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="dialog-wrapper">
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: 'alert-dialog' }}
      >
        <DialogTitle id="alert-dialog-title">{title || ''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content || ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
