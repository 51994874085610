import moment from 'moment';

const sortCurrentMeals = (meals) => {
  return meals?.sort((m2, m1) => {
    if (moment(m2?.date) > moment(m1?.date)) {
      return 1;
    } else if (moment(m2?.date) < moment(m1?.date)) {
      return -1;
    } else {
      return m2?.orderIndex > m1?.orderIndex ? 1 : -1;
    }
  });
};

const sortMeals = (meals) => {
  console.log('meals', meals);
  return meals?.sort((m1, m2) => {
    if (moment(m2?.date) > moment(m1?.date)) {
      return 1;
    } else if (moment(m2?.date) < moment(m1?.date)) {
      return -1;
    } else {
      return m2?.mealType?.orderIndex > m1?.mealType?.orderIndex ? 1 : -1;
    }
  });
};

const sortPastMeals = (meals) => {
  return meals?.sort((m1, m2) => {
    if (moment(m2?.date) > moment(m1?.date)) {
      return 1;
    } else if (moment(m2?.date) < moment(m1?.date)) {
      return -1;
    } else {
      return m2?.orderIndex > m1?.orderIndex ? 1 : -1;
    }
  });
};

const sortOrders = (orders) => {
  return orders?.sort((o1, o2) => {
    if (moment(o2?.mealDish?.meal?.date) > moment(o1?.mealDish?.meal?.date)) {
      return 1;
    } else if (
      moment(o2?.mealDish?.meal?.date) < moment(o1?.mealDish?.meal?.date)
    ) {
      return -1;
    } else {
      return o2?.mealDish?.meal?.mealType?.orderIndex >
        o1?.mealDish?.meal?.mealType?.orderIndex
        ? 1
        : -1;
    }
  });
};

export { sortMeals, sortCurrentMeals, sortOrders, sortPastMeals };
