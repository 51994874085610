import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from '../../services/http';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import { isTabletOrMobile } from '../../services/mediaQueries';
import {
  Alert,
  Dialog,
  SuccessDailog,
} from '../../components/Dialogs/SwalDialogs';
import Employee from './Employee';
import EmployeeToAdd from './EmployeeToAdd';
import Select from 'react-select';
import useDebounce from '../../hooks/UseDebounce';
import WEIcon from '../../components/WE-IconButton';
import { Button } from '@material-ui/core';
import { ActiveButton } from '../../components/WE-Button';
import AddEmployeePopup from './AddEmployee';
import { validateEmployeenew } from '../../services/validators';
import CopyUrlPopup from './copyUrl';
import ConfirmationPopup from './confirmation';
import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import search from '../../assets/images/Search.svg';
import file from '../../assets/images/file-export.svg';
import adduser from '../../assets/images/add-user.svg';
import more from '../../assets/images/more.svg';
import next from '../../assets/images/next.png';
import ViewEmployeeDetails from './viewEmployeeDetails';
import moment from 'moment';
import ReactExport from 'react-export-excel';

const initialiState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: 4,
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'created',
};

const EmployeeManagement = ({
  user,
  kitchen,
  setLayoutOptions,
  UserStatuses,
  Kitchens,
  Roles,
  useLoader,
  useFetchingLoader,
  companyName,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [kitchensIds, setKitchensIds] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [usersToDisplay1, setUsersToDisplay1] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [payload, setPayload] = useState(initialiState);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [errors, setErrors] = useState({});
  const [tablecolumn, setTableColumn] = useState();
  const [details, setDetails] = useState();
  const [viewDetails, setViewDetails] = useState();
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      setPayload(initialiState);
      setIsUpdate(false);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setKitchensIds([]);
    setErrors({});
  };

  const Limit = 2;
  const UserStatusesMap_Status = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status };
  }, {});
  const UserStatusesMap_Id = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(async () => {
    let filter = {
      // kitchenId: kitchen.id,
      roleId: [RolesMap.employee],
      companyId: user.companyId,
      // userStatusId: { ne: UserStatusesMap.deleted },
    };
    const { data, total } = await useFetchingLoader(
      async () => await http.get(`users`, { filter })
    );
    setPrefetchData(data);
    let serverUsers = data.map((u, i) => ({
      userId: u.id,
      'Employee name': [u.firstName, u.lastName].join(' '),
      firstName: u.firstName,
      lastName: u.lastName,
      employeeId: u.employeeId,
      'Card Number': u.cardId,
      Status: UserStatuses.find((el) => el.id === u.userStatusId).display,
      'Registration Date': u.createdAt
        ? u.createdAt.split('T')[0]
        : undefined /* Format : YYYY-MM-DD */,
      Kitchens: u.kitchens.map((k) => k.name),
      Email: u.email,
      Action: i,
      phone: u.phone?.split(' ').join(''),
      creditCard: u.creditCard,
      connectTeam: u.connectTeam,
      paymentMethod: u.paymentMethod,
      amount: u.amount,
      walletId: u?.walletId,
      totalReservation: u.totalReservation,
    }));

    let serverUsers1 = data.map((u, i) => ({
      firstName: u.firstName,
      lastName: u.lastName,
      Email: u.email,
      employeeId: u.employeeId,
      cardId: u.cardId,
      registrationOn: u.createdAt
        ? u.createdAt.split('T')[0]
        : undefined /* Format : YYYY-MM-DD */,
      kitchens: u?.kitchens?.map((k) => k?.name)?.join(', '),
      phone: u.phone?.split(' ').join(''),
      creditCard: u.creditCard === true ? 'Yes' : 'No',
      paymentMethod: u.paymentMethod,
      amount: u.amount || 0,
      status: UserStatuses.find((el) => el.id === u.userStatusId).display,
      totalReservation: u.totalReservation,
    }));

    setUsersToDisplay(serverUsers);
    setUsersToDisplay1(serverUsers1);
  }, [dummy, kitchen]);

  // ag grid data
  const columnDefs = [
    {
      field: 'Employee name',
      headerName: t('employeeName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
      // pinned: 'left',
    },
    {
      field: 'employeeId',
      headerName: t('employeeNo'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'Card Number',
      headerName: t('cardNo'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'Status',
      headerName: t('status'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'Registration Date',
      headerName: t('registrationDate'),
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressMovable: true,
    },
    {
      field: 'Kitchens',
      headerName: t('kitchenname'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'phone',
      headerName: t('phone'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'Credit Card',
      headerName: t('Credit Card'),
      cellRenderer: 'CreditCard',
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'Payment Method',
      headerName: t('paymentMethod'),
      cellRenderer: 'PaymentMethod',
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'totalReservation',
      headerName: t('reservations'),
      cellRenderer: 'OrderLink',
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      // field: 'Action',
      headerName: t('action'),
      cellRenderer: 'PopupCellRenderer',
      headerClass: 'resizable-header',
      width: 100,
      suppressMovable: true,
    },
  ];

  const mobColumnDefs = [
    {
      field: 'Employee name',
      headerName: t('employeeName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
    },
    {
      field: 'Action',
      headerName: '',
      headerComponentParams: {
        template: `<img className="more-info" src="${more}" />`,
      },
      cellRenderer: 'PopupCellRenderer',
      headerClass: 'resizable-header',
      width: 100,
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    width: 'auto',
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };
  /******* add New employee */
  const handleAddNewUser = async (userData) => {
    setDummy(false);
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];
    try {
      let userToAdd = userData;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`users/create`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      // handleClose();
      // setToggleAddUser(false);
      Dialog({
        title: t('addedmessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setDummy(true);
        },
      });
      setDummy(false);
      handleClose();
    } catch (err) {
      Alert({ error: err });
    }
  };

  /******** update employee details */
  const handleUpdateUser = async (event) => {
    event.preventDefault();
    try {
      // setDummy(false);
      let details = {
        firstName: payload.fName,
        lastName: payload.lName,
        email: payload.email,
        phone: payload.phone,
        // userStatusId:prefetchData?.find(el => el.id === payload.userId)?.userStatusId,
        userStatusId: UserStatuses.find((el) => el.display === payload.status)
          .id,
        kitchensIds: kitchensIds,
        password: payload.password,
        cardId: payload.card,
        employeeId: payload.employeeId,
        roleId: RolesMap.employee,
        // value: payload.value,
      };
      const { isValid, errors } = validateEmployeenew(details);
      setErrors({ ...errors });
      if (!isValid) {
        return;
      }
      setDummy(false);
      const userId = payload.userId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`users/${userId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      Dialog({
        title: t('updatedMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setDummy(true);
          handleClose();
        },
      });

      setKitchensIds([]);
    } catch (err) {
      Alert({ error: err });
    }
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const onBtnExport = () => {
    // gridApi.exportDataAsCsv();
    var params = {
      columnKeys: [
        'paymentMethod,Employee name',
        'employeeId',
        'creditCard',
        'phone',
        'Kitchens',
        'Registration Date',
        'Status',
        'Card Number',
      ],
    };
    gridApi.exportDataAsCsv({
      fileName:
        t('userManagement') + moment(new Date()).format('YYYY-MM-DD hh:mm A'),
      processCellCallback: function (cell) {
        let cellVal = cell.value;
        if (cell.column.colId === 'Payment Method') {
          cellVal = cell?.node?.data?.paymentMethod;
        }
        if (cell.column.colId === 'Credit Card') {
          if (cell?.node?.data?.creditCard === true) {
            cellVal = 'Yes';
          } else {
            cellVal = 'No';
          }
        }
        return cellVal;
      },
    });
  };
  const ResendVerification = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/resetPassword`, {
          userData: { email: el.Email, resendLink: true },
          companyId: localStorage.getItem('companyId'),
        })
    );
    if (res?.sent) {
      Dialog({
        title: t('resendMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          //setDummy(true);
        },
      });
    }
  };

  const handleDeleteUser = async () => {
    try {
      let details = {
        userStatusId: 5,
      };
      const response = await http.put(`users/${deleteId}`, { ...details });
      setConfirmation(false);
      if (response) {
        Dialog({
          title: t('deleteMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(true);
          },
        });
      }
      setDummy(false);
      setDeleteId('');
    } catch (err) {
      Alert({ error: err });
    }
  };

  const CopyDataUrl = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/copyUrl`, {
          userData: { email: el.Email },
        })
    );
    setResendData(res);

    setCopyData(true);
  };
  const handleCloseUrl = () => {
    setCopyData(false);
    setCopied(false);
  };
  const handleConfirmation = (userId) => {
    setConfirmation(!confirmation);
    setDeleteId(userId);
  };

  const handleWallet = (walletId) => {
    history.push(`/credit?${walletId}`);
  };
  const handleOrderLink = (userId) => {
    history.push(`/reservationManagement?user=${userId}`);
  };

  const lang = localStorage.getItem('language');

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setTableColumn(mobColumnDefs);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  const filterColumns = (data) => {
    if (usersToDisplay1 && usersToDisplay1.length) {
      const columns = Object.keys(data[0]);
      return columns; // OR return columns
    }
    return [];
  };
  return (
    <div className="user-management employee-management-page global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-input">
            <input
              type="text"
              id="filter-text-box"
              placeholder={t('freeTextSearch')}
              onInput={onFilterTextBoxChanged}
            />
            <img src={search} alt="search" />
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{usersToDisplay?.length}</span>
              </p>
            </div>
            <div className="actions">
              <ExcelFile
                filename={`${companyName}-users-${moment().format(
                  'DD-MMM-YYYY'
                )}`}
                element={
                  // <button className="btn">Download in XL</button>
                  <img src={file} alt="add" />
                }
              >
                <ExcelSheet data={usersToDisplay1} name="Test">
                  {filterColumns(usersToDisplay1).map((col) => (
                    <ExcelColumn label={col} value={col} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
              {/* <img src={file} alt="add" onClick={() => onBtnExport()} /> */}
              <img src={adduser} alt="add" onClick={() => handleOpen('add')} />
            </div>
            {/* <button onClick={() => onBtnExport()} className="download-csv">
              {t('downloadCSV')}
            </button>
            <div onClick={() => handleOpen('add')}>
              <ActiveButton label={t('add')} arrow={true} />
            </div> */}
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            frameworkComponents={{
              PopupCellRenderer: (e) => (
                <PopupCellRenderer
                  handleOpen={handleOpen}
                  e={e}
                  setUpdate={setUpdate}
                  setPayload={setPayload}
                  setIsEdit={setIsEdit}
                  t={t}
                  kitchensIds={kitchensIds}
                  setKitchensIds={setKitchensIds}
                  Kitchens={Kitchens}
                  ResendVerification={ResendVerification}
                  handleDeleteUser={handleDeleteUser}
                  CopyDataUrl={CopyDataUrl}
                  handleConfirmation={handleConfirmation}
                  UserStatuses={UserStatuses}
                />
              ),
              CreditCard: (e) => <CreditCard e={e} />,
              NameCellRender: (e) => <NameCellRender e={e} />,
              PaymentMethod: (e) => (
                <PaymentMethod e={e} handleWallet={handleWallet} />
              ),
              OrderLink: (e) => (
                <OrderLink e={e} handleOrderLink={handleOrderLink} />
              ),
              ViewMore: (e) => (
                <ViewMore e={e} handleViewMore={handleViewMore} />
              ),
            }}
            columnDefs={tablecolumn}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            debug={false}
            // rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            // pagination={true}
            // paginationPageSize={10}
            // paginationNumberFormatter={function (params) {
            //   return '[' + params.value.toLocaleString() + ']';
            // }}
          ></AgGridReact>
        </div>
        <div className="pagination-header">
          {t('pageSize')}:
          <select onChange={() => onPageSizeChanged()} id="page-size">
            <option value="10" selected={true}>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <AddEmployeePopup
          open={open}
          handleClose={handleClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          // userData={{ name: '' }}
          userData={prefetchData}
          newUserFlag={true}
          handleAddNewUser={handleAddNewUser}
          isEdit={true}
          payload={payload}
          setPayload={setPayload}
          handleUpdateUser={handleUpdateUser}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          t={t}
          errors={errors}
          setErrors={setErrors}
          user={user}
        />
        <CopyUrlPopup
          open={copyData}
          handleClose={CopyDataUrl}
          resendData={resendData}
          handleCloseUrl={handleCloseUrl}
          copied={copied}
          setCopied={setCopied}
        />
        <ConfirmationPopup
          open={confirmation}
          handleClose={() => setConfirmation(false)}
          handleDeleteUser={handleDeleteUser}
        />
        <ViewEmployeeDetails
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
        />
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const PaymentMethod = ({ e, handleWallet }) => {
  return (
    <div
      className={`payment-method ${
        e.data['walletId'] != undefined ? 'add-link' : 'without-link'
      }`}
    >
      {e.data.paymentMethod === 'Wallet' ? (
        e.data['walletId'] != undefined ? (
          <p
            role="presentation"
            onClick={() => handleWallet(e.data['walletId'])}
          >
            {e.data.paymentMethod}
          </p>
        ) : (
          e.data.paymentMethod
        )
      ) : (
        e.data.paymentMethod
      )}{' '}
      {e.data.paymentMethod === 'Wallet' ? (
        <>({e.data.amount ? e.data.amount : 0})</>
      ) : (
        ''
      )}
    </div>
  );
};
const OrderLink = ({ e, handleOrderLink }) => {
  return (
    <div
      className={`payment-method ${
        e.data['userId'] != undefined ? 'add-link' : 'without-link'
      }`}
    >
      <p role="presentation" onClick={() => handleOrderLink(e.data['userId'])}>
        {/* Order Link */}
        {e.data['totalReservation']}
      </p>
    </div>
  );
};

const ActionButton = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  ResendVerification,
  handleDeleteUser,
  CopyDataUrl,
  handleConfirmation,
  UserStatuses,
}) => {
  setUpdate(e.data);
  setIsEdit(true);
  return (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
    >
      <button
        onClick={() => {
          // e.data['Kitchens'].length
          //   ? setKitchensIds(
          //       Kitchens.map((el) => {
          //         if (e.data['Kitchens'].includes(el.name)) return el.id;
          //       })
          //     )
          //   : setKitchensIds([]);
          let ids = [];
          if (e.data['Kitchens'].length) {
            Kitchens.forEach((el) => {
              if (e.data['Kitchens'].includes(el.name)) {
                ids.push(el.id);
              }
            });
          }
          setKitchensIds(ids);
          setPayload({
            userId: e.data['userId'],
            card: e.data['Card Number'],
            email: e.data['Email'],
            fName: e.data['firstName'],
            lName: e.data['lastName'],
            phone: e.data['phone']
              ? e.data['phone'].split('-').join('')
              : e.data['phone'],
            // kitchensIds: e.data['Kitchens'],
            kitchensIds: ids,
            employeeId: e.data['employeeId'],
            password: e.data['password'],
            status: e.data['Status'],
          });
          handleOpen();
        }}
        className="edit-btn"
      >
        {t('editbutton')}
      </button>
      <button
        className="delete-btn"
        // onClick={() => handleDeleteUser(e.data['userId'])}
        onClick={() => handleConfirmation(e.data['userId'])}
      >
        {t('deletebutton')}
      </button>
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button
          className="resend-btn"
          onClick={() => ResendVerification(e.data)}
        >
          {t('resend')}
        </button>
      ) : (
        ''
      )}
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button className="copy-btn" onClick={() => CopyDataUrl(e.data)}>
          {t('copyUrl')}
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

const CreditCard = ({ e }) => {
  return (
    <div className={`credit-card-option`}>
      <input type="checkbox" checked={e?.data?.creditCard} />
    </div>
  );
};

const NameCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <h6>{e?.data?.firstName + ' ' + e?.data?.lastName}</h6>
      <p>{e?.data?.Email}</p>
    </div>
  );
};
const PopupCellRenderer = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
}) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const handleClick = (data) => {
    // console.log('ddddddd =>', e);
  };

  const dropDownContent = (
    <>
      <div
        className={`action-btn ${
          e.data.Status === 'Created' || e.data.Status === 'Pending'
            ? 'action-btn'
            : ''
        } `}
        style={{ position: 'relative' }}
      >
        <div className="menu-container">
          {/* {e?.data?.connectTeam == 1 ? (
            <div className="menu-item">
              <div>No Action</div>
            </div>
          ) : ( */}
          <>
            <div className="menu-item">
              <div
                onClick={() => {
                  // e.data['Kitchens'].length
                  //   ? setKitchensIds(
                  //       Kitchens.map((el) => {
                  //         if (e.data['Kitchens'].includes(el.name)) return el.id;
                  //       })
                  //     )
                  //   : setKitchensIds([]);
                  let ids = [];
                  if (e.data['Kitchens'].length) {
                    Kitchens.forEach((el) => {
                      if (e.data['Kitchens'].includes(el.name)) {
                        ids.push(el.id);
                      }
                    });
                  }
                  setKitchensIds(ids);
                  setPayload({
                    userId: e.data['userId'],
                    card: e.data['Card Number'],
                    email: e.data['Email'],
                    fName: e.data['firstName'],
                    lName: e.data['lastName'],
                    phone: e.data['phone']
                      ? e.data['phone'].split('-').join('')
                      : e.data['phone'],
                    // kitchensIds: e.data['Kitchens'],
                    kitchensIds: ids,
                    employeeId: e.data['employeeId'],
                    password: e.data['password'],
                    status: e.data['Status'],
                  });
                  handleOpen();
                }}
                // className="edit-btn"
              >
                {t('editbutton')}
              </div>
            </div>
            <div className="menu-item">
              <div
                // className="delete-btn"
                // onClick={() => handleDeleteUser(e.data['userId'])}
                onClick={() => handleConfirmation(e.data['userId'])}
              >
                {t('deletebutton')}
              </div>
            </div>

            {UserStatuses?.find((el) => el.display === e.data['Status'])
              ?.value === 'created' ||
            UserStatuses?.find((el) => el.display === e.data['Status'])
              ?.value === 'pending' ? (
              <div className="menu-item">
                <div
                  // className="resend-btn"
                  onClick={() => ResendVerification(e.data)}
                >
                  {t('resend')}
                </div>
              </div>
            ) : (
              ''
            )}

            {UserStatuses?.find((el) => el.display === e.data['Status'])
              ?.value === 'created' ||
            UserStatuses?.find((el) => el.display === e.data['Status'])
              ?.value === 'pending' ? (
              <div className="menu-item">
                <div onClick={() => CopyDataUrl(e.data)}>{t('copyUrl')}</div>
              </div>
            ) : (
              ''
            )}
          </>
          {/* )} */}
        </div>
      </div>
    </>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      });
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] });
    }

    if (option === 'edit') {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: 'make',
      });
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <img src={more} onClick={visible ? hide : show} className="more-info" />
      {/* <i
        className="fa fa-ellipsis-v"
        aria-hidden="true"
        // onClick={() => handleDropdownClick(e.data?.userId)}
        onClick={visible ? hide : show}
      ></i> */}
    </Tippy>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  companyName: state.companyConfig.companyName,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeManagement);
