import React, { useEffect, useState } from 'react';
import Header from './Header';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import SubHeader from './SubHeader';
import { setKitchen, getKitchens } from '../store/kitchens/actions';
import { setMealTime } from '../store/mealTime/actions';
import { setLanguage } from '../store/languages/actions';
import {
  toggleAlertMessage,
  setEnums,
  getMealTypes,
  redirectDone,
  useFetchingLoader,
  getWalletAmountFromApi,
  setISuccessCopyMealPopup,
  setOpenCopyMealPopup,
} from '../store/app/actions';
import Toast from 'react-bootstrap/Toast';
import { isMobile } from '../services/mediaQueries';
import AlertDialog from '../components/Dialogs/AlertDialog';
import enums from '../services/enums';
import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';
import CookiePopup from './cookie';
import { getUser } from '../store/user/actions';
import http from '../services/http';
import { getPaymentPreferences } from '../store/kitchens/actions';
import { Button, Dropdown } from 'react-bootstrap';
import packageJson from '../../package.json';
//  Safari & IE browsers do not support the date format “yyyy-mm-dd”
const fixDateForAllBrowsers = (dateString) => dateString?.replace(/-/g, '/');

function Layout({
  redirect,
  redirectDone,
  user,
  showCopyMealPopup,
  isSuccessCopyMealProcess,
  children,
  showLoader,
  kitchens,
  kitchen,
  setKitchen,
  getKitchens,
  setEnums,
  options,
  MealTypes,
  currentLanguage,
  message,
  toggleAlertMessage,
  setLanguage,
  getMealTypes,
  getUser,
  useFetchingLoader,
  getPaymentPreferences,
  languages,
  getWalletAmountFromApi,
  setISuccessCopyMealPopup,
  setOpenCopyMealPopup,
}) {
  const { t } = useTranslation();
  const {
    title = '',
    showDatePicker = false,
    showSideBar = true,
    showKitchen = true,
    isDatePickerClickable = false,
  } = options || {};
  const location = useLocation();
  let [date, mealTypeId] = showDatePicker
    ? location.pathname.split('/').slice(2)
    : ['', ''];

  const history = useHistory();
  if (redirect) {
    redirectDone();
    history.push(redirect);
  }
  const dateClicked = () => {
    history.push(`/mealTime/${date}/${mealTypeId}`);
  };

  const closeDialog = (onClose) => {
    toggleAlertMessage({ show: false });
    if (onClose) onClose();
  };

  Swal.close();

  const changeLanguage = async (language) => {
    if (
      window.location.hash.includes(
        currentLanguage?.shortCode && 'terms-condition'
      )
    ) {
      window.location.href = `#/${window.location.hash.split('/')[1]}/${
        language.shortCode
      }`;
    }
    if (
      window.location.hash.includes(
        currentLanguage?.shortCode && 'privacy-policy'
      )
    ) {
      window.location.href = `#/${window.location.hash.split('/')[1]}/${
        language.shortCode
      }`;
    }
    window.location.reload();
    await setLanguage(language);
    window.location.reload();
    if (user) {
      await getKitchens(user.companyId);
    }
    let _enums = await enums.init();
    await setEnums(_enums);
    await getMealTypes();
    document.body.classList.remove(language.isRTL ? 'ltr' : 'rtl');
    document.body.classList.add(language.isRTL ? 'rtl' : 'ltr');
  };

  const [show, setShow] = useState(true);
  const [showBalance, setShowBalance] = useState();
  const [showPaymentOption, setShowPaymentOption] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showKitchenList, setShowKitchenList] = useState();
  const [ver, setVer] = useState(0);

  const cookiePop = () => {
    localStorage.setItem('cookie', true);
    handleClose();
  };
  useEffect(() => {
    let value = localStorage.getItem('cookie');
    let datainlocal = JSON.parse(value) === true;
    if (datainlocal) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);
  useEffect(async () => {
    // console.log('UseEffect', packageJson.version);
    // console.log('process.env.REACT_APP_IS_PRODUCTION', process.env.NODE_ENV === 'development');
    setVer(packageJson.version);
  }, []);
  useEffect(async () => {
    if (user?.id) {
      getWalletAmountFromApi(user?.id);
    }
  }, [user, kitchen]);
  useEffect(async () => {
    if (kitchen) {
      const data = await getPaymentPreferences(kitchen?.id, '');
      setShowPaymentOption(data.selectedPaymentOptions);
    }
  }, [user, kitchen]);

  useEffect(async () => {
    //const data = await useFetchingLoader(async () => await getUser());
    const data = await getUser();
    const idx = data?.kitchens.map((el) => el.id);
    const duplicates = kitchens.filter((element) => idx.includes(element.id));
    setShowKitchenList(duplicates);
  }, [kitchens]);
  return (
    <div
      lang={currentLanguage?.shortCode}
      dir={currentLanguage?.isRTL ? 'rtl' : 'ltr'}
      className={`layout ${currentLanguage?.isRTL ? 'rtl' : 'ltr'} ${
        isMobile() ? 'mobile' : 'desktop'
      }`}
    >
      <>
        {localStorage.getItem('token') &&
          user?.id &&
          user.userStatusId != 5 && (
            <Header
              user={user}
              showSideBar={showSideBar}
              setLanguage={changeLanguage}
              kitchens={kitchens}
              kitchen={kitchen}
              setKitchen={setKitchen}
              showKitchen={showKitchen}
              showBalance={showBalance}
              showPaymentOption={showPaymentOption}
              userKitchen={showKitchenList}
            />
          )}
        {localStorage.getItem('token') && user?.id && user.userStatusId != 5 ? (
          <SubHeader
            title={title}
            isDatePickerClickable={isDatePickerClickable}
            showDatePicker={showDatePicker}
            user={user}
            kitchens={kitchens}
            kitchen={kitchen}
            setKitchen={setKitchen}
            MealTypes={MealTypes}
            mealTypeId={mealTypeId}
            showKitchen={showKitchen}
            date={moment(fixDateForAllBrowsers(date)).toDate()}
            dateClicked={dateClicked}
            currentLanguage={currentLanguage}
            userKitchen={showKitchenList}
          />
        ) : (
          ''
        )}
        <div className={`content-container`}>{children}</div>
        <div className={'we-footer'}>
          <Toast
            onClose={() => {
              setOpenCopyMealPopup(false);
              setISuccessCopyMealPopup(true);
            }}
            show={showCopyMealPopup}
            style={{
              position: 'absolute',
              left: '25px',
              bottom: '25px',
              backgroundColor: '#fff',
            }}
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
                onClick={() => {}}
              />
            </Toast.Header>
            <Toast.Body>
              {isSuccessCopyMealProcess
                ? t('copyMealSuccess')
                : t('genericError')}
            </Toast.Body>
          </Toast>
          <div>
            <p className={'align-left'}>{t('imagesForIllustration')}</p>
            {'id' in user ? '' : <p className="version-def-style">V {ver}</p>}
          </div>
          <div className="align-right privacy-text">
            {!localStorage.getItem('token') || user.userStatusId == 5 ? (
              <>
                <Link to={`/privacy-policy/${currentLanguage?.shortCode}`}>
                  {t('privacyPolicy')}
                </Link>
                <Link to={`/terms-condition/${currentLanguage?.shortCode}`}>
                  {' '}
                  {t('termsAndCondition')}
                </Link>
                <div className="lang-login-dropdown dropdown-lang">
                  <Dropdown>
                    {languages.length > 1 ? (
                      <React.Fragment>
                        {' '}
                        <Dropdown.Toggle id="dropdown-basic">
                          {currentLanguage?.shortCode}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {languages
                            .filter(
                              (l) => l.shortCode !== currentLanguage?.shortCode
                            )
                            .map((lang, index) => (
                              <>
                                <Dropdown.Item
                                  onClick={() => changeLanguage(lang)}
                                  key={index}
                                >
                                  {lang.shortCode}
                                </Dropdown.Item>
                              </>
                            ))}
                        </Dropdown.Menu>
                      </React.Fragment>
                    ) : (
                      <Button className="single-lang-btn dropdown-toggle">
                        {currentLanguage?.shortCode}
                      </Button>
                    )}
                  </Dropdown>
                </div>
              </>
            ) : (
              ''
            )}
            <p className={'align-right'}>{t('poweredByWorkEat')}</p>
          </div>
        </div>
        {show && (
          <CookiePopup cookiePop={cookiePop} handleClose={handleClose} t={t} />
        )}
      </>

      <AlertDialog
        isOpen={message.show}
        title={message.title}
        content={message.content}
        onClose={() => closeDialog(message.onClose)}
      />
    </div>
  );
}

// What data from the store shall we send to the component?
const mapStateToProps = (state) => {
  return {
    showLoader: state.app.showLoader,
    options: state.app.layoutOptions,
    user: state.user,
    showCopyMealPopup: state.app.showCopyMealPopup,
    isSuccessCopyMealProcess: state.app.isSuccessCopyMealProcess,
    kitchens: state.kitchens.options,
    kitchen: state.kitchens.current,
    mealTime: state.mealTime,
    MealTypes: state.app.enums.MealType,
    currentLanguage: state.languages.current,
    message: state.app.message,
    redirect: state.app.redirect,
    languages: state.languages.options,
  };
};
// Any actions to map to the component?
const mapDispatchToProps = {
  setKitchen,
  setMealTime,
  toggleAlertMessage,
  setLanguage,
  getKitchens,
  setEnums,
  getMealTypes,
  redirectDone,
  getUser,
  useFetchingLoader,
  getWalletAmountFromApi,
  setISuccessCopyMealPopup,
  setOpenCopyMealPopup,
  getPaymentPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
