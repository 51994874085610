export default {
  GET_KITCHENS: 'get_kitchens',
  SET_KITCHEN: 'set_kitchen',
  GET_PAYMENT_PREFERENCES: 'get_payment_preferences',
  LOADING_PAYMENT_PREFERENCES: 'loading_payment_preferences',
  GET_RESERVATIONS: 'get_reservations',
  GET_EMPLOYEE_LOBBY_DATA: 'get_employee_lobby_data',
  GET_REPORT_TYPE: 'get_report_type',
  GET_MEAL_TYPE: 'get_meal_type',
  GET_ATTENDANCE: 'get_attendance',
};
