import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { connect } from 'react-redux';
import { useEffect, useCallback, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  tagsData,
} from '../../store/app/actions';
import http from '../../services/http';
// import { ActiveButton } from '../../components/WE-Button';
import { useHistory } from 'react-router';
// import WEIcon from '../../components/WE-IconButton';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import Tippy from '@tippyjs/react';
import { Button } from 'react-bootstrap';
import search from '../../assets/images/Search.svg';
import AddTagPopup from './addTag';
import agGridLocales from '../../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';
const TagManagement = ({
  setLayoutOptions,
  useFetchingLoader,
  languages,
  tagsData,
  tagsToDisplay,
  CurrentLanguage,
  tagsTotal,
  showFetchingLoader,
  user,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  //const [tags, setTags] = useState<any>([]);
  // const [tagsToDisplay, setTagsToDisplay] = useState(null);
  const [tagPopup, settagPopup] = useState(false);
  const [editTag, setEditTag] = useState(null);
  const [tableColumn, setTableColumn] = useState<any>();
  const lang = localStorage.getItem('language');
  const [gridApi, setGridApi] = useState<any>();

  const [currentPageTags, setCurrentPageTags] = useState(1); // Current page
  const [newPageSizeTags] = useState(25); // Records per page
  // const newPageSizeTags = 25;
  const [totalTagsRecords, setTotalTagsRecords] = useState(0); // Total records
  const [pageCountsTags, setpageCountsTags] = useState(1); // Total pages count
  const [searchTagText, setSearchTagText] = useState(''); // Search text
  const [refresh, setRefresh] = useState(false);

  const columnDefs = [
    {
      field: 'displayName',
      headerName: t('tag'),
      sortable: true,
      filter: true,
      width: 300,
      cellRenderer: 'DisplayTagsRenderer',
      // cellRenderer: (params) => {
      //   let displ = `${params.data.displayName}`;
      //   return displ
      //     ? displ
      //     : '--';
      // },
    },
    {
      field: 'isPublic',
      headerName: t('isPublic'),
      cellRenderer: 'CheckedItemPublic',
      sortable: true,
      filter: true,
      width: 300,
    },
    {
      field: 'status',
      headerName: t('status'),
      cellRenderer: 'CheckedItem',
      sortable: true,
      filter: true,
      width: 700,
    },
    {
      // field: 'Action',
      headerName: t('action'),
      cellRenderer: 'PopupCellRenderer',
      headerClass: 'resizable-header',
      width: 100,
      flex: 1,
    },
  ];

  const mobColumnDefs = [
    {
      field: 'displayName',
      headerName: t('tag'),
      sortable: true,
      filter: true,
    },
    {
      field: 'isPublic',
      headerName: t('isPublic'),
      cellRenderer: 'CheckedItemPublic',
      sortable: true,
      filter: true,
    },
    {
      field: 'status',
      headerName: t('status'),
      cellRenderer: 'CheckedItem',
      sortable: true,
      filter: true,
    },
    {
      // field: 'Action',
      headerName: t('action'),
      cellRenderer: 'PopupCellRenderer',
      headerClass: 'resizable-header',
      width: 100,
      flex: 1,
    },
  ];

  const gridOptions = {
    rowHeight: 44,
    groupHeaderHeight: 44,
    headerHeight: 44,
  };

  const onEdit = async (el: any) => {
    const existingTagData = await http.get(`tags/${el.id}`);
    setEditTag(existingTagData);
    settagPopup((prev) => !prev);
  };

  const deleteTag = useCallback(async (tagId: string) => {
    await useFetchingLoader(async () => {
      return http.delete(`tags/${tagId}`);
    });
    Dialog({
      title: 'Deleted Successfully!',
      type: 'success',
      buttons: {
        showCancelButton: false,
        confirmButtonText: t('confirm'),
      },
      onConfirm: () => {
        fetchTag(currentPageTags, newPageSizeTags);
      },
    });
  }, []);

  const onDelete = (el) => {
    Dialog({
      title: t('confirmModalTitle'),
      type: 'success',
      buttons: {
        showCancelButton: true,
        cancelButtonText: t('cancel'),
        confirmButtonText: t('confirm'),
      },
      onConfirm: () => {
        try {
          deleteTag(el.id);
        } catch (error) {
          Dialog({
            title: error,
            type: 'error',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('confirm'),
            },
            onConfirm: () => {},
          });
          //alert(error);
          return error;
        }
      },
    });
  };
  const navigateDishMangement = (data: any) => {
    //with the display Name navigate to dish Mangement
    history.push(`/dishManagement/${data.displayName}`);
  };

  //open the tag Popup for Adding a new Tag
  const handleTagPoup = () => {
    setEditTag(null);
    settagPopup((prev) => !prev);
  };

  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    //gridApi.setQuickFilter(value);
    console.log(gridApi);
    setCurrentPageTags(1);
    setSearchTagText(value);
  };

  const fetchTag = useCallback(
    async (curPage, curLimit) => {
      console.log('I was called yeah');
      console.log('totalTagsRecords:', totalTagsRecords, pageCountsTags);
      const filterPayload = {
        page: curPage,
        limit: curLimit,
        keyword: searchTagText,
      };
      await useFetchingLoader(async () => {
        await tagsData(filterPayload);
      });
      // const { data } = await useFetchingLoader(async () => {
      //   return http.get('tags');
      // });
      // setTags(data);
      // let serverUsers = data.map((u) => ({
      //   id: u.id,
      //   userId: u.companyId,
      //   displayName: u.displayName,
      //   isPublic: u.isPublic,
      //   status: u.status,
      // }));
      // setTagsToDisplay(serverUsers);
    },
    [searchTagText]
  );

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setTableColumn(mobColumnDefs);
    } else {
      setTableColumn(columnDefs);
    }
    setLayoutOptions({
      title: t('tagManagement'),
    });
    fetchTag(currentPageTags, newPageSizeTags);
  }, [currentPageTags, newPageSizeTags, searchTagText, refresh]);

  useEffect(() => {
    if (tagsToDisplay) {
      setTotalTagsRecords(tagsToDisplay);
      setpageCountsTags(Math.ceil(tagsTotal / newPageSizeTags));
    }
  }, [tagsToDisplay]);
  // const createSelectItemsTags = (sel) => {
  //   var itemsTag: any = [];
  //   console.log(sel);
  //   let pageNumsTags = [25, 50, 100, 200, 500];
  //   for (let k = 0; k < 5; k++) {
  //     itemsTag.push(
  //       <option
  //         key={pageNumsTags[k]}
  //         value={pageNumsTags[k]}
  //         selected={pageNumsTags[k] == sel}
  //       >
  //         {pageNumsTags[k]}
  //       </option>
  //     );
  //   }
  //   return itemsTag;
  // };
  return (
    <div className="global-data-table tag-management-page">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-input">
            <input
              type="text"
              id="filter-text-box"
              placeholder={t('freeTextSearch')}
              onInput={onFilterTextBoxChanged}
            />
            <img src={search} alt="search" />
          </div>
          {user.roleId !== 9 && (
            <div className="active-button">
              <Button onClick={handleTagPoup}>
                <i className="las la-plus"></i> <span>{t('addTag')}</span>
              </Button>
            </div>
          )}
        </div>
        {showFetchingLoader ? (
          ''
        ) : (
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={tagsToDisplay}
              localeText={agGridLocales[i18n.language]}
              frameworkComponents={{
                PopupCellRenderer: (e) => (
                  <PopupCellRenderer
                    e={e}
                    t={t}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    navigateDishMangement={navigateDishMangement}
                    user={user}
                  />
                ),
                CheckedItem: (e) => <CheckedItem e={e} t={t} />,
                CheckedItemPublic: (e) => <CheckedItemPublic e={e} t={t} />,
                DisplayTagsRenderer: (e) => <DisplayTagsRenderer e={e} />,
              }}
              columnDefs={tableColumn}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              reactUi={lang === '"he"' ? false : true}
              enableRtl={lang === '"he"' ? true : false}
              overlayLoadingTemplate={'<p></p>'}
              onGridReady={(params) => {
                setGridApi(params.api);
                // setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              rowSelection="multiple"
            ></AgGridReact>
            {tagsTotal <= newPageSizeTags ? (
              ''
            ) : (
              <div className="pagination-view we-pagination-view">
                <div className="we_show_records_main">
                  {/* <select
                      id="we_change_record_count"
                      onChange={handleRecordCountChange}
                      defaultValue={localStorage.getItem('recordsPerPage') !== null ? localStorage.getItem('recordsPerPage'):newPageSizeDishes}
                    >
                      {createSelectItems()}
                    </select> */}
                  <p>
                    {newPageSizeTags * (currentPageTags - 1) + 1} to{' '}
                    {newPageSizeTags * currentPageTags} of {tagsTotal}
                  </p>
                </div>
                <Pagination>
                  <Pagination.First
                    disabled={pageCountsTags <= 1 ? true : false}
                    onClick={() => {
                      setCurrentPageTags(1);
                    }}
                  />
                  <Pagination.Prev
                    disabled={pageCountsTags <= 1 ? true : false}
                    onClick={() => {
                      currentPageTags - 1 > 0
                        ? setCurrentPageTags(currentPageTags - 1)
                        : '';
                    }}
                  />
                  <Pagination.Item active>
                    Page {currentPageTags} of{' '}
                    {Math.ceil(tagsTotal / newPageSizeTags)}
                  </Pagination.Item>
                  <Pagination.Next
                    disabled={pageCountsTags <= 1 ? true : false}
                    onClick={() => {
                      currentPageTags < Math.ceil(tagsTotal / newPageSizeTags)
                        ? setCurrentPageTags(currentPageTags + 1)
                        : '';
                    }}
                  />
                  <Pagination.Last
                    disabled={pageCountsTags <= 1 ? true : false}
                    onClick={() => {
                      setCurrentPageTags(
                        Math.ceil(tagsTotal / newPageSizeTags)
                      );
                    }}
                  />
                </Pagination>
                {/* <div className="we_show_records_main">
                  <div>Showing&nbsp;</div>
                  <select
                    id="we_change_record_count"
                    onChange={handleTagsRecordCountChange}
                  >
                    {createSelectItemsTags(newPageSizeTags)}
                  </select>
                  <div>&nbsp;records out of {tagsTotal}</div>
                </div> */}
              </div>
            )}
          </div>
        )}
      </div>
      {tagPopup && (
        <AddTagPopup
          editTag={editTag}
          show={tagPopup}
          handleClose={handleTagPoup}
          t={t}
          CurrentLanguage={CurrentLanguage}
          languages={languages}
          fetchTag={fetchTag}
          setRefresh={setRefresh}
        />
      )}
    </div>
  );
};

const CheckedItem = ({ e, t }) => {
  console.log(t);
  return (
    <div className="check-item">
      {e?.data?.status ? (
        <i className="las la-check"></i>
      ) : (
        <i className="las la-times"></i>
      )}
      {/* <p> {t('isActive')}</p> */}
    </div>
  );
};

const CheckedItemPublic = ({ e, t }) => {
  console.log(t);
  return (
    <div className="check-item">
      {e?.data?.isPublic ? (
        <i className="las la-check"></i>
      ) : (
        <i className="las la-times"></i>
      )}
      {/* <p> {t('IsPublic')}</p> */}
    </div>
  );
};

const DisplayTagsRenderer = ({ e }) => {
  return <>{e?.data?.displayName ? e?.data?.displayName : '--'}</>;
};

const PopupCellRenderer = ({
  e,
  t,
  onEdit,
  onDelete,
  navigateDishMangement,
  user,
}) => {
  const tippyRef = useRef<any>();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div
      className={`action-btn ${
        e.data.status === 'Created' || e.data.status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
      style={{ position: 'relative' }}
    >
      <div className="menu-container">
        {user.roleId !== 9 && (
          <>
            <div className="menu-item">
              <div onClick={() => onEdit(e.data)}>{t('editbutton')}</div>
            </div>
            <div className="menu-item">
              <div onClick={() => onDelete(e.data)}>{t('deletebutton')}</div>
            </div>{' '}
          </>
        )}
        <div className="menu-item">
          <div onClick={() => navigateDishMangement(e.data)}>
            {t('showdishes')}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <i
        className="las la-angle-right tag-actions"
        onClick={visible ? hide : show}
      ></i>
      {/* <img src={more} onClick={visible ? hide : show} className="more-info" alt="more" /> */}
    </Tippy>
  );
};

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
  languages: state.languages.options,
  CurrentLanguage: state.languages.current,
  tagsToDisplay: state.app.tagsToDisplay,
  tagsTotal: state.app.tagsTotal,
  showFetchingLoader: state.app.showFetchingLoader,
  user: state.user,
});

const mapDispatchToProps = {
  useFetchingLoader,
  setLayoutOptions,
  tagsData,
};

// export default ReservationManagement;
export default connect(mapStateToProps, mapDispatchToProps)(TagManagement);
