import React, { useState, useEffect, useRef } from 'react';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { connect, useStore } from 'react-redux';
import http from '../../services/http';
import { sleep } from '../../services/utils';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { ActiveButton } from '../../components/WE-Button';
import { StaticStars } from '../../components/RatingStars';
import DishImage from '../client/DishImage';
import WEIcon from '../../components/WE-IconButton';
import { Modal, Grid, styled, Paper } from '@material-ui/core';
import cancel from '../../assets/images/close.svg';
import downarrow from '../../assets/icons/down-arrow.png';
import {
  Alert,
  Dialog,
  DialogWithInput,
} from '../../components/Dialogs/SwalDialogs';
import AddEditDishPopup from './AddEditdishPopup';
/* Date picker */
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dates from '../../services/dates';
import WEBackButton from '../../components/WE-BackButton';
import WECheckbox from '../../components/Forms/WE-CheckBox';
import Switch from 'react-switch';
import WEToggle from '../../components/WE-Toggle';
import MealSummary from '../../components/Kitchen/MealSummary';
import MealDishSummary from '../../components/Kitchen/MealDishSummary';
import MealExtraSummary from '../../components/Kitchen/MealExtraSummary';
import PrintReports from './printReports';
import { sortMeals } from '../../services/sortItems';
import UpdateReservations from './UpdateReservations';
import action from '../../store/action';
import BackLink from '../../components/BackLink';
import { Button, Dropdown } from 'react-bootstrap';
import enums from '../../services/enums';
import ViewMenuContructionDetail from './ViewMenuContructionDetail';
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 15px',
  p: 4,
  borderRadius: '5px',
  Width: '550px',
  minHeight: '250px',
};
const MenuConstruction = ({
  setLayoutOptions,
  mealTime,
  kitchen,
  MealTypes,
  MealStatuses,
  MealDishStatuses,
  DishStatuses,
  useLoader,
  useFetchingLoader,
  showAlert,
  currentLanguage,
  languages,
  DishCategories,
  user,
}) => {
  const { t } = useTranslation();
  const InitialValues = {
    image: null,
    dishCategoryId: 1,
    translations: languages.map((lang) => {
      return { languageId: lang.id };
    }),
    price: 0,
  };
  const history = useHistory();
  const location = useLocation();

  const { backText, backLink, mealID } = location.state ?? {};
  const { mealTypeId, date } = useParams();
  const [printPopup, setPrintPopup] = useState(false);
  const [company, setCompany] = useState([]);
  const [copyMeal, setCopyMeal] = useState(false);
  const [dummy, setDummy] = useState(false);
  const [previousMeal, setPreviousMeal] = useState(null);
  const [showPrevious, setShowPrevious] = useState(false);
  const [meals, setMeals] = useState(null);
  const [mealsToDisplay, setMealsToDisplay] = useState(null);
  const [alreadyAdded, setAlreadyAdded] = useState();
  const [updateReservation, setUpdateReservations] = useState(false);
  const [mealId, setMealId] = useState(null);
  const [mealSlot, setMealSlot] = useState(null);
  const [noDate, setNoDate] = useState(false);
  const [noMealType, setNoMealtype] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [mealDishSummary, setMealDishSummary] = useState([]);
  const [mealTypeName, setMealTypeName] = useState([null]);
  const [extrsData, setExtrasData] = useState();
  const [openModel, setOpenModel] = useState();

  //state which could coantain the dish Id so to popualte the Edit Dish Popup
  const [dish_Id, setdish_Id] = useState();
  //edit Poup which would be opened and closed  based upon the dit button
  const [editPopup, seteditPopup] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [translations, setTranslations] = useState({});
  const [fileValue, setFileValue] = useState();
  const [values, setValues] = useState(InitialValues);
  // const [dummy_, setDummy_] = useState(false);
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState({});
  const [mealIdAfterDeleting, setMealIdAfterDeleting] = useState();
  const [pastFlag, setPastFlag] = useState(false);
  useEffect(async () => await useLoader(() => sleep()), []);

  const [type, setMealTypeId] = useState(
    mealTypeId ? Number.parseInt(mealTypeId) : ''
  );
  const [d, setDate] = useState('');
  useEffect(() => {
    const now = moment(new Date()).format('YYYY-MM-DD');
    const selectedDate = moment(d).format('YYYY-MM-DD');
    console.log('before', moment(selectedDate).isBefore(moment(now)));
    setPastFlag(moment(selectedDate).isBefore(moment(now)));
  }, [d]);
  useEffect(() => {
    if (date) {
      setDate(new Date(date));
    }
    if (mealTypeId) {
      const mealty = MealTypes.filter(
        (el) => el.id === Number.parseInt(mealTypeId)
      );

      if (mealty.length > 0) {
        setMealTypeName(mealty[0].display);
      }
    }
  }, [date, mealTypeId]);

  const [data, setData] = useState(null);

  const [mealSummary, setMealSummary] = useState({});

  const MealDishStatusMap = MealDishStatuses.reduce((acc, mealDish) => {
    return { ...acc, [mealDish.value]: mealDish.id };
  }, {});

  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.display };
  }, {});

  const [toggleCalendar, setToggleCalendar] = useState(false);

  const fetchDataFromAPI = async () => {
    if (d && type) {
      setLayoutOptions({
        title: `${t('menuConstruction')}`,
        showDatePicker: true,
        isDatePickerClickable: false,
      });

      if (!kitchen || !d) {
        return;
      }
      const { data } = await useFetchingLoader(async () => {
        return await http.get(`mealsDishes`, {
          filter: {
            mealTypeId: type,
            date: d,
            kitchenId: kitchen.id,
            mealDishStatusId: [
              MealDishStatusMap.active,
              MealDishStatusMap.blocked,
            ],
          },
        });
      });
      setData(
        data.sort(function (a, b) {
          return a.order - b.order;
        })
      );

      const getMealId = data && data[0] ? data[0].meal.id : '';
      setMealId(getMealId);

      setMealDishSummary(data);

      setMealSummary({
        ordered: data.reduce((total, dish) => total + dish.ordered, 0),
        realized: data.reduce((total, dish) => total + dish.realized, 0),
        inventory: data.reduce((total, dish) => total + dish.inventory, 0),
      });
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [d, type, kitchen, dummy]);

  /********* get extras data  */
  useEffect(async () => {
    if (mealId) {
      const data = await useFetchingLoader(async () => {
        return await http.get(`mealExtras/getMealExtrasMeal/${mealId}`);
      });
      setExtrasData(data);
    } else {
      setExtrasData([]);
    }
  }, [mealId]);

  /***********Get prevoius meal */
  const MealStatusMap = MealStatuses.reduce((acc, meal) => {
    return { ...acc, [meal.value]: meal.id };
  }, {});

  useEffect(async () => {
    setDummy(false);
    if (!kitchen) return;
    setLayoutOptions({ title: `${t('mealManagement')} ${kitchen.name}` });
    if (d && type) {
      const { data } = await useFetchingLoader(
        async () =>
          await http.get(`meals`, {
            filter: {
              kitchenId: kitchen.id,
              mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
            },
            meta: true,
          })
      );

      setPreviousMeal(
        sortMeals(
          data.filter(
            (ele) =>
              // new Date(ele.date).toISOString().slice(0, 10) !==
              // new Date().toISOString().slice(0, 10)
              ele.mealType.id === type
          )
        )
      );
    }
    const response = await useFetchingLoader(async () => {
      return await http.get(`companies`);
    });
    setCompany(response.data);
    // setMealsToDisplay(sortMeals(data));
  }, []);

  /********copy meal poup */
  const handleCopyMeal = () => {
    // setDummy(false);
    // setCopyMeal(true);
    // if (!kitchen) return;
    // setLayoutOptions({ title: `${t('mealManagement')} ${kitchen.name}` });
    // const { data } = await useFetchingLoader(
    //   async () =>
    //     await http.get(`meals`, {
    //       filter: {
    //         kitchenId: kitchen.id,
    //         mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
    //       },
    //       meta: true,
    //     })
    // );

    // setPreviousMeal(
    //   sortMeals(
    //     data.filter(
    //       (ele) =>
    //         // new Date(ele.date).toISOString().slice(0, 10) !==
    //         // new Date().toISOString().slice(0, 10)
    //         ele.mealType.id === type
    //     )
    //   )
    // );
    // setMealsToDisplay(sortMeals(data));
    history.push({
      pathname: `/copyPreviousMeal/${date}/${type}`,
    });
  };

  // useEffect(async () => {

  // }, [kitchen]);

  /***clsoe copy popup */
  const handleCloseCopy = () => {
    setCopyMeal(!copyMeal);
  };

  useEffect(() => {
    let filterMeals = meals?.filter(
      (meal) => showPrevious || moment(meal.date) > today
    );
    setMealsToDisplay(sortMeals(filterMeals));
  }, [showPrevious]);

  const handleInventoryChange = async (mealId, inventory) => {
    try {
      setOpenModel(true);
      await http.put(`mealsDishes/${mealId}`, {
        inventory: parseInt(inventory),
      });
      const mealIndex = data.findIndex((m) => m.id === mealId);
      data[mealIndex].inventory = inventory;
      // Update the meal inventory with the response from the db
      setData([...data]);
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handleDelete = async (mealDishId) => {
    try {
      await useFetchingLoader(async () => {
        let mealDish = await http.get(`mealsDishes/${mealDishId}`);
        if (mealDish.ordered > 0) {
          // Don't allow to delete mealDish with orders
          throw { content: t('thereIsAlreadyAnOrder') };
        } else {
          await http.put(`mealsDishes/${mealDishId}`, {
            mealDishStatusId: MealDishStatusMap.deleted,
          });
          setData((state) =>
            state.filter((mealDish) => mealDish.id !== mealDishId)
          );
        }
      });
    } catch (err) {
      Alert({ error: err });
    }
  };

  /****** delete extra meal */
  const handleDeleteExtraMeal = async (mealExtraId) => {
    try {
      await useFetchingLoader(async () => {
        // let mealDish = await http.get(`mealsDishes/${mealExtraId}`);
        const check = await http.delete(
          `mealExtras/deleteMealExtrasMeal/${mealId}/${mealExtraId}`
        );
        if (check.status === 500) {
        } else {
          setExtrasData((state) => state.filter((el) => el.id !== mealExtraId));
        }
      });
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handleUpdateChefMealDish = async (mealDishId, isChefMealDish) => {
    await useFetchingLoader(
      async () =>
        await http.put(`mealsDishes/${mealDishId}`, { isChefMealDish })
    );
    setData((state) =>
      state.map((mealDish) =>
        mealDish.id === mealDishId ? { ...mealDish, isChefMealDish } : mealDish
      )
    );
  };

  const handleAddDish = () => {
    if (d && mealTypeId) {
      let dateFormat = dates.formatServer(d);
      history.push({
        pathname: `/addDishToMeal/${dateFormat}/${type}`,
        state: { backText: t('returnMenuConstruction') },
      });
    } else if (d) {
      setNoMealtype(true);
    } else if (mealTypeId) {
      setNoDate(true);
    } else {
      setNoMealtype(true);
      setNoDate(true);
    }
  };

  const onTypeChange = (newTypeId) => {
    if (d) {
      let dateFormat = dates.formatServer(d);
      history.replace({
        pathname: `/menuConstruction/${dateFormat}/${
          newTypeId ? Number.parseInt(newTypeId) : ''
        }`,
      });
      setData([]);
      setMealTypeId(newTypeId);
      setNoMealtype(false);
      // const mealT = MealTypes.filter((el) => el.id === newTypeId);
      // if (mealT.length > 0) {
      //   setMealTypeName(mealT[0].display);
      // }
    }
  };

  const handledate = () => {
    setToggleCalendar(true);
  };
  const onDateChange = (newDate) => {
    setData([]);
    let dateFormat = dates.formatServer(newDate);
    history.replace({ pathname: `/menuConstruction/${dateFormat}/${type}` });
    setDate(newDate);
    setNoDate(false);
    setToggleCalendar(false);
  };

  /* Close calendar on outside clicks */
  const clickOutSideOfCalendar = (ref, setToggleCalendar) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setToggleCalendar(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, [ref]);
  };
  const closeCalendarRef = useRef(null);
  clickOutSideOfCalendar(closeCalendarRef, setToggleCalendar);

  const handlePrint = () => {
    history.push({
      pathname: '/printReport',
      state: {
        data,
        company,
      },
    });
  };

  const handleDeleteClickall = async () => {
    if (data.ordered > 0) {
      Alert({ error: { content: t('thereIsAlreadyAnOrder') } });
    } else {
      Dialog({
        title: t('removeDishTitle'),
        text: `${t('clearText')}`,
        buttons: {
          confirmButtonText: t('removeDishBtnLabel'),
          cancelButtonText: t('back'),
        },
        onConfirm: async () => {
          try {
            await handleDeleteAll();
          } catch (err) {
            Alert({ error: err });
          }
        },
      });
    }
  };
  const handleDeleteAll = async () => {
    let mealId = data && data[0].meal.id;
    setMealIdAfterDeleting(mealId);
    try {
      await useFetchingLoader(async () => {
        const dataTest = await http.put(`mealsDishes/deleteAll`, {
          mealId: mealId,
          //mealDishStatusId: MealDishStatusMap.deleted
        });
        console.log('dataTest', dataTest);
        /*setData([]);
        setMealSummary({
          ordered: 0,
          realized: 0,
          inventory: 0,
        });*/
      });
      fetchDataFromAPI();
    } catch (err) {
      Alert({ error: err });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
  };

  const [actionDropdown, setActionDropdown] = useState(false);

  const handleDropdownClick = (id) => {
    setActionDropdown(id);
    setOpenModel(true);
  };

  /*** all Inventory update  */
  const UpdateAllInventory = () => {
    DialogWithInput({
      title: `${t('updateInventory')}`,
      type: 'number',
      onConfirm: async (data) =>
        await handleAllInventoryChange(Number(data.value)),
      buttons: {
        confirmButtonText: t('saveInfo'),
        cancelButtonText: t('back'),
      },
    });
  };

  /*** all Inventory update  */

  const handleAllInventoryChange = async (inventory) => {
    const filter = data.filter((el) => el.ordered <= inventory);

    if (data.length != filter.length) {
      Alert({ error: { content: t('inventoryLessThanOrdered') } });
    } else {
      let mealId = data && data[0].meal.id;
      setDummy(false);
      try {
        await http.put(`mealsDishes/updateAll`, {
          inventory: parseInt(inventory),
          mealId: mealId,
        });
        const mealIndex = data.findIndex((m) => m.id);
        data[mealIndex].inventory = inventory;
        // Update the meal inventory with the response from the db
        setData([...data]);
        setDummy(true);
      } catch (err) {
        Alert({ error: err });
      }
    }
  };

  // update reservations in meal

  const handleOpenUpdateReservation = async () => {
    setUpdateReservations(true);
    let getAllSlot;
    await useFetchingLoader(async () => {
      getAllSlot = await http.get(`mealSlots/getByMealId/${mealId}`);
    });
    setMealSlot(getAllSlot);
  };
  const handleCloseUpdateReservation = () => {
    setUpdateReservations(false);
  };

  /**********Add meals in dishes **********/
  const handleMoreInfo = () => {
    history.push({
      pathname: `/menuConstruction/${date}/${type}`,
      state: { backText: t('returnToMealManagement') },
    });
  };
  const handleAddDishAll = async (mealData) => {
    const jj = { ...mealData.mealDish };

    const propertyValues = Object.values(jj);
    const countDishes = propertyValues.length;

    Dialog({
      title: `copy ${countDishes} dishes from ${dates.formatServer(
        mealData.date
      )}  ${mealTypeName} to ${date} ${mealTypeName}? `,
      buttons: {
        showCancelButton: true,
        confirmButtonText: t('ok'),
      },
      onConfirm: async () => {
        let date = dates.formatServer(mealData.date);
        let dd = dates.formatServer(d);
        let requestValues = {
          // dishId: di,
          mealId: mealData.id,
          date,
          kitchenId: mealData.kitchen.id,
          mealTypeId: type,
          cdate: dd,
        };
        try {
          await useFetchingLoader(
            async () =>
              await http.post(`mealsDishes/addPreviousDishes`, requestValues)
          );
          setDummy(true);
          setAlreadyAdded(true);
          setCopyMeal(!copyMeal);
          // Update dishes, set the added dish as included
        } catch (err) {
          Alert({ error: err });
        }
      },
    });
  };

  let today = new Date().toISOString().slice(0, 10);

  const lang = localStorage.getItem('langauge');

  const handleMoveItem = async (item) => {
    console.log('Move');
    setDummy(false);
    let arr = [];
    item.forEach((el) => {
      let it = {
        id: el.id,
        mealId: el.mealId,
      };
      arr.push(it);
    });
    await http.put(`mealsDishes/updateByOrderId`, { arr });
    setItemList({ items: item });
    setDummy(true);
  };

  const dropdownRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setActionDropdown(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  /**** add Extras */
  const handleAddExtrasDish = () => {
    if (d && mealTypeId) {
      let dateFormat = dates.formatServer(d);
      history.push({
        pathname: `/addExtrasDishToMeal/${dateFormat}/${type}/${mealId}`,
        state: { backText: t('returnMenuConstruction') },
      });
    } else if (d) {
      setNoMealtype(true);
    } else if (mealTypeId) {
      setNoDate(true);
    } else {
      setNoMealtype(true);
      setNoDate(true);
    }
  };
  const handleAddEdit = async (dishId) => {
    //here the Edit Popup will open and dishId will be stored in the state of dish_Id
    setdish_Id(dishId);
    seteditPopup(true);
  };

  const handleCloseEditPopup = async () => {
    //setDummy(false);
    setFileValue();
    setValues(InitialValues);
    setTranslations({});
    setdish_Id(null);
    seteditPopup((prev) => !prev);
  };

  const updateData = () => {
    setFileValue();
    setValues(InitialValues);
    setTranslations({});
    setdish_Id(null);
    seteditPopup((prev) => !prev);
    fetchDataFromAPI();
  };

  const deleteMeal = async () => {
    await useFetchingLoader(async () => {
      return await http.post(
        `meals/${mealId || mealID || mealIdAfterDeleting}/deleteMeal`
      );
    });
    history.push('/mealManagement');
  };
  const MealsCallBacks = {
    delete: deleteMeal,
  };

  const handleDeleteMeal = async () => {
    try {
      // let mealIdTemp = data && data[0].meal.id;
      const response = await useFetchingLoader(async () => {
        return await http.get(`meals/currentMeal`, {
          filter: {
            kitchenId: kitchen.id,
            mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
          },
          limit: 300,
          page: 1,
          order: [['date', 'DESC']],
          meta: true,
        });
      });

      let { data } = response;
      if (data?.filterMeals?.length > 0) {
        let specificMeal = data?.filterMeals?.filter(
          (ele) => ele.id === parseInt(mealId || mealID || mealIdAfterDeleting)
        );
        console.log('specificMeal', specificMeal);
        if (specificMeal.length > 0) {
          let isOrder = specificMeal[0].ordered;
          if (isOrder > 0) {
            Alert({ error: { content: t('cantDeleteMealWithOrders') } });
            return;
          } else {
            Dialog({
              title: t('removeMealTitle'),
              text: `${t('areYouSureYouWantToRemoveMeal')}  ${dates.ddmmFormat(
                specificMeal[0].date
              )} - ${MealTypesMap[specificMeal[0].mealTypeId]} `,
              buttons: {
                confirmButtonText: t('removeMealBtnLabel'),
                cancelButtonText: t('back'),
              },
              onConfirm: async () => await MealsCallBacks.delete(),
            });
          }
        }
      }
    } catch (err) {
      Alert({ error: err });
    }
  };

  return (
    <>
      <div className="menu-construction-page">
        <div className="container">
          <div className={'menu-construction'}>
            <section className={'filter'}>
              {/* <BackLink text={backText} redirectHistory={backLink} /> */}
              <div className="filter-left-section">
                <div className="no-data">
                  <div className={'item date'}>
                    <div className={'date-picker'}>
                      <div ref={closeCalendarRef}>
                        <DatePicker
                          selected={d}
                          onChange={(newDate) => onDateChange(newDate)}
                          customInput={
                            <p>{d ? dates.format(d) : `${t('selectDate')}`}</p>
                          }
                          open={toggleCalendar}
                          popperPlacement="bottom-end"
                        />
                        <div
                          onClick={handledate}
                          className="open-calendar"
                          role="presentation"
                        ></div>
                      </div>
                    </div>

                    {/* <WEIcon icon={'calendar'} /> */}
                    <i class="las la-calendar-check"></i>
                  </div>
                  {noDate ? <p className="error-msg">{t('selectDate')}</p> : ''}
                </div>

                {/* Filter by meal type*/}
                <div className="no-data no-meal-data">
                  <div
                    className={`select-meal-type ${
                      !d ? 'disable-mealtype' : ''
                    }`}
                  >
                    <select
                      value={mealTypeId}
                      onChange={(event) => onTypeChange(event.target.value)}
                      disabled={!d ? true : false}
                      test-id="mealtype"
                    >
                      <option value="">{t('selectMealtype')}</option>
                      {MealTypes.filter((el) => el.kitchenId === kitchen.id)
                        .sort((mt1, mt2) =>
                          mt1.orderIndex < mt2.orderIndex ? -1 : 1
                        )
                        .map((t) => (
                          <option value={t.id} key={t.id}>
                            {t.display}
                            {''}
                            {''} {''} ({`${t.from}-${t.to}`})
                          </option>
                        ))}
                    </select>
                  </div>
                  {noMealType ? (
                    <p className="error-msg">{t('selectMealtype')}</p>
                  ) : (
                    ''
                  )}
                </div>
                {/* <div className="preview-print">
                <ActiveButton
                  label={t('printReport')}
                  arrow={false}
                  cb={handlePrint}
                />
              </div> */}
                {/* <PrintReports
                data={data}


                company={company}
              /> */}
              </div>
              <div className="meal-action-dropdown">
                {d && mealTypeId && (
                  <Dropdown drop="down">
                    <Dropdown.Toggle id="dropdown-basic">
                      {t('mealAction')} <img src={downarrow} alt="arrow" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as="button" onClick={handlePrint}>
                        {t('printReport')}
                      </Dropdown.Item>
                      {mealId &&
                        user.roleId !== 9 &&
                        !(pastFlag && user.roleId === 3) && (
                          <Dropdown.Item
                            as="button"
                            onClick={handleOpenUpdateReservation}
                          >
                            {t('UpdateMaxReservations')}
                          </Dropdown.Item>
                        )}
                      {data?.length > 0 &&
                        user.roleId !== 9 &&
                        !(pastFlag && user.roleId === 3) && (
                          <Dropdown.Item
                            as="button"
                            onClick={handleDeleteClickall}
                          >
                            {t('clearDish')}
                          </Dropdown.Item>
                        )}
                      {data?.length > 0 &&
                        user.roleId !== 9 &&
                        !(pastFlag && user.roleId === 3) && (
                          <Dropdown.Item
                            as="button"
                            onClick={UpdateAllInventory}
                          >
                            {t('updateInventory')}
                          </Dropdown.Item>
                        )}
                      {d &&
                        user.roleId !== 9 &&
                        !(pastFlag && user.roleId === 3) && (
                          <div>
                            {new Date().toISOString().slice(0, 10) <=
                            new Date(d).toISOString().slice(0, 10) ? (
                              <Dropdown.Item
                                as="button"
                                onClick={handleCopyMeal}
                              >
                                {t('CopyFromPreviousDish')}
                              </Dropdown.Item>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      {user.roleId !== 9 && !(pastFlag && user.roleId === 3) && (
                        <Dropdown.Item as="button" onClick={handleDeleteMeal}>
                          {t('deleteMeal')}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Tooltip title="">
                  <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                    {t('mealAction')} <img src={downarrow} alt="arrow" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 10,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                className="dropdwon-list"
              >
                <MenuItem onClick={handlePrint}>{t('printReport')}</MenuItem>
                {mealId && (
                  <MenuItem onClick={handleOpenUpdateReservation}>
                    {t('UpdateMaxReservations')}
                  </MenuItem>
                )}
                {data?.length > 0 && (
                  <MenuItem onClick={handleDeleteClickall}>
                    {t('clearDish')}
                  </MenuItem>
                )}
                {data?.length > 0 && (
                  <MenuItem onClick={UpdateAllInventory}>
                    {t('updateInventory')}
                  </MenuItem>
                )}
                {d && (
                  <div>
                    {new Date().toISOString().slice(0, 10) <=
                    new Date(d).toISOString().slice(0, 10) ? (
                      <MenuItem onClick={handleCopyMeal}>
                        {t('copyMeal')}
                      </MenuItem>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </Menu> */}
            </section>
            {/* Labels and Summary and AddDish */}
            <section className={'menuconstruction-header'}>
              <p className={'titleLabel'}>{t('dishesInMeal')}</p>
              <div className={'summaryLabel'}>
                <MealSummary mealData={mealSummary} />
              </div>
              {date >= today && user.roleId !== 9 ? (
                <Button onClick={handleAddDish}>
                  {' '}
                  <i class="las la-plus"></i> {t('addDish')}
                </Button>
              ) : (
                ''
              )}
            </section>
            {data?.length > 0 ? (
              <div className={'dishes'}>
                {/* {data
              .sort((d1, d2) => d1.dish.name.localeCompare(d2.dish.name))
              .map((data) => ( */}
                {/* <MealDish
                  key={data.id}
                  data={data}
                  showAlert={showAlert}
                  handleChange={handleInventoryChange}
                  handleDelete={handleDelete}
                  useFetchingLoader={useFetchingLoader}
                  handleUpdateChefMealDish={handleUpdateChefMealDish}
                  printPopup={printPopup}
                  handlePrint={handlePrint}
                  alreadyAdded={alreadyAdded}
                  handleCloseCopy={handleCloseCopy}
                /> */}
                {/* ))} */}
                <div className="header-listing">
                  <h6>{t('name')}</h6>
                  <h6>{t('inventory')}</h6>
                  <h6>{t('orders')}</h6>
                  <h6>{t('realized')}</h6>
                  <h6>{t('chefDish')}</h6>
                  <h6>{t('category')}</h6>
                  <h6>{t('price')}</h6>
                  <h6></h6>
                </div>
                <RLDD
                  items={data}
                  itemRenderer={(item) => {
                    return (
                      <MealDish
                        key={item.id}
                        data={item}
                        showAlert={showAlert}
                        handleAddEdit={handleAddEdit}
                        handleChange={handleInventoryChange}
                        handleDelete={handleDelete}
                        useFetchingLoader={useFetchingLoader}
                        handleUpdateChefMealDish={handleUpdateChefMealDish}
                        printPopup={printPopup}
                        handlePrint={handlePrint}
                        alreadyAdded={alreadyAdded}
                        handleCloseCopy={handleCloseCopy}
                        setActionDropdown={setActionDropdown}
                        actionDropdown={actionDropdown}
                        handleDropdownClick={handleDropdownClick}
                        wrapperRef={wrapperRef}
                        openModel={openModel}
                        handleCloseModel={handleCloseModel}
                        setOpenModel={setOpenModel}
                        user={user}
                        pastFlag={pastFlag}
                      />
                    );
                  }}
                  onChange={(e) => handleMoveItem(e)}
                />
              </div>
            ) : (
              <div className="no-dishes">
                <p>{t('noDishes')}</p>
              </div>
            )}

            {/* extras data */}
            <div className="extras-section">
              <section className={'menuconstruction-header'}>
                <p className={'titleLabel'}>{t('extrameal')}</p>
                {data?.length > 0 ? (
                  <div className="add-extras">
                    {date >= today && user.roleId !== 9 ? (
                      <Button onClick={handleAddExtrasDish}>
                        <i class="las la-plus"></i> {t('addExtras')}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </section>
              {extrsData && extrsData?.length > 0 ? (
                <div className={'dishes'}>
                  <div className="header-listing">
                    <h6>{t('name')}</h6>
                    <h6>{t('orders')}</h6>
                    <h6>{t('realized')}</h6>
                    <h6>{t('price')}</h6>
                    <h6></h6>
                  </div>
                  <RLDD
                    items={extrsData}
                    itemRenderer={(item) => {
                      return (
                        <MealExtra
                          key={item.id}
                          data={item}
                          showAlert={showAlert}
                          handleChange={handleInventoryChange}
                          handleDelete={handleDelete}
                          useFetchingLoader={useFetchingLoader}
                          handleUpdateChefMealDish={handleUpdateChefMealDish}
                          printPopup={printPopup}
                          handlePrint={handlePrint}
                          alreadyAdded={alreadyAdded}
                          handleCloseCopy={handleCloseCopy}
                          setActionDropdown={setActionDropdown}
                          actionDropdown={actionDropdown}
                          handleDropdownClick={handleDropdownClick}
                          wrapperRef={wrapperRef}
                          handleDeleteExtraMeal={handleDeleteExtraMeal}
                          mealData={mealSummary}
                          user={user}
                          pastFlag={pastFlag}
                        />
                      );
                    }}
                    onChange={() => handleMoveItem()}
                  />
                </div>
              ) : (
                <div className="no-dishes">
                  <p>{t('noExtrasDisplay')}</p>
                </div>
              )}
            </div>
          </div>
          {/* )} */}
          <UpdateReservations
            open={updateReservation}
            handleClose={handleCloseUpdateReservation}
            mealId={mealId}
            mealSlot={mealSlot}
            t={t}
            useFetchingLoader={useFetchingLoader}
          />
          <Modal
            open={copyMeal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="lg"
            className="add-employee-popup copymeal-popup"
          >
            <Box sx={style}>
              <div className="close-btn">
                <img src={cancel} alt="close" onClick={handleCloseCopy} />
              </div>

              <Typography id="modal-modal-title" variant="h5" component="h2">
                <div className={'meal-management'}>
                  <h4> {`Copy dishes to ${date} ${mealTypeName} from:`}</h4>
                  {previousMeal && previousMeal.length > 0 ? (
                    <div className={'meals'}>
                      {previousMeal.map((data) => (
                        <Meal
                          key={data.id}
                          mealData={data}
                          MealTypes={MealTypes}
                          handleAddDishAll={handleAddDishAll}
                          currentLanguage={currentLanguage}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="no-dishes">
                      <p>{t('noDishes')}</p>
                    </div>
                  )}
                </div>
              </Typography>
            </Box>
          </Modal>
          <AddEditDishPopup
            show={editPopup}
            handleClose={handleCloseEditPopup}
            dish={dish_Id}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            translations={translations}
            setTranslations={setTranslations}
            fileValue={fileValue}
            setFileValue={setFileValue}
            languages={languages}
            errors={errors}
            setErrors={setErrors}
            DishStatuses={DishStatuses}
            DishCategories={DishCategories}
            tags={tags}
            setTags={setTags}
            values={values}
            setValues={setValues}
            InitialValues={InitialValues}
            setDummy={setDummy}
            kitchen={kitchen}
            setAddEditPopup={seteditPopup}
            updateData={updateData}
            pastFlag={pastFlag}
          />
        </div>
      </div>
    </>
  );
};

const MealDish = ({
  data,
  handleChange,
  handleDelete,
  handleUpdateChefMealDish,
  useFetchingLoader,
  printPopup,
  handlePrint,
  handleDropdownClick,
  setActionDropdown,
  handleAddEdit,
  actionDropdown,
  openDropdown,
  wrapperRef,
  openModel,
  handleCloseModel,
  setOpenModel,
  user,
  pastFlag,
}) => {
  const { t } = useTranslation();
  const dishCategory = enums.display('DishCategory', data.dish.dishCategoryId);
  const history = useHistory();
  const [inventoryValue, setInventoryValue] = useState(data.inventory);
  const isNonNegNumber = (value) => Number.parseInt(value) > -1;

  useEffect(() => {
    setInventoryValue(data.inventory);
  }, [data.inventory]);

  const handleInventoryChange = async (inventory) => {
    try {
      setOpenModel(true);
      if (isNonNegNumber(inventory)) {
        // Entered value is undefined or negative
        if (inventory >= data.ordered) {
          await useFetchingLoader(() => handleChange(data.id, inventory));
        } else {
          Alert({ error: { content: t('inventoryLessThanOrdered') } });
        }
      } else {
        Alert({ error: { content: t('inventoryLessThanZero') } });
      }
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handlePlusClick = async () => handleInventoryChange(inventoryValue + 1);
  const handleMinusClick = async () =>
    handleInventoryChange(inventoryValue - 1);

  const handleDeleteClick = async () => {
    if (data.ordered > 0) {
      Alert({ error: { content: t('thereIsAlreadyAnOrder') } });
    } else {
      Dialog({
        title: t('removeDishTitle'),
        text: `${t('areYouSureYouWantToRemoveDish')} ${data?.dish?.name}`,
        buttons: {
          confirmButtonText: t('removeDishBtnLabel'),
          cancelButtonText: t('back'),
        },
        onConfirm: async () => {
          try {
            await handleDelete(data.id);
          } catch (err) {
            Alert({ error: err });
          }
        },
      });
    }
  };

  const openInventoryModal = (dishName = '') => {
    DialogWithInput({
      title: `${t('enterInventoryFor')} ${dishName}`,
      type: 'number',
      onConfirm: async (data) =>
        await handleInventoryChange(Number(data.value)),
      buttons: {
        confirmButtonText: t('saveInfo'),
        cancelButtonText: t('back'),
      },
    });
  };

  return (
    <>
      <div className={`meal-dish mealdish-mob`}>
        {/* <div className={'image'}>
          <DishImage fileName={data?.dish?.image} />
        </div> */}
        <section className="dish-name">
          <p className={'name'}>{data?.dish?.name ? data?.dish?.name : '--'}</p>
          {/* <MealSummary mealData={data} /> */}
        </section>
        <section className="inventroy">
          <div className={'inventory'}>
            <i
              class="las la-plus"
              onClick={
                user.roleId === 9 || (pastFlag && user.roleId === 3)
                  ? () => {}
                  : handlePlusClick
              }
            ></i>
            <input
              className={'inv-input'}
              type={'number'}
              min={0}
              disabled={user.roleId === 9 || (pastFlag && user.roleId === 3)}
              value={inventoryValue}
              onClick={() => openInventoryModal(data?.dish?.name)}
            />
            {/* {inventoryValue} */}
            <i
              onClick={
                user.roleId === 9 || (pastFlag && user.roleId === 3)
                  ? () => {}
                  : handleMinusClick
              }
              className={`las la-minus ${
                inventoryValue < 1 ? 'inventroy-disable' : ''
              }`}
            ></i>
          </div>
        </section>
        <section className="orders">
          <PercentData
            label={t('orders')}
            value={data?.ordered}
            outOfValue={data?.inventory}
          />
        </section>
        <section className="realized">
          <PercentData
            label={t('realized')}
            value={data?.realized}
            outOfValue={data?.inventory}
          />
        </section>
        <section>
          <WEToggle
            onChange={() =>
              handleUpdateChefMealDish(data.id, !data.isChefMealDish)
            }
            label={''}
            checked={data.isChefMealDish}
            onColor={'#14cd31'}
            size={'small'}
            disabled={user.roleId === 9 || (pastFlag && user.roleId === 3)}
          />
        </section>
        <section>{dishCategory && dishCategory}</section>
        <section>{data.dish.price}</section>
        <section>
          {user.roleId !== 9 && (
            <div className={'actions'}>
              <i
                class={
                  pastFlag && user.roleId === 3 ? 'las la-file' : 'las la-pen'
                }
                onClick={() => handleAddEdit(data?.dish.id)}
              ></i>
              {!(pastFlag && user.roleId === 3) && (
                <i class="las la-trash" onClick={handleDeleteClick}></i>
              )}
            </div>
          )}
          <div className="mob-icon">
            <div className="custom-dropdown">
              <div className="dropdown-menu-icon">
                <i
                  class="las la-angle-right"
                  onClick={() => handleDropdownClick(data?.id)}
                ></i>
              </div>
              {actionDropdown === data?.id && (
                <ViewMenuContructionDetail
                  open={openModel}
                  handleCloseModel={handleCloseModel}
                  setActionDropdown={setActionDropdown}
                  actionDropdown={actionDropdown}
                  data={data}
                  handleDelete={handleDelete}
                  handleUpdateChefMealDish={handleUpdateChefMealDish}
                  useFetchingLoader={useFetchingLoader}
                  printPopup={printPopup}
                  handlePrint={handlePrint}
                  handleDropdownClick={handleDropdownClick}
                  wrapperRef={wrapperRef}
                  inventoryValue={inventoryValue}
                  handleDeleteClick={handleDeleteClick}
                  handleAddEdit={handleAddEdit}
                  pastFlag={pastFlag}
                  handlePlusClick={handlePlusClick}
                  handleMinusClick={handleMinusClick}
                  handleInventoryChange={handleInventoryChange}
                  setOpenModel={setOpenModel}
                  user={user}
                />
                // <div className="dropdwon-list" ref={wrapperRef}>
                //   <div className="dropdown-item">
                //     <div className={'inventory'}>
                //       <p
                //         className={'label'}
                //         onClick={() => openInventoryModal(data?.dish?.name)}
                //       >
                //         {t('inventory')}: {inventoryValue}{' '}
                //       </p>
                //     </div>
                //   </div>
                //   <div className="dropdown-item">
                //     <div className={'inventory'}>
                //       <p className={'label'}>
                //         {t('orders')}:{' '}
                //         <PercentData
                //           label={t('orders')}
                //           value={data?.ordered}
                //           outOfValue={data?.inventory}
                //         />
                //       </p>
                //     </div>
                //   </div>
                //   <div className="dropdown-item">
                //     <div className={'inventory'}>
                //       <p className={'label'}>
                //         {t('realized')}:{' '}
                //         <PercentData
                //           label={t('realized')}
                //           value={data?.realized}
                //           outOfValue={data?.inventory}
                //         />
                //       </p>
                //     </div>
                //   </div>
                //   <div
                //     className="dropdown-item"
                //     onClick={() => handleAddEdit(data?.dish.id)}
                //   >
                //     {t('edit')}
                //   </div>
                //   <div className="dropdown-item" onClick={handleDeleteClick}>
                //     {t('delete')}
                //   </div>
                //   <div className="dropdown-item">
                //     <p>{t('chefDish')}</p>
                //     <WEToggle
                //       onChange={() =>
                //         handleUpdateChefMealDish(data.id, !data.isChefMealDish)
                //       }
                //       label={''}
                //       checked={data.isChefMealDish}
                //       onColor={'#14cd31'}
                //       size={'small'}
                //     />
                //   </div>
                // </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const MealExtra = ({
  data,
  handleChange,
  handleDeleteExtraMeal,
  handleUpdateChefMealDish,
  useFetchingLoader,
  printPopup,
  handlePrint,
  handleDropdownClick,
  setActionDropdown,
  actionDropdown,
  openDropdown,
  wrapperRef,
  extrsData,
  mealData,
  user,
  pastFlag,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [inventoryValue, setInventoryValue] = useState(data.inventory);
  const isNonNegNumber = (value) => Number.parseInt(value) > -1;

  useEffect(() => {
    setInventoryValue(data.inventory);
  }, [data.inventory]);

  const handleInventoryChange = async (inventory) => {
    try {
      if (isNonNegNumber(inventory)) {
        // Entered value is undefined or negative
        if (inventory >= data.ordered) {
          await useFetchingLoader(() => handleChange(data.id, inventory));
        } else {
          Alert({ error: { content: t('inventoryLessThanOrdered') } });
        }
      } else {
        Alert({ error: { content: t('inventoryLessThanZero') } });
      }
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handlePlusClick = async () => handleInventoryChange(inventoryValue + 1);
  const handleMinusClick = async () =>
    handleInventoryChange(inventoryValue - 1);

  const handleAddEdit = async (dishId) => {
    history.push({
      pathname: `/addEditDish${dishId ? `/${dishId}` : ''}`,
      state: { backText: t('returnToMenuConstruction') },
    });
  };

  const handleDeleteExtras = async () => {
    if (data.ordered > 0) {
      Alert({ error: { content: t('thereIsAlreadyAnOrder') } });
    } else {
      Dialog({
        title: t('removeDishTitle'),
        text: `${t('areYouSureYouWantToRemoveDish')} ${data?.text}`,
        buttons: {
          confirmButtonText: t('removeDishBtnLabel'),
          cancelButtonText: t('back'),
        },
        onConfirm: async () => {
          try {
            await handleDeleteExtraMeal(data.id);
          } catch (err) {
            Alert({ error: err });
          }
        },
      });
    }
  };

  const openInventoryModal = (dishName = '') => {
    DialogWithInput({
      title: `${t('enterInventoryFor')} ${dishName}`,
      type: 'number',
      onConfirm: async (data) =>
        await handleInventoryChange(Number(data.value)),
      buttons: {
        confirmButtonText: t('saveInfo'),
        cancelButtonText: t('back'),
      },
    });
  };

  return (
    <>
      <div className={`meal-dish mealdish-mob extras-dish-section`}>
        {/* <div className={'image'}>
          <DishImage fileName={data?.imageUrl} />
        </div> */}

        {/* Name and Rating */}
        <section className={''}>
          <p className={'name'}>{data?.text}</p>
          {/* <MealExtraSummary mealData={data} orderData={mealData} /> */}
        </section>
        <section className="ordered">
          <PercentData
            label={t('orders')}
            value={data?.ordered}
            outOfValue={data?.inventory}
          />
        </section>
        <section className="realized">
          <PercentData
            label={t('realized')}
            value={data?.realized}
            outOfValue={data?.inventory}
          />
        </section>
        <section>{data?.price}</section>

        {/* Inventory and Deletion */}
        <section className={'section2'}>
          {user.roleId !== 9 && (
            <div className={'actions'}>
              <i
                class="las la-trash"
                onClick={handleDeleteExtras}
                disabled={pastFlag && user.roleId === 3}
              ></i>
            </div>
          )}
          {/* <div className="mob-icon">
            <div className="custom-dropdown">
              <div className="dropdown-menu-icon">
                <i
                  className="las la-angle-right"
                  aria-hidden="true"
                  onClick={() => handleDropdownClick(data?.id)}
                ></i>
              </div>
              {actionDropdown === data?.id && (
                <div className="dropdwon-list" ref={wrapperRef}>
                  <div className="dropdown-item">
                    <div className={'inventory'}>
                      <p className={'label'}>
                        {t('orders')}:{' '}
                        <PercentData
                          label={t('orders')}
                          value={data?.ordered}
                          outOfValue={data?.inventory}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="dropdown-item">
                    <div className={'inventory'}>
                      <p className={'label'}>
                        {t('realized')}:{' '}
                        <PercentData
                          label={t('realized')}
                          value={data?.realized}
                          outOfValue={data?.inventory}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="dropdown-item" onClick={handleDeleteExtras}>
                    {t('delete')}
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </section>
      </div>
    </>
  );
};

const Meal = ({ mealData, MealTypes, handleAddDishAll, alreadyAdded }) => {
  const { t } = useTranslation();
  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.display };
  }, {});

  const handleDeleteClick = async () => {
    if (mealData.ordered > 0) {
      Alert({ error: { content: t('cantDeleteMealWithOrders') } });
      return;
    }
    Dialog({
      title: t('removeMealTitle'),
      text: `${t('areYouSureYouWantToRemoveMeal')} ${dates.ddmmFormat(
        mealData?.date
      )} - ${MealTypesMap[mealData?.mealTypeId]}`,
      buttons: {
        confirmButtonText: t('removeMealBtnLabel'),
        cancelButtonText: t('back'),
      },
      onConfirm: async () => await MealsCallBacks.delete(mealData),
    });
  };

  return (
    <div
      className={`meal item-card ${alreadyAdded ? 'meal-added' : ''}`}
      onClick={() => handleAddDishAll(mealData)}
      role="presentation"
    >
      <section className={'section1 dash-left'}>
        <div className={'date-type'}>
          <p className={'date'}>{dates.ddmmFormat(mealData?.date)}</p>
          <p className={'date'}>{dates.ddddformat(mealData?.date)}</p>
          <p className={'type'}>{MealTypesMap[mealData?.mealTypeId]}</p>
        </div>
        <MealDishSummary
          mealData={mealData}
          currentLanguage={currentLanguage}
        />
      </section>
      {/* <section className={'section2'}>
        <div className={'rating'}>{mealData && mealData?.mealDish}</div>
      </section> */}
      <section className={'section2'}>
        <div className={'rating'}>
          <StaticStars
            numOfRaters={mealData?.numOfRaters || 0}
            rating={mealData?.rating || -1}
          />
        </div>
      </section>
    </div>
  );
};

const percentToDisplay = (value, outOfValue) =>
  outOfValue > 0 ? Number.parseInt((value / outOfValue) * 100) : 0;
const PercentData = ({ value, outOfValue, label }) => {
  value = value ?? 0;
  outOfValue = outOfValue ?? 0;
  return (
    <>
      <div className={'percent-data'}>
        <p>
          {value} {`(${percentToDisplay(value, outOfValue)}%)`}
        </p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  MealDishStatuses: state.app.enums.MealDishStatus,
  DishStatuses: state.app.enums.DishStatus,
  MealStatuses: state.app.enums.MealStatus,
  currentLanguage: state.languages.current,
  languages: state.languages.options,
  DishCategories: state.app.enums.DishCategory,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuConstruction);
