export enum EPaymentOption {
  CREDIT_CARD = 0b0010,
  SALARY = 0b0001,
}

export const computePaymentOptions = ({
  creditCard,
  salary,
}: {
  creditCard: boolean;
  salary: boolean;
}) => {
  let value = 0;
  if (creditCard) {
    value |= EPaymentOption.CREDIT_CARD;
  }
  if (salary) {
    value |= EPaymentOption.SALARY;
  }
  return value;
};

export const decodePaymentOptions = (paymentOptions: number) => {
  const res = {
    creditCard: false,
    salary: false,
  };

  if (paymentOptions & EPaymentOption.CREDIT_CARD) res.creditCard = true;
  if (paymentOptions & EPaymentOption.SALARY) res.salary = true;

  return res;
};
