import React from 'react';
import { Button } from 'react-bootstrap';
import '../../style/myOrders.scss';

const TableBtn = ({ img, btnName, func }) => {
  return (
    <Button className="table-btn" onClick={func}>
      <img src={img} alt="icon" />
      {btnName}
    </Button>
  );
};

export default TableBtn;
