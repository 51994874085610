import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../style/myOrders.scss';
import TableBtn from './TableBtn';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import http from '../../services/http';
import { sortOrders } from '../../services/sortItems';
import edit from '../../assets/icons/pencil.png';
import cancel from '../../assets/icons/cancel.png';
import voucher from '../../assets/icons/voucher.png';
import arrow from '../../assets/icons/angle-right-black.svg';
import { StaticStars } from '../../components/RatingStars';
import reviewImg from '../../assets/icons/review.png';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import { useFetchingLoader } from '../../store/app/actions';
import cibusImg from '../../assets/icons/cibus.png';
import { ActiveButton } from '../../components/WE-Button';

const MyOrdersDetailsMobilePopup = ({
  myOrdersMob,
  handleMyOrdersMob,
  handleVoucherModal,
  mobOderData,
  t,
  OrderStatusesMap,
  MealTypes,
  setOrders,
  paymentMethod,
  handlePopup,
  paymentKitchenId,
  user,
  revertCollectPoints,
}) => {
  let history = useHistory();
  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type };
  }, {});

  let meal = {};
  let dishIds = [];
  let dish = {};

  if (mobOderData) {
    const { mealDish } = mobOderData;
    if (mealDish?.length > 0) {
      meal =
        (mobOderData &&
          mobOderData?.mealDish &&
          mobOderData?.mealDish?.length > 0 &&
          mobOderData?.mealDish[0]?.mealDish?.meal) ??
        {};
    } else {
      meal = mealDish?.meal ?? {};
    }

    dish = mealDish?.dish ?? {};
    const dishArray =
      mobOderData &&
      mobOderData.mealDish &&
      mobOderData.mealDish.length > 0 &&
      mobOderData.mealDish;

    if (dishArray?.length > 0) {
      const a = dishArray?.map((el) => {
        dishIds.push(el?.mealDish?.dish?.id);
      });
    }
  }

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }
  const isOrder = (order, value) => mobOderData?.orderStatusId === value;
  const isFutureMeal = (order) => {
    let { date, mealType } = (order?.mealDish?.meal ||
      (order &&
        order.mealDish &&
        order.mealDish.length > 0 &&
        order.mealDish[0].mealDish.meal)) ?? [{}, {}];
    let lastTimeInMeal = MealTypesMap[mealType?.id]?.to;
    let lastTimeToRealizeOrder = moment([date, lastTimeInMeal].join(' '));
    let now = moment();

    return lastTimeToRealizeOrder > now;
  };

  const handleOrderCancel = (orderId) => {
    setOrders((state) =>
      sortOrders(state.filter((order) => order.id !== orderId))
    );
  };

  const handleCancelOrder = async () => {
    try {
      await http.put(`orders/${mobOderData.id}/cancel`);
      handleOrderCancel(mobOderData.id);
      SwalDialogs.Dialog({
        title: t('orderWasCanceled'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: handleMyOrdersMob,
      });
    } catch (err) {
      SwalDialogs.Dialog({
        title: 'An error has occurred',
        text: err,
        type: 'error',
        buttons: {
          showCancelButton: false,
          showConfirmButton: false,
        },
      });
    }
  };

  const cancelOrderDialog = () =>
    SwalDialogs.Dialog({
      title: t('cancelOrderTitle'),
      text: ` ${t('cancelOrderText')} ${mobOderData?.mealDish.map(
        (el) => el.mealDish?.dish?.name
      )} ${' \n'} ${t('cancelOrderTextin')} ${
        mobOderData?.mealDish[0]?.mealDish?.meal?.date
      } ${t('cancelOrderTextin')} ${
        mobOderData?.mealDish[0]?.mealDish?.dish?.kitchen?.name
      } `,
      buttons: {
        confirmButtonText: t('cancelOrderBtnLabel'),
        cancelButtonText: t('back'),
      },
      onConfirm: handleCancelOrder,
    });

  const cibusPayment = () => {
    SwalDialogs.Dialog({
      title: t('cibusPayment'),
      text: t('cibusPaymentAlert'),
      buttons: {
        confirmButtonText: t('confirm'),
        cancelButtonText: t('back'),
      },
      closeBtnStyle: {},
      onConfirm: handleCibusPayment,
    });
  };

  const handleCibusPayment = async () => {
    const res = await http.post(`users/userPaymentByCibus`, {
      orderId: mobOderData.id,
      price: mobOderData.orderPrice,
      paymentOptionId: paymentMethod,
      kitchenId: paymentKitchenId,
    });
    window.location.href = res.url;
  };
  return (
    <Modal
      show={myOrdersMob}
      className="myOrdersMobile-modal"
      onHide={handleMyOrdersMob}
      centered
    >
      <Modal.Header closeButton>
        {mobOderData?.point > 0
          ? moment(mobOderData?.meal?.date).format('dddd') +
            ' ' +
            moment(mobOderData?.meal?.date).format('DD/MM/YYYY')
          : moment(mobOderData?.mealDish?.[0]?.mealDish?.meal?.date).format(
              'dddd'
            ) +
            ' ' +
            moment(mobOderData?.mealDish?.[0]?.mealDish?.meal?.date).format(
              'DD/MM/YYYY'
            )}
      </Modal.Header>
      <Modal.Body>
        <div className="meal-details">
          <div className="meal-details-field">
            <h5>{t('kitchen')}</h5>
            <p>
              {mobOderData?.point > 0
                ? mobOderData?.kitchen?.name
                : mobOderData?.mealDish?.[0]?.mealDish?.dish?.kitchen?.name}
            </p>
          </div>
          <div className="meal-details-field">
            <h5>{t('mealType')}</h5>
            <p>
              {mobOderData?.point > 0
                ? mobOderData?.mealType?.value
                : mobOderData?.mealDish?.[0]?.mealDish?.meal?.mealType?.value}
            </p>
          </div>
          <div className="meal-details-field">
            <h5>{t('dishes')}</h5>
            <ul>
              {mobOderData &&
                mobOderData?.mealDish?.map((el, i) => (
                  <li>{el?.mealDish?.dish?.name}</li>
                ))}
            </ul>
          </div>
          {mobOderData?.mealExtra?.name ? (
            <div className="meal-details-field">
              <h5>{t('extras')}</h5>
              <p>{mobOderData?.mealExtra?.name}</p>
            </div>
          ) : (
            ''
          )}
          {user?.roleId !== 8 && user?.roleId !== 7 ? (
            <div className="meal-details-field">
              {mobOderData?.point > 0 ? (
                <>
                  <h5>{t('pendingCollectedPoint')}</h5>
                  <p>{mobOderData?.point}</p>
                </>
              ) : (
                <>
                  <h5>{t('totalPrice')}</h5>
                  {mobOderData?.paymentOptionId === 0 ||
                  mobOderData?.paymentOptionId === null ? (
                    <p>{'--'}</p>
                  ) : (
                    <p>₪ {mobOderData?.orderPrice}</p>
                  )}
                </>
              )}
            </div>
          ) : (
            ''
          )}
          <div className="meal-details-field">
            <h5>{t('orderId')}</h5>
            <p>
              {mobOderData?.chargeResponse
                ? JSON.parse(mobOderData?.chargeResponse)?.deal_id
                  ? JSON.parse(mobOderData?.chargeResponse)?.deal_id
                  : JSON.parse(mobOderData?.chargeResponse)?.orderID
                  ? JSON.parse(mobOderData?.chargeResponse)?.orderID
                  : '--'
                : mobOderData?.id
                ? mobOderData?.id
                : '--'}
            </p>
          </div>

          <div className="meal-details-field">
            <h5>{t('paymentMethod')}</h5>
            <p>
              {' '}
              {mobOderData?.paymentOptionId == 6
                ? t('paymentOption-cibus')
                : mobOderData?.paymentOptionId == 5
                ? t('wallet')
                : mobOderData?.paymentOptionId == 4
                ? t('paymentOption-salary')
                : mobOderData?.paymentOptionId == 3
                ? t('paymentOption-creditCard')
                : user.roleId === 4
                ? t('--')
                : t('invoice')}
            </p>
          </div>
          <div className="meal-details-field">
            <h5>{t('status')}</h5>
            <p>{mobOderData?.paymentStatus ? t('success') : t('pending')}</p>
          </div>

          {/* <div className="meal-details-field">
            <h5>{t('review')}</h5>
            <p
              className="rating"
              onClick={() => {
                history.push({
                  pathname: `/addReview/${mobOderData?.id}`,
                  state: { backText: t('returnToMyMeals') },
                });
              }}
            >
            <StaticStars rating={mobOderData?.rating || -1}
                  numOfRaters={mobOderData?.mealDish?.dish?.numOfRaters} />
              {/* {!mobOderData?.rating ? (
                <TableBtn img={reviewImg} btnName="Submit Review" />
              ) : (
                <StaticStars
                  rating={mobOderData?.rating || -1}
                  numOfRaters={mobOderData?.mealDish?.dish?.numOfRaters}
                />
              )} /}
            </p>
          </div> */}
        </div>
        {isOrder(mobOderData, OrderStatusesMap.realized) ? (
          <div
            className={`table-btn-section ${
              isEmpty(mobOderData.chargeResponse) ? 'table-single-btn' : ''
            }`}
          >
            {!isEmpty(mobOderData.chargeResponse) && (
              <div className={'rating'}>
                <TableBtn
                  img={voucher}
                  btnName={t('paymentDetails')}
                  func={() => handlePopup(mobOderData.id)}
                />
              </div>
            )}
            <div
              className={'rating'}
              onClick={() => {
                history.push({
                  pathname: `/addReview/${mobOderData?.id}`,
                  state: { backText: t('returnToMyMeals') },
                });
              }}
            >
              {!mobOderData?.rating ? (
                <TableBtn img={reviewImg} btnName={t('sendReview')} func={''} />
              ) : (
                <StaticStars
                  rating={mobOderData?.rating || -1}
                  numOfRaters={
                    mobOderData?.mealDish?.[0]?.mealDish?.dish?.numOfRaters
                  }
                  showNumOfRaters={false}
                />
              )}
            </div>
          </div>
        ) : isFutureMeal(mobOderData) ? (
          mobOderData?.point > 0 ? (
            ''
          ) : isOrder(mobOderData, OrderStatusesMap.canceled) ? (
            t('orderWasCanceled')
          ) : (
            <div className="table-btn-section">
              {mobOderData.paymentOptionId === 6
                ? mobOderData.paymentOptionId === 6 &&
                  mobOderData?.paymentStatus === false && (
                    <>
                      <TableBtn
                        img={cibusImg}
                        btnName={t('payment')}
                        func={cibusPayment}
                      />

                      {!(
                        mobOderData?.reported && mobOderData?.paymentStatus
                      ) && (
                        <TableBtn
                          img={edit}
                          btnName={t('edit')}
                          func={() =>
                            history.push({
                              pathname: `/orderSummary`,
                              state: {
                                date: meal?.date,
                                mealType: meal?.mealTypeId,
                                slotId: mobOderData?.mealSlotId,
                                orderId: mobOderData?.id,
                                dishId: dish?.id,
                                dishIds: dishIds,
                                backLink: '/myMeals',
                                backText: t('returnToMyMeals'),
                              },
                            })
                          }
                        />
                      )}
                    </>
                  )
                : !(mobOderData?.reported && mobOderData?.paymentStatus) && (
                    <TableBtn
                      img={edit}
                      btnName={t('edit')}
                      func={() =>
                        history.push({
                          pathname: `/orderSummary`,
                          state: {
                            date: meal?.date,
                            mealType: meal?.mealTypeId,
                            slotId: mobOderData?.mealSlotId,
                            orderId: mobOderData?.id,
                            dishId: dish?.id,
                            dishIds: dishIds,
                            backLink: '/myMeals',
                            backText: t('returnToMyMeals'),
                          },
                        })
                      }
                    />
                  )}

              {true ? (
                !(mobOderData?.reported && mobOderData?.paymentStatus) && (
                  <TableBtn
                    img={cancel}
                    btnName={t('cancel')}
                    func={() => cancelOrderDialog()}
                  />
                )
              ) : (
                <TableBtn
                  img={reviewImg}
                  btnName={t('sendReview')}
                  func={() => {
                    history.push({
                      pathname: `/addReview/${mobOderData?.id}`,
                      state: { backText: t('returnToMyMeals') },
                    });
                  }}
                />
              )}
              <TableBtn
                img={voucher}
                btnName={t('voucher')}
                func={handleVoucherModal}
              />
            </div>
          )
        ) : mobOderData?.point > 0 ? (
          <>
            {mobOderData?.reported ? (
              mobOderData?.status ? (
                <ActiveButton
                  additionalClassName="lobby-button"
                  label={t('collectedPointMsg')}
                />
              ) : (
                <ActiveButton
                  additionalClassName="lobby-button"
                  label={t('pending')}
                  cb={() => {
                    console.log('revertCollectPoints');
                    revertCollectPoints(mobOderData);
                  }}
                />
              )
            ) : (
              <ActiveButton
                additionalClassName="lobby-button"
                label={
                  mobOderData?.status == 1
                    ? t('collectedPointMsg')
                    : t('pending')
                }
                cb={() => {
                  console.log('revertCollectPoints');
                  revertCollectPoints(mobOderData);
                }}
              />
            )}

            {/*<p
              className={
                mobOderData.status
                  ? 'order-status success'
                  : 'order-status pending'
              }
              onClick={()=>console.log('mobOderData', mobOderData)}
            >
              {mobOderData.status ? t('success') : t('pending')}
            </p>*/}
          </>
        ) : (
          <p className={'single-content'}>
            {' '}
            {isOrder(mobOderData, OrderStatusesMap.canceled)
              ? t('orderWasCanceled')
              : t('orderNotRealized')}
          </p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MyOrdersDetailsMobilePopup;
