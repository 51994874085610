import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ContractDetails = ({
  open,
  handleClose,
  details,
  handleConfirmation,
  handleOpen,
  setPayload,
  payload,
  initialState,
  cibusEnabled,
}) => {
  let { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    console.log('details', details);
  }, [details]);
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-employee-popup"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('contractorManagement')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup">
            {/* <div className="back">
        <img src={back} alt="back" onClick={handleBack} />
      </div> */}
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('contractorName')}:</h6>
                  <p>{details?.name ? details?.name : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('email')}:</h6>
                  <p>{details?.contactEmail ? details?.contactEmail : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('companyNumber')}:</h6>
                  <p>
                    {details?.companyNumber ? details?.companyNumber : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('phone')}:</h6>
                  <p>{details?.contactPhone ? details?.contactPhone : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('contactPersonName')}:</h6>
                  <p>
                    {details?.contactPersonName
                      ? details?.contactPersonName
                      : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('invoice')}:</h6>
                  <p>{Boolean(details?.isInvoice) ? t('yes') : t('no')}</p>
                </div>
                {cibusEnabled && (
                  <div className="user-details-block">
                    <h6>{t('magneticStripe')}:</h6>
                    <p>
                      {!Boolean(details?.isInvoice) &&
                      Boolean(details?.magneticStripe)
                        ? details?.magneticStripe
                        : '--'}
                    </p>
                  </div>
                )}
                {/* <div className="user-details-block">
                  <h6>{t('action')}:</h6>
                  <p>
                    <div className="actions-btns">
                      <i
                        class="las la-pen"
                        title="edit"
                        onClick={() => {
                          setPayload({
                            userId: details?.userId,
                            companyNumber: details?.companyNumber,
                            contactEmail: details?.contactEmail,
                            contactPersonName: details?.contactPersonName,
                            contactPhone: details?.contactPhone
                              ? details?.contactPhone?.split('-')?.join('')
                              : details?.contactPhone,
                          });
                          handleOpen();
                        }}
                      ></i>
                      <i
                        class="las la-trash"
                        title="delete"
                        onClick={() => handleConfirmation(details?.userId)}
                      ></i>
                    </div>
                  </p>
                </div> */}
                <div className="modal-footer-btn">
                  <Button
                    onClick={() => {
                      setPayload({
                        name: details?.name,
                        userId: details?.userId,
                        companyNumber: details?.companyNumber,
                        contactEmail: details?.contactEmail,
                        contactPersonName: details?.contactPersonName,
                        contactPhone: details?.contactPhone
                          ? details?.contactPhone?.split('-')?.join('')
                          : details?.contactPhone,
                        magneticStripe: details?.magneticStripe,
                        isInvoice: details?.isInvoice,
                      });
                      handleOpen();
                    }}
                  >
                    <i class="las la-pen" title="edit"></i>
                    {t('edit')}
                  </Button>
                  <Button onClick={() => handleConfirmation(details?.userId)}>
                    <i class="las la-trash" title="delete"></i>
                    {t('delete')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Typography>
        {/* <div className="view-popup-btn">
          <button className="close-modal-btn" onClick={handleClose}>
            {t('close')}
          </button>
        </div> */}
      </Box>
    </Modal>
  );
};
export default ContractDetails;
