import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { validateMealType } from '../../services/validators';
import http from '../../services/http';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import Select from 'react-select';
import { useFetchingLoader } from '../../store/app/actions';
const AddMealType = ({
  show,
  handleClose,
  t,
  key,
  isActive,
  Kitchens,
  Languages,
  MealTypes,
  handleChange,
  dataValue,
  handleMealType,
  kitchenId,
  allData,
  CurrentLanguage,
  setIsAdd,
  errors,
  setErrors,
  translations,
  setTranslations,
  data,
  isEdit,
  setTransData,
  setEditMeal,
  toggleValueMultiDish,
  setToggleValueMultiDish,
  toggleValueMultiReservations,
  setToggleValueMultiReservations,
  editMeal,
  toggleValueSalaryFile,
  settoggleValueSalaryFile,
  toggleValueCalculatePrice,
  settoggleValueCalculatePrice,
  paymentMethodsID,
  resetDataValue,
  useFetchingLoader,
  getMealTypes,
}) => {
  const [selectStyles, setSelectStyles] = useState({
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minWidth: 250,
      maxWidth: 500,
      fontWeight: 400,
      fontSize: 14,
      backgroundColor: 'transparent',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
      borderRadius: '5px',
    }),
  });
  const [relatedMealTypesId, setRelatedMealTypesId] = useState([]);
  const [filteredMealTypes, setFilteredMealTypes] = useState([]);

  useEffect(() => {
    let dummyData = {};
    Languages.forEach((el) => (dummyData[el.id] = el));
    setTranslations(dummyData);
    setErrors({});
    return () => {
      setEditMeal(false);
      setToggleValueMultiReservations(0);
      settoggleValueSalaryFile(0);
      settoggleValueCalculatePrice(0);
      setToggleValueMultiDish(0);
      resetDataValue();
    };
  }, []);
  useEffect(() => {
    const kitchensIds = Kitchens.map((el) => el.id);
    setFilteredMealTypes(
      MealTypes.filter(
        (elem) =>
          kitchensIds.includes(elem.kitchenId) && isEdit?.id !== elem?.id
      )
    );
  }, [MealTypes]);

  useEffect(() => {
    setRelatedMealTypesId(
      isEdit?.relatedMealTypes1
        ? isEdit?.relatedMealTypes1.concat(isEdit?.relatedMealTypes2)
        : []
    );
  }, [isEdit]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      value: translations[CurrentLanguage.id]?.value,
      from: dataValue.from,
      to: dataValue.to,
      kitchenId: kitchenId,
      translations: Object.values(translations),
      externalId: dataValue.externalId,
      orderIndex: dataValue.orderIndex,
      cancellationTime: dataValue.cancellationTime
        ? dataValue.cancellationTime
        : null,
      creditPoints: dataValue.creditPoints
        ? dataValue.creditPoints
        : isEdit.externalId
        ? isEdit.externalId
        : null,
      multipleDish: toggleValueMultiDish,
      multipleReservations: toggleValueMultiReservations,
      price: dataValue.price ? dataValue.price : isEdit?.price,
      calculateDishPrice: dataValue.calculateDishPrice
        ? dataValue.calculateDishPrice
        : toggleValueCalculatePrice,
      includeSalary: dataValue.includeSalary
        ? dataValue.includeSalary
        : toggleValueSalaryFile,
      relatedMealTypes: relatedMealTypesId.map((elem) => elem.id),
    };

    const { isValid, errors } = validateMealType(details);
    setErrors(errors);
    if (isValid) {
      await handleMealType({ ...details });
    }
  };

  /********** edit mealtype */
  const [value, setvalue] = useState('');

  const handleUpdate = async (id) => {
    try {
      let details = {
        value: translations[CurrentLanguage.id].value,
        from: dataValue.from ? dataValue.from : isEdit.from,
        to: dataValue.to ? dataValue.to : isEdit.to,
        kitchenId: kitchenId,
        translations: Object.values(translations),
        externalId: dataValue.externalId
          ? dataValue.externalId
          : isEdit.externalId,
        orderIndex:
          dataValue.orderIndex && dataValue.orderIndex !== '0'
            ? dataValue.orderIndex
            : isEdit.orderIndex,
        cancellationTime:
          dataValue.cancellationTime === '' || dataValue.cancellationTime
            ? dataValue.cancellationTime
            : isEdit.cancellationTime,
        creditPoints:
          dataValue.creditPoints !== undefined &&
          (dataValue.creditPoints.trim() === '' ||
            dataValue.creditPoints !== '')
            ? dataValue.creditPoints
            : isEdit.creditPoints,
        multipleDish: dataValue.multipleDish
          ? dataValue.multipleDish
          : toggleValueMultiDish,
        multipleReservations: dataValue.multipleReservations
          ? dataValue.multipleReservations
          : toggleValueMultiReservations,
        price: dataValue.price ? dataValue.price : isEdit.price,
        calculateDishPrice: dataValue.calculateDishPrice
          ? dataValue.calculateDishPrice
          : toggleValueCalculatePrice,
        includeSalary: dataValue.includeSalary
          ? dataValue.includeSalary
          : toggleValueSalaryFile,
        relatedMealTypes: relatedMealTypesId.map((elem) => elem.id),
      };
      const { isValid, errors } = validateMealType(details);
      setErrors(errors);
      if (isValid) {
        const dttt = await http.put(`mealTypes/${isEdit.id}`, details);
        Dialog({
          title: t('editSuccessfully'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            // setDummy(true);
            setEditMeal(false);
            window.location.reload();
          },
        });
      }
    } catch (err) {
      if (err === 'error') {
        Alert({ error: t('orderIndexerror') });
      } else if (err === 'error1') {
        Alert({ error: t('orderZeroError') });
      } else {
        Alert({ error: err });
      }
    }
  };
  useEffect(() => {
    if (setEditMeal) {
      let dummyData = {};
      isEdit?.translations?.forEach((el) => (dummyData[el.languageId] = el));

      setTranslations((prev) => {
        return { ...prev, ...dummyData };
      });
    }
  }, []);
  const handleToggleBtnDish = (data) => {
    let value = 0;
    if (data === 0) {
      value = 1;
    }
    setToggleValueMultiDish(value);
  };
  const handleToggleBtnRes = (data) => {
    let value = 0;
    if (data === 0) {
      value = 1;
    }
    setToggleValueMultiReservations(value);
  };

  const handleIsSallaryFileToggleBtn = (data) => {
    let value = 0;
    if (data === 0) {
      value = 1;
    }
    settoggleValueSalaryFile(value);
  };

  const handleIsCalculateProceToggleBtn = (data) => {
    let value = 0;
    if (data === 0) {
      value = 1;
    }
    settoggleValueCalculatePrice(value);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-mealtypes-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isEdit ? t('updateMealType') : t('addMealType')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className={`add-mealtypes`}>
          <div className="add-mealtype-section">
            <div className="input-field order-index">
              <GetInputField
                placeholder={t('orderIndex')}
                label={t('orderIndex')}
                name="orderIndex"
                type="number"
                value={isEdit?.orderIndex ? isEdit?.orderIndex : ''}
                cb={(value) => {
                  handleChange('orderIndex', value);
                }}
                required
              />
              {errors && <p className="error-msg">{errors.orderIndex}</p>}
            </div>

            {Languages.map((lang) => (
              <div className="input-field" key={lang.id}>
                <GetInputField
                  placeholder={`${t('mealName')} (${lang.shortCode})`}
                  label={`${t('mealName')} (${lang.shortCode})`}
                  name={`name_${lang.id}`}
                  type="text"
                  value={translations[lang.id]?.value || ''}
                  cb={(value) =>
                    setTranslations((state) => ({
                      ...state,
                      [lang.id]: {
                        languageId: lang.id,
                        value: value,
                        display: value,
                      },
                    }))
                  }
                />
                {errors && !translations[lang.id]?.value && (
                  <p className="error-msg">{errors.translations}</p>
                )}
              </div>
            ))}
            <div className="input-field">
              <label className="fs-15">{t('startTime')}</label>

              <TimePicker
                defaultValue={
                  isEdit?.from ? moment(isEdit.from + ':00', 'HH:mm') : moment()
                }
                showSecond={false}
                minuteStep={15}
                name="from"
                onChange={(val) => {
                  handleChange('from', val);
                }}
              />
              {errors && <p className="error-msg">{errors.from}</p>}
            </div>
            <div className="input-field">
              <label className="fs-15">{t('endTime')}</label>

              <TimePicker
                defaultValue={
                  isEdit?.to ? moment(isEdit.to + ':00', 'HH:mm') : moment()
                }
                showSecond={false}
                minuteStep={15}
                name="to"
                onChange={(val) => {
                  handleChange('to', val);
                }}
              />
              {errors && <p className="error-msg">{errors.to}</p>}
            </div>
            <div className="input-field">
              <GetInputField
                placeholder={t('extendalId')}
                label={t('extendalId')}
                name="extenralID"
                type="text"
                value={isEdit?.externalId ? isEdit.externalId : ''}
                cb={(value) => {
                  handleChange('externalId', value);
                }}
                required
              />
              {errors && <p className="error-msg">{errors.externalId}</p>}
            </div>
            <div className="input-field">
              <GetInputField
                placeholder={t('Credit Points')}
                label={t('Credit Points')}
                name="creditPoints"
                type="text"
                value={isEdit?.creditPoints ? isEdit.creditPoints : ''}
                cb={(value) => {
                  handleChange('creditPoints', value);
                }}
                required
              />
            </div>
            <div className="input-field">
              <GetInputField
                placeholder={t('cancellationTime')}
                label={t('cancellationTime')}
                name="cancellationTime"
                type="text"
                value={isEdit?.cancellationTime ? isEdit?.cancellationTime : ''}
                cb={(value) => {
                  handleChange('cancellationTime', value);
                }}
              />
            </div>

            <div className="input-field">
              <GetInputField
                placeholder={t('price')}
                label={t('price')}
                className="fs-15"
                name="price"
                type="number"
                value={isEdit?.price ? isEdit?.price : '0'}
                cb={(value) => {
                  handleChange('price', value);
                }}
              />
            </div>
            {paymentMethodsID == 4 ? (
              <div className="input-field toggle-mealtype">
                <span className="fs-15">{t('is_include_sallary_file')}</span>
                <button
                  type="button"
                  className={`toggle-btn ${
                    toggleValueSalaryFile === '1' || toggleValueSalaryFile === 1
                      ? 'toggle-btn-on'
                      : 'toggle-btn-off'
                  }`}
                  onClick={() =>
                    handleIsSallaryFileToggleBtn(toggleValueSalaryFile)
                  }
                >
                  <span></span>
                </button>
              </div>
            ) : (
              ''
            )}

            <div className="input-field toggle-mealtype">
              <span className="fs-15">{t('is_calculate_Price')}</span>
              <button
                type="button"
                className={`toggle-btn ${
                  toggleValueCalculatePrice === '1' ||
                  toggleValueCalculatePrice === 1
                    ? 'toggle-btn-on'
                    : 'toggle-btn-off'
                }`}
                onClick={() =>
                  handleIsCalculateProceToggleBtn(toggleValueCalculatePrice)
                }
              >
                <span></span>
              </button>
            </div>
            <div className="input-field toggle-mealtype">
              <span className="fs-15">{t('multipleDish')}</span>
              <button
                type="button"
                className={`toggle-btn ${
                  toggleValueMultiDish === 1
                    ? 'toggle-btn-on'
                    : 'toggle-btn-off'
                }`}
                onClick={() => handleToggleBtnDish(toggleValueMultiDish)}
              >
                <span></span>
              </button>
            </div>
            <div className="input-field toggle-mealtype">
              <span className="fs-15">{t('multipleReservations')}</span>
              <button
                type="button"
                className={`toggle-btn ${
                  toggleValueMultiReservations === 1
                    ? 'toggle-btn-on'
                    : 'toggle-btn-off'
                }`}
                onClick={() => handleToggleBtnRes(toggleValueMultiReservations)}
              >
                <span></span>
              </button>
            </div>
          </div>
          <div className="ai-center">
            <div>
              <span className="fs-15">{t('relatedMealTypes')}</span>
              <Select
                isMulti
                name={'relatedMealTypes'}
                defaultValue={
                  isEdit?.relatedMealTypes1
                    ? isEdit?.relatedMealTypes1
                        .concat(isEdit?.relatedMealTypes2)
                        .map((elem) => {
                          return {
                            value: elem?.id,
                            label: `${
                              MealTypes.filter(
                                (elem) =>
                                  Kitchens.map((el) => el.id).includes(
                                    elem.kitchenId
                                  ) && isEdit?.id !== elem?.id
                              ).find((el) => el.id == elem.id)?.display
                            } (${
                              Kitchens.find((el) => el.id == elem.kitchenId)
                                ?.name
                                ? Kitchens.find((el) => el.id == elem.kitchenId)
                                    ?.name
                                : 'Deleted kitchen'
                            })`,
                          };
                        })
                    : []
                }
                onChange={(newData) => {
                  setRelatedMealTypesId(
                    newData.map((el) => ({ id: el.value, label: el.label }))
                  );
                }}
                options={filteredMealTypes.map((elem) => {
                  return {
                    value: elem.id,
                    label: `${elem.display} (${
                      elem?.kitchen?.name
                        ? elem?.kitchen?.name
                        : 'Deleted kitchen'
                    })`,
                  };
                })}
                placeholder={t('relatedMealTypes')}
                styles={selectStyles}
                required
              />
            </div>
          </div>
          <div className="add-btn">
            {}
            <Button onClick={editMeal ? handleUpdate : handleFormSubmit}>
              {isEdit ? t('update') : t('add')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        type={type}
      />
    </>
  );
};

export default AddMealType;
