import React from 'react';
import { ActiveButton } from '../../components/WE-Button';
import dates from '../../services/dates';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import moment from 'moment';

const ShowNextmealContainer = ({
  items,
  assignMethod,
  showPreviousLabel = 'showPreviousDates',
  showPrevious,
  showPreviousOnClick,
  nextMeal,
}) => {
  const { t } = useTranslation();
  const today = dates.formatServer(new Date());

  /*let d = new Date().toLocaleString('he-IL', {
    timeZone: 'Asia/Jerusalem',
  });
  let getTime = d.split(',')[1];
  let finalHours =
    (getTime.split(':')[0] < 10 ? '0' : '') +
    getTime.split(':')[0].split(' ').join('') +
    ':' +
    getTime.split(':')[1];
  // console.log("items",items,"ggggggggggggg",moment(new Date()).format('YYYY-MM-DD'));
  const newArr = items.filter(
    (el) =>
      el?.mealDish?.[0]?.mealDish?.meal?.date ==
        moment(new Date()).format('YYYY-MM-DD') ||
      el?.mealDish?.meal?.date == moment(new Date()).format('YYYY-MM-DD')
  );
  let getNextMealInfo = newArr?.filter(
    (ele) =>
      ele?.mealDish?.[0]?.mealDish?.meal?.mealType?.from >= finalHours ||
      finalHours <= ele?.mealDish?.[0]?.mealDish?.meal?.mealType?.to ||
      ele?.mealDish?.meal?.mealType?.from >= finalHours ||
      ele?.mealDish?.meal?.mealType?.to >= finalHours
  );
  const filterMeals2 = items.filter(
    (el) =>
      el?.mealDish[0]?.mealDish?.meal?.date >
      moment(new Date()).format('YYYY-MM-DD')
  );

  let len = 3 - getNextMealInfo.length;
  const slicedArray = filterMeals2.slice(0, len);
  const filterNextMealsOrder = getNextMealInfo.concat(slicedArray);

  //console.log('filterNextMeals==>', filterNextMealsOrder);

  //let itemsInfo = futureItems.slice(0, 2);
  let itemsInfo = filterNextMealsOrder.slice(0, 2);*/

  return (
    <>
      {
        // itemsInfo?.length === 0 ? (
        //   <h3 style={{ marginTop: '100px' }}>{t('noFutureDates')}</h3>
        // ) : (
        items
          .sort((item1, item2) =>
            item2?.order.date?.localeCompare(item1?.order?.date)
          )
          .map((item) =>
            assignMethod(item, item?.order?.date?.localeCompare(today) >= 0)
          )
        // )
      }
    </>
  );
};

const ShowPrevious = ({
  showPrevious,
  showPreviousOnClick,
  label,
  fakeItem,
}) => {
  return (
    !showPrevious && (
      <div className="show-previous">
        <div className={'in-front'}>
          <Button onClick={showPreviousOnClick}>{label}</Button>
        </div>
      </div>
    )
  );
};

export default ShowNextmealContainer;
