import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { validateMealType } from '../../services/validators';
import http from '../../services/http';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import TagForm from '../../components/Forms/TagForm';
import { useHistory, useParams } from 'react-router-dom';
import { useFetchingLoader } from '../../store/app/actions';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';

const adaptPayload = (data) => {
  const translations = data.translations;
  const n = Object.keys(translations).length;
  const translationsArray = [];
  for (let i = 1; i <= n; i++) {
    translationsArray.push(translations[i]);
  }
  return { ...data, translations: translationsArray };
};

const AddTagPopup = ({
  show,
  handleClose,
  t,
  languages,
  CurrentLanguage,
  fetchTag,
  editTag,
  setRefresh,
}) => {
  const { tagId } = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [translations, setTranslations] = useState({});

  const handleSubmit = async () => {
    let details = {
      status: isActive,
      displayName: translations[CurrentLanguage.id]?.displayName,
      isPublic: isPublic,
      translations: translations,
    };

    if (editTag) {
      const res = await http.put(`tags/${editTag?.id}`, {
        ...adaptPayload(details),
      });
      if (res) {
        SwalDialogs.Dialog({
          title: t('tagUpdatedSuccessfully'),
          text: '',
          type: 'success',
          buttons: {
            showCancelButton: false,
            showConfirmButton: true,
          },
          onConfirm: () => {
            handleClose();
            setRefresh((prev) => !prev);
          },
          confirmButtonText: t('okay'),
        });
      }
    } else {
      try {
        const res = await http.post('tags/create', {
          ...adaptPayload(details),
        });
        if (res.id) {
          SwalDialogs.Dialog({
            title: t('Tag Added successfully'),
            text: '',
            type: 'success',
            buttons: {
              showCancelButton: false,
              showConfirmButton: true,
            },
            onConfirm: () => {
              handleClose();
              setRefresh((prev) => !prev);
            },
            confirmButtonText: t('okay'),
          });
        }
      } catch (error) {
        showDailog(error);
        return error;
      }
    }
  };

  useEffect(() => {
    if (editTag) {
      console.log('this is the edit Tag', editTag);
      let obj = {};
      editTag.translations.forEach((translation) => {
        obj[translation.languageId] = {
          languageId: translation.languageId,
          displayName: translation.text,
        };
      });
      setIsActive(editTag.status);
      setIsPublic(editTag.isPublic);
      setTranslations(obj);
    }
  }, [editTag]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-mealtypes-popup add-tag-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {editTag ? t('updateTag') : t('addTag')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className={`add-mealtypes`}>
          <div className="add-mealtype-section">
            {languages.map((language, idx) => (
              <div className="input-field order-index" key={idx}>
                <GetInputField
                  placeholder={'Tag Name'}
                  label={language.displayName}
                  name="orderIndex"
                  type="text"
                  value={
                    translations[language.id]?.displayName ||
                    translations[language.id]?.value ||
                    ''
                  }
                  cb={(value) =>
                    setTranslations((state) => ({
                      ...state,
                      [language.id]: {
                        languageId: language.id,
                        displayName: value,
                      },
                    }))
                  }
                  required
                />
              </div>
            ))}
          </div>
          <div className="toggle-section">
            <input
              type="checkbox"
              id="switch1"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
            <label htmlFor="switch1">Toggle</label>
            <h6>{t('isActive')}</h6>
          </div>
          <div className="toggle-section">
            <input
              type="checkbox"
              id="switch2"
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
            />
            <label htmlFor="switch2">Toggle</label>
            <h6>{t('isPublic')}</h6>
          </div>
          <div className="add-btn">
            <Button onClick={handleSubmit}>
              {editTag ? t('update') : t('add')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTagPopup;

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        type={type}
      />
    </>
  );
};
