import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Chart } from 'react-google-charts';

import {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
} from '../../store/app/actions';
import http from '../../services/http';
import dates from '../../services/dates';
import { ActiveButton, DisabledButton } from '../../components/WE-Button';
import { LobbyCard } from '../../components/ManagersLobby';

/* Assets */
import { PieChart, LineChart } from '../Charts';
import { StaticStars } from '../../components/RatingStars';
import { sleep } from '../../services/utils';
import next from '../../assets/images/next.png';
import info from '../../assets/newimages/icon-info.svg';
import { Button } from 'react-bootstrap';
import moment from 'moment';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import ViewLobbyDetails from '../SiteManager/viewLobbyDetails';

const Lobby = ({
  user,
  kitchen,
  MealTypes,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  MealStatuses,
}) => {
  window.gtag('event', 'Kitchen_manager_lobby', {
    title: 'kitchen-manager-lobby',
    client: window.location.host.split('.')[0],
  });

  const { t } = useTranslation();
  const history = useHistory();
  useEffect(async () => await useLoader(() => sleep()), []);

  //const [nextMeal, setNextMeal] = useState();
  const [previousMeal, setPreviousMeal] = useState([]);
  const [nextMealPercent, setNextMealPercent] = useState(0);
  const [nextMeal, setNextMeal] = useState([]);
  const [showChart, setshowChart] = useState(0);
  const [chartDataReview, setChartDataReview] = useState([]);

  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const lang = localStorage.getItem('language');

  const MealStatusMap = MealStatuses.reduce((acc, meal) => {
    return { ...acc, [meal.value]: meal.id };
  }, {});

  const MealTypeMap = MealTypes.reduce((acc, mealType) => {
    return { ...acc, [mealType.id]: mealType.display };
  }, {});

  const handleClick = () => {
    history.push('/controlPanel');
  };

  useEffect(async () => {
    //if (!kitchen) return;
    setLayoutOptions({ title: t(''), showKitchen: true });

    // const { nextMeal, lastMeal } = await useFetchingLoader();
    //async () => await http.get(`kitchens/${kitchen.id}/controlPanel`);

    if (!kitchen) return;

    setLayoutOptions({ title: `${t('mealManagement')} ${kitchen.name}` });
    const { data } = await useFetchingLoader(
      async () =>
        await http.get(`meals/nextMeals`, {
          filter: {
            kitchenId: kitchen.id,
            mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
          },
          limit: 15,
          order: [['date', 'ASC']],
          meta: true,
        })
    );
    const nextMeal = data.slice(0, 5);

    setNextMeal(nextMeal);

    const today = new Date();
    const lastMonthFirstDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );

    let myVariable = new Date();
    let makeDate = new Date(myVariable);

    makeDate.setMonth(makeDate.getMonth() - 1);

    const { pastMeal } = await useFetchingLoader(
      async () =>
        await http.get(`meals/previousMeals`, {
          from: moment(new Date()).format('YYYY-MM-DD'),
          to: moment(makeDate).format('YYYY-MM-DD'),
          // from: new Date().toISOString().split('T')[0],
          // to: moment(lastMonthFirstDate).format('YYYY-MM-DD'),
          filter: {
            kitchenId: kitchen.id,
            mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
          },
          limit: 5,
          order: [['date', 'DESC']],
          meta: true,
        })
    );
    const pastMeals = pastMeal.slice(0, 5);
    setPreviousMeal(pastMeals);

    // const myDate = moment(today).subtract(30, 'days').calendar();
    // const myDates = moment(myDate).format('YYYY-MM-DD');
    // const todaysDate = moment(today).format('YYYY-MM-DD');

    // let { chartData } = await useFetchingLoader(
    //   async () =>
    //     await http.get(`meals/reviewsChartOfLastMonth`, {
    //       // from: todaysDate,
    //       // to: myDates,
    //       from: moment(new Date()).format('YYYY-MM-DD'),
    //       to: moment(makeDate).format('YYYY-MM-DD'),
    //       filter: {
    //         kitchenId: kitchen.id,
    //         mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
    //       },
    //       //limit: 300,
    //       order: [['date', 'DESC']],
    //       meta: true,
    //     })
    // );
    // const newYdata = chartData.yAvgDailyScore.map((el) => parseFloat(el));
    // chartData.yAvgDailyScore = newYdata;
    // setshowChart(1);
    // setChartDataReview(chartData);

    data &&
      setNextMealPercent(Math.round((data.ordered / data.inventory) * 100));
  }, [kitchen]);

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };
  const handleOpen = (value) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  // ag grid data
  const columnDefs = [
    {
      field: 'date',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'DateCellRender',
      resizable: true,
    },
    {
      field: 'from',
      headerName: t('time'),
      cellRenderer: 'TimeCellRender',
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'value',
      headerName: t('reservationMealType'),
      cellRenderer: 'MealTypeCellRender',
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'inventory',
      headerName: t('inventory'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'ordered',
      headerName: t('orders'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'realized',
      headerName: t('realized'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'rating',
      headerName: t('rating'),
      cellRenderer: 'RatingCellRender',
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      flex: 1,
    },
  ];

  const columnDefsTab = [
    {
      field: 'date',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'DateCellRender',
      resizable: true,
    },
    {
      field: 'from',
      headerName: t('time'),
      cellRenderer: 'TimeCellRender',
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'value',
      headerName: t('reservationMealType'),
      cellRenderer: 'MealTypeCellRender',
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'inventory',
      headerName: t('inventory'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'ordered',
      headerName: t('orders'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'realized',
      headerName: t('realized'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'rating',
      headerName: t('rating'),
      cellRenderer: 'RatingCellRender',
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 40,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'date',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'DateCellRender',
      resizable: true,
      width: 60,
    },
    {
      field: 'value',
      headerName: t('reservationMealType'),
      cellRenderer: 'MealTypeCellRender',
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
      width: 80,
    },
    {
      field: 'inventory',
      headerName: t('inventory'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
      width: 50,
    },
    {
      field: 'ordered',
      headerName: t('orders'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
      width: 50,
    },
    {
      field: 'realized',
      headerName: t('realized'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      resizable: true,
      width: 50,
    },
    // {
    //   field: 'rating',
    //   headerName: t('rating'),
    //   cellRenderer: 'RatingCellRender',
    //   sortable: true,
    //   filter: true,
    //   suppressMovable: true,
    //   resizable: true,
    //   width: 100,
    // },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 40,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
    // sizeColumnsToFit: true,
    suppressSizeToFit: true,
    width: 'auto',
  };

  const onFirstDataRendered = (params) => {
    // arbitrarily expand a row for presentational purposes
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1).setExpanded(true);
    }, 0);
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  const handleRowNextClick = () => {
    history.push('/mealManagement');
  };
  const handleRowPastClick = () => {
    history.push('/pastMeals');
  };
  const handleMoreInfo = (mealData) => {
    let type = mealData.mealTypeId;
    let date = dates.formatServer(mealData.date);

    history.push({
      pathname: `/menuConstruction/${date}/${type}`,
      state: { backText: t('returnToMealManagement') },
    });
  };

  const MealsCallBacks = {
    moreInfo: handleMoreInfo,
  };
  return (
    <>
      <div className="lobby-manager user-management-table global-data-table">
        <div className="container">
          <div className="next-meal-table my-orders-table">
            <div className="table-title">
              <h6>{t('nextMeals')}</h6>
              <Link to="/mealManagement" test-id="nextMeal">
                {t('allMeal')}{' '}
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </Link>
            </div>
            <div>
              <div
                className="ag-theme-alpine"
                style={{ height: '100%', width: '100%' }}
              >
                <AgGridReact
                  className="ag-theme-alpine"
                  rowData={nextMeal}
                  columnDefs={tableColumn}
                  reactUi={lang === '"he"' ? false : true}
                  enableRtl={lang === '"he"' ? true : false}
                  domLayout={'autoHeight'}
                  gridOptions={gridOptions}
                  onGridReady={(params) => {
                    setGridApi(params.api);
                    setColumnApi(params.columnApi);
                    params.api.sizeColumnsToFit();
                    document
                      .querySelector('.ag-pinned-left-cols-container')
                      .remove();
                  }}
                  onFirstDataRendered={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                  frameworkComponents={{
                    ViewMore: (e) => (
                      <ViewMore e={e} handleViewMore={handleViewMore} />
                    ),
                    DateCellRender: (e) => (
                      <DateCellRender e={e} dates={dates} />
                    ),
                    TimeCellRender: (e) => <TimeCellRender e={e} />,
                    MealTypeCellRender: (e) => <MealTypeCellRender e={e} />,
                    RatingCellRender: (e) => <RatingCellRender e={e} />,
                  }}
                  debug={false}
                  rowSelection={'multiple'}
                  rowGroupPanelShow={'always'}
                  pivotPanelShow={'always'}
                  enableRangeSelection={true}
                  pagination={false}
                  onRowClicked={(e) => MealsCallBacks.moreInfo(e.data)}
                  paginationPageSize={10}
                  paginationNumberFormatter={function (params) {
                    return params.value.toLocaleString();
                  }}
                ></AgGridReact>
                <ViewLobbyDetails
                  open={viewDetails}
                  details={details}
                  handleClose={handleViewMore}
                  handleOpen={handleOpen}
                  // setPayload={setPayload}
                />
              </div>
            </div>
            {/* <div className="table-header my-orders-table-head">
              <div class="table-text-section">
                <h6>{t('date')}</h6>
                <h6>{t('time')}</h6>
                <h6>{t('reservationMealType')}</h6>
                <h6>{t('inventory')}</h6>
                <h6>{t('orders')}</h6>
                <h6>{t('realized')}</h6>
                <h6>{t('rating')}</h6>
              </div>
            </div>
            <div className="table-body my-orders-table-body">
              {nextMeal.length > 0 &&
                nextMeal.map((el, i) => <MealRow dataList={el} index={i} />)}
            </div>
          </div> */}
            <div className="past-meal-table my-orders-table">
              <div className="table-title">
                <h6>{t('pastMeals')}</h6>
                <Link to="/pastMeals" test-id="pastMeal">
                  {t('allMeal')}{' '}
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </Link>
              </div>
              <div
                className="ag-theme-alpine"
                style={{ height: '100%', width: '100%' }}
              >
                <AgGridReact
                  className="ag-theme-alpine"
                  rowData={previousMeal}
                  columnDefs={tableColumn}
                  reactUi={lang === '"he"' ? false : true}
                  enableRtl={lang === '"he"' ? true : false}
                  domLayout={'autoHeight'}
                  gridOptions={gridOptions}
                  onRowClicked={(e) => MealsCallBacks.moreInfo(e.data)}
                  onGridReady={(params) => {
                    setGridApi(params.api);
                    setColumnApi(params.columnApi);
                    params.api.sizeColumnsToFit();
                    document
                      .querySelector('.ag-pinned-left-cols-container')
                      .remove();
                  }}
                  onFirstDataRendered={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                  frameworkComponents={{
                    ViewMore: (e) => (
                      <ViewMore e={e} handleViewMore={handleViewMore} />
                    ),
                    DateCellRender: (e) => (
                      <DateCellRender e={e} dates={dates} />
                    ),
                    TimeCellRender: (e) => <TimeCellRender e={e} />,
                    MealTypeCellRender: (e) => <MealTypeCellRender e={e} />,
                    RatingCellRender: (e) => <RatingCellRender e={e} />,
                  }}
                  debug={false}
                  rowSelection={'multiple'}
                  rowGroupPanelShow={'always'}
                  pivotPanelShow={'always'}
                  enableRangeSelection={true}
                  pagination={false}
                  paginationPageSize={10}
                  paginationNumberFormatter={function (params) {
                    return params.value.toLocaleString();
                  }}
                ></AgGridReact>
                <ViewLobbyDetails
                  open={viewDetails}
                  details={details}
                  handleClose={handleViewMore}
                  handleOpen={handleOpen}
                  // setPayload={setPayload}
                />
              </div>
              {/* <div className="table-header my-orders-table-head">
              <div class="table-text-section">
                <h6>{t('date')}</h6>
                <h6>{t('time')}</h6>
                <h6>{t('reservationMealType')}</h6>
                <h6>{t('inventory')}</h6>
                <h6>{t('orders')}</h6>
                <h6>{t('realized')}</h6>
                <h6>{t('rating')}</h6>
              </div>
            </div>
            <div className="table-body my-orders-table-body">
              {previousMeal.map((el, i) => (
                <PreviousMealRow dataList={el} index={i} />
              ))}
            </div>
          </div> */}
            </div>
          </div>

          <div className="review-score-chart">
            <div className="review-score-header">
              {/* <div className="review-score-left">
                <h5>{t('reviews')} </h5>
                <h6>{chartDataReview?.totalReviews}</h6>
                <p>{t('SecondaryText')} </p>
              </div> */}
              {/* <div className="review-score-right">
                <Link to="/allReviews">
                  <img src={info} alt="" />
                </Link>
              </div> */}
            </div>
            {/* {showChart && <ReviewChart chartDataReview={chartDataReview} />} */}
            {/*<div className="review-score-footer">
              <Button onClick={handleClick}>{t('GotoControlPanel')}</Button>
            </div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      {/* <img src={next} alt="next" onClick={() => handleViewMore(e.data)} /> */}
      <img src={next} alt="next" />
    </div>
  );
};
const DateCellRender = ({ e, dates }) => {
  // return <p>{moment(e?.value).format('YYYY/MM/DD')}</p>;
  return <p> {dates.ddmmFormat(e?.value)}</p>;
};

const TimeCellRender = ({ e }) => {
  return (
    <p>
      {e?.data?.mealType?.from} - {e?.data?.mealType?.to}{' '}
    </p>
  );
};
const MealTypeCellRender = ({ e }) => {
  return <p>{e.data?.mealType.display}</p>;
};
const RatingCellRender = ({ e }) => {
  return (
    <div className={'rating'}>
      <StaticStars
        numOfRaters={e.data?.numOfRaters}
        rating={e.data?.rating}
        size={'small'}
        showNumOfRaters={true}
      />
    </div>
  );
};

const MealRow = (dataList, index) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="my-orders-table-item">
        <div className="table-text-section">
          <p>{moment(dataList.dataList?.date).format('YYYY/MM/DD')}</p>

          <p>
            {dataList.dataList?.mealType.from} -{' '}
            {dataList.dataList?.mealType.to}{' '}
          </p>

          <p>{dataList.dataList?.mealType.display}</p>

          <p>{dataList.dataList?.inventory}</p>

          <p>{dataList.dataList?.ordered}</p>

          <p>{dataList.dataList?.realized}</p>

          <div className={'rating'}>
            <StaticStars
              numOfRaters={dataList.dataList?.numOfRaters}
              rating={dataList.dataList?.rating}
              size={'small'}
              showNumOfRaters={true}
            />
          </div>
        </div>
        {/* <div className="table-btn-section more-img-icon">
          <img src={next} alt="next" />
        </div> */}
      </div>
    </>
  );
};

const PreviousMealRow = (dataList, index) => {
  const { t } = useTranslation();
  return (
    <div className="my-orders-table-item">
      <div className="table-text-section">
        <p>{moment(dataList.dataList?.date).format('YYYY/MM/DD')}</p>

        <p>
          {dataList.dataList?.mealType.from} - {dataList.dataList?.mealType.to}{' '}
        </p>

        <p>{dataList.dataList?.mealType.display}</p>

        <p>{dataList.dataList?.inventory}</p>

        <p>{dataList.dataList?.ordered}</p>

        <p>{dataList.dataList?.realized}</p>

        <div className={'rating'}>
          <StaticStars
            numOfRaters={dataList.dataList?.numOfRaters}
            rating={dataList.dataList?.rating}
            size={'small'}
            showNumOfRaters={true}
          />
        </div>
      </div>
      {/* <div className="table-btn-section more-img-icon">
        <img src={next} alt="next" />
      </div> */}
    </div>
  );
};

export const ReviewChart = (chartDataReview) => {
  let concatHtml = [];

  const d = chartDataReview?.chartDataReview?.xDate?.map((el, i) => {
    let f = chartDataReview?.chartDataReview?.yAvgDailyScore[i];
    concatHtml.push([el, f]);
  });
  // const data = [
  //   ['x', 'Chart'],
  //   [0, 0],
  //   [1, 10],
  //   [2, 23],
  //   [3, 17],
  //   [4, 18],
  //   [5, 9],
  //   [6, 11],
  //   [7, 27],
  // ];
  const data = [['x', 'Review'], ...concatHtml];
  const options = {
    hAxis: {
      //title: 'Average daily reviews score',
      title: '',
    },
    vAxis: {
      //title: 'Date',
      title: '',
    },
    series: {
      1: { curveType: 'function' },
    },
    legend: { position: 'bottom' },
  };
  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="300px"
      data={data}
      options={options}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  MealStatuses: state.app.enums.MealStatus,
  MealDishStatuses: state.app.enums.MealDishStatus,
});

const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
export default connect(mapStateToProps, mapDispatchToProps)(Lobby);

// import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// import {
//   setLayoutOptions,
//   useFetchingLoader,
//   useLoader,
// } from '../../store/app/actions';
// import http from '../../services/http';
// import dates from '../../services/dates';
// import { sleep } from '../../services/utils';
// import { ActiveButton, DisabledButton } from '../../components/WE-Button';

// /* Assets */
// import CardBackground from '../../assets/images/food_bg.png';
// import RestaurantIcon from '../../assets/images/restaurant.svg';
// import { PieChart } from '../Charts';

// const Lobby = ({
//   user,
//   kitchen,
//   MealTypes,
//   setLayoutOptions,
//   useLoader,
//   useFetchingLoader,
// }) => {
//   const { t } = useTranslation();
//   const history = useHistory();

//   useEffect(async () => await useLoader(() => sleep()), []);

//   const [nextMeal, setNextMeal] = useState();
//   const [nextMealToDefine, setNextMealToDefine] = useState();
//   const [nextMealToDefineDate, setNextMealToDefineDate] = useState();
//   const [nextMealPercent, setNextMealPercent] = useState(0);

//   const MealTypeMap = {};
//   MealTypes.forEach((meal) => (MealTypeMap[meal.id] = meal.value));

//   useEffect(async () => {
//     if (!kitchen) return;
//     setLayoutOptions({ title: t(''), showKitchen: true });

//     const { nextMeal, nextMealToDefine } = await useFetchingLoader(
//       async () => await http.get(`kitchens/${kitchen.id}/kitchenLobby`)
//     );
//     setNextMeal(nextMeal);
//     setNextMealToDefine(nextMealToDefine);
//     setNextMealPercent(
//       Math.round((nextMeal?.ordered / nextMeal?.inventory) * 100)
//     );

//     let d = nextMealToDefine?.date.split('T')[0]; // YYYY-MM-DD
//     setNextMealToDefineDate(d);
//   }, [kitchen]);

//   return (
//     <div className="lobby-kitchen">
//       <div className="lobby-cards">
//         <>
//           {
//             <LobbyCard
//               title={t('nextMeal')}
//               subTitle={
//                 nextMeal &&
//                 `${dates.format(nextMeal.date)} / ${
//                   MealTypeMap[nextMeal.mealTypeId]
//                 }`
//               }
//               mainContent={
//                 <>
//                   {nextMeal ? (
//                     nextMeal.inventory > 0 ? (
//                       <PieChart
//                         data={[
//                           ['Ordered', nextMealPercent],
//                           ['Inventory left', 100 - nextMealPercent],
//                         ]}
//                         size={{ w: 200, h: 200 }}
//                         colors={['#14cd31']}
//                       />
//                     ) : (
//                       <p>{t('noInventoryInMeal')}</p>
//                     )
//                   ) : (
//                     <p>{t('noNextMeal')}</p>
//                   )}
//                 </>
//               }
//               actionBtn={
//                 nextMeal ? (
//                   <ActiveButton
//                     label={t('mealReport')}
//                     additionalClassName={'lobby-button'}
//                     // cb={() =>
//                     //   nextMeal &&
//                     //   history.push(
//                     //     `mealReport/${dates.formatServer(nextMeal.date)}/${
//                     //       nextMeal.mealTypeId
//                     //     }`
//                     //   )
//                     // }
//                     cb={() => nextMeal && history.push(`mealManagement`)}
//                   />
//                 ) : (
//                   <DisabledButton label={t('mealReport')} />
//                 )
//               }
//             />
//           }
//         </>

//         <>
//           <LobbyCard
//             title={t('nextMealToDefine')}
//             subTitle={
//               nextMealToDefine &&
//               `${dates.format(nextMealToDefine.date)} / ${
//                 MealTypeMap[nextMealToDefine.mealTypeId]
//               }`
//             }
//             mainContent={
//               <img className={'content-icon'} src={RestaurantIcon} alt={''} />
//             }
//             actionBtn={
//               nextMealToDefine ? (
//                 <ActiveButton
//                   label={t('defineNewMeal')}
//                   additionalClassName={'lobby-button'}
//                   cb={() =>
//                     nextMealToDefine &&
//                     history.push(
//                       `menuConstruction/${dates.formatServer(
//                         nextMealToDefine.date
//                       )}/${nextMealToDefine.mealTypeId}`
//                     )
//                   }
//                 />
//               ) : (
//                 <DisabledButton label={t('defineNewMeal')} />
//               )
//             }
//           />
//         </>

//         <div className={'item action-blocks'}>
//           <a className={'block'} href={'/#/controlPanel'}>
//             {t('controlPanel')}
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// const LobbyCard = ({ title, subTitle, mainContent, actionBtn }) => {
//   return (
//     <div
//       className="item kitchen-lobby-card shadow-card"
//       style={{ backgroundImage: `url(${CardBackground})` }}
//     >
//       <h4 className="title">{title}</h4>
//       <p className="sub-title">{subTitle}</p>
//       <section className={'content'}>{mainContent}</section>
//       {actionBtn}
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   user: state.user,
//   kitchen: state.kitchens.current,
//   MealTypes: state.app.enums.MealType,
// });

// const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
// export default connect(mapStateToProps, mapDispatchToProps)(Lobby);
