import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import dates from '../../services/dates';
import { getAssetUrl } from '../../services/aws';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  getWalletAmountFromApi,
} from '../../store/app/actions';
import { StaticStars } from '../../components/RatingStars';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from '../../services/mediaQueries';

import enums from '../../services/enums';
import DishImage from './DishImage';
import { sleep } from '../../services/utils';
import ShowNextmealContainer from '../../screens/client/ShowNextmeal';
import ShowPreviousContainer from '../../screens/client/ShowPrevious';
import WEIcon from '../../components/WE-IconButton';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import { sortOrders } from '../../services/sortItems';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import cancel from '../../assets/images/close.svg';
import TableBtn from '../Newfolder/TableBtn';
import edit from '../../assets/icons/pencil.png';
import cancelImg from '../../assets/icons/cancel.png';
import voucher from '../../assets/icons/voucher.png';
import arrow from '../../assets/icons/angle-right-black.svg';
import reviewImg from '../../assets/icons/review.png';
import MyOrdersDetailsMobilePopup from '../Newfolder/MyOrdersDetailsMobilePopup';
import NextMealImage from '../../assets/images/Lobby-NextMeal.png';
import { ActiveButton, DisabledButton } from '../../components/WE-Button';
import { Button } from 'react-bootstrap';
import {
  getPaymentPreferences,
  getEmployeeLobbyData,
} from '../../store/kitchens/actions';
import cibusImg from '../../assets/icons/cibus.png';
import { Dropdown } from 'react-bootstrap';
import agGridLocales from '../../translations/ag-grid';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 35px',
  p: 6,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  minHeight: '300px',
};
// @ts-ignore
const MyMeals = ({
  user,
  kitchen,
  kitchens,
  MealTypes,
  OrderStatuses,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  languages,
  CurrentLanguage,
  getEmployeeLobbyData,
  emplobbydata,
  getWalletAmountFromApi,
}) => {
  const history = useHistory();
  const [orders, setOrders] = useState(null);
  const { t, i18n } = useTranslation();
  useEffect(async () => await useLoader(() => sleep()), []);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [orderId, setOrderId] = useState();
  const [viewOrderData, setViewOrderData] = useState();
  const [mobOderData, setMobOrderData] = useState();
  const [myOrdersMob, setMyOrdersMob] = useState(false);
  const handleMyOrdersMob = (id) => {
    let filterData = orders.filter((el) => el.id === id);
    setMobOrderData(filterData[0]);
    setMyOrdersMob(!myOrdersMob);
  };
  const [lastOrder, setLastOrder] = useState();
  const [mealType, setMealType] = useState(null);
  const [mealTypeId, setMealTypeId] = useState(null);
  const [date, setDate] = useState(null);
  const [allSlot, setAllSlot] = useState(null);
  const [mealId, setMealId] = useState(null);
  const [mealSlot, setMealSlot] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentKitchenId, setPaymentKitchenId] = useState();
  const [dummy, setDummy] = useState(false);
  const [isUpdate, setIsUpdate] = useState(true);
  const [isRefreshMeals, setIsRefreshMeals] = useState(false);
  const [showPrevious, setShowPrevious] = useState(false);
  // const [openSlot, setOpenSlot] = useState(false);
  // const [collected, setcollected] = useState(false);
  if (user?.roleId === 8) {
    let redirectTo = `/mealTime/`;
    history.push(redirectTo);
  }
  window.gtag('event', 'Client_Lobby', {
    title: 'client-lobby',
    client: window.location.host.split('.')[0],
  });

  const MealTypeMap = MealTypes.reduce((acc, mealType) => {
    return { ...acc, [mealType.id]: mealType.display };
  }, {});

  const [nextMeal, setNextMeal] = useState();

  // setLayoutOptions({ title: t('myMeals') });

  const OrderStatusesMap = OrderStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(() => {
    if (nextMeal && isUpdate) {
      setNextMeal((prev) => {
        const res = prev.map((el, i, arr) => {
          if (el?.order && el?.order?.length > 0) {
            const elem = el.order[el.order.length - 1];
            if (
              elem.orderStatusId === 1 ||
              (elem.orderStatusId === 2 &&
                elem.mealDish[elem.mealDish.length - 1].mealDish.meal.mealType
                  .multipleReservations === 1)
            ) {
              return el;
            }
          } else {
            return el;
          }
        });
        return res.filter((item) => item !== undefined).slice(0, 3);
      });
      setIsUpdate(false);
    }
  }, [nextMeal, isUpdate]);

  const getMealList = async () => {
    if (kitchen?.id == localStorage.getItem('kitchen')) {
      await getEmployeeLobbyData(
        localStorage.getItem('kitchen') ||
          kitchen?.id ||
          user?.lastUsedKitchenId
      );
      setIsUpdate(true);
    }
  };

  useEffect(async () => {
    await useFetchingLoader(async () => {
      setIsRefreshMeals(false);
      await getMealList();
      setIsRefreshMeals(true);
    });
  }, [kitchen?.id]);

  useEffect(async () => {
    if (!kitchen) return;
    if (emplobbydata?.length === 0 && isRefreshMeals) {
      await useFetchingLoader(async () => await getMealList());
    } else {
      let empData = [];
      if (
        kitchen?.id == localStorage.getItem('kitchen') /*&&
        emplobbydata.nextMeal.length !== 0 &&
        emplobbydata.nextMeal[0].kitchenId == localStorage.getItem('kitchen')*/
      ) {
        empData = emplobbydata;
      }
      let nextMeal = empData?.nextMeal || [];
      let lastOrder = [];
      let currentOrder = [];
      let orders = empData?.pastOrders || [];

      const filterNextMeals = nextMeal;

      let getCollectPoint = await useFetchingLoader(
        async () =>
          await http.get(`users/getCollectPoint`, {
            filter: { kitchenId: kitchen?.id, userId: user.id },
          })
      );
      orders = [...orders, ...getCollectPoint];
      const paymentdata = await getPaymentPreferences(kitchen?.id, '');
      setPaymentMethod(paymentdata?.selectedPaymentOptions);
      //setNextMeal(nextMeal);
      setNextMeal(nextMeal);
      setIsUpdate(true);
      setPaymentKitchenId(kitchen?.id);
      setLastOrder(lastOrder);

      setMealType(nextMeal?.mealTypeId);
      setMealId(nextMeal?.id);
      setDate(nextMeal?.date);
      setMealTypeId(nextMeal?.mealTypeId);
      setOrders(sortOrders(orders));
      setLayoutOptions({ title: t(''), showKitchen: true });
      setDummy(false);
    }
  }, [kitchen, dummy, emplobbydata]);

  const onUpdateNextMeal = (param) => {
    setNextMeal(param);
    setIsUpdate(true);
  };
  useEffect(async () => {
    let getAllSlot = await http.get(`mealSlots/getByMealId/${mealId}`);
    setAllSlot(getAllSlot);
  }, [mealId]);

  const selectMealSlot = async (slot) => {
    setMealSlot(slot);

    const getTotalReservations = await http.get(
      `mealSlots/getByMealSlotId/${slot}`
    );
    setAvailableSlot(getTotalReservations);
    if (getTotalReservations === 0) {
      setSlotBooked([...slotBooked, slot]);
      Dialog({
        title: `${t('maxReservationMessage')}`,
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('ok'),
        },
        onConfirm: () => {
          console.log('step2');
          // setCheck(false);
        },
      });
    }
  };
  const getAvailableMealTypes = async (date) => {
    const { data: meals } = await useFetchingLoader(
      async () =>
        await http.get(`meals`, {
          filter: {
            date,
            kitchenId: kitchen.id,
            mealStatusId: enums.getMap('MealStatus').active,
          },
        })
    );

    let futureMeals = getFutureMeals(date, MealTypes);
    const availableMealsTypes = MealTypes.map((mt) => {
      return {
        available:
          !!meals.find((m) => m.mealTypeId === mt.id) &&
          futureMeals.hasOwnProperty(mt.id),
        ...mt,
      };
    });

    let sortedAvailableMealTypes = availableMealsTypes
      .filter((m) => m.available)
      .sort((mt1, mt2) => (mt1.orderIndex > mt2.orderIndex ? 1 : -1));
    await setAvailableMealTypes(sortedAvailableMealTypes);

    if (sortedAvailableMealTypes.length === 1) {
      let id = sortedAvailableMealTypes[0].id;
      setMealType(id);
      let redirectTo = `/mealTime/${moment(date).format('YYYY-MM-DD')}/${id}`;
      history.push(redirectTo);
      return;
    }

    let redirectTo = `/mealTime/${moment(date).format('YYYY-MM-DD')}/${
      mealTypeId || ''
    }`;
    history.push(redirectTo);
  };

  /*const handelMealSlot = () => {
    setOpenSlot(false);
  };
  const handleClose = () => {
    setOpenSlot(false);
  };
  const handleSlot = () => {
    setOpenSlot(true);
  };*/

  const handleOrderCancel = async (orderId) => {
    setDummy(true);
    await useFetchingLoader(async () => await getMealList());
    setOrders((state) =>
      sortOrders(state.filter((order) => order.id !== orderId))
    );
  };
  const handlePopup = (id) => {
    let orderfilterData = orders.filter((el) => el.id === id);
    setViewOrderData(orderfilterData);
    setOrderId(id);
    setPaymentPopup(!paymentPopup);
  };
  useEffect(() => {}, [orders]);

  const nextMealClicked = ({ kitchenId, date, mealTypeId }) => {
    localStorage.setItem('date', date);
    localStorage.setItem('mealTypeId', mealTypeId);
    history.push({
      pathname: '/orderSummary',
      state: {
        date: date && date,
        mealType: mealTypeId && mealTypeId,
      },
    });
  };

  const getCollectPoints = async ({ kitchenId, date, mealTypeId, id }) => {
    const getMealTypeInfo = await http.post(`users/getMealTypeInfoById`, {
      mealTypeId: mealTypeId,
      kitchenId: kitchenId,
      date: date,
    });
    if (getMealTypeInfo?.data[0]?.creditPoints) {
      try {
        let errFlag = false;
        let errMess = '';
        Dialog({
          title: `${t('confirmCollectPointMessage')} ${
            getMealTypeInfo?.data[0]?.creditPoints
          } ${t('pointMsg')}`,
          type: 'success',
          buttons: {
            cancelButtonText: t('back'),
            confirmButtonText: t('ok'),
          },
          onConfirm: async () => {
            const getResponseInfo = await useFetchingLoader(async () => {
              try {
                return await http.post(`users/createCreditPoint`, {
                  mealTypeId: mealTypeId,
                  point: getMealTypeInfo?.data[0]?.creditPoints,
                  kitchenId: kitchenId,
                  date: date,
                  mealId: id,
                  paymentOptionId: paymentMethod,
                });
              } catch (err) {
                console.log('Error', err);
                errFlag = true;
                errMess = err;
              }
            });

            if (getResponseInfo?.data[0]['totalPoints'] === 0 && !errFlag) {
              Dialog({
                title: `${getMealTypeInfo?.data[0]?.creditPoints} ${t(
                  'successMealTypePointMessage'
                )}`,
                type: 'success',
                buttons: {
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: t('ok'),
                },
                onConfirm: async () => {
                  const params = await useFetchingLoader(
                    async () => await getMealList()
                  );
                  setNextMeal(params?.nextMeal);
                  setIsUpdate(true);
                  setLastOrder(params?.lastOrder);
                  getWalletAmountFromApi(user?.id);
                  // setDummy(true);
                },
              });
            } else {
              if (errFlag) {
                Dialog({
                  title:
                    errMess !== ''
                      ? errMess
                      : `${t('errorMealTypePointMessage')}`,
                  type: 'error',
                  buttons: {
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: t('ok'),
                  },
                  onConfirm: () => {
                    errMess !== '' ? {} : setDummy(true);
                  },
                });
              }
            }
          },
        });
      } catch (err) {
        SwalDialogs.Dialog({
          text: err,
          type: 'error',
          buttons: {
            showCancelButton: true,
          },
        });
      }
    } else {
      SwalDialogs.Dialog({
        title: `${t('alertNoMealsAbleMessage')}`,
        type: 'error',
        buttons: {
          showCancelButton: true,
          showConfirmButton: false,
        },
      });
    }
  };
  const deleteCP = async ({
    kitchenId,
    date,
    mealTypeId,
    getMealTypeInfo,
    id,
  }) => {
    try {
      const res = await http.delete(`users/deleteCreditPoint`, {
        mealTypeId: mealTypeId,
        point: getMealTypeInfo?.data[0]?.creditPoints,
        kitchenId: kitchenId,
        date: date,
        mealId: id,
      });
      return res;
    } catch (err) {
      return { error: err };
    }
  };
  const revertCollectPoints = async ({ kitchenId, date, mealTypeId, id }) => {
    const getMealTypeInfo = await http.post(`users/getMealTypeInfoById`, {
      mealTypeId: mealTypeId,
      kitchenId: kitchenId,
      date: date,
    });

    if (getMealTypeInfo?.data[0]?.creditPoints) {
      Dialog({
        title: `${t('revertCollectPointMessage')} ${
          getMealTypeInfo?.data[0]?.creditPoints
        } ${t('pointMsg')}`,
        type: 'success',
        buttons: {
          cancelButtonText: t('back'),
          confirmButtonText: t('ok'),
        },
        onConfirm: async () => {
          const getResponseInfo = await useFetchingLoader(
            async () =>
              await deleteCP({
                kitchenId,
                date,
                mealTypeId,
                getMealTypeInfo,
                id,
              })
          );
          if ('status' in getResponseInfo && getResponseInfo?.status === 200) {
            Dialog({
              title: `${getMealTypeInfo?.data[0]?.creditPoints} ${t(
                'successReturnMealTypePointMessage'
              )}`,
              type: 'success',
              buttons: {
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: t('ok'),
              },
              onConfirm: async () => {
                const params = await useFetchingLoader(
                  async () => await getMealList()
                );
                getWalletAmountFromApi(user?.id);
                setNextMeal(params?.nextMeal);
                setIsUpdate(true);
                setLastOrder(params?.lastOrder);
              },
            });
          }
          if ('error' in getResponseInfo) {
            Dialog({
              title: getResponseInfo.error,
              type: 'error',
              buttons: {
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: t('ok'),
              },
              onConfirm: () => {
                // setDummy(true);
              },
            });
          }
        },
      });
    } else {
      SwalDialogs.Dialog({
        title: `${t('alertNoMealsAbleMessage')}`,
        type: 'error',
        buttons: {
          showCancelButton: true,
          showConfirmButton: false,
        },
      });
    }
  };

  return (
    <div className="my-meals my-orders-page lobby-user">
      <div className="lobby-user-grid">
        <div className="lobby-user-left">
          <div className="table-title">
            <h6>
              <span>{t('nextLobbyMeals')} </span>
            </h6>
            {user.roleId !== 7 && (
              <Link className="btn btn-primary order-meal-btn" to="/mealTime">
                {t('orderMeal')}
                {/* <i class="fa fa-angle-right" aria-hidden="true"></i> */}
              </Link>
            )}
          </div>
          <div className="my-orders-table-section">
            <div className="my-orders-table">
              <div className="my-orders-table-body">
                {nextMeal && nextMeal.length !== 0 ? (
                  nextMeal.map((el) => {
                    if (el?.order)
                      return (
                        <div className="my-orders-table-body">
                          <ShowNextmealContainer
                            items={[
                              {
                                ...el,
                                date:
                                  el.order.orderMealDishes?.length > 0
                                    ? el.order?.orderMealDishes[
                                        el?.order?.orderMealDishes.length - 1
                                      ]?.mealDish?.meal?.date
                                    : [],
                              },
                            ]}
                            nextMeal={nextMeal}
                            assignMethod={(order, future) => (
                              <OrderCard
                                key={order.id}
                                isFuture={future}
                                kitchens={kitchens}
                                elements={order}
                                OrderStatusesMap={OrderStatusesMap}
                                MealTypes={MealTypes}
                                useFetchingLoader={useFetchingLoader}
                                handleOrderCancel={handleOrderCancel}
                                t={t}
                                handlePopup={handlePopup}
                                handleMyOrdersMob={handleMyOrdersMob}
                                nextMeal={nextMeal}
                                setNextMeal={onUpdateNextMeal}
                                setDummy={setDummy}
                                paymentMethod={paymentMethod}
                                paymentKitchenId={paymentKitchenId}
                                user={user}
                                getMealList={getMealList}
                                nextMealClicked={nextMealClicked}
                                nextMealClickedElem={el}
                                isNext={true}
                              />
                            )}
                            showPrevious={showPrevious}
                            showPreviousOnClick={async () =>
                              await useFetchingLoader(() =>
                                setShowPrevious(true)
                              )
                            }
                          />
                        </div>
                      );

                    return (
                      <>
                        <div className="my-orders-table-item lobby-order-row ">
                          <div className="table-text-section">
                            <p>
                              {dates.ddmmFormat(el?.date)}{' '}
                              {el?.mealType?.from
                                ? el?.mealType?.from + ' - ' + el?.mealType?.to
                                : ''}
                            </p>
                            <p> {MealTypeMap[el?.mealTypeId]}</p>
                            <p>
                              {
                                kitchens.find((k) => k.id === el.kitchenId)
                                  ?.name
                              }
                            </p>
                            <p></p>
                            <p></p>
                          </div>
                          <div className="table-btn-section">
                            {' '}
                            {el?.mealType?.creditPoints &&
                            el?.mealType?.creditPoints !== 0 ? (
                              <>
                                {user.roleId !== 7 && !el?.isOrdered ? (
                                  el?.collectPoint ? (
                                    el?.collectPoint?.status ? (
                                      <ActiveButton
                                        additionalClassName="lobby-button"
                                        label={t('collectedPointMsg')}
                                      />
                                    ) : (
                                      <ActiveButton
                                        additionalClassName="lobby-button"
                                        label={t('pending')}
                                        cb={() => revertCollectPoints(el)}
                                      />
                                    )
                                  ) : (
                                    <ActiveButton
                                      additionalClassName="lobby-button"
                                      label={t('collectPoints')}
                                      cb={() => getCollectPoints(el)}
                                    />
                                  )
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                            {el?.order ||
                            user?.roleId === 7 ||
                            el.collectPoint ? (
                              ''
                            ) : (
                              <ActiveButton
                                additionalClassName="lobby-button"
                                label={t('order')}
                                cb={() => nextMealClicked(el)}
                              />
                            )}
                            {el?.order &&
                              el?.order?.orderStatusId === 2 &&
                              el?.order?.orderMealDishes[
                                el?.order?.orderMealDishes.length - 1
                              ].mealDish.meal.mealType?.multipleReservations ===
                                1 &&
                              !el.collectPoint && (
                                <ActiveButton
                                  additionalClassName="lobby-button"
                                  label={t('order')}
                                  cb={() => nextMealClicked(el)}
                                />
                              )}
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <p>{t('noOrder')}</p>
                )}
              </div>
              {viewOrderData && viewOrderData.length > 0 && (
                <Modal
                  open={paymentPopup}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  maxWidth="lg"
                  className="add-employee-popup copymeal-popup  check-payment-popup meal-payment-popup"
                >
                  <Box sx={style}>
                    <div className="extras-listing-section">
                      <div className="close-btn">
                        <img src={cancel} alt="close" onClick={handlePopup} />
                      </div>
                      <h2>{t('paymentDetails')}</h2>
                      <div className={'extras-meal-management'}>
                        <div className="payment-details">
                          <p>{t('cardLastDigits')}:</p>
                          <h6>
                            {viewOrderData && viewOrderData[0]?.last4Digits}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('amountPaid')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.Amount}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('timeOfPayment')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0] &&
                              new Date(
                                viewOrderData[0]?.realizeDate
                              ).toLocaleString('he-IL')}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('transactionId')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.tranId}
                          </h6>
                        </div>
                      </div>
                      <div className="extras-button other-button">
                        <button onClick={handlePopup}>{t('ok')}</button>
                        {/* <button onClick={skipAddOrder}>{t('skip')}</button> */}
                      </div>
                    </div>
                  </Box>
                </Modal>
              )}
            </div>
            <MyOrdersDetailsMobilePopup
              myOrdersMob={myOrdersMob}
              handleMyOrdersMob={handleMyOrdersMob}
              handleVoucherModal={() =>
                history.push({
                  pathname: `/voucher/${mobOderData?.id}`,
                  state: { backText: t('returnToMyMeals') },
                })
              }
              mobOderData={mobOderData}
              OrderStatusesMap={OrderStatusesMap}
              MealTypes={MealTypes}
              setOrders={setOrders}
              t={t}
              paymentMethod={paymentMethod}
              user={user}
            />
          </div>
        </div>
        <div className="lobby-user-right">
          <div className="table-title">
            <h6>
              <span>{t('pastLobbyMeals')} </span>
            </h6>
          </div>
          <div className="my-orders-table-section">
            <div className="my-orders-table">
              {orders && orders.length ? (
                <div className="my-orders-table-body">
                  <ShowPreviousContainer
                    items={orders.map((order) => {
                      return {
                        ...order,
                        date:
                          order.orderMealDishes?.length > 0
                            ? order?.orderMealDishes[
                                order?.orderMealDishes.length - 1
                              ]?.mealDish?.meal?.date
                            : order?.point > 0
                            ? order?.meal?.date
                            : order?.orderMealDishes[
                                order?.orderMealDishes.length - 1
                              ]?.mealDish?.meal?.date,
                      };
                    })}
                    assignMethod={(order, future) => (
                      <PastMealOrderCard
                        key={order.id}
                        isFuture={future}
                        kitchens={kitchens}
                        order={order}
                        OrderStatusesMap={OrderStatusesMap}
                        MealTypes={MealTypes}
                        useFetchingLoader={useFetchingLoader}
                        handleOrderCancel={handleOrderCancel}
                        t={t}
                        handlePopup={handlePopup}
                        handleMyOrdersMob={handleMyOrdersMob}
                        paymentMethod={paymentMethod}
                        user={user}
                        getMealList={getMealList}
                      />
                    )}
                    showPrevious={showPrevious}
                    showPreviousOnClick={async () =>
                      await useFetchingLoader(() => setShowPrevious(true))
                    }
                  />
                </div>
              ) : (
                <div className="no-meals">
                  {nextMeal &&
                  Array.isArray(nextMeal) &&
                  nextMeal.find((element) => element?.order?.length !== 0) !==
                    undefined
                    ? t('noPastDates')
                    : t('noOrdersToDisplay')}
                </div>
              )}
              {viewOrderData && viewOrderData.length > 0 && (
                <Modal
                  open={paymentPopup}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  maxWidth="lg"
                  className="add-employee-popup copymeal-popup  check-payment-popup meal-payment-popup"
                >
                  <Box sx={style}>
                    <div className="extras-listing-section">
                      <div className="close-btn">
                        <img src={cancel} alt="close" onClick={handlePopup} />
                      </div>
                      <h2>{t('paymentDetails')}</h2>
                      <div className={'extras-meal-management'}>
                        <div className="payment-details">
                          <p>{t('cardLastDigits')}:</p>
                          <h6>
                            {viewOrderData && viewOrderData[0]?.last4Digits}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('amountPaid')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.Amount}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('timeOfPayment')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0] &&
                              new Date(
                                viewOrderData[0]?.realizeDate
                              ).toLocaleString('he-IL')}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('transactionId')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.tranId}
                          </h6>
                        </div>
                      </div>
                      <div className="extras-button other-button">
                        <button onClick={handlePopup}>{t('ok')}</button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              )}
            </div>
            <MyOrdersDetailsMobilePopup
              myOrdersMob={myOrdersMob}
              handleMyOrdersMob={handleMyOrdersMob}
              handleVoucherModal={() =>
                history.push({
                  pathname: `/voucher/${mobOderData?.id}`,
                  state: { backText: t('returnToMyMeals') },
                })
              }
              mobOderData={mobOderData}
              OrderStatusesMap={OrderStatusesMap}
              MealTypes={MealTypes}
              setOrders={setOrders}
              t={t}
              paymentMethod={paymentMethod}
              paymentKitchenId={paymentKitchenId}
              user={user}
              getMealList={getMealList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const NextMeal = ({
  nextMeal,
  MealTypeMap,
  handelMealSlot,
  handleSlot,
  handleClose,
  allSlot,
  mealSlot,
  getMealList,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const nextMealClicked = ({ kitchenId, date, mealTypeId }) => {
    localStorage.setItem('date', date);
    localStorage.setItem('mealTypeId', mealTypeId);
    history.push({
      pathname: '/orderSummary',
      state: {
        date: date && date,
        mealType: mealTypeId && mealTypeId,
      },
    });
    //  history.push(`/mealDishes/${date}/${mealTypeId}`);
  };

  const order = nextMeal?.order?.[0];

  let aa = nextMeal?.order?.[0]?.mealDish;
  let myArr = [];
  nextMeal?.order?.[0]?.mealDish.map((el) => myArr.push(el.mealDish.dish.id));
  // let editId = myArr?.join('-');
  let editId = myArr;
  //  console.log("kkkkkkkkkk",myArr)

  return (
    <div className="lobby-card shadow-card">
      <div
        className="card-img"
        style={{ backgroundImage: `url(${NextMealImage})` }}
      />
      <div className="details">
        <div className="title">{t('nextMeal')}</div>

        <>
          {nextMeal ? (
            <>
              <div className="date">
                {nextMeal
                  ? `${dates.format(nextMeal?.date)} / ${
                      MealTypeMap[nextMeal?.mealTypeId]
                    }`
                  : t('noInfo')}
              </div>
              {order && <p className="name">{order?.mealDish?.dish?.name}</p>}
            </>
          ) : (
            <DisabledButton label={t('noFutureMeal')} />
          )}
        </>
      </div>
      {nextMeal && (
        <>
          {order ? (
            order.orderStatusId == 1 ? (
              <ActiveButton
                additionalClassName="lobby-button"
                label={t('changeOrder')}
                cb={() =>
                  // history.push(
                  //   `/mealDishes/${nextMeal?.date}/${nextMeal?.mealTypeId}/${
                  //     editId && editId
                  //   }/${order?.id}${
                  //     order?.mealSlotId ? '/' + order?.mealSlotId : ''
                  //   }`
                  // )
                  history.push({
                    pathname: '/orderSummary',
                    state: {
                      date: nextMeal?.date && nextMeal?.date,
                      mealType: nextMeal?.mealTypeId && nextMeal?.mealTypeId,
                      slotId:
                        order?.[0]?.order?.mealSlotId &&
                        order?.[0]?.order?.mealSlotId,
                      dishIds: editId && editId,
                      orderId: order?.id && order?.id,
                    },
                  })
                }
              />
            ) : (
              ''
            )
          ) : (
            <>
              {allSlot && allSlot.length > 0 ? (
                <ActiveButton
                  additionalClassName="lobby-button"
                  label={t('toOrder')}
                  // cb={() => nextMealClicked(nextMeal)}
                  cb={() => handleSlot()}
                />
              ) : (
                <ActiveButton
                  additionalClassName="lobby-button"
                  label={t('toOrder')}
                  cb={() => nextMealClicked(nextMeal)}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
const nextMealClicked = ({ kitchenId, date, mealTypeId }) => {
  localStorage.setItem('date', date);
  localStorage.setItem('mealTypeId', mealTypeId);
  history.push({
    pathname: '/orderSummary',
    state: {
      date: date && date,
      mealType: mealTypeId && mealTypeId,
    },
  });
  //  history.push(`/mealDishes/${date}/${mealTypeId}`);
};

const OrderCard = ({
  elements,
  isFuture,
  kitchens,
  MealTypes,
  OrderStatusesMap,
  handleOrderCancel,
  useFetchingLoader,
  t,
  handlePopup,
  handleMyOrdersMob,
  nextMeal,
  setNextMeal,
  setDummy,
  paymentMethod,
  paymentKitchenId,
  user,
  getMealList,
  nextMealClicked,
  nextMealClickedElem,
}) => {
  const [cancellation, setCancellation] = useState();
  const { mealDish } = elements.order;
  let meal = {};
  if (mealDish?.length > 0) {
    meal =
      (elements.order &&
        elements.order?.mealDish &&
        elements.order?.orderMealDishes?.length > 0 &&
        elements.order?.orderMealDishes[elements.order?.orderMealDishes - 1]
          ?.mealDish?.meal) ??
      {};
  } else {
    meal = mealDish?.meal ?? {};
  }

  const { dish } = mealDish ?? {};

  const dishArray =
    elements.order &&
    elements.order.orderMealDishes &&
    elements.order.orderMealDishes.length > 0 &&
    elements.order.orderMealDishes;
  //const { meal, dish } = mealDish  ?? { meal: {}, dish:{}};
  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.display };
  }, {});
  let dishIds = [];
  if (dishArray?.length > 0) {
    const a = dishArray?.map((el) => {
      dishIds.push(el?.mealDish?.dish?.id);
    });
  }
  useEffect(() => {
    if (
      elements.order?.orderMealDishes.length > 0 &&
      elements.order?.orderMealDishes[
        elements?.order?.orderMealDishes.length - 1
      ]?.mealDish?.meal?.date === moment(new Date()).format('YYYY-MM-DD')
    ) {
      let now = elements?.mealType?.to;
      let hour = now.split(':')[0];
      let minute = now.split(':')[1];
      let dt = new Date(null, null, null, hour, minute);
      dt.setMinutes(dt.getMinutes() - elements?.mealType?.cancellationTime);

      if (moment(dt).format('HH:mm') < moment(new Date()).format('HH:mm')) {
        setCancellation(true);
      } else {
        setCancellation(false);
      }
    }
  }, [elements, cancellation]);
  return (
    <>
      <div className={`my-orders-table-item ${isFuture ? 'future-card' : ''}`}>
        <div className="table-text-section">
          <p>
            {dates.ddmmFormat(elements?.date)}{' '}
            {elements?.mealType?.from
              ? elements?.mealType?.from + ' - ' + elements?.mealType?.to
              : ''}
          </p>
          <p>{MealTypesMap[elements?.mealTypeId]}</p>
          {elements?.order.orderStatusId === 1 ? (
            <p>
              <ul>
                {dishArray && dishArray.length > 0 ? (
                  dishArray?.map((el, i) => {
                    return <li>{el?.mealDish?.dish?.name}</li>;
                  })
                ) : (
                  <li>{dish?.name}</li>
                )}
              </ul>
            </p>
          ) : (
            <p> </p>
          )}

          <p>
            {elements.order?.orderMealDishes
              ? kitchens.find(
                  (k) =>
                    k.id ===
                    elements.order?.orderMealDishes[
                      elements?.order?.orderMealDishes.length - 1
                    ]?.mealDish?.meal.kitchenId
                )?.name
              : ''}
          </p>
          {elements?.order?.orderStatusId === 1 ? (
            <p>
              {user?.contractors &&
              Boolean(user?.contractors.find((el) => el.isInvoice === 1))
                ? ''
                : paymentMethod == 5
                ? elements?.order?.orderPrice
                : '₪' + elements?.order?.orderPrice}
            </p>
          ) : (
            <p> </p>
          )}
        </div>

        <div className="table-btn-section">
          <OrderActionsNextMeal
            element={elements}
            OrderStatusesMap={OrderStatusesMap}
            MealTypes={MealTypes}
            useFetchingLoader={useFetchingLoader}
            handleOrderCancel={handleOrderCancel}
            handlePopup={handlePopup}
            meal={meal}
            dish={dish}
            dishArray={dishArray}
            dishIds={dishIds}
            kitchens={kitchens}
            nextMeal={nextMeal}
            setNextMeal={setNextMeal}
            setDummy={setDummy}
            paymentMethod={paymentMethod}
            paymentKitchenId={paymentKitchenId}
            user={user}
            getMealList={getMealList}
            nextMealClicked={nextMealClicked}
            nextMealClickedElem={nextMealClickedElem}
          />
        </div>
      </div>
    </>
  );
};

const PastMealOrderCard = ({
  order,
  isFuture,
  kitchens,
  MealTypes,
  OrderStatusesMap,
  handleOrderCancel,
  useFetchingLoader,
  t,
  handlePopup,
  handleMyOrdersMob,
  paymentMethod,
  user,
  getMealList = { getMealList },
}) => {
  const [cancellation, setCancellation] = useState();
  const { mealDish } = order;
  let meal = {};

  if (mealDish?.length > 0) {
    meal =
      (order &&
        order?.orderMealDishes &&
        order?.orderMealDishes?.length > 0 &&
        order?.orderMealDishes[order?.orderMealDishes.length - 1]?.mealDish
          ?.meal) ??
      {};
  } else {
    meal = mealDish?.meal ?? {};
  }

  const { dish } = mealDish ?? {};
  const dishArray =
    order &&
    order.orderMealDishes &&
    order.orderMealDishes.length > 0 &&
    order.orderMealDishes;
  //const { meal, dish } = mealDish  ?? { meal: {}, dish:{}};
  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.display };
  }, {});
  let dishIds = [];
  if (dishArray?.length > 0) {
    const a = dishArray?.map((el) => {
      dishIds.push(el?.mealDish?.dish?.id);
    });
  }

  useEffect(() => {
    /*if (
      order?.orderMealDishes[0]?.mealDish?.meal?.date ===
      moment(new Date()).format('YYYY-MM-DD')
    ) {
      console.log();
      let now = order?.orderMealDishes[0]?.mealDish?.meal?.mealType?.to;
      let hour = now.split(':')[0];
      let minute = now.split(':')[1];
      let dt = new Date(null, null, null, hour, minute);
      dt.setMinutes(
        dt.getMinutes() -
          order?.orderMealDishes[0]?.mealDish?.meal?.mealType?.cancellationTime
      );
      setCancellation(false);
      /*if (moment(dt).format('HH:mm') < moment(new Date()).format('HH:mm')) {
        setCancellation(true);
      } else {
        setCancellation(false);
      }
    }*/
    setCancellation(false);
  }, [order, cancellation]);
  return (
    <>
      {/* <div className={`my-orders-table-item ${isFuture ? 'future-card' : ''}`}> */}
      <div className="my-orders-table-item future-card">
        <div className="table-text-section">
          <p>
            {order?.point > 0
              ? dates.ddmmFormat(
                  order?.orderMealDishes[order?.orderMealDishes.length - 1]
                    .mealDish?.meal?.date
                ) +
                ' ' +
                order?.orderMealDishes[order?.orderMealDishes.length - 1]
                  .mealDish?.meal?.mealType.display
              : dates.ddmmFormat(
                  order?.orderMealDishes[order?.orderMealDishes.length - 1]
                    .mealDish?.meal?.date
                )}{' '}
            {/* {dates.ddmmFormat(meal?.date)}{' '} */}
            {order.mealSlot?.mealTypesSlot?.from
              ? order?.mealSlot?.mealTypesSlot?.from +
                ' - ' +
                order?.mealSlot?.mealTypesSlot?.to
              : ''}
          </p>
          <p>
            {
              MealTypesMap[
                order?.orderMealDishes[order?.orderMealDishes.length - 1]
                  .mealDish?.meal?.mealTypeId
              ]
            }
          </p>
          <p>
            <ul>
              {dishArray && dishArray.length > 0 ? (
                dishArray?.map((el, i) => <li>{el?.mealDish?.dish?.name}</li>)
              ) : (
                <li>
                  {order?.point > 0 ? t('pendingCollectedPoint') : dish?.name}
                </li>
              )}
            </ul>
          </p>

          {order?.point > 0 ? (
            <p> {order?.kitchen.name}</p>
          ) : (
            <p>
              {
                kitchens.find(
                  (k) =>
                    k.id ===
                    order?.orderMealDishes[order?.orderMealDishes.length - 1]
                      .mealDish?.meal?.kitchenId
                )?.name
              }
            </p>
          )}

          <p>
            {user?.contractors &&
            user?.contractors.length !== 0 &&
            Boolean(user?.contractors.find((el) => el.isInvoice === 1)) ? (
              ''
            ) : order?.point > 0 ? (
              <p>{order?.point}</p>
            ) : paymentMethod == 5 ? (
              order?.orderPrice
            ) : (
              '₪' + order?.orderPrice
            )}
          </p>
        </div>
        <div className="table-btn-section">
          <OrderActions
            order={order}
            OrderStatusesMap={OrderStatusesMap}
            MealTypes={MealTypes}
            useFetchingLoader={useFetchingLoader}
            handleOrderCancel={handleOrderCancel}
            handlePopup={handlePopup}
            meal={meal}
            dish={dish}
            dishArray={dishArray}
            dishIds={dishIds}
            paymentMethod={paymentMethod}
            getMealList={getMealList}
            user={user}
            kitchens={kitchens}
          />
        </div>
      </div>
    </>
  );
};

const OrderActions = ({
  order,
  OrderStatusesMap,
  MealTypes,
  handleOrderCancel,
  useFetchingLoader,
  handlePopup,
  meal,
  dish,
  dishArray,
  dishIds,
  cancellation,
  paymentMethod,
  getMealList,
  user,
  kitchens,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type };
  }, {});
  const isOrder = (order, value) => order?.orderStatusId === value;

  const isFutureMeal = (order) => {
    let { date, mealType } = (order?.mealDish?.meal ||
      (order &&
        order.mealDish &&
        order.mealDish.length > 0 &&
        order.mealDish[0].mealDish.meal)) ?? [{}, {}];
    let lastTimeInMeal = MealTypesMap[mealType?.id]?.to;
    let lastTimeToRealizeOrder = moment([date, lastTimeInMeal].join(' '));
    let now = moment();

    return lastTimeToRealizeOrder > now;
  };

  const reload = async () => {
    await useFetchingLoader(async () => await getMealList());
  };

  const handleCancelOrder = async () => {
    try {
      // await useFetchingLoader(
      //   async () => await http.put(`orders/${order.id}/cancel`)
      // );
      const getCancelOrder = await useFetchingLoader(
        async () => await http.put(`orders/${order.id}/cancel`)
      );
      if (getCancelOrder?.orderStatusId == 3) {
        SwalDialogs.Dialog({
          title: t('orderWasCanceled'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          closeBtnStyle: {},
          onConfirm: reload,
        });
        //  handleOrderCancel(order.id);
      }
    } catch (err) {
      SwalDialogs.Dialog({
        title: 'An error has occurred',
        text: err,
        type: 'error',
        buttons: {
          showCancelButton: false,
          showConfirmButton: false,
        },
      });
    }
  };

  const cancelOrderDialog = () => {
    return SwalDialogs.Dialog({
      title: t('cancelOrderTitle'),
      text: ` ${t('cancelOrderText')} ${order?.orderMealDishes.map(
        (el) => el.mealDish?.dish?.name
      )} ${' \n'} ${t('cancelOrderTextin')} ${
        order?.orderMealDishes[order?.orderMealDishes.length - 1]?.mealDish
          ?.meal?.date
      } ${t('cancelOrderTextin')} ${
        kitchens.find(
          (k) =>
            k.id ===
            order?.orderMealDishes[order?.orderMealDishes.length - 1].mealDish
              ?.meal?.kitchenId
        )?.name
      } `,
      buttons: {
        confirmButtonText: t('cancelOrderBtnLabel'),
        cancelButtonText: t('back'),
      },
      closeBtnStyle: {},
      onConfirm: handleCancelOrder,
    });
  };
  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }
  return (
    <>
      {isOrder(order, OrderStatusesMap.realized) ? (
        <>
          <div
            className={'rating'}
            onClick={() => {
              history.push({
                pathname: `/addReview/${order?.id}`,
                state: { backText: t('returnToMyMeals') },
              });
            }}
          >
            {!order?.rating ? (
              <Button>{t('review')} </Button>
            ) : (
              <StaticStars
                rating={order?.rating || -1}
                numOfRaters={
                  order?.orderMealDishes[order?.orderMealDishes.length - 1]
                    ?.mealDish?.dish?.numOfRaters
                }
                showNumOfRaters={false}
              />
            )}
          </div>
        </>
      ) : isFutureMeal(order) ? (
        isOrder(order, OrderStatusesMap.active) ? (
          <>
            <div className="lobyy-action-dd">
              <img
                src={voucher}
                className="voucher-img"
                onClick={() =>
                  history.push({
                    pathname: `/voucher/${order?.id}`,
                    state: { backText: t('returnToMyMeals') },
                  })
                }
              />
              {!order.reported && (
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i class="las la-ellipsis-v"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {user?.roleId === 6 ||
                    user?.roleId === 7 ||
                    user?.roleId === 8 ? (
                      ''
                    ) : paymentMethod === 6 ? (
                      paymentMethod === 6 && order.paymentStatus === false ? (
                        <Dropdown.Item onClick={() => cibusPayment()}>
                          <img src={cibusImg} className="cibus-img" />
                          {t('payment')}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item>
                          <img src={cibusImg} className="cibus-img" />
                          {t('paid')}
                        </Dropdown.Item>
                      )
                    ) : (
                      ''
                    )}

                    {paymentMethod === 6 && order.paymentStatus === true ? (
                      ''
                    ) : (
                      <Dropdown.Item
                        btnName={t('edit')}
                        onClick={() =>
                          history.push({
                            pathname: `/orderSummary`,
                            state: {
                              date: order?.orderMealDishes[
                                order?.orderMealDishes.length - 1
                              ]?.mealDish?.meal?.date,
                              mealType:
                                order?.orderMealDishes[
                                  order?.orderMealDishes.length - 1
                                ]?.mealDish?.meal?.mealTypeId,
                              slotId: order?.mealSlotId,
                              orderId: order.id,
                              dishId:
                                order?.orderMealDishes[
                                  order?.orderMealDishes.length - 1
                                ]?.mealDish?.dishId,
                              dishIds: dishIds,
                              backLink: '/myMeals',
                              backText: t('returnToMyMeals'),
                            },
                          })
                        }
                      >
                        <img src={edit} className="cibus-img" /> {t('edit')}
                      </Dropdown.Item>
                    )}

                    {cancellation === true ? (
                      <Dropdown.Item
                        img={cancelImg}
                        btnName={t('cancel')}
                        disabled
                        onClick={cancelOrderDialog}
                      >
                        <img src={cancelImg} className="cibus-img" />{' '}
                        {t('cancel')}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        img={cancelImg}
                        btnName={t('cancel')}
                        onClick={cancelOrderDialog}
                      >
                        <img src={cancelImg} className="cibus-img" />{' '}
                        {t('cancel')}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </>
        ) : (
          <p className={'position left'}>{t('orderNotActive')}</p>
        )
      ) : /* Past Orders */

      order.point > 0 ? (
        <>
          <p
            className={
              order.status ? 'order-status success' : 'order-status pending'
            }
          >
            {order.status ? t('success') : t('pending')}
          </p>
        </>
      ) : (
        <p className={'single-content'}>{t('orderNotRealized')}</p>
      )}
    </>
  );
};

const OrderActionsNextMeal = ({
  element,
  OrderStatusesMap,
  MealTypes,
  handleOrderCancel,
  useFetchingLoader,
  handlePopup,
  meal,
  dish,
  dishArray,
  dishIds,
  setNextMeal,
  nextMeal,
  cancellation,
  setDummy,
  paymentMethod,
  paymentKitchenId,
  user,
  getMealList,
  nextMealClicked,
  nextMealClickedElem,
  kitchens,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type };
  }, {});
  const { order } = element;
  console.log(
    'Order',
    order.orderMealDishes[order?.orderMealDishes.length - 1].mealDish.dishId
  );
  const isOrder = (order, value) => order?.orderStatusId === value;

  const isFutureMeal = (element) => {
    let { date, mealType } = element;
    let lastTimeInMeal = mealType?.to;
    let lastTimeToRealizeOrder = moment([date, lastTimeInMeal].join(' '));
    let now = moment();
    return lastTimeToRealizeOrder > now;
  };
  const reload = async () => {
    await useFetchingLoader(async () => await getMealList());
  };

  const handleCancelOrder = async () => {
    try {
      const getCancelOrder = await useFetchingLoader(
        async () => await http.put(`orders/${order.id}/cancel`)
      );
      if (getCancelOrder?.orderStatusId == 3) {
        SwalDialogs.Dialog({
          title: t('orderWasCanceled'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          closeBtnStyle: {},
          onConfirm: reload,
        });
      }
    } catch (err) {
      SwalDialogs.Dialog({
        title: 'An error has occurred',
        text: err,
        type: 'error',
        buttons: {
          showCancelButton: false,
          showConfirmButton: false,
        },
      });
    }
  };
  const cancelOrderDialog = () =>
    SwalDialogs.Dialog({
      title: t('cancelOrderTitle'),
      text: ` ${t('cancelOrderText')} ${order?.orderMealDishes?.map(
        (el) => el.mealDish?.dish?.name
      )} ${' \n'} ${t('cancelOrderTextin')} ${
        order?.orderMealDishes[order?.orderMealDishes.length - 1]?.mealDish.meal
          ?.date
      } ${t('cancelOrderTextin')} ${
        kitchens.find(
          (k) =>
            k.id ===
            order?.orderMealDishes[order?.orderMealDishes.length - 1].mealDish
              ?.meal?.kitchenId
        )?.name
      } `,
      buttons: {
        confirmButtonText: t('cancelOrderBtnLabel'),
        cancelButtonText: t('back'),
      },
      closeBtnStyle: {},
      onConfirm: handleCancelOrder,
    });

  const cibusPayment = () => {
    SwalDialogs.Dialog({
      title: t('cibusPayment'),
      text: t('cibusPaymentAlert'),
      buttons: {
        confirmButtonText: t('confirm'),
        cancelButtonText: t('back'),
      },
      closeBtnStyle: {},
      onConfirm: handleCibusPayment,
    });
  };

  const handleCibusPayment = async () => {
    const res = await http.post(`users/userPaymentByCibus`, {
      orderId: order.id,
      price: order.orderPrice,
      paymentOptionId: paymentMethod,
      kitchenId: paymentKitchenId,
    });
    window.location.href = res.url;
  };

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }
  return order.orderStatusId === 2 &&
    order.orderMealDishes[order?.orderMealDishes.length - 1].mealDish.meal
      .mealType.multipleReservations === 1 ? (
    <div className="lobby-order-row">
      <div className="table-btn-section">
        <ActiveButton
          additionalClassName="lobby-button"
          label={t('order')}
          cb={() => nextMealClicked(nextMealClickedElem)}
        />
      </div>
    </div>
  ) : (
    <>
      {isOrder(order, OrderStatusesMap.realized) ? (
        <>
          <p class="user-wallet">
            <h6>{t('orderRealized')}</h6>
          </p>
        </>
      ) : isFutureMeal(element) ? (
        isOrder(order, OrderStatusesMap.active) ? (
          <>
            <div className="lobyy-action-dd">
              <img
                src={voucher}
                className="voucher-img"
                onClick={() =>
                  history.push({
                    pathname: `/voucher/${order?.id}`,
                    state: { backText: t('returnToMyMeals') },
                  })
                }
              />

              {!order.reported && (
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i class="las la-ellipsis-v"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {user?.roleId === 6 ||
                    user.roleId === 7 ||
                    user?.roleId === 8 ? (
                      ''
                    ) : paymentMethod === 6 ? (
                      paymentMethod === 6 && order.paymentStatus === false ? (
                        <Dropdown.Item onClick={() => cibusPayment()}>
                          <img src={cibusImg} className="cibus-img" />
                          {t('payment')}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item>
                          <img src={cibusImg} className="cibus-img" />
                          {t('paid')}
                        </Dropdown.Item>
                      )
                    ) : (
                      ''
                    )}

                    {paymentMethod === 6 && order.paymentStatus === true
                      ? ''
                      : !order?.reported && (
                          <Dropdown.Item
                            btnName={t('edit')}
                            onClick={() =>
                              history.push({
                                pathname: `/orderSummary`,
                                state: {
                                  date: order.orderMealDishes[
                                    order?.orderMealDishes.length - 1
                                  ].mealDish.meal.date,
                                  mealType:
                                    order.orderMealDishes[
                                      order?.orderMealDishes.length - 1
                                    ].mealDish.meal.mealTypeId,
                                  slotId: order?.mealSlotId,
                                  orderId: order.id,
                                  dishId:
                                    order.orderMealDishes[
                                      order?.orderMealDishes.length - 1
                                    ].mealDish.dishId,
                                  dishIds: dishIds,
                                  backLink: '/myMeals',
                                  backText: t('returnToMyMeals'),
                                },
                              })
                            }
                          >
                            <img src={edit} className="cibus-img" /> {t('edit')}
                          </Dropdown.Item>
                        )}

                    {cancellation === true
                      ? !order?.reported && (
                          <Dropdown.Item
                            img={cancelImg}
                            btnName={t('cancel')}
                            disabled
                            onClick={cancelOrderDialog}
                          >
                            <img src={cancelImg} className="cibus-img" />{' '}
                            {t('cancel')}
                          </Dropdown.Item>
                        )
                      : !order?.reported && (
                          <Dropdown.Item
                            img={cancelImg}
                            btnName={t('cancel')}
                            onClick={cancelOrderDialog}
                          >
                            <img src={cancelImg} className="cibus-img" />{' '}
                            {t('cancel')}
                          </Dropdown.Item>
                        )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </>
        ) : (
          <p className={'position left'}>{t('orderNotActive')}</p>
        )
      ) : (
        <p className={'single-content'}>{t('orderNotRealized')}</p>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens.options,
  MealTypes: state.app.enums.MealType,
  OrderStatuses: state.app.enums.OrderStatus,
  emplobbydata: state.kitchens.emplobbydata,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getEmployeeLobbyData,
  getWalletAmountFromApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMeals);
