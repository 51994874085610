//import { useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import { setLayoutOptions, useFetchingLoader } from '../../store/app/actions';
import { useHistory } from 'react-router-dom';
import SignupForm from '../../components/Forms/SignupForm';
import { update } from '../../store/user/actions';
import { showAlert } from '../../store/app/actions';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Alert } from '../../components/Dialogs/SwalDialogs';
/*import WEBackButton from '../../components/WE-BackButton'; */
// import { ActiveButton } from '../../components/WE-Button';
import { useEffect, useState } from 'react';
import { getUser } from '../../store/user/actions';
import http from '../../services/http';
//import Swal from 'sweetalert2';
// import BackLink from '../../components/BackLink';
import { Modal, Box } from '@material-ui/core';
import ChangePassword from './ChangePassword';
import cancel from '../../assets/images/close.svg';
import ProfileLayout from '../profile/profileLayout';
import { Button } from 'react-bootstrap';

/* @ts-ignore */

// @ts-ignore
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  height: 'auto',
};
//const currentValidator = (value) => value !== '';

const GetInputField = ({ value, placeholder, cb, type, label, name }) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        // disabled={!editMode}
        type={type}
      />
    </>
  );
};

const Profile = ({
  user,
  setLayoutOptions,
  //update,
  useFetchingLoader,
  getPaymentPreferences,
  kitchen,
  getUser,
}) => {
  const [paymentMethod, setPaymentMethod] = useState();
  const [userKitchen, setUserKitchen] = useState();
  const [userRoleId, setUserRoleId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [paymentType, setPaymentType] = useState();
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [curentPassword, setCurentPassword] = useState('');
  const [proflieData, setProflieData] = useState<any>();
  const [receiveConfirmations, setReceiveConfirmations] = useState();
  setLayoutOptions({ title: t('userProfile'), showKitchen: false });
  useEffect(() => {
    getUser();
  }, []);
  useEffect(() => {
    const getData = async () => {
      let idd = user.id;
      const res = await http.get(`users/getUserKitchen/${idd}`);
      setUserKitchen(res.KitchenId);
      setUserRoleId(res.roleId);
      if (res.roleId === 4) {
        const data = await getPaymentPreferences(kitchen.id, '');
        setPaymentType(data.paymentType);
        setPaymentMethod(data.selectedPaymentOptions);
      }
    };
    getData();
  }, [user]);

  const handleChange = (name, value) => {
    console.log('hhhhh', name);
    setCurentPassword(value);
  };

  const handleSubmit = async (form) => {
    setProflieData(form);
    if (
      form.roleId &&
      (form.roleId === 1 || form.roleId === 2 || form.roleId === 7)
    ) {
      setCurentPassword('');
      handleProflieData(form);
    } else {
      setOpenPasswordModal(true);
      setProflieData(form);
      setCurentPassword('');
    }
  };

  const handleProflieData = async (proflieData) => {
    try {
      if (
        curentPassword ||
        proflieData.roleId === 1 ||
        proflieData.roleId === 2 ||
        proflieData.roleId === 7
      ) {
        let details = {
          firstName: proflieData?.firstName,
          lastName: proflieData?.lastName,
          email: proflieData?.email,
          phone: proflieData?.phoneNumber || proflieData?.phone,
          currentPassword: curentPassword,
          receiveConfirmations: receiveConfirmations,
        };
        // await useFetchingLoader(
        //   async () => await update(details, true, true)
        // );

        await useFetchingLoader(async () => await http.put(`users`, details));

        SwalDialogs.Dialog({
          title: t('profileUpdated'),
          type: 'success',
          onConfirm: () => {
            setOpenPasswordModal(false);
            getUser();
          },
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
        });
      } else {
        Alert({ error: t('enterNonEmptyPassword') });
      }
    } catch (err) {
      Alert({ error: err });
    }
    // SwalDialogs.DialogWithInput({
    //   title: t('currentPasswordError'),
    //   text: t('currentPasswordError'),
    //   buttons: {
    //     confirmButtonText: t('send'),
    //     cancelButtonText: t('back'),
    //   },
    //   onPreConfirm: async (currentPassword) => {
    //     let details = { ...form, currentPassword };
    //     if (!currentValidator(currentPassword)) {
    //       Swal.showValidationMessage(t('currentPasswordError'));
    //       return;
    //     }

    //   },
    //   onConfirm: async () => {},
    // });
  };

  // const useOutsideAlerter = (ref) => {
  //   useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       // if (ref.current && !ref.current.contains(event.target)) {
  //       //   setOpenDropdown(false);
  //       // }
  //     };
  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside);
  //     };
  //   }, [ref]);
  // };
  // const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);
  // console.log('userKitchen=>', { userKitchen, userRoleId, paymentMethod });
  // const handleDropdownClick = () => {
  //   setOpenDropdown(!openDropdown);
  // };
  const handlePassword = () => {
    setOpenModal(!openModal);
  };
  const handleClosePassword = () => {
    setCurentPassword('');
    setOpenPasswordModal(!openPasswordModal);
  };

  // const handlePayment = async (type) => {
  //   // call kitchen payment method  data
  //   if (type === 'hyp') {
  //     const data = await getPaymentPreferences(kitchen.id, type);

  //     setPaymentMethod(data.selectedPaymentOptions);
  //     history.push(`/paymentManagement?paymenttype=hyp`);
  //   }
  //   if (type === 'nayax') {
  //     const data = await getPaymentPreferences(kitchen.id, type);

  //     setPaymentMethod(data.selectedPaymentOptions);
  //     history.push(`/paymentManagement?paymenttype=nayax`);
  //   }
  // };

  // const useOutsideAlerter = (ref) => {
  //   useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         setOpenDropdown(false);
  //       }
  //     };
  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside);
  //     };
  //   }, [ref]);
  // };
  // const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);

  // password changes function

  const handlePasswordChange = async (form: any) => {
    let res;
    try {
      res = await http.put(`users/changePassword`, {
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
      });

      if (res.errorMessage === 'passwordPolicy') {
        SwalDialogs.Dialog({
          title: t('inCorrectPassword'),
          text:
            t('character') +
            ' ' +
            res.errorLetter +
            ' ' +
            t('isNotVaild') +
            ' ' +
            t('passwordSecurity'),
          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('passwordUpdatedConfirmButtonText'),
          },
        });
      } else {
        SwalDialogs.Dialog({
          title: t('passwordUpdatedTitle'),
          text: t('passwordUpdatedText'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('passwordUpdatedConfirmButtonText'),
          },
          onConfirm: () => history.push(`/login`),
        });
      }
      // SwalDialogs.Dialog({
      //   title: t('passwordUpdatedTitle'),
      //   type: 'success',
      //   buttons: {
      //     showCancelButton: false,
      //     confirmButtonText: t('passwordUpdatedConfirmButtonText'),
      //   },
      //   onConfirm: () => setOpenModal(!openModal),
      // });
    } catch (err) {
      // console.log('err =>', err, res);
      Alert({ error: err });
    }
  };

  return (
    <ProfileLayout>
      <div className="employee-payment-management">
        <div>
          {/* <div className="back-button-remove">
          <WEBackButton />
        </div> */}
          {/* <BackLink /> */}
          <div className="page-heading">
            <h3>{t('accountSettings')}</h3>
            {userRoleId === 4 && userKitchen && paymentMethod === 3 ? (
              <Button
                onClick={() =>
                  history.push(`/paymentManagement?type=${paymentType}`)
                }
              >
                {t('paymentManagementButton')}
              </Button>
            ) : (
              ''
            )}
          </div>
          {/* {userRoleId === 4 && userKitchen && paymentMethod === 3 ? (
          <ActiveButton
            arrow={true}
            label={t('paymentManagementButton')}
            cb={() => history.push(`/paymentManagement`)}
          />
        ) : (
          ''
        )} */}

          {/* {userRoleId === 3 ? (
          <div className="custom-dropdown">
            <div className="dropdown-menu" onClick={handleDropdownClick}>
              {t('paymentManagementButton')}
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </div>
            {openDropdown && (
              <div className="dropdwon-list" ref={wrapperRef}>
                <div className="dropdown-item"> */}
          {/* {userRoleId === 4 && userKitchen && paymentMethod === 3 ? (
                  <p
                    className={'label'}
                    onClick={() =>handlePayment('nayax')}
                  >
                    Nayax
                  </p>
                ) : (
                  ''
                )} */}
          {/* <p className={'label'} onClick={() => handlePayment('nayax')}>
                    Nayax
                  </p>
                </div>
                <div className="dropdown-item">
                  <p className={'label'} onClick={() => handlePayment('hyp')}>
                    Hyp (Caspit)
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          ''
        )} */}
        </div>
        <div className="profile">
          <SignupForm
            onSubmit={handleSubmit}
            initialValues={{ ...user }}
            handlePassword={handlePassword}
            setReceiveConfirmations={setReceiveConfirmations}
          />
        </div>
        <Modal
          open={openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="add-employee-popup copymeal-popup show-extra-popup change-password-popup"
        >
          <Box sx={style}>
            <div className="close-btn">
              <img src={cancel} alt="close" onClick={handlePassword} />
            </div>
            <div className="change-password-form">
              <ChangePassword
                onSubmit={handlePasswordChange}
                initialValues={{ ...user }}
              />
            </div>
          </Box>
        </Modal>
        <Modal
          open={openPasswordModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="add-employee-popup  change-password-popup new-current-password"
        >
          <Box sx={style}>
            <div className="user-popup-heading">
              <h6>{t('currentPasswordError')}</h6>
              <div className="close-button">
                <img src={cancel} alt="close" onClick={handleClosePassword} />
              </div>
            </div>
            <form>
              <div className="change-password-form">
                <div className="input-field">
                  <GetInputField
                    placeholder={t('currentPasswordError')}
                    label={t('currentPasswordError')}
                    name="currentPassword"
                    type="password"
                    value={curentPassword}
                    cb={(value) => {
                      handleChange('currentPassword', value);
                    }}
                  />

                  <div className="add-employee-btn">
                    <Button onClick={handleClosePassword}>
                      {t('cancelOrder')}
                    </Button>
                    <Button onClick={() => handleProflieData(proflieData)}>
                      {t('confirm')}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </ProfileLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  cibusEnabled: state.companyConfig.paymentsSettings.cibusEnabled,
});

const mapDispatchToProps = {
  setLayoutOptions,
  update,
  showAlert,
  getUser,
  useFetchingLoader,
  getPaymentPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
