// Link to swal: https://sweetalert2.github.io/
import i18n from '../../services/i18n';
import React from 'react';
import Swal from 'sweetalert2';
import successIcon from '../../assets/images/icons/success.svg';

// Configs

let lang = localStorage.getItem('languageId') || 1;

let confirmText = '';
let cancelText = '';

if (lang == 1) {
  confirmText = 'אישור';
  cancelText = 'ביטול';
}
if (lang == 2) {
  confirmText = 'Confirm';
  cancelText = 'Cancel';
}
if (lang == 3) {
  confirmText = 'Подтвердить';
  cancelText = 'Отмена';
}

const btns = {
  reverseButtons: true,
  showCancelButton: true,
  confirmButtonColor: '#14cd31',
  confirmButtonText: confirmText,
  cancelButtonColor: '',
  cancelButtonText: cancelText,
};

const Dialog = ({
  title,
  text = '',
  type = '',
  buttons,
  onConfirm = () => {},
  onCancel = () => {},
  confirmButtonText = confirmText,
}) => {
  buttons = { ...btns, ...buttons };
  let dd = type === 'success' ? `<img src=${successIcon}>` : '';
  Swal.fire({
    title,
    text,
    iconHtml: dd,
    icon: type,
    ...buttons,
    confirmButtonText,
    customClass: {
      container: 'sw_payment_popup',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    } else {
      onCancel();
    }
  });
};

const DialogLogin = ({
  title,
  text = '',
  type = '',
  buttons,
  onConfirm = () => {},
  confirmButtonText = i18n.t('ok'),
}) => {
  buttons = { ...btns, ...buttons };

  Swal.fire({
    title,
    text,
    icon: 'warning',
    ...buttons,
    confirmButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    }
  });
};
const DialogWithInput = ({
  title,
  text = '',
  buttons,
  onPreConfirm,
  onConfirm,
  type = 'password',
}) => {
  buttons = { ...btns, ...buttons };

  Swal.fire({
    title,
    text,
    ...buttons,
    input: type,
    inputAttributes: {
      autocapitalize: 'off',
    },
    showCancelButton: true,
    preConfirm: onPreConfirm,
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm(result);
    }
  });
};

const Alert = ({ error, buttons = {} }) => {
  let lang = localStorage.getItem('languageId');
  let isProduction = false;
  let alertText =
    isProduction && !error.forceDisplay
      ? i18n.t('genericError')
      : error.message || error;
  alertText = error.content || alertText;
  buttons = { ...btns, showCancelButton: false, ...buttons };
  Swal.fire({
    text: alertText,
    icon: 'warning',
    ...buttons,
    textAlign: 'center !importnat',
  });
};

const paymentPop = ({
  title,
  text = '',
  type = '',
  buttons,
  onConfirm = () => {},
  confirmButtonText = i18n.t('ok'),
}) => {
  buttons = { ...btns, ...buttons };

  Swal.fire({
    title,
    text,
    icon: type,
    ...buttons,
    confirmButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    }
  });
};

const SuccessDailog = ({ title, type, text }) => {
  Swal.fire(title, text, type);
};
export {
  Alert,
  Dialog,
  DialogWithInput,
  SuccessDailog,
  paymentPop,
  DialogLogin,
};
