import React, { useEffect, useState, useRef } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  pastMealsData,
  allPastMealsData,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../services/mediaQueries';

import { StaticStars } from '../../components/RatingStars';
import { ActiveButton } from '../../components/WE-Button';
import WEIcon from '../../components/WE-IconButton';
import DishImage from '../client/DishImage';
import { useHistory } from 'react-router';
import enums from '../../services/enums';
import dates from '../../services/dates';
import { sleep } from '../../services/utils';
import ShowPreviousContainer from '../../components/ShowPrevious';
import moment from 'moment';
import { sortMeals, sortPastMeals } from '../../services/sortItems';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import WECheckbox from '../../components/Forms/WE-CheckBox';
import MealSummary from '../../components/Kitchen/MealSummary';
import WEToggle from '../../components/WE-Toggle';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ViewPastMealDetail from './ViewPastMealDetail';
import next from '../../assets/images/next.png';
import agGridLocales from '../../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';
import search from '../../assets/icons/search.png';
import DatePicker from 'react-datepicker';
import file from '../../assets/images/file-export.svg';
import ReactExport from 'react-export-excel';
import Select from 'react-select';
import WESpinner from '../../../src/components/WE-Spinner';
const PastMeals = ({
  setLayoutOptions,
  user,
  kitchen,
  MealTypes,
  MealStatuses,
  useLoader,
  useFetchingLoader,
  MealDishStatuses,
  currentLanguage,
  pastMealsData,
  allPastMealsData,
  meals,
  pastmealsToDisplay,
  pastMealsTotal,
  showFetchingLoader,
  allpastmealsToDisplay,
  allpastMealsTotal,
  companyName,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const MealDishStatusMap = MealDishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});

  const today = dates.formatServer(new Date());
  const [mealsToDisplay, setMealsToDisplay] = useState(null);
  const [showPrevious, setShowPrevious] = useState(false);
  const [futureData, setFutureData] = useState(true);
  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState();
  const [details, setDetails] = useState();
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const [newPageSizePastMeals, setPageSizePastMeals] = useState(25); // Records per page
  const [totalPastMealRecords, setTotalPastMealRecords] = useState(0); // Total records
  const [pageCountsPastMeals, setpageCountsPastMeals] = useState(1); // Total pages count
  const [currentPagePastMeals, setCurrentPagePastMeals] = useState(1); // Current page
  const [searchKeyword, setSearchKeywordPastMeals] = useState(''); // Search text
  const [allPastMealsExcel, setAllPastMealsExcel] = useState();
  const [checkKitchen, setCheckKitchen] = useState();
  const [loading, setLoading] = useState(0);
  const [mealTypes, setMealTypes] = useState();
  const [selectedMealTypes, setSelectedMealTypes] = useState();
  let newDate = new Date();
  //  console.log("hhhhhhhhhhhhh",new Date(Date.now() - 864e5))
  const [toDate, setToDate] = useState(new Date(Date.now() - 864e5));
  const [fromDate, setFromDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1)
  );
  const MealStatusMap = MealStatuses.reduce((acc, meal) => {
    return { ...acc, [meal.value]: meal.id };
  }, {});

  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.display };
  }, {});
  const lang = localStorage.getItem('language');
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      backgroundColor: 'transparent',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
      borderRadius: '5px',
      width: '100%',
    }),
  };
  const onFilterTextBoxChanged = (e) => {
    let value = e.target.value;
    let newValue = '';
    if (value.search('/') > 0) {
      let newDateFormat = value.split('/');
      for (
        let newDateIndex = newDateFormat.length - 1;
        newDateIndex >= 0;
        newDateIndex--
      ) {
        const element = newDateFormat[newDateIndex];
        if (newValue.search('-') < 0) {
          newValue += element + '-';
        } else {
          newValue += element;
        }
      }
      if (newValue.length >= 3) {
        value = newValue;
      }
    }
    setSearchKeywordPastMeals(value);
    setpageCountsPastMeals(1);
  };

  const filterColumns = (data) => {
    if (allPastMealsExcel && allPastMealsExcel.length) {
      const columns = Object.keys(data[0]);
      const filterColsByKey = columns.filter(
        (c) =>
          c !== 'kitchen' &&
          // c !== 'mealType' &&
          c !== 'mealDish' &&
          // c !== 'mealTypeId' &&
          c !== 'kitchenId' &&
          c !== 'mealStatusId'
      );
      return filterColsByKey;
    }
    return [];
  };
  const handleMealtypeChange = async (e) => {
    setpageCountsPastMeals(1);
    var value = '';
    if (e.length) {
      value = [];
      for (var i = 0; i < e.length; i++) {
        value.push(e[i].value);
      }
    }
    setSelectedMealTypes(value);
  };
  const handleChange = async (name, value) => {
    if (name === 'fromDate') {
      if (!value) {
        setFromDate(null);
      }
      setpageCountsPastMeals(1);
      setFromDate(value);
    }
    if (name === 'toDate') {
      if (!value) {
        setToDate(null);
      }
      setpageCountsPastMeals(1);
      setToDate(value);
    }
  };
  const getPastMealData = async () => {
    const today = new Date();
    const lastMonthFirstDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const filterPayload = {
      from: moment(toDate).format('YYYY-MM-DD'),
      to: moment(fromDate).format('YYYY-MM-DD'),
      filter: {
        kitchenId: kitchen?.id || [],
        mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
      },
      page: currentPagePastMeals,
      mealtypeids: selectedMealTypes ? selectedMealTypes : '',
      limit: newPageSizePastMeals,
      order: [['date', 'DESC']],
      meta: true,
      keyword: searchKeyword,
    };
    await useFetchingLoader(async () => {
      await pastMealsData(filterPayload);
    });
    await handleExcelDownload();
  };
  // useEffect(async () => {
  // if (localStorage.getItem('recordsPerPage') !== null) {
  //   setPageSizePastMeals(localStorage.getItem('recordsPerPage'));
  //  }
  //  }, [])
  useEffect(async () => {
    if (!kitchen) return;
    setLayoutOptions({ title: `${t('mealManagement')} ${kitchen.name}` });
    setCheckKitchen(kitchen.id);
    await getPastMealData();
    setMealTypes(MealTypes.filter((el) => el.kitchenId === kitchen.id));
  }, [kitchen, newPageSizePastMeals, currentPagePastMeals]);
  useEffect(() => {
    if (checkKitchen !== undefined && kitchen.id !== checkKitchen) {
      window.location.reload();
    }
  }, [kitchen]);

  useEffect(() => {
    let filterMeals = mealsToDisplay?.filter(
      (meal) => showPrevious || moment(meal.date) > today
    );
    setMealsToDisplay(sortMeals(filterMeals));
  }, [showPrevious]);
  useEffect(() => {
    if (pastmealsToDisplay) {
      let serverMeals = pastmealsToDisplay.map((u, i) => ({
        mealId: u.id,
        mealInventory: u.inventory,
        mealOrdered: u.ordered,
        mealRating: u.rating,
        mealDate: dates.ddmmFormat(u?.date),
        mealType: MealTypesMap[u?.mealTypeId],
        mealTypeId: u?.mealTypeId,
        mealTime: u.mealType.from + ' - ' + u.mealType.to,
        mealRealized: u?.realized,
        mealNumOfRaters: u?.numOfRaters,
        date: u?.date,
        orderIndex: u.mealType.orderIndex,
      }));
      //if(!showFetchingLoader)
      setMealsToDisplay(sortPastMeals(serverMeals));

      setTotalPastMealRecords(pastMealsTotal);
      setpageCountsPastMeals(Math.ceil(pastMealsTotal / newPageSizePastMeals));
    }
  }, [pastmealsToDisplay]);

  const handleMoreInfo = (mealData) => {
    let type = mealData.mealTypeId;
    let date = dates.formatServer(mealData.date);

    history.push({
      pathname: `/menuConstruction/${date}/${type}`,
      state: { backText: t('returnToMealManagement') },
    });
  };
  const createSelectItems = () => {
    let items = [];
    let pageNums = [25, 50, 100, 200, 500];
    for (let j = 0; j < pageNums.length; j++) {
      items.push(
        <option key={pageNums[j]} value={pageNums[j]}>
          {pageNums[j]}
        </option>
      );
    }
    return items;
  };
  const handleRecordCountChange = (elem) => {
    setCurrentPagePastMeals(1);
    setPageSizePastMeals(elem?.target?.value);
    localStorage.setItem('recordsPerPage', elem?.target?.value);
  };
  const handleDeleteMeal = async (mealData) => {
    let mealId = mealData.id;
    try {
      await useFetchingLoader(async () => {
        await http.post(`meals/${mealId}/deleteMeal`);
        setMealsToDisplay((state) =>
          state.filter((meal) => meal.id !== mealId)
        );
      });
    } catch (err) {
      Alert({ error: err });
    }
  };

  const MealsCallBacks = {
    moreInfo: handleMoreInfo,
    delete: handleDeleteMeal,
  };

  const handleBlockMeal = async (mealId, mealStatusId) => {
    let changeToStatus =
      MealDishStatusMap.active === mealStatusId
        ? MealDishStatusMap.blocked
        : MealDishStatusMap.active;
    try {
      await useFetchingLoader(
        async () =>
          await http.put(`mealsDishes/updateByMealId/${mealId}`, {
            mealStatusId: changeToStatus,
          })
      );
      setMealsToDisplay((state) =>
        state.map((mealDish) =>
          mealDish.id === mealId
            ? { ...mealDish, mealStatusId: changeToStatus }
            : mealDish
        )
      );
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handleRatingPage = (mealData) => {
    history.push({
      pathname: `/allReviews/${mealData.date}/${mealData.id}`,
    });
  };

  const handlePastMealData = () => {
    setFutureData(false);
    const pastItems = mealsToDisplay.filter(
      (item) => showPrevious || item?.date?.localeCompare(today) < 0
    );
    setMealsToDisplay(sortMeals(pastItems));
  };
  const handleFutureMealData = () => {
    setFutureData(true);
    const futureItems = mealsToDisplay.filter(
      (item) => showPrevious || item?.date?.localeCompare(today) >= 0
    );
    setMealsToDisplay(sortMeals(futureItems));
  };

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  const handleExcelDownload = async () => {
    const todayNew = new Date();
    const lastMonthFirstDateNew = new Date(
      todayNew.getFullYear(),
      todayNew.getMonth() - 1,
      1
    );
    const filterPayloadNew = {
      from: moment(toDate).format('YYYY-MM-DD'),
      to: moment(fromDate).format('YYYY-MM-DD'),
      filter: {
        kitchenId: kitchen.id || [],
        mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
      },
      page: 1,
      mealtypeids: selectedMealTypes ? selectedMealTypes : '',
      //limit: 1000,
      order: [['date', 'DESC']],
      meta: true,
      keyword: searchKeyword ? searchKeyword : '',
    };
    setLoading(0);
    //await useFetchingLoader(async () => {
    await allPastMealsData(filterPayloadNew);
    //});
  };
  useEffect(() => {
    if (allpastmealsToDisplay) {
      let arrangeData = allpastmealsToDisplay.map((u, i) => ({
        mealDate: dates.ddmmFormat(u?.date),
        mealTime: u.mealType.from + ' - ' + u.mealType.to,
        mealType: MealTypesMap[u?.mealTypeId],
        mealInventory: u.inventory,
        mealOrdered: u.ordered,
        mealOrderedPercentage: `${
          u?.inventory > 0
            ? Number.parseInt((u?.ordered / u.inventory) * 100)
            : 0
        }%`,
        mealRealized: u?.realized,
        mealRealizedPercentage: `${
          u?.inventory > 0
            ? Number.parseInt((u?.realized / u?.inventory) * 100)
            : 0
        }%`,
        mealRating: u?.numOfRaters ? u?.numOfRaters : 0,
        date: u?.date,
        orderIndex: u?.mealType.orderIndex,
      }));

      setAllPastMealsExcel(sortPastMeals(arrangeData));
      // setTimeout(() => {
      //   setLoading(1);
      // }, 3000);
      setLoading(1);
    }
  }, [allpastmealsToDisplay]);

  const columnDefs = [
    {
      field: 'mealDate',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealTime',
      headerName: t('time'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealType',
      headerName: t('mealType'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealInventory',
      headerName: t('tInventory'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealOrdered',
      headerName: t('ordered'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealOrdered',
      headerName: t('ordered') + '(%)',
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'ReservationsInventory',
    },
    {
      field: 'mealRealized',
      headerName: t('realizedOrder'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealRealized',
      headerName: t('realizedOrder') + '(%)',
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'RealizedReservations',
    },
    {
      field: 'mealRating',
      headerName: t('rating'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'RatingStar',
    },
    {
      field: 'Action',
      headerName: t('action'),
      headerClass: 'resizable-header',
      suppressMovable: true,
      cellRenderer: 'ActionButton',
    },
  ];

  const columnDefsTab = [
    {
      field: 'mealDate',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealTime',
      headerName: t('time'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealType',
      headerName: t('mealType'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealInventory',
      headerName: t('tInventory'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealOrdered',
      headerName: t('ordered'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealOrdered',
      headerName: t('ordered') + '(%)',
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'ReservationsInventory',
    },
    {
      field: 'mealRealized',
      headerName: t('realizedOrder'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'mealRealized',
      headerName: t('realizedOrder') + '(%)',
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'RealizedReservations',
    },
    {
      field: 'mealRating',
      headerName: t('rating'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'RatingStar',
    },
    {
      // field: 'hghgh',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 40,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'mealDate',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      width: 60,
    },
    {
      field: 'mealType',
      headerName: t('mealType'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      width: 80,
    },
    {
      field: 'mealInventory',
      headerName: t('tInventory'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      width: 50,
    },
    {
      field: 'mealOrdered',
      headerName: t('ordered'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      width: 50,
    },
    {
      field: 'mealRating',
      headerName: t('rating'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'RatingStar',
      width: 100,
    },
    {
      // field: 'hghgh',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 40,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  return (
    <div className={'meal-management past-meals global-data-table'}>
      <div className="container">
        <div className="add-employee-btn">
          <div className="left-filter">
            <div className="filter-section">
              <div className="search-filter">
                <div className="xyz">
                  <div className="from-date">
                    <label>{t('fromDate')}</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={fromDate ? fromDate : new Date()}
                      name="fromDate"
                      onChange={(event) => handleChange('fromDate', event)}
                      placeholder="DD/MM/YYYY"
                      endDate={new Date()}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="to-date">
                    <label>{t('toDate')}</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={toDate ? toDate : new Date()}
                      name="toDate"
                      onChange={(event) => handleChange('toDate', event)}
                      placeholder="DD/MM/YYYY"
                      endDate={new Date()}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="global-multi-select">
              <label>{t('mealTypeInReservation')}</label>
              <Select
                isMulti
                name={'meal_type[]'}
                onChange={(event) => {
                  handleMealtypeChange(event);
                }}
                options={
                  mealTypes &&
                  mealTypes.map((mealType) => {
                    return { value: mealType.id, label: mealType.display };
                  })
                }
                styles={SelectStyles}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            <div className="search-input searchBtn">
              <label>{t('filter')}</label>
              <input
                type="text"
                id="filter-text-box"
                placeholder={t('freeTextSearch')}
                onInput={onFilterTextBoxChanged}
              />
              <img src={search} alt="search" />
            </div>
            <button onClick={getPastMealData} className="search-button">
              <img src={search} alt="search" />
            </button>
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{totalPastMealRecords}</span>
              </p>
            </div>
            {loading == 0 ? (
              <div className="loading_excel" title="Loading">
                <WESpinner size={15} color={'#14cd31'} />
              </div>
            ) : (
              ''
            )}
            <div className={`actions ${loading == 1 ? '' : 'hidden'}`}>
              {loading == 1 && allPastMealsExcel ? (
                <ExcelFile
                  filename={`${companyName}-past-meals-${moment().format(
                    'DD-MMM-YYYY'
                  )}`}
                  element={<img src={file} alt="add" />}
                >
                  <ExcelSheet data={allPastMealsExcel} name="Test">
                    {filterColumns(allPastMealsExcel).map((col) => (
                      <ExcelColumn label={col} value={col} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className={'top'}></div>
        {false ? (
          ''
        ) : (
          <div className="add-dishes-table">
            <AgGridReact
              className="ag-theme-alpine"
              rowData={mealsToDisplay}
              columnDefs={tableColumn}
              localeText={agGridLocales[i18n.language]}
              reactUi={lang === '"he"' ? false : true}
              enableRtl={lang === '"he"' ? true : false}
              frameworkComponents={{
                RatingStar: (e) => <RatingStar e={e} />,
                ReservationsInventory: (e) => <ReservationsInventory e={e} />,
                RealizedReservations: (e) => <RealizedReservations e={e} />,
                ActionButton: (e) => (
                  <ActionButton
                    e={e}
                    t={t}
                    MealsCallBacks={MealsCallBacks}
                    isFuture={futureData}
                    MealTypesMap={MealTypesMap}
                    dates={dates}
                  />
                ),
                ViewMore: (e) => (
                  <ViewMore
                    e={e}
                    handleViewMore={handleViewMore}
                    MealsCallBacks={MealsCallBacks}
                  />
                ),
              }}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              debug={false}
              overlayLoadingTemplate={'<p></p>'}
              rowSelection={'multiple'}
              rowGroupPanelShow={'always'}
              pivotPanelShow={'always'}
              enableRangeSelection={true}

              //onRowClicked={(e) => MealsCallBacks.moreInfo(e.data)}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                {/* <select
                    id="we_change_record_count"
                    onChange={handleRecordCountChange}
                    defaultValue={localStorage.getItem('recordsPerPage') !== null ? localStorage.getItem('recordsPerPage'):newPageSizePastMeals}
                  >
                    {createSelectItems()}
                  </select> */}
                <p>
                  {newPageSizePastMeals * (currentPagePastMeals - 1) + 1} to{' '}
                  {newPageSizePastMeals * currentPagePastMeals} of{' '}
                  {totalPastMealRecords}
                </p>
              </div>
              <Pagination>
                <Pagination.First
                  disabled={pageCountsPastMeals <= 1 ? true : false}
                  onClick={() => {
                    setCurrentPagePastMeals(1);
                  }}
                />
                <Pagination.Prev
                  disabled={pageCountsPastMeals <= 1 ? true : false}
                  onClick={() => {
                    currentPagePastMeals - 1 > 0
                      ? setCurrentPagePastMeals(currentPagePastMeals - 1)
                      : '';
                  }}
                />

                <Pagination.Item active>
                  Page {currentPagePastMeals} of{' '}
                  {Math.ceil(pastMealsTotal / newPageSizePastMeals)}
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCountsPastMeals <= 1 ? true : false}
                  onClick={() => {
                    currentPagePastMeals <
                    Math.ceil(pastMealsTotal / newPageSizePastMeals)
                      ? setCurrentPagePastMeals(currentPagePastMeals + 1)
                      : '';
                  }}
                />
                <Pagination.Last
                  disabled={pageCountsPastMeals <= 1 ? true : false}
                  onClick={() => {
                    setCurrentPagePastMeals(
                      Math.ceil(pastMealsTotal / newPageSizePastMeals)
                    );
                  }}
                />
              </Pagination>
            </div>
          </div>
        )}
        <ViewPastMealDetail
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
          handleRatingPage={handleRatingPage}
          MealsCallBacks={MealsCallBacks}
          isFuture={futureData}
        />
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore, MealsCallBacks }) => {
  return (
    <div className="view-more-btn">
      {/* <img src={next} alt="next" onClick={() => handleViewMore(e.data)} /> */}
      <img
        src={next}
        alt="next"
        onClick={() => MealsCallBacks.moreInfo(e.data)}
      />
    </div>
  );
};
const RealizedReservations = ({ e }) => {
  return (
    <p>
      {/* {e?.data?.mealRealized} ( */}
      {e?.data?.mealInventory > 0
        ? Number.parseInt(
            (e?.data?.mealRealized / e?.data?.mealInventory) * 100
          )
        : 0}
      %{/* ) */}
    </p>
  );
};
const ReservationsInventory = ({ e }) => {
  return (
    <p>
      {/* {e?.data?.mealOrdered} ( */}
      {e?.data?.mealInventory > 0
        ? Number.parseInt((e?.data?.mealOrdered / e?.data?.mealInventory) * 100)
        : 0}
      %{/* ) */}
    </p>
  );
};

const RatingStar = ({ e }) => {
  return (
    <section className={'section2'}>
      <div
        className={`rating ${
          e?.data?.mealNumOfRaters > 0 ? '' : 'disable-rating'
        }`}
        onClick={() => handleRatingPage(e.data)}
      >
        <StaticStars
          numOfRaters={e?.data?.mealNumOfRaters || 0}
          rating={e?.data?.mealRating || -1}
        />
      </div>
    </section>
  );
};

const ActionButton = ({
  e,
  t,
  MealsCallBacks,
  isFuture,
  MealTypesMap,
  dates,
}) => {
  const handleDeleteClick = async () => {
    if (e.data.mealOrdered > 0) {
      Alert({ error: { content: t('cantDeleteMealWithOrders') } });
      return;
    }
    Dialog({
      title: t('removeMealTitle'),
      text: `${t('areYouSureYouWantToRemoveMeal')} ${e.data.mealDate} - ${
        e.data.mealType
      } `,
      buttons: {
        confirmButtonText: t('removeMealBtnLabel'),
        cancelButtonText: t('back'),
      },
      onConfirm: async () => await MealsCallBacks.delete(e.data),
    });
  };
  return (
    <section>
      <div className={'actions'}>
        <WEIcon
          icon="info"
          text={t('moreInfo')}
          iconSize={'small'}
          cb={() => MealsCallBacks.moreInfo(e.data)}
        />
        {/* {isFuture ? (
            <WEIcon
              icon="delete"
              text={t('delete')}
              iconSize={'small'}
             cb={handleDeleteClick}
            />
          ) : (
            ''
          )} */}
      </div>
    </section>
  );
};

// const Meal = ({
//   mealData,
//   MealTypes,
//   isFuture,
//   handleOpenToOrders,
//   MealDishStatusMap,
//   MealsCallBacks = () => {},
//   handleRatingPage,
// }) => {
//   const { t } = useTranslation();
//   const MealTypesMap = MealTypes.reduce((acc, meal) => {
//     return { ...acc, [meal.id]: meal.value };
//   }, {});

//   const handleDeleteClick = async () => {
//     if (mealData.ordered > 0) {
//       Alert({ error: { content: t('cantDeleteMealWithOrders') } });
//       return;
//     }
//     Dialog({
//       title: t('removeMealTitle'),
//       text: `${t('areYouSureYouWantToRemoveMeal')} ${dates.ddmmFormat(
//         mealData?.date
//       )} - ${MealTypesMap[mealData?.mealTypeId]}`,
//       buttons: {
//         confirmButtonText: t('removeMealBtnLabel'),
//         cancelButtonText: t('back'),
//       },
//       onConfirm: async () => await MealsCallBacks.delete(mealData),
//     });
//   };
//   return (
//     <div className={`meal dish dish-content`}>
//       <section>
//         {/* <div className={'date-type'}> */}
//         <p className={'date'}>{dates.ddmmFormat(mealData?.date)}</p>
//       </section>
//       <section>
//         <p className={'type'}>{MealTypesMap[mealData?.mealTypeId]}</p>
//       </section>
//       <section>
//         <p className={'type'}>
//           ({mealData.mealType.from + ' - ' + mealData.mealType.to})
//         </p>
//       </section>
//       <section>
//         {/* </div> */}
//         {/* <MealSummary mealData={mealData} /> */}
//         <p className={'type'}>({mealData.inventory})</p>
//       </section>
//       <section>
//         <p className={'type'}>({mealData.ordered})</p>
//       </section>
//       <section>
//         <p className={'type'}>({mealData.realized})</p>
//       </section>

//       <section>
//         <div
//           className={`rating ${
//             mealData.numOfRaters > 0 ? '' : 'disable-rating'
//           }`}
//           onClick={() => handleRatingPage(mealData)}
//         >
//           <StaticStars
//             numOfRaters={mealData?.numOfRaters || 0}
//             rating={mealData?.rating || -1}
//           />
//         </div>
//       </section>
//       <section>
//         <div className={'actions'}>
//           <WEIcon
//             icon="info"
//             text={t('moreInfo')}
//             iconSize={'small'}
//             cb={() => MealsCallBacks.moreInfo(mealData)}
//           />
//           {/* {isFuture ? (
//             <WEIcon
//               icon="delete"
//               text={t('delete')}
//               iconSize={'small'}
//               cb={handleDeleteClick}
//             />
//           ) : (
//             ''
//           )} */}
//         </div>
//       </section>
//     </div>
//   );
// };

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  MealStatuses: state.app.enums.MealStatus,
  MealDishStatuses: state.app.enums.MealDishStatus,
  Languages: state.app.enums.Languages,
  currentLanguage: state.languages.current,
  pastmealsToDisplay: state.app.pastmealsToDisplay,
  pastMealsTotal: state.app.pastMealsTotal,
  showFetchingLoader: state.app.showFetchingLoader,
  allpastmealsToDisplay: state.app.allpastmealsToDisplay,
  allpastMealsTotal: state.app.allpastMealsTotal,
  companyName: state.companyConfig.companyName,
});
const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  pastMealsData,
  allPastMealsData,
};
export default connect(mapStateToProps, mapDispatchToProps)(PastMeals);
