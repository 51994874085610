import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import dates from '../../services/dates';
import { getAssetUrl } from '../../services/aws';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  getWalletAmountFromApi,
} from '../../store/app/actions';
import { StaticStars } from '../../components/RatingStars';
import { useHistory } from 'react-router-dom';
import { isMobile } from '../../services/mediaQueries';

import enums from '../../services/enums';
import DishImage from './DishImage';
import { sleep } from '../../services/utils';
import ShowPreviousContainer from '../../components/ShowPrevious';
import WEIcon from '../../components/WE-IconButton';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import { sortOrders } from '../../services/sortItems';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import cancel from '../../assets/images/close.svg';
import TableBtn from '../Newfolder/TableBtn';
import edit from '../../assets/icons/pencil.png';
import cancelImg from '../../assets/icons/cancel.png';
import cibusImg from '../../assets/icons/cibus.png';
import voucher from '../../assets/icons/voucher.png';
import arrow from '../../assets/icons/angle-right-black.svg';
import reviewImg from '../../assets/icons/review.png';
import MyOrdersDetailsMobilePopup from '../Newfolder/MyOrdersDetailsMobilePopup';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import CibusPaymentSuccessPopup from '../Newfolder/CibusPaymentSuccessPopup';
import CibusPaymentFailurePopup from '../Newfolder/CibusPaymentFailurePopup';
import { useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { getEmployeeLobbyData } from '../../store/kitchens/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 35px',
  p: 6,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  minHeight: '300px',
};
// @ts-ignore
const MyMeals = ({
  user,
  kitchen,
  kitchens,
  MealTypes,
  OrderStatuses,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getEmployeeLobbyData,
  getWalletAmountFromApi,
}) => {
  const history = useHistory();
  const [orders, setOrders] = useState(null);
  const { t } = useTranslation();
  useEffect(async () => await useLoader(() => sleep()), []);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [orderId, setOrderId] = useState();
  const [viewOrderData, setViewOrderData] = useState();
  const [mobOderData, setMobOrderData] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentKitchenId, setPaymentKitchenId] = useState();
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [myOrdersMob, setMyOrdersMob] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [refresh, setRefresh] = useState(false);
  const { search } = useLocation();
  const handleMyOrdersMob = (id) => {
    let filterData = orders.filter((el) => el.id === id);
    setMobOrderData(filterData[0]);
    setMyOrdersMob(!myOrdersMob);
  };
  useEffect(() => {
    getWalletAmountFromApi(user?.id);
  }, []);
  setLayoutOptions({ title: t('myMeals'), showKitchen: true });
  const OrderStatusesMap = OrderStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(async () => {
    if (!kitchen) return;
    let { data: orders } = await useFetchingLoader(
      async () =>
        await http.get(`orders`, {
          filter: { kitchenId: kitchen.id, userId: user?.id },
        })
    );

    let getCollectPoint = await useFetchingLoader(
      async () =>
        await http.get(`users/getCollectPoint`, {
          filter: { kitchenId: kitchen.id, userId: user.id },
        })
    );
    orders = [...orders, ...getCollectPoint];

    const paymentdata = await getPaymentPreferences(kitchen.id, '');
    setPaymentMethod(paymentdata?.selectedPaymentOptions);
    setPaymentKitchenId(kitchen.id);
    setOrders(sortOrders(orders.filter((order) => order)));
  }, [kitchen, refresh]);
  // useEffect(async () => {
  //   let url = window.location.href;
  //   let token = url?.split('&')[2]?.split('token=')[1];
  //   let dealId = url?.split('&')[3]?.split('deal_id=')[1];
  //   if (token && dealId) {
  //     setShowPaymentPopup(!showPaymentPopup);
  //   } if(token &) {
  //     setShowErrorPopup(!showErrorPopup);
  //   }
  // }, []);

  useEffect(() => {
    const queryStrings = search.substring(1).split('&');
    if (queryStrings.length) {
      const query = {};
      queryStrings.forEach((el) => {
        const [key, value] = el.split('=');
        query[key] = value;
      });
      let token = query['token'];
      let dealId = query['deal_id'];
      let err = query['err'];
      let errUI = query['errUI'];
      let b = errUI?.split('+')?.join(' ');
      let c = b?.split('%0d%0a')?.join('');
      let errorMsg = c?.split('%2c')?.join('!');
      if (token && dealId) {
        setShowPaymentPopup(!showPaymentPopup);
      }
      if (err) {
        setErrorMessage(errorMsg);
        setShowErrorPopup(!showErrorPopup);
      }
    }
  }, []);

  const [showPrevious, setShowPrevious] = useState(false);

  const handleOrderCancel = (orderId) => {
    setOrders((state) =>
      sortOrders(state.filter((order) => order.id !== orderId))
    );
  };
  const deleteCP = async ({ order, getMealTypeInfo }) => {
    try {
      return await http.delete(`users/deleteCreditPoint`, {
        mealTypeId: order?.mealTypeId,
        point: getMealTypeInfo?.data[0]?.creditPoints,
        kitchenId: order?.kitchenId,
        date: order?.date ?? order?.addDate,
        mealId: order?.mealId,
      });
    } catch (err) {
      return { error: err };
    }
  };
  const revertCollectPoints = async (order) => {
    const getMealTypeInfo = await http.post(`users/getMealTypeInfoById`, {
      mealTypeId: order?.mealTypeId,
      kitchenId: order?.kitchenId,
      date: order?.date ?? order?.addDate,
    });

    if (getMealTypeInfo?.data[0]?.creditPoints) {
      Dialog({
        title: `${t('revertCollectPointMessage')} ${
          getMealTypeInfo?.data[0]?.creditPoints
        } ${t('pointMsg')}`,
        type: 'success',
        buttons: {
          cancelButtonText: t('back'),
          confirmButtonText: t('ok'),
        },
        onConfirm: async () => {
          const getResponseInfo = await useFetchingLoader(
            async () =>
              await deleteCP({
                order,
                getMealTypeInfo,
              })
          );
          if ('status' in getResponseInfo && getResponseInfo.status === 200) {
            SwalDialogs.Dialog({
              title: `${getMealTypeInfo?.data[0]?.creditPoints} ${t(
                'successReturnMealTypePointMessage'
              )}`,
              type: 'success',
              buttons: {
                showCancelButton: false,
                showConfirmButton: true,
              },
              onConfirm: async () => {
                setMyOrdersMob(false);
                setRefresh(!refresh);
                getWalletAmountFromApi(user?.id);
                await getEmployeeLobbyData(order?.kitchenId);
              },
            });
          }
          if ('error' in getResponseInfo) {
            Dialog({
              title: getResponseInfo.error,
              type: 'error',
              buttons: {
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: t('ok'),
              },
              onConfirm: () => {
                setDummy(true);
              },
            });
          }
        },
      });
    } else {
      SwalDialogs.Dialog({
        title: `${t('alertNoMealsAbleMessage')}`,
        type: 'error',
        buttons: {
          showCancelButton: true,
          showConfirmButton: false,
        },
      });
    }
  };
  const handlePopup = (id) => {
    let orderfilterData = orders.filter((el) => el.id === id);
    setViewOrderData(orderfilterData);
    setOrderId(id);
    setPaymentPopup(!paymentPopup);
  };
  const handlePaymentSuccessPopupClose = () => {
    setShowPaymentPopup(!showPaymentPopup);
    history.push('/myMeals');
  };
  const handlePaymentFailurePopupClose = () => {
    setShowErrorPopup(!showErrorPopup);
    history.push('/myMeals');
  };

  return (
    <div className="my-meals my-orders-page">
      <h1 className="page-heading">{t('myOrders')}</h1>
      <div className="my-orders-table-section">
        <div className="my-orders-table">
          <div className="my-orders-table-head">
            <div className="table-text-section">
              <h5>{t('name')}</h5>
              <h5>
                {t('mealDate')} &amp; {t('type')}
              </h5>
              <h5>{t('extra')}</h5>
              <h5>{t('reservationKitchen')}</h5>
              <h5>{t('price')}</h5>
              <h5>{t('orderId')}</h5>
              <h5>{t('paymentMethod')}</h5>
              <h5>{t('status')}</h5>
            </div>
            <div className="table-btn-section"></div>
          </div>
          {orders && orders.length ? (
            <div className="my-orders-table-body">
              <ShowPreviousContainer
                items={orders.map((order) => {
                  return {
                    ...order,
                    date: order.mealDish?.length
                      ? order?.mealDish?.[0]?.mealDish?.meal?.date
                      : order?.mealDish?.meal?.date,
                  };
                })}
                assignMethod={(order, future) => (
                  <OrderCard
                    key={order.id}
                    isFuture={future}
                    kitchens={kitchens}
                    order={order}
                    OrderStatusesMap={OrderStatusesMap}
                    MealTypes={MealTypes}
                    useFetchingLoader={useFetchingLoader}
                    handleOrderCancel={handleOrderCancel}
                    t={t}
                    handlePopup={handlePopup}
                    handleMyOrdersMob={handleMyOrdersMob}
                    paymentMethod={paymentMethod}
                    paymentKitchenId={paymentKitchenId}
                    user={user}
                    revertCollectPoints={revertCollectPoints}
                  />
                )}
                showPrevious={showPrevious}
                showPreviousOnClick={async () =>
                  await useFetchingLoader(() => setShowPrevious(true))
                }
              />
            </div>
          ) : (
            <div className="no-meals">{t('noOrdersToDisplay')}</div>
          )}
          {viewOrderData && viewOrderData.length > 0 && (
            <Modal
              open={paymentPopup}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              maxWidth="lg"
              className="add-employee-popup copymeal-popup  check-payment-popup meal-payment-popup"
            >
              <Box sx={style}>
                <div className="extras-listing-section">
                  {!(
                    viewOrderData[0]?.reported &&
                    viewOrderData[0]?.paymentStatus
                  ) && (
                    <div className="close-btn">
                      <img src={cancel} alt="close" onClick={handlePopup} />
                    </div>
                  )}
                  <h2>{t('paymentDetails')}</h2>
                  {paymentMethod === 5 && (
                    <>
                      <div className={'extras-meal-management'}>
                        <div className="payment-details">
                          <p>{t('cardLastDigits')}:</p>
                          <h6>
                            {viewOrderData && viewOrderData[0]?.last4Digits}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('amountPaid')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.Amount}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('timeOfPayment')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0] &&
                              new Date(
                                viewOrderData[0]?.realizeDate
                              ).toLocaleString('he-IL')}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('transactionId')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.tranId}
                          </h6>
                        </div>
                      </div>
                    </>
                  )}
                  {paymentMethod === 6 && (
                    <>
                      <div className={'extras-meal-management'}>
                        <div className="payment-details">
                          <p>{t('orderId')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.deal_id}
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.orderID}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('amountPaid')}:</p>
                          <h6>
                            {user.roleId === 4
                              ? viewOrderData && viewOrderData[0]?.orderPrice
                              : 0}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('timeOfPayment')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0] &&
                              new Date(
                                viewOrderData[0]?.updatedAt
                              ).toLocaleString('he-IL')}
                          </h6>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="extras-button other-button">
                    <button onClick={handlePopup}>{t('ok')}</button>
                    {/* <button onClick={skipAddOrder}>{t('skip')}</button> */}
                  </div>
                </div>
              </Box>
            </Modal>
          )}
        </div>
        <MyOrdersDetailsMobilePopup
          myOrdersMob={myOrdersMob}
          handleMyOrdersMob={handleMyOrdersMob}
          handleVoucherModal={() =>
            history.push({
              pathname: `/voucher/${mobOderData?.id}`,
              state: { backText: t('returnToMyMeals') },
            })
          }
          mobOderData={mobOderData}
          OrderStatusesMap={OrderStatusesMap}
          MealTypes={MealTypes}
          setOrders={setOrders}
          t={t}
          paymentMethod={paymentMethod}
          handlePopup={handlePopup}
          paymentKitchenId={paymentKitchenId}
          user={user}
          revertCollectPoints={revertCollectPoints}
        />
        {showPaymentPopup && (
          <CibusPaymentSuccessPopup
            showPaymentPopup={showPaymentPopup}
            t={t}
            handlePaymentSuccessPopupClose={handlePaymentSuccessPopupClose}
          />
        )}
        {showErrorPopup && (
          <CibusPaymentFailurePopup
            showErrorPopup={showErrorPopup}
            t={t}
            handlePaymentFailurePopupClose={handlePaymentFailurePopupClose}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

const OrderCard = ({
  order,
  isFuture,
  kitchens,
  MealTypes,
  OrderStatusesMap,
  handleOrderCancel,
  useFetchingLoader,
  t,
  handlePopup,
  handleMyOrdersMob,
  paymentMethod,
  paymentKitchenId,
  user,
  revertCollectPoints,
}) => {
  const [cancellation, setCancellation] = useState();
  const { mealDish } = order;
  let meal = {};
  useEffect(() => {
    console.log('order', order);
    console.log(
      'order?.chargeResponse && order?.chargeResponse !== ',
      Boolean(order?.chargeResponse && order?.chargeResponse !== '')
    );
  }, [order]);
  if (mealDish?.length > 0) {
    meal =
      (order &&
        order?.mealDish &&
        order?.mealDish?.length > 0 &&
        order?.mealDish[0]?.mealDish?.meal) ??
      {};
  } else {
    meal = mealDish?.meal ?? {};
  }

  const { dish } = mealDish ?? {};

  const dishArray =
    order && order.mealDish && order.mealDish.length > 0 && order.mealDish;
  //const { meal, dish } = mealDish  ?? { meal: {}, dish:{}};
  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.display };
  }, {});
  let dishIds = [];
  if (dishArray?.length > 0) {
    const a = dishArray?.map((el) => {
      dishIds.push(el?.mealDish?.dish?.id);
    });
  }

  useEffect(() => {
    if (order?.meal?.date === moment(new Date()).format('YYYY-MM-DD')) {
      let now = order?.mealDish?.meal?.mealType?.to;
      let hour = now?.split(':')[0];
      let minute = now?.split(':')[1];
      let dt = new Date(null, null, null, hour, minute);
      dt?.setMinutes(
        dt?.getMinutes() - order?.mealDish?.meal?.mealType?.cancellationTime
      );

      if (moment(dt).format('HH:mm') < moment(new Date()).format('HH:mm')) {
        setCancellation(true);
      } else {
        setCancellation(false);
      }
    }
  }, [order, cancellation]);

  return (
    <>
      <div
        className={`my-orders-table-item ${isFuture ? 'future-card' : ''} ${
          order.orderStatusId === 3 ? 'cancelleSection' : ''
        }`}
      >
        <div
          className="table-text-section"
          onClick={() => handleMyOrdersMob(order?.id)}
        >
          {/* {console.log("order",order.orderStatusId ===3)} */}
          <p>
            <ul>
              {dishArray && dishArray?.length > 0 ? (
                dishArray?.map((el, i) => (
                  <li>
                    {el?.mealDish?.dish?.name ? el?.mealDish?.dish?.name : '--'}
                  </li>
                ))
              ) : (
                <li>
                  {order?.point > 0
                    ? t('pendingCollectedPoint')
                    : dish?.name
                    ? dish?.name
                    : '--'}
                </li>
              )}
            </ul>
          </p>
          <p>
            {order?.point > 0
              ? (order?.meal?.date
                  ? dates.ddmmFormat(order?.meal?.date)
                  : '--') +
                ' ' +
                (order?.mealType.display ? order?.mealType.display : '--')
              : meal?.date
              ? dates.ddmmFormat(meal?.date)
              : '--'}{' '}
            {order.mealSlot?.mealTypesSlot?.from
              ? order?.mealSlot?.mealTypesSlot?.from +
                ' - ' +
                order?.mealSlot?.mealTypesSlot?.to
              : ''}
            {MealTypesMap[meal?.mealTypeId]}
          </p>
          {order.mealExtra?.name ? (
            <p>
              {t('extras')}:{order?.mealExtra?.name}
            </p>
          ) : (
            <p>{'--'}</p>
          )}

          {order?.point > 0 ? (
            <p> {order?.kitchen.name ? order?.kitchen.name : '--'}</p>
          ) : (
            <p>{kitchens.find((k) => k.id === meal.kitchenId)?.name}</p>
          )}

          {order?.paymentOptionId === 0 || order?.paymentOptionId === null ? (
            <p>{'--'}</p>
          ) : order?.point > 0 ? (
            <p>{order?.point ? order?.point : '--'}</p>
          ) : (
            <p>
              {paymentMethod && paymentMethod == 5 ? '' : '₪'}{' '}
              {user.roleId === 4 || user.roleId === 6 || user.roleId === 8
                ? order?.orderPrice
                : 0}
            </p>
          )}

          <p>
            {Boolean(order?.chargeResponse && order?.chargeResponse !== '')
              ? JSON.parse(order?.chargeResponse)?.orderID
                ? JSON.parse(order?.chargeResponse)?.orderID
                : JSON.parse(order?.chargeResponse)?.deal_id
                ? JSON.parse(order?.chargeResponse)?.deal_id
                : '--'
              : order?.id}
          </p>
          <p>
            {order?.paymentOptionId == 6
              ? t('paymentOption-cibus')
              : order?.paymentOptionId == 5
              ? t('wallet')
              : order?.paymentOptionId == 4
              ? t('paymentOption-salary')
              : order?.paymentOptionId == 3
              ? t('paymentOption-creditCard')
              : user.roleId === 4
              ? t('--')
              : t('invoice')}
          </p>
          <p>{order?.paymentStatus ? t('paid') : t('pending')}</p>
        </div>
        <div className="mobile-popup-btn">
          <TableBtn img={arrow} func={() => handleMyOrdersMob(order?.id)} />
        </div>
        <div className="table-btn-section">
          <OrderActions
            order={order}
            OrderStatusesMap={OrderStatusesMap}
            MealTypes={MealTypes}
            useFetchingLoader={useFetchingLoader}
            handleOrderCancel={handleOrderCancel}
            handlePopup={handlePopup}
            meal={meal}
            dish={dish}
            dishArray={dishArray}
            dishIds={dishIds}
            paymentMethod={paymentMethod}
            OrderActions={OrderActions}
            paymentKitchenId={paymentKitchenId}
            user={user}
            revertCollectPoints={revertCollectPoints}
          />
        </div>
      </div>
    </>
  );
};

const OrderActions = ({
  order,
  OrderStatusesMap,
  MealTypes,
  handleOrderCancel,
  useFetchingLoader,
  handlePopup,
  meal,
  dish,
  dishArray,
  dishIds,
  cancellation,
  paymentMethod,
  paymentKitchenId,
  user,
  revertCollectPoints,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type };
  }, {});

  const isOrder = (order, value) => order?.orderStatusId === value;

  const isFutureMeal = (order) => {
    let { date, mealType } = (order?.mealDish?.meal ||
      (order &&
        order.mealDish &&
        order.mealDish.length > 0 &&
        order.mealDish[0].mealDish.meal)) ?? [{}, {}];
    let lastTimeInMeal = MealTypesMap[mealType?.id]?.to;
    let lastTimeToRealizeOrder = moment([date, lastTimeInMeal].join(' '));
    let now = moment();

    return lastTimeToRealizeOrder > now;
  };

  const handleCancelOrder = async () => {
    try {
      await useFetchingLoader(
        async () => await http.put(`orders/${order.id}/cancel`)
      );
      handleOrderCancel(order.id);
      SwalDialogs.Dialog({
        title: t('orderWasCanceled'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
      });
    } catch (err) {
      SwalDialogs.Dialog({
        title: 'An error has occurred',
        text: err,
        type: 'error',
        buttons: {
          showCancelButton: false,
          showConfirmButton: false,
        },
      });
    }
  };

  const cancelOrderDialog = () =>
    SwalDialogs.Dialog({
      title: t('cancelOrderTitle'),
      text: ` ${t('cancelOrderText')} ${order?.mealDish.map(
        (el) => el.mealDish?.dish?.name
      )} ${' \n'} ${t('cancelOrderTextin')} ${
        order?.mealDish[0]?.mealDish?.meal?.date
      } ${t('cancelOrderTextin')} ${
        order?.mealDish[0]?.mealDish?.dish?.kitchen?.name
      } `,
      buttons: {
        confirmButtonText: t('cancelOrderBtnLabel'),
        cancelButtonText: t('back'),
      },
      closeBtnStyle: {},
      onConfirm: handleCancelOrder,
    });

  const cibusPayment = () => {
    SwalDialogs.Dialog({
      title: t('cibusPayment'),
      text: t('cibusPaymentAlert'),
      buttons: {
        confirmButtonText: t('confirm'),
        cancelButtonText: t('back'),
      },
      closeBtnStyle: {},
      onConfirm: handleCibusPayment,
    });
  };

  const handleCibusPayment = async () => {
    const res = await http.post(`users/userPaymentByCibus`, {
      orderId: order.id,
      kitchenId: paymentKitchenId,
      price: order.orderPrice,
      paymentOptionId: paymentMethod,
    });
    window.location.href = res.url;
  };
  // const paymentDialog = () =>
  //   Dialog({
  //     title: t('Payment Details'),
  //     text: `${t('Last Digits of card')}:${order.last4Digits} ${t(
  //       'Amount Paid'
  //     )}:${JSON.parse(order.chargeResponse).Amount} ${t('Time of payment')}:${
  //       JSON.parse(order.chargeResponse).Date
  //     } ${t('Transaction ID')}:${JSON.parse(order.chargeResponse).TransID}`,

  //     buttons: {
  //       showCancelButton: false,
  //       okButtonText: t('ok'),
  //     },
  //     onConfirm: () => {},
  //     // onConfirm: handleCancelOrder,
  //   });

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }
  return (
    <>
      {isOrder(order, OrderStatusesMap.realized) ? (
        <>
          {isEmpty(order.chargeResponse) === false && (
            <TableBtn
              img={voucher}
              btnName={t('paymentDetails')}
              func={() => handlePopup(order.id)}
            />
          )}

          <div
            className={'rating'}
            onClick={() => {
              history.push({
                pathname: `/addReview/${order?.id}`,
                state: { backText: t('returnToMyMeals') },
              });
            }}
          >
            {!order?.rating ? (
              <TableBtn img={reviewImg} btnName={t('sendReview')} func={''} />
            ) : (
              <StaticStars
                rating={order?.rating || -1}
                numOfRaters={order?.mealDish?.dish?.numOfRaters}
                showNumOfRaters={false}
              />
            )}
          </div>
        </>
      ) : isFutureMeal(order) ? (
        isOrder(order, OrderStatusesMap.active) ? (
          <>
            {order.paymentOptionId === 6
              ? order.paymentOptionId === 6 &&
                order.paymentStatus === false && (
                  <>
                    <TableBtn
                      img={cibusImg}
                      btnName={t('payment')}
                      func={cibusPayment}
                    />

                    {!(order?.reported && order?.paymentStatus) && (
                      <TableBtn
                        img={edit}
                        btnName={t('edit')}
                        func={() =>
                          history.push({
                            pathname: `/orderSummary`,
                            state: {
                              date: meal?.date,
                              mealType: meal?.mealTypeId,
                              slotId: order?.mealSlotId,
                              orderId: order.id,
                              dishId: dish?.id,
                              dishIds: dishIds,
                              backLink: '/myMeals',
                              backText: t('returnToMyMeals'),
                            },
                          })
                        }
                      />
                    )}
                  </>
                )
              : !(order?.reported && order?.paymentStatus) && (
                  <TableBtn
                    img={edit}
                    btnName={t('edit')}
                    func={() =>
                      history.push({
                        pathname: `/orderSummary`,
                        state: {
                          date: meal?.date,
                          mealType: meal?.mealTypeId,
                          slotId: order?.mealSlotId,
                          orderId: order.id,
                          dishId: dish?.id,
                          dishIds: dishIds,
                          backLink: '/myMeals',
                          backText: t('returnToMyMeals'),
                        },
                      })
                    }
                  />
                )}
            {cancellation === true &&
            !(order.reported && order.paymentStatus) ? (
              <TableBtn
                img={cancelImg}
                btnName={t('cancel')}
                disabled
                func={cancelOrderDialog}
              />
            ) : (
              !(order.reported && order.paymentStatus) && (
                <TableBtn
                  img={cancelImg}
                  btnName={t('cancel')}
                  func={cancelOrderDialog}
                />
              )
            )}

            <TableBtn
              img={voucher}
              btnName={t('voucher')}
              func={() =>
                history.push({
                  pathname: `/voucher/${order?.id}`,
                  state: { backText: t('returnToMyMeals') },
                })
              }
            />
          </>
        ) : (
          <p className={'position left'}>
            {isOrder(order, OrderStatusesMap.canceled)
              ? t('orderWasCanceled')
              : t('orderNotActive')}
          </p>
        )
      ) : /* Past Orders */
      order.point > 0 ? (
        <>
          {order?.reported ? (
            order?.status ? (
              <ActiveButton
                additionalClassName="lobby-button"
                label={t('collectedPointMsg')}
              />
            ) : (
              <ActiveButton
                additionalClassName="lobby-button"
                label={t('pending')}
                cb={() => {
                  revertCollectPoints(order);
                }}
              />
            )
          ) : (
            <ActiveButton
              additionalClassName="lobby-button"
              label={order?.status == 1 ? t('collectedPointMsg') : t('pending')}
              cb={() => {
                revertCollectPoints(order);
              }}
            />
          )}
          {/*<p
            onClick={() => console.log('order', order)}
            className={
              order.status ? 'order-status success' : 'order-status pending'
            }
          >
            {order.status ? t('success') : t('pending')}
          </p>*/}
        </>
      ) : (
        <p className={'single-content'}>
          {isOrder(order, OrderStatusesMap.canceled)
            ? t('orderWasCanceled')
            : t('orderNotRealized')}
        </p>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens.options,
  MealTypes: state.app.enums.MealType,
  OrderStatuses: state.app.enums.OrderStatus,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getEmployeeLobbyData,
  getWalletAmountFromApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMeals);
