import { ITranslationStrings } from './interface';

const translations: ITranslationStrings = {
  //@ts-ignore
  translation: {
    poweredBy: 'Powered By',
    /* Sidebar translations */
    // Client
    orderMeal: 'Order meal',
    fastVoucher: 'Meal voucher',
    noFutureVoucher: 'No future voucher',
    reviewOrder: 'Review order',
    noOrderToReview: 'No order to review',
    myMeals: 'My Meals',
    choosingMealTime: 'Choose meal date',
    chooseDishForMeal: 'Choose Dish',
    chooseDishFor: 'Choose dish for',
    chooseDish: 'Choose dish',
    changeDishFor: 'Change dish for',
    changeDish: 'Change dish',
    userProfile: 'Profile',
    error: 'Error',
    logout: 'Logout',
    forgotPassword: 'Forgot Password',

    // Kitchen
    mealManagement: 'Meal management',
    dishManagement: 'Dish management',
    reservationManagement: 'Reservation management',
    reservationByUser: 'Reservation by user',
    tagManagement: 'Tag management',
    ordersHistory: 'Order history',
    Settings: 'Settings',
    dishDelivery: 'Dish delivery',

    registerSuccessfully: 'Registered successfully',
    profileUpdated: 'Profile was updated',
    orderVoucher: 'Order voucher',
    nextMeal: 'Next Meal',
    nextMeals: 'Next Meals',
    noInfo: 'No information',
    noRating: 'No rating',
    mealRanOut: 'Run out',
    toOrder: 'Place order',
    lastOrder: 'Last order',
    selectDishType: 'Dish type',
    selectMealType: 'All meal types',
    mealType: 'Meal Type',
    selectMealDate: 'Select meal date',
    addReview: 'Add Review',
    noOrdersToDisplay: 'There are no orders, time to order your first meal!',
    lobby: 'Lobby',
    hello: 'Hello',
    confirm: 'Confirm',
    signup: 'Sign up',
    resetPassword: 'Reset password',
    orderSummaryTitle: 'Order summary',
    loginTitle: 'Sign in',
    addReviewTitle: 'Add review',
    backToDishSelection: 'Back to dish selection',
    dishSelection: 'Dish selection',
    changeOrder: 'Change',
    orderConfirmation: 'Order confirmation',
    noMeals: 'There are no available dishes for the requested date',
    rateUsingStars: 'Rate this dish',
    writeDishOpinion: 'Write your opinion (optional)',
    sendReview: 'Submit Review',
    additionalInfo: 'More info',
    orderReceivedSuccessfully: 'Order was successfully received',
    viewVoucher: 'View voucher',
    cancelOrder: 'Cancel',
    orderNotRealized: 'Order not realized',
    orderNotActive: 'Order not active',
    dishIsOrdered: 'Ordered dish',
    chooseAnotherDish: 'Choose another dish',
    backToMyMeals: 'Back to my meals',
    backToMealConstruction: 'Back to menu management',
    backToDishManagement: 'Back to dish management',
    backToMealManagement: 'Back to meal management',
    realizedOrders: 'Realized orders',
    ordersFromInventory: 'Orders from inventory',
    alreadyRealizedForDate: 'Order Already realized for this meal',

    realizedOrder: 'Realized',
    realizeOrder: 'Realize order',

    // Form placeholders And Labels
    emailPH: 'Email',
    emailLoginPH: 'Username',
    namePH: 'Name',
    passwordPH: 'Password',
    repeatPasswordPH: 'Repeat Password',
    updatePassword: 'Update Password',
    rolePH: 'Role',
    kitchenPH: 'Kitchen',
    firstNamePH: 'First name',
    lastNamePH: 'Last name',
    phonePH: 'Phone number',
    cardIdPH: 'Card number',
    employeeIdPH: 'Employee number ',
    employeeIdOrEmail: 'Employee number or email',
    connectLabel: 'Connect',
    submitLabel: 'Submit',
    keepMeLoggedLabel: 'Keep me logged in',
    iWantToReceiveViaMail: 'I want to receive via email order confirmations',
    saveInfo: 'Save',
    category: 'Category',
    name: 'Name',
    shortDescription: 'Short Description',
    cancel: 'Cancel',
    raters: 'Raters',
    loginWithOtp: 'Login with OTP',
    labelSendOtp: 'How would you like to receive a password?',
    sendEmailOtp: 'Send me a one-time password',
    labelEnterOtp: 'Enter OTP',
    placeholderOtp: 'Received Code',
    verify: 'Verify',
    or: 'or',

    // Kitchen Management
    controlPanel: 'Control panel',
    addClientUser: 'Add user',
    defineNewMeal: 'Create a new meal',
    mealReport: 'Meal report',
    nextMealToDefine: 'Next meal to define',
    addDish: 'Add dish',
    editDish: 'Edit dish',
    edit: 'Edit',
    manageDishes: 'Manage dishes',

    showPreviousMeal: 'Show previous meal',
    showPreviousDates: 'Show previous dates',
    noFutureDates: 'There are no future dates',

    menuConstruction: 'Menu construction',
    orders: 'Orders',
    dishesInMeal: 'Dishes in meal',
    inventory: 'Inventory',
    delete: 'Delete',
    addImage: 'Add image',
    date: 'Date',
    type: 'Type',

    addDishToMeal: 'Add dish to a meal',
    includedInMeal: 'Included in meal',
    noDishes: 'There are no dishes to display',
    moreInfo: 'More info',
    addMeal: 'Add meal',

    open: 'Open',
    hide: 'Hide',
    toOrders: 'to orders',
    dishes: 'Dishes',

    // Control Panel
    previousMeal: 'Previous meal',
    mealSegmentation: 'Meal segmentation',
    moreMeals: 'More meals',

    dishesRanOutOutOf: 'dishes ran out of',

    numOfReviews: 'Number of reviews',
    averageScore: 'Avg score',

    dishesRatingInTheLast: 'Dishes rating in the last',
    popularMealInTheLast: 'Popular meals in the last',
    days: 'days',

    noInventoryInMeal: 'Meal has no inventory',

    userManagement: 'User Management',
    addUser: 'Add user',
    enterUserDetails: "Enter user's details",
    noUsers: 'There are no users to display',
    systemUsers: 'System users',

    // Validators
    invalidEmail: 'Invalid email',
    invalidID: 'Invalid ID',
    invalidPhoneNumber: 'Invalid number',
    enterNameInHebrew: 'Enter name in Hebrew',
    requiredField: 'Required field',
    passMinLength: 'Use at least 8 characters',
    passwordsDontMatch: 'Password fields dont match',

    // Swal Alerts
    back: 'Back',
    removeDishTitle: 'Remove dish',
    removeDishBtnLabel: 'Remove dish',
    areYouSureYouWantToRemoveDish: "Clicking 'Remove dish' will remove",
    cancelOrderTitle: 'Cancel order',
    orderWasCanceled: 'Order was canceled',
    cancelOrderBtnLabel: 'Cancel order',
    areYouSureYouWantToCancelOrder: "Clicking 'Cancel order' will remove",
    replaceOrderBtnLabel: 'Replace order',
    areYouSureYouWantToReplaceOrder: "Clicking 'Replace order' will replace",
    selectAnotherDishBtnLabel: 'Select another',
    areYouSureYouWantToSelectAnotherDish:
      "Clicking 'Select Another' will present dish selection to replace",
    orderExistsTitle: 'An order already exists',
    enterEmailTitle: 'Enter email',
    enterEmailText: 'Enter your email for a reset link',
    sendViaEmail: 'Send via email',

    resetPasswordTitle: 'Reset password',
    resetPasswordText: 'Check for a link in your email',
    passwordUpdatedTitle: 'Password was updated successfully',
    passwordUpdatedText: 'You can now use your new password to login',
    passwordUpdatedConfirmButtonText: 'Go to login',

    otpEmailSuccess: 'OTP was successfully sent to your Email',
    otpPhoneSuccess: 'SMS was successfully sent to xxx-xxx-',
    otpAddAtleastOneField: 'please fil in a phone number or email',

    // New translations 11/4/21
    inventoryLessThanOrdered: "Inventory can't be lower than reservations",
    inventoryLessThanZero: "Inventory can't be lower than zero",
    returnToMyMeals: 'Return to my meals',
    returnToDishSelection: 'Return to dish selection',
    returnToChangeDish: 'Return to change dish',
    returnToDishManagement: 'Return to dish management',
    returnToMealManagement: 'Return to meal management',
    returnMenuConstruction: 'Return to menu construction',

    noMealsForThisDate: 'No meals for this date',

    // New translations 5/5/21
    imagesForIllustration: 'Pictures are for illustration only',
    noFutureMeal: 'No future meal',
    firstTimeHere: 'First time here? Go to signup >>',
    loginDetails: 'Login details',
    identifyByEmployeeId: 'Login with employee ID',
    identifyByEmail: 'Login with Email',

    // New translations 12/5/21
    thereIsAlreadyAnOrder: 'There is already an order placed for this dish',
    signupFormExplanation: 'Manage your personal information',
    noNextMeal: "There isn't an upcoming meal",

    // New translations 17/6/21
    genericError: 'Oh no :( something went wrong',
    cantDeleteMealWithOrders: 'Meal has orders, therefore cannot be deleted',

    removeMealTitle: 'Remove meal',
    removeMealBtnLabel: 'Remove meal',
    areYouSureYouWantToRemoveMeal:
      "Clicking 'Remove meal' will remove the meal of",

    loadingLabel: 'Eating Smart',

    // New translations 12/7/21
    enterInventoryFor: 'Enter inventory for',

    // New translations 21/7/21
    returnToMenuConstruction: 'Return to Meal Construction',
    returnToMealReport: 'Return to Meal Report',
    realized: 'Realized',
    chefDish: "Chef's Dish",

    // New translations 26/7/21
    addKitchen: 'Add Kitchen',
    kitchensManagement: 'Kitchen Management',
    employeeManagement: 'Employee Management',
    lock: 'Block',
    unlock: 'Unblock',
    noKitchens: 'There are no available kitchens',
    systemKitchens: 'System Kitchens',
    close: 'Close',
    enterKitchenDetails: 'Enter kitchen details',
    active: 'Active',
    notActive: 'Not Active',
    statusPH: 'Status',
    registrationDatePH: 'Registration Date',

    // New translations 15/8/21
    filterByStatus: 'Filter by status',
    statusesPH: 'Status',
    blocked: 'Blocked',
    kitchensPH: 'Kitchens',

    // New translations 2/9/21
    deleteUserTitle: 'Delete User',
    deleteUserBtnLabel: 'Delete User',
    areYouSureYouWantToDeleteUser:
      "Clicking 'Delete User' will delete the user",
    tempPasswordPH: 'Temporary password',
    setPassword: 'Set Password',
    employeeWasAdded: 'Employee was added',

    // New translations 29/9/21
    welcomeToWorkeat: 'Welcome to WorkEat',
    theWayTo: 'the way to eat at work',
    setPasswordDescription: `
                <p style="text-align: left; width:30%">
                    Hi again,<br>
                    We're glad you've come.<br>
                    Let's start with a fresh password:
                </p>
            `,
    resetPasswordDescription: `
                <p style="text-align: left; width:30%">
                Please select a new password
                </p>
            `,
    // New translation 14/10/21
    permissionsError:
      'You have been disconnected from the system. Please Login.',
    paymentManagementButton: 'Payment Management',

    companyNumber: 'Company Number',
    personalHashKey: 'Personal Hash Key',
    merchantId: 'Merchant ID',

    'paymentOption-tenBis': '10bis',
    'paymentOption-cibus': 'Cibus',
    'paymentOption-creditCard': 'Credit Card',
    'paymentOption-salary': 'Salary',
    paymentManagementFormExplanation:
      'Please fill the following details according to your preferences and from NayaX dashboard.',
    paymentPreferncesUpdated: 'Payment Management settings have been updated.',

    price: 'Price',
    positiveNumber: 'It has to be a positive number.',
    needValidEmployeeIdOrEmail:
      'It has to be a valid employee number or an email.',

    reservedByString: 'Reserved by',
    reservedByFirstName: 'First name',
    reservedByLastName: 'Last name',

    reservationDate: 'Order date',
    reservationNumber: '#',

    reservationDetails: 'Details',
    reservationKitchen: 'Kitchen',
    reservationMealType: 'Meal Type',
    reservationMealDate: 'Meal Date',
    reservationDish: 'Dish',
    reservationPayment: 'Payment',
    reservationChannel: 'Channel / Source',
    cardLastDigits: 'Card Last Digits',

    addNewPaymentMethod: 'Add new payment method',
    successfullySavedPaymentMethod: 'Payment method saved successfully.',
    errorSavingPaymentMethod:
      'An error occurred while trying to save payment method. Try again!',
    viewStoredPaymentMethods: 'View stored payment methods',
    updateDefaultPaymentMethod: 'Update default payment method',

    employeeName: 'Name',
    employeeNo: 'Internal #',
    cardNo: 'Card Number',
    status: 'Status',
    tag: 'Tag Name',
    addTag: 'Add tag',
    returnToTagManagement: 'Return to tag management',
    isActive: 'Active',
    isPublic: 'Public',
    selectTags: 'Select tags',
    registrationDate: 'Registered on',
    kitchenname: 'Kitchens',
    email: 'Email',
    action: 'Action',
    downloadCSV: 'Download CSV export file',
    add: 'Add',
    pageSize: 'Page Size',
    editbutton: 'Edit',
    deletebutton: 'Delete',
    filter: 'Filter',
    role: 'Role',
    userName: 'Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    password: 'New Password',
    roles: 'Roles',
    update: 'Update',
    updateUser: 'Update User',
    updateEmployee: 'Update Employee ',
    addUsers: 'Add User',
    addEmployee: 'Add Employee ',
    okay: 'Okay',
    tagUpdatedSuccessfully: 'Tag updated successfully.',
    cookieContent:
      'Our website uses cookies to improve your experience. We will assume you are ok with this',
    acceptbtn: 'Accept',
    rejectbtn: 'Reject',
    mealtype: 'Meal Type',
    kitchenName: ' Kitchen Name',
    createddate: 'date',
    companyName: ' company Name',
    companyNameLabel: 'Company name',
    generateSalaryFile: 'Generate salary file',
    dateError: 'To date cannot be less than from date',
    totalInventory: 'Inventory',
    totalReservations: ' Total reservations',
    totalRealized: 'Total realized',
    dishName: 'Dish name',
    tagsname: 'Tags Name',
    tInventory: 'Inventory',
    reservations: 'Reservations',
    tRealized: 'Realized',
    chef: 'Chef',
    printReport: 'Print Report',

    security: 'Security',
    securityFormTitle: "Allow management access to the following IP's",
    securityFormBlacklistPlaceholder: 'Comma seperated IPv4 list',
    securitySubmitButton: 'Save',
    ipv4FormatIncorrect: 'IPV4 format is not correct!',
    securityFormSuccess: 'Security is successfully updated',
    confirmModalTitle: 'Are you sure?',
    //19-12-2021
    fromDate: 'From Date',
    toDate: 'To Date',
    orderId: 'Order ID',
    clearDish: 'Clear All Dishes',
    updateInventory: 'Update Inventory',
    copyMeal: 'Copy from another Meal',
    clearText: 'Are you sure you want to delete all dishes?',
    deleteMealTypeConfirmation: 'Are you sure you want to delete meal type?',
    selectSlot: 'Select Slot',
    preferedTime: 'Please select your preferred time',
    breakfast: 'Breakfast Timing',
    lunch: 'Lunch Time',
    dinner: 'Dinner Time',
    overtime: 'Overtime Time',
    night: 'Night Time',
    firstSeat: 'First seat',
    secondSeat: 'Second seat',
    thirdSeat: 'Third seat',
    dishSelections: 'Continue to dish selection',

    addMealtype: 'Meal Types',
    mealtypes: 'Meal Type',
    startTime: 'Start Time',
    endTime: 'End Time',
    mealtypeSlot: 'Meal Slots',
    maxReservations: 'Max Reservations',
    selectDate: 'Select Date',
    selectMealtype: 'Select Meal',
    voucherExpired: 'Voucher Expired',
    mealTimeManagement: 'Meal Type Management',
    maxReservationMessage: 'No more room left at this time',
    ok: 'OK',
    extendalId: 'External ID',
    orderIndex: 'Display Order',
    existTime: 'There is already a slot setup in this time',
    deleteMealTypeSlotError: 'Only meals without orders can be deleted',
    deletedSuccessfully: 'Deleted Successfully',
    editSuccessfully: 'Edited Successfully',
    addedSuccessfully: 'Added Successfully',
    orderIndexerror: 'Order should be unique',
    orderZeroError: 'order can not be zero',
    noSlotsAvailable: 'No slots available',
    UpdateMaxReservations: 'Update Max Reservations',
    realizedOrderText: 'Order was realized',

    /*******logs  */
    description: 'Description',
    time: 'Time',
    controller: 'Controller',
    tableName: 'TableName',
    updatedData: 'Updated Data',

    welcomeText: 'Welcome',
    mealAction: 'Meal actions',
    send: 'Send',

    viewMore: 'View more',
    allRatingDish: 'All Rating for this dish',
    reviews: 'Reviews',
    comments: 'Comments',
    comment: 'Comment',
    addComment: 'Add a Comment',
    showdishes: 'Show Dishes',

    /* Audit translate */
    audit: 'Audit',
    id: 'ID',
    fieldName: 'Field name',
    eventType: 'Event type',
    prevValue: 'Previous value',
    currValue: 'Current value',
    pageName: 'Page name',

    /* ***** */

    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    logViewer: 'Log Viewer',
    user: 'User',
    rating: 'Rating',
    averageMeal: 'Average meal review score',
    popuplar: 'Popular',
    apply: 'Apply',
    noAverageMealReview: ' Average meal has no review',
    noPopularDishes: 'No popular dishes',
    noFastVoucher:
      "Once the next meal begins we will present a link to your reservation QR code. Meal started and no link? It's time to make an order",
    day: 'Day',
    noofDishes: 'No of Dishes',
    addPreviousDish: 'Add',
    availableExtras: 'Extras for your meal',
    finalizeOrder: 'Finalize Order',
    skip: 'Skip',
    extrameal: 'Extras in meal',
    addExtras: 'Add Extras',
    paymentConfirm:
      'When your order is realized, a Transaction will be made with credit card ending with: ',
    paymentAlert:
      ' Your account is missing an active payment method - In order to complete the reservation your need to add a credit card and start over. Click on the "Add payment method" to go to the payment management page.',
    paymentInfo: 'Payment Info',
    addMethod: 'Add payment method',
    extras: 'Extras',
    none: 'None',
    slot: 'Slot',
    reservationExport: 'Reservations exported on',
    fromtime: 'From (Time)',
    totime: 'To (Time)',
    applyfilter: 'Apply Filter',
    CopyFromPreviousDish: 'Copy from another meal',
    CopyMeal: 'Copy Meal',
    'Credit Card': 'Credit Card',

    //sms message //
    yourOrder: 'Your order at',
    of: 'of',
    in: 'in',
    smsMessage:
      'has been accepted, click on the link to display the QR code for the kitchen redeem',
    RedirectingYouToLoginPage: 'Redirecting you to login page',
    cancelOrderText: 'Click on "OK" to cancel your order of',
    cancelOrderTextin: 'in',
    changeOrderText: 'Click on "Confirm" to change your order of',
    changeOrderTextto: 'to',
    passwordSecurity:
      'Password length must be minimum 8 letters, at least 1 uppercase letter, 1 lower case letter and 1 symbol.',
    updatedMessage: 'Updated Successfully',
    addedmessage: 'Added successfully',
    deleteMessage: 'Deleted Successfully',
    currentPasswordError: 'Enter Current Password',
    voucherRealized: 'Voucher already realized.',
    voucherNotDisplay: 'The voucher cannot be displayed',
    search: 'Search',
    chooseYourDish: 'Choose your dish',
    profile: ' Profile',
    scanVoucher: 'Please scan this QR to realize your order.',
    contractorManagement: 'Contractor Management',
    contactPersonName: 'Contact Person Name',
    contactEmail: 'Contact Email',
    contactPhone: 'Contact Phone',
    addContractor: 'Add Contractor',
    reservation: 'Reservation',
    updateContractor: 'Update Contractor',
    contractor: 'Contractor',
    paymentDetails: 'Payment Details',
    amountPaid: 'Amount Paid',
    timeOfPayment: 'Time of payment',
    transactionId: 'Transaction ID',
    futureMeals: 'Current Meal Management',
    pastMeals: 'Past Meals',
    reports: 'Reports',

    changePassword: 'Change Password',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    paymentManagement: 'Payment Management',
    selectPaymentOption: 'Select a payment option',
    selectCurrency: 'Select a currency',
    realizedStatus: 'Realized Status',
    contractorName: 'Contractor Name',
    selectContractor: 'Select contractor',
    allContractor: 'All contractors',
    alertForContractor:
      "You can't  order the meal because you don't have any kitchen.",
    realizedDate: 'Realized Date',
    realizedMessage: 'Reservation already realized on',
    at: 'at',
    webapp: 'Webapp',
    dispenser: 'Dispenser',
    resetPasswordExpired:
      'The link to reset the password isn’t valid or it was already used - If needed please resend a request for reset password link.',
    character: 'The character',
    isNotVaild: 'isn’t valid with the password policy -',
    inCorrectPassword: 'Incorrect Password',
    contractorEmployeeManagement: 'Contractor users',
    addContractorEmployee: 'Add Contractor Employee',
    updateContractorEmployee: 'Update Contractor Employee',
    contractorId: 'Contractor ID',
    userWalletManagement: 'User Wallet Management',
    creditDate: 'Credit Date',
    creditAmount: 'Credit Amount',
    debitAmount: 'Debit Amount',
    debitDate: 'Debit Date',
    remainingAmount: 'Balance',
    wallet: 'Wallet',
    chooseCurrency: 'Please choose currency',
    cancellationIsAvaiableUntill: ' Cancellation is available until',
    ofTheSame:
      'of the same day as the meal, reservation that won’t be cancelled or redeemed will be charged',
    cancellationTime: 'Cancellation Time',
    multipleDish: 'Multiple Dish',
    creditPoints: 'Credit Points',
    orderMealHeading: 'ORDER MEAL',
    continue: 'Continue',
    my: 'My',
    order: 'Order',
    finish: 'Finish',
    chooseADishToContinue: 'Choose a dish to Continue',
    totalAmount: 'Total Amount',
    viewOrder: 'View Order',
    orderDate: 'Order Date',
    paymentMethod: 'Payment Method',
    totalData: 'Total Data',
    dish: 'Dish',
    product: 'Product',
    extra: 'Extra',
    quantity: 'Quantity',
    mealDate: 'Meal Date',
    myOrders: 'My Orders',
    voucher: 'Voucher',
    menu: 'Menu',
    selectMealTypeSecond: 'Select Meal Type',
    Category: 'Category',
    accountSettings: 'Account Settings',
    account: 'Account',
    password1: 'Password',
    payment: 'Payment',
    save: 'Save',
    passwordSettings: 'Password Settings',
    currentPassword: 'Current Password',
    newPassword1: 'New Password',
    confirmPassword: 'Confirm Password',
    enterCurrentPassword: 'Enter Current Password',
    enterNewPassword: 'Enter New Password',
    confirmNewPassword: 'Confirm New Password',
    terminalNumber: 'Terminal Number',
    russian: 'Russian',
    english: 'English',
    hebrew: 'Hebrew',
    editKitchen: 'Edit Kitchen',
    placeOrderMessage: 'Your order has been successfully placed',
    orderDetails: 'Order Details',
    confirmOrder: 'Are you sure you want to confirm order of',
    alertOrderMsg: 'You have allready ordered!.',
    loginText: 'Log in',
    loginFormText: 'Let’s get started',
    loginDesc: 'Log in to continue in our website',
    remember: 'Remember me',
    addToOrder: 'Add to Order',
    runOut: 'Run Out',
    userStatus: 'User Status',
    UnavailableMealTypes: 'Currently there is no meal Types Available.',
    addMealType: 'Add Meal Type',
    image: 'Image',
    noExtrasDisplay: 'There are no Extras to display',
    walletManagement: 'Wallet Management',
    mealName: 'Meal Name',
    credit: 'Credit',
    walletDetails: 'Wallet details',
    insufficientWalletAlert:
      'Your Wallet balance is not sufficient to make this order.',
    walletBalance: 'Wallet Balance',
    BreakfastRealized: 'Breakfast Realized',
    BreakfastReservation: 'Breakfast Reservation',
    EveningRealized: 'Evening Realized',
    EveningReservation: 'Evening Reservation',
    LunchRealized: 'Lunch Realized',
    LunchReservation: 'Lunch Reservation',
    NightExtraRealized: 'Night Extra Realized',
    NightExtraReservation: 'Night Extra Reservation',
    NightRealized: 'Night Realized',
    NightReservation: 'Night Reservation',
    OverTimeRealized: 'Over Time Realized',
    OverTimeReservation: 'Over Time Reservation',
    cardId: 'Card Id',
    employeeId: 'Employee Id',
    totalReservation: 'Total Reservation',
    user_type: 'User Type',
    copyUrl: 'Copy URL',
    mealTypeName: 'Meal Type',
    totalOfMeals: 'Total Meals',
    ofreservations: 'Reservations',
    totalOfInventory: 'Inventory',
    perceReservedInventory: 'Reserved/Inventory %',
    ofRealised: 'Realised',
    ofRealisedInventory: 'Realized/Inventory %',
    detailedReservations: 'Detailed reservations',
    sortBy: 'Sort By',
    dishNameAscending: 'Dish Name(A-Z)',
    dishNameDescending: 'Dish Name(Z-A)',
    priceAscending: 'Price(Highest to Lowest)',
    priceDescending: 'Price(Lowest to Highest)',
    ratingAscending: 'Rating(Highest to Lowest)',
    ratingDescending: 'Rating(Lowest to Highest)',
    contractors: 'Contractors',
    wallets: 'Wallets',
    employee: 'Employee',
    kitchens: 'Kitchens',
    kitchen: 'Kitchen',
    totalPrice: 'Total Price',
    review: 'Review',
    alreadyAdded: 'Already added',
    allMeal: 'All Meals',
    reviewScore: 'Review Score',
    SecondaryText: 'Secondary Text',
    GotoControlPanel: 'Go to Control Panel',
    Averagedailyreviewsscore: 'Average daily reviews score',
    home: 'Home',
    placeHolderByDishSearch: 'Search Dishes by Name, Category, Price or Rating',
    added: 'Added',
    reviewManagement: 'Review Management',
    updateDish: 'Update Dish',
    openSelectMenu: 'Open this select menu',
    dishAddedMessage: 'Dish Added Successfully',
    dishUpdatedMessage: 'Dish Updated Successfully',
    selectUserType: 'Select user type',
    admin: 'Admin',
    siteManager: 'Site Manager',
    kitchenManager: 'Kitchen Manager',
    kitchenWorker: 'Kitchen worker',
    contractorEmployee: 'Contractor Employee',
    contractorAdmin: 'Contractor Admin',
    client: 'Client',
    userType: 'User Type',
    reviewTrend: 'Review Trend',
    termsAndCondition: 'Terms & Condition',
    inv: 'Inv',
    ord: 'Ord',
    rel: 'Rel',
    // New translations 08/02/22
    forgotSubHeading:
      'Fill in your e-mail address below and we will send you an email with further instructions.',
    poweredByWorkEat: 'Powered By WorkEat',
    searchEmployeePH: 'Search Employee by Name, Email, or Date',
    searchUserPH: 'Search Users by Name, Email or Date',
    setting: 'Setting',
    searchForTagManagement: 'Search Tags by Name',
    updateTag: 'Update Tag',
    kitchenDetail: 'Kitchen Details',
    logViewerDetails: 'Log Viewer Details',
    previousMealDetail: 'Previous Meal Detail',
    ordered: 'Ordered',
    mealsDetailsTitle: 'Meals Details',
    realizeDate: 'Realize date',
    totalsByUsers: 'Reservations by users',
    mealTypeInReservation: 'Reservation by meal type',
    freeTextSearch: 'Free text search',
    pending: 'Pending',
    success: 'Success',
    canceled: 'Cancelled',
    reportType: 'Report Type',
    invoice: 'Invoice',
    guestUsers: 'Guest Users',
    updateGuestUser: 'Update Guest User',
    addGuestUser: 'Add Guest User',
    guestUserManagement: 'Guest User Management',
    startDate: 'Start Date',
    endDate: 'End Date',
    past: 'Past',
    next: 'Next',
    meal: 'Meal',
    noOrder: 'No meal for order',
    dateDay: 'Date & Day',
    selectDishes: 'Select Dishes',
    approve: 'Approve',
    nextLobbyMeals: 'Next Meals',
    pastLobbyMeals: 'Past Meals',
    noMeal: 'There is no meal to copy so please choose another one.',
    orderRealized: 'Order realized',
    ofRealizedReserved: 'Realized/Reserved %',
    today: 'Today',
    walletAmount: 'Amount',
    walletAmountWord: 'Amount',
    UpdateWalletAMount: 'Update Wallet Amount',
    updatedOn: 'Updated On',
    realizeError:
      'The meal hasn’t started yet - Do you want to continue with the redemption or cancel?',
    notRealized: 'Not Realized',
    realizedMethod: 'Realized Method',
    realizedByUserName: 'Realized by user name',
    realizedByDispenserName: 'Realized by dispenser Name',
    resend: 'resend',
    collectPoints: 'Collect',
    confirmCollectPointMessage: 'Do you want to collect',
    alertNoMealsAbleMessage: 'No meals available!.',
    successMealTypePointMessage: 'points have been collected successfully',
    errorMealTypePointMessage:
      'You have allready collected point for this meals.',
    pointMsg: 'points for this meal?',
    collectedPointMsg: 'Approved',
    is_include_sallary_file: 'Include Salary File',
    is_calculate_Price: 'Calculate dish price',
    updateMealType: 'Update Meal Type',
    revertCollectPointMessage: 'Do you want to return collect',
    successReturnMealTypePointMessage: 'points have been returned successfully',
    pendingCollectedPoint: 'Credit Point',
    textManually: 'Manually',
    UserName: 'User Name',
    pay: 'Pay',
    magneticStripe: 'Magnetic stripe',
    paid: 'Paid',
    cibus: 'Cibus',
    paymentSuccessMessage: 'Payment has been successful.',
    paymentFailureMessage: 'Sorry! Payment fail.',
    paymentOrderId: 'Payment Order Id',
    notPaid: 'Not paid',
    paymentStatus: 'Payment Status',
    cibusPayment: 'Cibus payment',
    cibusPaymentAlert: 'Are you sure to want to pay this order ?',
    isSalary: 'Salary',
    isSalaryReport: 'Salary',
    isfile: 'File name',
    isCreatedAt: 'CreatedAt',
    isUpdatedAt: 'UpdatedAt',
    isDownload: 'Download',
    salaryFileReport: 'Salary report',
    isEntries: 'Entries',
    getReservation: 'Get Reservations',
    dropImage: 'Click here or drag an image',
    remove: 'Remove',
    deleteMeal: 'Delete Meal',
    companyId: 'Company ID',
    companyCard: 'Company Card',
    referenceId: 'Restaurant ID',
    posId: 'POS ID',
    applicationId: 'Application ID',
    authorization: 'Authorization',
    showReservation: 'Show Reservation',
    resendMessage: "Password reset mail has been send to user's email.",
    cibusError: 'Cibus Error',
    codeNumber: 'Code No.',
    manual: 'Manual',
    qr: 'QR',
    card: 'Card',
    isAproved: 'Approved',
    guestLink: 'Guest Link',
    enterQuantityFor: 'Enter quantity for',
    forceSetPasswordDescription: ` <p style="text-align: left; width:30%">Your password has expired - choose a new password </p>`,
    yes: 'Yes',
    no: 'No',
    cancelledBy: 'Order cancelled by',
    cancelledOrderTime: 'Order cancelled time',
    selectReservationStatus: 'All reservation statuses',
    reservationStatus: 'Reservation status',
    reservationType: 'Reservation type',
    totalCreditPointRequest: 'Total Points',
    pendingCreditPointRequest: 'Pending Points',
    approvedCreditPointReqquest: 'Approved Points',
    creditPointPercentage: 'Credit Point %',
    paymentType: 'Payment type',
    selectPaymentType: 'All payment types',
    selectReservationType: 'All reservation types',
    displayName: 'Display Name',
    deviceType: 'Device Type',
    location: 'Location',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    addKitchenDevice: 'Add Kitchen Device',
    kitchenDevices: 'Kitchen Devices',
    updateKitchenDevice: 'Update Kitchen Device',
    token: 'Token',
    kitchenDevice: 'Kitchen Device',
    vatFreePrice: 'Vat Free Price',
    addOrderUrl: 'Add Order Url',
    paymentUrl: 'Payment Url',
    webAuth: 'WebAuth',
    oAuth: 'OAuth',
    host: 'Host Url',
    languages: 'Languages',
    shortCode: 'Short Code',
    isRTL: 'Is RTL',
    languageStatus: 'Language Status',
    addLanguage: 'Add Language',
    updateLanguage: 'Update Language',
    language: 'Language',
    notEdit: 'Not editable',
    copyToken: 'Copy Token',
    downloadFile:
      'Your file is downloading in the background, please do not close the site',
    cancellOrder: 'Order canceled successfully',
    BeerShevaconveniencestoreRealized: 'Beer Sheva convenience store Realized',
    BeerShevaconveniencestoreReservation:
      'Beer Sheva convenience store Reservation',
    LunchCafeteriaRealized: 'Lunch Cafeteria Realized',
    LunchCafeteriaReservation: 'Lunch Cafeteria Reservation',
    MorningCafeteriaRealized: 'Morning Cafeteria Realized',
    MorningCafeteriaReservation: 'Morning Cafeteria Reservation',
    userId: 'User ID',
    createdDate: 'Created date',
    Refusal_by_credit_company: 'Отказ кредитной компании',
    enterNonEmptyPassword: 'Enter current password',
    FileInpEmpty: 'File was not uploaded',
    TitleEmpty: 'Header cannot be empty',
    removeForeignContractor:
      'This contractor cannot be removed as it relates to another contractor administrator',
    attendance: 'Attendance',
    attendanceTitle: 'Attendance',
    entry: 'Entry',
    exit: 'Exit',
    all: 'All',
    deleteAttachedFile: 'Are you sure you want to delete the attached file?',
    attachedFile: 'Attached file',
    multipleReservations: 'Multiple Reservations',
    fileTitle: 'File title',
    titleTooLarge: 'max 20 characters',
    fileDeleted: 'File was deleted',
    orderedAndRealized:
      'On the selected date and time the order has already been ordered and realized',
    orderedMeal:
      'On the selected date and time the order has already been ordered. Do you want to edit your order?',
    selectKitchen: 'Select kitchen',
    deleted: 'Deleted',
    created: 'Created',
    selectUserStatus: 'Select user status',
    backOrEdit:
      'Do you want to continue adding products to your shopping cart or do you want to finish your reservation?',
    checkout: 'Checkout',
    paymentNotMatch:
      'There are no payment details in this account - the payment must be completed and redeemed again',
    generateSalaryText:
      'Select the dates that you would like the salary file to include:',
    dateRange: 'Date range',
    salaryGenerated: 'Salary file successfully generated',
    reservationByMeal: 'Reservation by meal',
    copyMeals: 'Copy meals',
    copyMealFromTitle: 'Select the dates on which you want to copy meals from',
    copyMealToTitle: 'To which dates to copy the meals from',
    copyMealFromText: 'Copy meals from',
    to: 'to',
    copy: 'Copy',
    copyMealSuccess: 'Copy was successful',
    copyMealStarted:
      'Copying of dishes started, when dishes are updated you will receive a notice',
    dateRangeNotMatch:
      'In the selected date range, the number of days does not match the number of days in the previous step',
    dateRangesIntersect: 'Date ranges intersect',
    copyPastDateTitle: 'Copying past date',
    copyPastText: 'Are you sure you want to copy the dishes to the past?',
    selectMealTypes: 'Select meal types',
    allMealTypes: 'All meal types',
    specificMealTypes: 'Select specific meal types',
    mealsNotSelect: 'Meals not selected',
    noPastDates: 'There are no past meals',
    relatedMealTypes: 'Related meal types',
    totalByMeals: 'total by meals',
    totalByUsers: 'total by users',
    totalByReservations: 'total by reservations',
  },
};

export default translations;
