import React, { useState } from 'react';
import { setLanguage } from '../../store/languages/actions';
import { connect } from 'react-redux';

import DownArrow from '../../assets/images/arrow-down__green.svg';
import { getMealTypes, setEnums } from '../../store/app/actions';
import enums from '../../services/enums';
import Dropdown from 'react-bootstrap/Dropdown';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const LanguageSelection = ({
  languages,
  currentLanguage,
  setLanguage,
  getMealTypes,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [hide, setHide] = useState(true);

  const currentLocation = useLocation();

  const changeLanguage = async (language) => {
    setHide(false);
    if (
      window.location.hash.includes(
        currentLanguage?.shortCode && 'terms-condition'
      )
    ) {
      window.location.href = `#/${window.location.hash.split('/')[1]}/${
        language.shortCode
      }`;
    }
    if (
      window.location.hash.includes(
        currentLanguage?.shortCode && 'privacy-policy'
      )
    ) {
      window.location.href = `#/${window.location.hash.split('/')[1]}/${
        language.shortCode
      }`;
    }
    window.location.reload();
    setIsSelectOpen(false);
    await setLanguage(language);
    let _enums = await enums.init();
    await setEnums(_enums);
    await getMealTypes();
    document.body.classList.remove(language.isRTL ? 'ltr' : 'rtl');
    document.body.classList.add(language.isRTL ? 'rtl' : 'ltr');
  };

  const getFlagUrl = (shortCode) => {
    return `${process.env.PUBLIC_URL}/flags/${shortCode}.svg`;
  };
  return hide ? (
    <Dropdown>
      {languages.length > 1 ? (
        <React.Fragment>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="language-left-section">
              <img
                src={getFlagUrl(currentLanguage?.shortCode)}
                width={37}
                height={37}
                alt={''}
              />
              <span>{currentLanguage?.displayName}</span>
            </div>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {languages
              .filter((l) => l.shortCode != currentLanguage?.shortCode)
              .map((lang, index) => (
                <Dropdown.Item key={index} onClick={() => changeLanguage(lang)}>
                  <img
                    src={getFlagUrl(lang?.shortCode)}
                    width={37}
                    height={37}
                    alt={''}
                  />{' '}
                  {lang.displayName}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </React.Fragment>
      ) : (
        <Button style={{ cursor: 'default' }}>
          <div className="language-left-section">
            <img
              src={getFlagUrl(currentLanguage?.shortCode)}
              width={37}
              height={37}
              alt={''}
            />
            <span>{currentLanguage?.displayName}</span>
          </div>
        </Button>
      )}
    </Dropdown>
  ) : (
    ''
  );
};

const mapStateToProps = (state) => ({
  languages: state.languages.options,
  currentLanguage: state.languages.current,
});

const mapDispatchToProps = {
  setLanguage,
  setEnums,
  getMealTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelection);
