import action from '../action.js';
import types from './types';
import Storage from '../../services/storage';
import http from '../../services/http';
import Http from '../../services/http';

export function setEnums(enums) {
  return action(async (dispatch) => {
    dispatch({ type: types.SET_ENUMS, payload: enums });
    return enums;
  });
}

export function toggleModal(visible) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_MODAL_VISIBLE, payload: visible });
  });
}

export function toggleSidebar(opened) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_SIDEBAR, payload: opened });
  });
}

export function toggleLoader(status) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_LOADER, payload: status });
  });
}

export function toggleFetchingLoader(status) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: status });
  });
}

export function toggleAlertMessage(message) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_ALERT_MESSAGE, payload: message });
  });
}

export function showAlert(message) {
  return action(async (dispatch) => {
    dispatch({
      type: types.TOGGLE_ALERT_MESSAGE,
      payload: { show: true, ...message },
    });
  });
}

export function setLayoutOptions(opts) {
  return action(async (dispatch) => {
    dispatch({
      type: types.SET_LAYOUT_OPTIONS,
      payload: opts,
    });
  });
}

export function useLoader(func) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_LOADER, payload: true });
    let res = await func();
    dispatch({ type: types.TOGGLE_LOADER, payload: false });
    return res;
  });
}

export function useFetchingLoader(func) {
  return action(async (dispatch) => {
    try {
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: true });
      //await new Promise((resolve) => setTimeout(resolve, 200));
      let res = await func();
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: false });
      return res;
    } catch (ex) {
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: false });
      if (ex.redirect) redirect(dispatch, ex.redirect);

      throw ex;
    }
  });
}

const redirect = async (dispatch, path) => {
  dispatch({ type: 'reset' });
  dispatch({ type: types.SET_REDIRECT, payload: path });
};

export function redirectDone() {
  return action(async (dispatch) => {
    dispatch({ type: types.SET_REDIRECT, payload: null });
  });
}

export function getMealTypes() {
  return action(async (dispatch) => {
    let { data: mealTypes } = await http.get('mealTypes');
    dispatch({ type: types.GET_MEAL_TYPES, payload: mealTypes });
  });
}

export function pastMealsData(filter) {
  return action(async (dispatch) => {
    let data = await http.get('meals/pastMeal', filter);
    dispatch({ type: types.GET_PAST_MEAL, payload: data });
  });
}

export function allPastMealsData(filter) {
  return action(async (dispatch) => {
    let data = await http.get('meals/pastMeal', filter);
    dispatch({ type: types.GET_ALL_PAST_MEAL, payload: data });
  });
}

export function dishesData(filter) {
  return action(async (dispatch) => {
    const data = await http.get('dishes', filter);
    dispatch({
      type: types.GET_DISH_DATA,
      payload: data,
    });
  });
}

export function getAllReviews(filter) {
  return action(async (dispatch) => {
    const data = await http.get('orders/getAllRating', filter);
    dispatch({
      type: types.GET_ALL_REVIEWS,
      payload: data,
    });
  });
}

export function currentMealsData(filter) {
  return action(async (dispatch) => {
    const data = await http.get('meals/currentMeal', filter);
    dispatch({
      type: types.GET_CURRENT_MEAL,
      payload: data,
    });
  });
}

export function getAllLogs(filter) {
  return action(async (dispatch) => {
    const data = await http.get('activityLogs/getAll', filter);
    dispatch({
      type: types.GET_ALL_LOGS,
      payload: data,
    });
  });
}
export function getAllAuditMess(filter) {
  return action(async (dispatch) => {
    const data = await http.get('audit/getAll', filter);
    dispatch({
      type: types.GET_ALL_AUDITS,
      payload: data,
    });
  });
}

export function tagsData(filter) {
  return action(async (dispatch) => {
    const data = await http.get('tags', filter);
    dispatch({
      type: types.GET_TAG_DATA,
      payload: data,
    });
  });
}
export function getWalletAmountFromApi(userId) {
  return action(async (dispatch) => {
    const walletData = await http.get(`users/getUserWallet/${userId}`);
    dispatch({
      type: types.SET_WALLET_DATA,
      payload: walletData?.amount,
    });
  });
}
export function setWalletAmount(amount) {
  return action(async (dispatch) => {
    dispatch({
      type: types.SET_WALLET_DATA,
      payload: amount,
    });
  });
}
export function setOpenCopyMealPopup(value) {
  return action(async (dispatch) => {
    dispatch({
      type: types.OPEN_COPY_MEAL_POPUP,
      payload: value,
    });
  });
}
export function setISuccessCopyMealPopup(value) {
  return action(async (dispatch) => {
    dispatch({
      type: types.IS_SUCCESS_COPY_MEAL_POPUP,
      payload: value,
    });
  });
}
