import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import {
  validateEmployeenew,
  validateUsers,
  validateGuestUsersNew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import http from '../../services/http';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '20px 30px',
  borderRadius: '5px',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
  editMode,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(event) => cb(event.target.value)}
        required
        disabled={!editMode}
        type={type}
        autoComplete="new-password"
      />
    </>
  );
};

const AddUserPopup = ({
  open,
  handleClose,
  handleAddNewUser,
  cibusEnabled,
  handleAddNewGuestUser,
  Roles,
  Kitchens,
  UserStatuses,
  setPayload,
  payload,
  handleUpdateUser,
  handleUpdateGuestUser,
  setIsEdit,
  isUpdate,
  userData,
  t,
  setRolesIds,
  rolesIds,
  setKitchensIds,
  kitchensIds,
  errors,
  setErrors,
  newRoles,
  user,
  contractorList,
  showContractor,
  setShowContractor,
  setSelectContractor,
  selectContractor,
  setshowGuestUser,
  showGuestUser,
  setselectGuestUser,
  selectGuestUser,
  getMealTypes,
  setMealTypesIds,
  mealTypesIds,
  forceResetPassword,
  setForceResetPassword,
  setMealTypesKitchenWise,
  getMealTypesKitchenWise,
  cardGenerationUrlForUser,
  employeeNumberLengthForUser,
  cardNumberLengthForUser,
  isStrippedCard,
}) => {
  const [cardGenerationFunc, setCardGenerationFunc] = useState();
  useEffect(() => {
    if (open) {
      if (payload.role[0] === 6 || payload.role[0] === 7) {
        setshowGuestUser(false);
        setShowContractor(true);
      } else if (payload.role[0] === 8) {
        setShowContractor(false);
        setshowGuestUser(true);
      } else {
        setshowGuestUser(false);
        setShowContractor(false);
      }
    }
  }, [open]);
  useEffect(() => {
    setSelectContractor(
      payload?.contractors?.map((contractor) => {
        return {
          value: contractor.id,
          label: ContractorMap_ValueId[contractor.id],
        };
      })
    );
  }, []);

  useEffect(() => {
    if (contractorList && Array.isArray(contractorList)) {
      setPayload({
        ...payload,
        contractorsIds: [contractorList[0].id],
        magneticStripe: contractorList[0]?.magneticStripe ?? '',
      });
    }
  }, []);
  const KitchensMap = Kitchens.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const MealTypesMap = getMealTypes?.reduce((acc, mealType) => {
    return { ...acc, [mealType.id]: mealType.display };
  }, {});
  const RolesMap_ValueId = newRoles?.reduce((acc, role) => {
    if (role.id !== 5) {
      return { ...acc, [role.id]: role.display };
    } else {
      return acc;
    }
  }, {});
  const ContractorMap_ValueId = contractorList?.reduce((acc, contractor) => {
    return { ...acc, [contractor.id]: contractor.name };
  }, {});
  const StatusMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.display };
  }, {});

  useEffect(() => {
    if (cardGenerationUrlForUser && open) {
      const fetch = async () => {
        const response = await http.get(cardGenerationUrlForUser);
        const func = new Function(`return ${response.cardGenerationWD}`);
        setCardGenerationFunc(func);
      };
      fetch();
    }
    if (isUpdate && payload?.mealTypeId && payload?.mealTypeId.length !== 0) {
      // setMealTypesIds([newMealTypeDateArray[0].value]);
      let res;
      setPayload((prev) => {
        res = prev?.mealTypeId.filter(
          (elem) => MealTypesMap[elem] !== undefined
        );
        return { ...prev, mealTypesIds: res, mealTypeId: res };
      });
      setMealTypesIds(
        res
          ? res.map((el) => {
              return { id: el, label: MealTypesMap[elem] };
            })
          : []
      );
    }
  }, [open]);

  const [role] = useState(4);
  // const [errors, setErrors] = useState({});
  const [pError, setPError] = useState(false);
  const [status, setStatus] = useState({ value: 3, label: 'Active' });

  const handleChange = (name, value) => {
    if (name === 'isPasswordTemporary') {
      setForceResetPassword(!forceResetPassword);
      value = !forceResetPassword;
    }

    const { isValid, error } = validateUsers(
      value,
      name,
      cardNumberLengthForUser,
      employeeNumberLengthForUser
    );
    setErrors({ ...errors, ...error });
    if (cardGenerationUrlForUser && cardGenerationFunc) {
      if (name === 'employeeId' && value) {
        const result = cardGenerationFunc(value);
        setPayload((prev) => ({ ...prev, card: result }));
      }
      if (name === 'employeeId' && !value) {
        setPayload((prev) => ({ ...prev, card: '' }));
      }
    }
    setPayload((prev) => ({ ...prev, [name]: value }));
    if (isUpdate && payload['role'][0] === 8) {
      setshowGuestUser(true);
    }
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      firstName: payload.fName,
      lastName: payload.lName,
      email: payload.email,
      phone: payload.phone,
      userStatusId: status,
      password: payload.password === '' ? undefined : payload.password,
      cardId:
        !payload.card || payload.card === '' || payload.card === '--'
          ? null
          : isStrippedCard
          ? payload.card.replace(/^0+/, '')
          : payload.card,
      employeeId: payload.employeeId,
      roleId: [rolesIds],
      kitchensIds,
      contractorsIds:
        rolesIds == 4
          ? null
          : payload.contractorsIds
          ? payload.contractorsIds
          : null,
      connectTeam: 0,
      magneticStripe: payload.magneticStripe,
      hireFrom: payload?.hireFrom ? payload?.hireFrom : null,
      hireTill: payload?.hireTill ? payload?.hireTill : null,
      mealTypeId: payload?.mealTypeId ? payload?.mealTypeId : null,
      isPasswordTemporary: rolesIds === 8 ? false : payload.isPasswordTemporary,
    };
    if (showGuestUser && !isUpdate) {
      const { isValid, errors } = validateGuestUsersNew(details);
      setErrors(errors);
      if (kitchensIds && kitchensIds?.length == 0)
        return setErrors({ ...errors, kitchensIds: 'Kitchen is required' });
      if (isValid) {
        details.cardId = '';
        details.employeeId = '';
        details.password = '';
        //details.contractorId = '';
        await handleAddNewGuestUser({ ...details });
      }
    } else {
      const { isValid, errors } = validateEmployeenew(
        details,
        cardNumberLengthForUser,
        employeeNumberLengthForUser
      );
      setErrors(errors);
      if (!rolesIds) return setErrors({ ...errors, role: 'Role is required' });
      if (kitchensIds && kitchensIds?.length == 0)
        return setErrors({ ...errors, kitchensIds: 'Kitchen is required' });
      if (rolesIds == 6 || rolesIds == 7) {
        if (details?.contractorsIds && details?.contractorsIds.length === 0) {
          return setErrors({
            ...errors,
            contractorId: 'Contractor is required',
          });
        }
      }
      // if (!selectContractor && showContractor === true) {
      //   return setErrors({ ...errors, contractor: 'Contractor is required' });
      // }
      if (isValid) {
        await handleAddNewUser({ ...details });
        delete details.hireFrom;
        delete details.hireTill;
        delete details.mealTypeId;
      } else {
        console.log('This', errors);
      }
    }
  };

  const CheckPassword = (value) => {
    if (
      value.label === 'Active' &&
      (payload.password === '' || !payload.password)
    ) {
      // setPError(true);
      // setErrors({ ...errors, password: 'Password is required' });
      setPayload({ ...payload, status: 'Active' });
    } else {
      setPError(false);
      setStatus(value);
      setPayload({ ...payload, status: value.label });
      setErrors({ ...errors, password: '' });
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div className="user-popup-heading">
          <h6>{isUpdate ? t('updateUser') : t('addUsers')}</h6>

          <div className="close-button">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <form>
            <div className="item">
              <div className="input-field">
                <label>{t('roles') + '*'}</label>
                <Select
                  // isMulti
                  name={'rolesIds'}
                  defaultValue={payload?.role?.map((el) => {
                    return { value: el, label: RolesMap_ValueId[el] };
                  })}
                  onChange={(newData) => {
                    if (newData.value === 6 || newData.value === 7) {
                      setshowGuestUser(false);
                      setShowContractor(true);
                    } else if (newData.value === 8) {
                      setShowContractor(false);
                      setshowGuestUser(true);
                    } else {
                      setshowGuestUser(false);
                      setShowContractor(false);
                    }
                    let magneticStripe = payload.magneticStripe;
                    if (
                      contractorList &&
                      selectContractor.length !== 0 &&
                      contractorList
                        .filter((elem) => selectContractor.includes(elem.id))
                        .find((elem) => elem.magneticStripe !== '') !==
                        undefined
                    ) {
                      magneticStripe = '';
                    }
                    setSelectContractor([]);
                    setRolesIds(newData.value);
                    setPayload({
                      ...payload,
                      role: [newData.value],
                      contractorsIds: [],
                      contractor: [],
                      magneticStripe: magneticStripe,
                    });

                    if (
                      contractorList &&
                      Array.isArray(contractorList) &&
                      contractorList.length === 1 &&
                      (newData.value === 6 ||
                        newData.value === 7 ||
                        newData.value === 8)
                    ) {
                      const elem = contractorList[0];
                      if (newData.value === 6 || newData.value === 8) {
                        setSelectContractor([elem?.id]);
                      }
                      setPayload((prev) => {
                        return {
                          ...prev,
                          contractorsIds: [elem?.id],
                          magneticStripe: cibusEnabled
                            ? elem?.magneticStripe ?? ''
                            : '',
                        };
                      });
                    }

                    if (
                      Kitchens &&
                      Array.isArray(Kitchens) &&
                      Kitchens.length === 1
                    ) {
                      setKitchensIds([Kitchens[0].id]);
                      const mealTypesArr = getMealTypes.filter(
                        (elem) => elem?.kitchenId === Kitchens[0].id
                      );
                      setMealTypesKitchenWise(mealTypesArr);
                      setPayload((prev) => {
                        return {
                          ...prev,
                          kitchensIds: [Kitchens[0].id],
                        };
                      });
                      if (mealTypesArr && mealTypesArr.length === 1) {
                        setPayload((prev) => {
                          return {
                            ...prev,
                            mealTypeId: [mealTypesArr[0].id],
                          };
                        });
                      }
                    }
                  }}
                  options={newRoles
                    ?.filter((el) => el.id !== 5)
                    .map((roles) => {
                      return { value: roles.id, label: roles.display };
                    })}
                  placeholder={t('roles')}
                  styles={SelectStyles}
                  required
                />
                {errors.role && <p className="error-msg">Role is required</p>}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('firstName')}
                  label={t('firstName') + ' *'}
                  name="fName"
                  type="text"
                  value={payload['fName']}
                  cb={(value) => {
                    handleChange('fName', value);
                  }}
                  editMode={true}
                />
                {errors && (
                  <p className="error-msg">
                    {errors.fName || errors.firstName}
                  </p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('lastName')}
                  label={t('lastName') + ' *'}
                  name="lName"
                  type="text"
                  value={payload['lName']}
                  cb={(value) => {
                    handleChange('lName', value);
                  }}
                  required
                  editMode={true}
                />
                {errors && (
                  <p className="error-msg">{errors.lName || errors.lastName}</p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('phone')}
                  label={t('phone')}
                  name="phone"
                  type="tel"
                  value={payload['phone']?.replace('+', '')}
                  cb={(value) => {
                    handleChange('phone', value);
                  }}
                  required
                  editMode={true}
                />
                {errors && <p className="error-msg">{errors.phone}</p>}
              </div>

              <div className="input-field">
                <GetInputField
                  placeholder={t('email')}
                  label={showGuestUser ? t('email') : t('email') + ' *'}
                  name="email"
                  type="email"
                  value={payload['email']}
                  cb={(value) => {
                    handleChange('email', value);
                  }}
                  required
                  editMode={true}
                />
                {errors && <p className="error-msg">{errors.email}</p>}
              </div>

              {!showGuestUser && (
                <div className="input-field">
                  <GetInputField
                    placeholder={t('password')}
                    label={t('password')}
                    name="password"
                    type="password"
                    value={payload['password']}
                    cb={(value) => {
                      handleChange('password', value);
                    }}
                    required
                    editMode={true}
                  />
                  {(errors || pError) && (
                    <p className="error-msg">{errors.password}</p>
                  )}
                </div>
              )}

              <div className="input-field">
                <label>{t('kitchenname') + ' *'}</label>
                <Select
                  isMulti
                  name={'kitchensIds'}
                  value={kitchensIds?.map((kitchenId) => {
                    return { value: kitchenId, label: KitchensMap[kitchenId] };
                  })}
                  onChange={(newData) => {
                    setKitchensIds(newData.map((k) => k.value));
                    setPayload({
                      ...payload,
                      kitchensIds: newData.map((k) => k.value),
                    });

                    let newMealTypeDateArray = [];
                    newData?.forEach((elData) => {
                      let newMealTypeInfo = getMealTypes?.filter(
                        (elInfo) => elInfo?.kitchenId === elData?.value
                      );
                      newMealTypeDateArray = [
                        ...newMealTypeDateArray,
                        ...newMealTypeInfo,
                      ];
                    });
                    setMealTypesKitchenWise(newMealTypeDateArray);
                    if (newMealTypeDateArray.length === 1) {
                      setMealTypesIds([newMealTypeDateArray[0].value]);
                      setPayload({
                        ...payload,
                        mealTypeId: [newMealTypeDateArray[0].id],
                      });
                    }
                    if (
                      payload?.mealTypeId &&
                      payload?.mealTypeId?.length > 0
                    ) {
                      let newMealTypeDateArrayInfo = [];
                      newMealTypeDateArray?.forEach((elDataInfo) => {
                        let newMealTypeInfo1 = payload.mealTypeId?.filter(
                          (elInfoID) => elInfoID === elDataInfo?.id
                        );
                        newMealTypeDateArrayInfo = [
                          ...newMealTypeDateArrayInfo,
                          ...newMealTypeInfo1,
                        ];
                      });
                      setPayload({
                        ...payload,
                        mealTypeId: newMealTypeDateArrayInfo,
                      });
                    }
                  }}
                  options={Kitchens.map((kitchen) => {
                    return { value: kitchen.id, label: kitchen.name };
                  })}
                  placeholder={t('kitchenname')}
                  styles={SelectStyles}
                  required
                />
                {errors.kitchensIds && (
                  <p className="error-msg">Kitchen is required</p>
                )}
              </div>
              {showGuestUser && (
                <>
                  <div className="input-field">
                    <label>{t('mealType')}</label>
                    <Select
                      isMulti
                      name={'mealTypeId'}
                      // value={mealTypesIds?.map((el) => {
                      //   return { value: el, label: MealTypesMap[el] };
                      // })}
                      value={
                        Array.isArray(payload?.mealTypeId)
                          ? payload?.mealTypeId?.map((el) => {
                              return { value: el, label: MealTypesMap[el] };
                            })
                          : {
                              value: payload?.mealTypeId,
                              label: MealTypesMap[payload?.mealTypeId],
                            }
                      }
                      onChange={(newData) => {
                        setMealTypesIds(newData.map((k) => k.value));
                        setPayload({
                          ...payload,
                          mealTypeId: newData.map((k) => k.value),
                        });
                      }}
                      // options={getMealTypes?.map((el) => {
                      //   return { value: el.id, label: el.display };
                      // })}
                      options={getMealTypesKitchenWise?.map((el) => {
                        return { value: el.id, label: el.display };
                      })}
                      placeholder={t('mealType')}
                      styles={SelectStyles}
                      required
                    />
                    {/* {errors && <p className="error-msg">{errors.mealTypeId}</p>} */}
                  </div>
                  <div className="input-field">
                    <GetInputField
                      placeholder={t('startDate')}
                      label={t('startDate')}
                      name="hireFrom"
                      type="date"
                      value={payload['hireFrom']}
                      cb={(value) => {
                        handleChange('hireFrom', value);
                      }}
                      required
                      editMode={true}
                    />
                    {errors && <p className="error-msg">{errors.hireFrom}</p>}
                  </div>
                  <div className="input-field">
                    <GetInputField
                      placeholder={t('endDatee')}
                      label={t('endDate')}
                      name="hireTill"
                      type="date"
                      value={payload['hireTill']}
                      cb={(value) => {
                        handleChange('hireTill', value);
                      }}
                      required
                      editMode={true}
                    />
                    {errors && <p className="error-msg">{errors.hireTill}</p>}
                  </div>
                </>
              )}

              {showContractor || payload?.role[0] === 7 ? (
                <div className="input-field">
                  <label>{t('contractor')}</label>
                  <Select
                    isMulti={payload?.role[0] === 7 ? true : false}
                    name={'contractorsIds'}
                    value={
                      payload?.contractorsIds?.map((contractor) => {
                        return {
                          value: contractor,
                          label: ContractorMap_ValueId[contractor],
                        };
                      }) ||
                      payload?.contractors?.map((contractor) => {
                        return {
                          value: contractor.id,
                          label: ContractorMap_ValueId[contractor.id],
                        };
                      }) ||
                      []
                    }
                    onChange={(newData) => {
                      setSelectContractor(
                        payload?.role[0] === 7
                          ? newData.map((k) => k.value)
                          : [newData.value]
                      );

                      setPayload({
                        ...payload,
                        contractorsIds:
                          payload?.role[0] === 7
                            ? newData.map((k) => k.value)
                            : [newData.value],
                        magneticStripe:
                          payload?.role[0] !== 7 && cibusEnabled
                            ? contractorList.find(
                                (el) => el.id === newData.value
                              )?.magneticStripe ?? ''
                            : '',
                      });
                      /*setSelectContractor(newData.value);
                      setPayload({ ...payload, contractorId: newData.value });*/
                    }}
                    options={contractorList?.map((contractors) => {
                      return {
                        value: contractors.id,
                        label: [contractors.name],
                      };
                    })}
                    placeholder={t('contractor')}
                    styles={SelectStyles}
                    required
                  />
                  {errors && (
                    <p className="error-msg">{errors.contractorsIds}</p>
                  )}
                </div>
              ) : showGuestUser ? (
                <div className="input-field">
                  <label>{t('contractor')}</label>
                  <Select
                    name={'contractorIds'}
                    value={
                      payload?.contractorsIds?.map((contractor) => {
                        return {
                          value: contractor,
                          label: ContractorMap_ValueId[contractor],
                        };
                      }) ||
                      payload?.contractors?.map((contractor) => {
                        return {
                          value: contractor.id,
                          label: ContractorMap_ValueId[contractor.id],
                        };
                      }) ||
                      []
                    }
                    onChange={(newData) => {
                      setSelectContractor([newData.value]);
                      setPayload({
                        ...payload,
                        contractorsIds: [newData.value],
                        magneticStripe: cibusEnabled
                          ? contractorList.find((el) => el.id === newData.value)
                              ?.magneticStripe ?? ''
                          : '',
                      });
                      /*setSelectContractor(newData.value);
                      setPayload({ ...payload, contractorId: newData.value });*/
                    }}
                    options={contractorList?.map((contractors) => {
                      return {
                        value: contractors.id,
                        label: [contractors.name],
                      };
                    })}
                    placeholder={t('contractor')}
                    styles={SelectStyles}
                    required
                  />
                  {errors && (
                    <p className="error-msg">{errors.contractorsIds}</p>
                  )}
                </div>
              ) : (
                ''
              )}

              {!showGuestUser && (
                <>
                  <div className="input-field">
                    <GetInputField
                      placeholder={t('employeeNo')}
                      label={t('employeeNo')}
                      name="employee"
                      type="number"
                      value={payload['employeeId']}
                      cb={(value) => handleChange('employeeId', value)}
                      editMode={true}
                    />
                    {errors && <p className="error-msg">{errors.employeeNo}</p>}
                  </div>
                  <div className="input-field">
                    <GetInputField
                      placeholder={t('cardNo')}
                      label={t('cardNo')}
                      name="card"
                      type="number"
                      value={payload['card']}
                      cb={(value) => handleChange('card', value)}
                      editMode={cardGenerationUrlForUser ? false : true}
                    />
                    {errors && <p className="error-msg">{errors.card}</p>}
                  </div>
                  {cibusEnabled ? (
                    <div className="input-field">
                      <GetInputField
                        placeholder={t('magneticStripe')}
                        label={t('magneticStripe')}
                        name="magneticStripe"
                        type="number"
                        value={payload['magneticStripe']}
                        cb={(value) => {
                          handleChange('magneticStripe', value);
                        }}
                        editMode={true}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="input-field type-checkbox">
                    <input
                      type="checkbox"
                      checked={payload['isPasswordTemporary']}
                      name="isPasswordTemporary"
                      value={payload['isPasswordTemporary']}
                      onClick={(value) => {
                        handleChange('isPasswordTemporary', value);
                      }}
                    />
                    <label>{t('resetPassword')}</label>
                  </div>
                </>
              )}

              {!showGuestUser &&
                (user.roleId === 1 || isUpdate ? (
                  <div className="input-field">
                    <label>{t('status')}</label>
                    <Select
                      name={'status'}
                      defaultValue={
                        UserStatuses.find((el) => el.display === payload.status)
                          ? {
                              value: UserStatuses.find(
                                (el) => el.display === payload.status
                              )?.id,
                              label: UserStatuses.find(
                                (el) => el.display === payload.status
                              )?.display,
                            }
                          : { value: 3, label: StatusMap[3] }
                      }
                      onChange={(newData) => {
                        CheckPassword(newData);
                      }}
                      options={UserStatuses.map((kitchen) => {
                        return { value: kitchen.id, label: kitchen.display };
                      })}
                      placeholder={t('status')}
                      styles={SelectStyles}
                      // isMulti
                      required
                    />
                  </div>
                ) : (
                  ''
                ))}
            </div>
          </form>{' '}
          {isUpdate ? (
            <div className="add-employee-btn">
              {showGuestUser ? (
                <Button onClick={handleUpdateGuestUser}>{t('update')}</Button>
              ) : (
                <Button onClick={handleUpdateUser}>{t('update')}</Button>
              )}
            </div>
          ) : (
            <div className="add-employee-btn">
              <Button onClick={handleFormSubmit}>{t('add')}</Button>
            </div>
          )}
        </Typography>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  employeeNumberLengthForUser: state.companyConfig.employeeNumberLengthForUser,
  cardNumberLengthForUser: state.companyConfig.cardNumberLengthForUser,
  cardGenerationUrlForUser: state.companyConfig.cardGenerationUrlForUser,
  isStrippedCard: state.companyConfig.isStrippedCard,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserPopup);
