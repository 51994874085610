import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import '../../style/myOrders.scss';

// button img
import edit from '../../assets/icons/pencil.png';
import cancel from '../../assets/icons/cancel.png';
import voucher from '../../assets/icons/voucher.png';
import arrow from '../../assets/icons/angle-right-black.svg';
import { connect } from 'react-redux';
import moment from 'moment';
import TableBtn from './TableBtn';
import MyOrdersDetailsMobilePopup from './MyOrdersDetailsMobilePopup';
import VoucherPopup from './VoucherPopup';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { useTranslation } from 'react-i18next';
import { sleep } from '../../services/utils';
import { sortOrders } from '../../services/sortItems';
import http from '../../services/http';
import ShowPreviousContainer from '../../components/ShowPrevious';
import dates from '../../services/dates';

const MyOrdersPage = ({
  user,
  kitchen,
  kitchens,
  MealTypes,
  OrderStatuses,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
}) => {
  const [myOrdersMob, setMyOrdersMob] = useState(false);
  const handleMyOrdersMob = () => setMyOrdersMob(!myOrdersMob);
  const [voucherModal, setVoucherModal] = useState(false);
  const handleVoucherModal = () => setVoucherModal(!voucherModal);
  const [orders, setOrders] = useState(null);
  const { t } = useTranslation();
  useEffect(async () => await useLoader(() => sleep()), []);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [orderId, setOrderId] = useState();
  const [viewOrderData, setViewOrderData] = useState();
  // setLayoutOptions({ title: t('myMeals') });

  const OrderStatusesMap = OrderStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(async () => {
    if (!kitchen) return;
    let { data: orders } = await useFetchingLoader(
      async () =>
        await http.get(`orders`, {
          filter: { kitchenId: kitchen.id, userId: user?.id },
        })
    );
    setOrders(
      sortOrders(
        orders.filter(
          (order) => order.orderStatusId !== OrderStatusesMap.canceled
        )
      )
    );
  }, [kitchen]);

  const [showPrevious, setShowPrevious] = useState(false);

  const handleOrderCancel = (orderId) => {
    setOrders((state) =>
      sortOrders(state.filter((order) => order.id !== orderId))
    );
  };
  const handlePopup = (id) => {
    let orderfilterData = orders.filter((el) => el.id === id);
    setViewOrderData(orderfilterData);
    setOrderId(id);
    setPaymentPopup(!paymentPopup);
  };
  useEffect(() => {}, [orders]);

  const OrderItem = [
    {
      Name: ['Chicken drumstick in BBQ sauce'],
      MealDateType: '24/08 Breakfast',
      Extra: 'Beverages',
      Kitchen: 'Modiin Test',
      Price: '0',
    },
    {
      Name: [
        'Ravioli (Vegan)',
        'Bulgarian cheese sandwich',
        'chicken nuggets',
        'Malawah & Meat',
      ],
      MealDateType: '24/08 Breakfast',
      Extra: 'Beverages',
      Kitchen: 'Modiin Test',
      Price: '0',
    },
    {
      Name: ['Bulgarian cheese sandwich'],
      MealDateType: '24/08 Breakfast',
      Extra: 'Beverages',
      Kitchen: 'Modiin Test',
      Price: '0',
    },
    {
      Name: ['chicken nuggets'],
      MealDateType: '24/08 Breakfast',
      Extra: 'Beverages',
      Kitchen: 'Modiin Test',
      Price: '0',
    },
    {
      Name: ['Malawah & Meat'],
      MealDateType: '24/08 Breakfast',
      Extra: 'Beverages',
      Kitchen: 'Modiin Test',
      Price: '0',
    },
  ];
  return (
    <div className="my-orders-page">
      <h1 className="page-heading">My Orders</h1>
      <div className="my-orders-table-section">
        <div className="my-orders-table">
          <div className="my-orders-table-head">
            <div className="table-text-section">
              <h5>Name</h5>
              <h5>Meal Date &amp; Type</h5>
              <h5>Extra</h5>
              <h5>Kitchen</h5>
              <h5>Price</h5>
            </div>
            <div className="table-btn-section"></div>
          </div>
          <div className="my-orders-table-body">
            {orders && orders.length ? (
              <ShowPreviousContainer
                items={orders.map((order) => {
                  return {
                    ...order,
                    date:
                      order?.mealDish?.meal?.date ||
                      (order &&
                        order.mealDish &&
                        order.mealDish.length > 0 &&
                        order.mealDish[0].mealDish.meal.date),
                  };
                })}
                assignMethod={(order, future) => (
                  <MyOrdersTableItem
                    key={order.id}
                    isFuture={future}
                    kitchens={kitchens}
                    order={order}
                    OrderStatusesMap={OrderStatusesMap}
                    MealTypes={MealTypes}
                    useFetchingLoader={useFetchingLoader}
                    handleOrderCancel={handleOrderCancel}
                    t={t}
                    handlePopup={handlePopup}
                    handleMyOrdersMob={handleMyOrdersMob}
                    handleVoucherModal={handleVoucherModal}
                  />
                )}
                showPrevious={showPrevious}
                showPreviousOnClick={async () =>
                  await useFetchingLoader(() => setShowPrevious(true))
                }
              />
            ) : (
              <div className="no-meals">{t('noOrdersToDisplay')}</div>
            )}
          </div>
        </div>
      </div>
      <MyOrdersDetailsMobilePopup
        myOrdersMob={myOrdersMob}
        handleMyOrdersMob={handleMyOrdersMob}
        handleVoucherModal={handleVoucherModal}
      />
      <VoucherPopup
        voucherModal={voucherModal}
        handleVoucherModal={handleVoucherModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens.options,
  MealTypes: state.app.enums.MealType,
  OrderStatuses: state.app.enums.OrderStatus,
});

const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
export default connect(mapStateToProps, mapDispatchToProps)(MyOrdersPage);

const MyOrdersTableItem = ({
  order,
  isFuture,
  kitchens,
  MealTypes,
  OrderStatusesMap,
  handleOrderCancel,
  useFetchingLoader,
  t,
  handlePopup,
  handleMyOrdersMob,
  handleVoucherModal,
}) => {
  const [cancellation, setCancellation] = useState();
  const { mealDish } = order;
  let meal = {};

  if (mealDish?.length > 0) {
    meal =
      (order &&
        order?.mealDish &&
        order?.mealDish?.length > 0 &&
        order?.mealDish[0]?.mealDish?.meal) ??
      {};
  } else {
    meal = mealDish?.meal ?? {};
  }

  const { dish } = mealDish ?? {};
  const dishArray =
    order && order.mealDish && order.mealDish.length > 0 && order.mealDish;
  //const { meal, dish } = mealDish  ?? { meal: {}, dish:{}};
  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.value };
  }, {});

  let dishIds = [];
  if (dishArray?.length > 0) {
    const a = dishArray?.map((el) => {
      dishIds.push(el?.mealDish?.dish?.id);
    });
  }
  useEffect(() => {
    if (
      order?.mealDish?.meal?.date === moment(new Date()).format('YYYY-MM-DD')
    ) {
      let now = order?.mealDish?.meal?.mealType?.to;
      let hour = now.split(':')[0];
      let minute = now.split(':')[1];
      let dt = new Date(null, null, null, hour, minute);
      dt.setMinutes(
        dt.getMinutes() - order?.mealDish?.meal?.mealType?.cancellationTime
      );

      if (moment(dt).format('HH:mm') < moment(new Date()).format('HH:mm')) {
        setCancellation(true);
      } else {
        setCancellation(false);
      }
    }
  }, [order, cancellation]);
  return (
    <div className="my-orders-table-item">
      <div className="table-text-section">
        <p>
          <ul>
            {dishArray && dishArray.length > 0 ? (
              dishArray?.map((el, i) => (
                <li key={i}>{el?.mealDish?.dish?.name}</li>
              ))
            ) : (
              <li key={i}>{dish?.name}</li>
            )}
          </ul>
        </p>
        <p>
          {dates.ddmmFormat(meal?.date)} {MealTypesMap[meal?.mealTypeId]}
        </p>
        <p>{order?.mealExtra?.name}</p>
        <p>{kitchens.find((k) => k.id === meal?.kitchenId)?.name}</p>
        <p> {order.orderPrice > 0 ? '₪' + order?.orderPrice : ''}</p>
      </div>
      <div className="mobile-popup-btn">
        <TableBtn img={arrow} func={handleMyOrdersMob} />
      </div>
      <div className="table-btn-section">
        <TableBtn img={edit} btnName="Edit" func={''} />
        <TableBtn img={cancel} btnName="Cancel" func={''} />
        <TableBtn img={voucher} btnName="Voucher" func={handleVoucherModal} />
      </div>
    </div>
  );
};
