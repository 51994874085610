import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import DishForm from '../../components/Forms/DishForm';
import { connect } from 'react-redux';
import http from '../../services/http';
import axios from 'axios';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import AppConfig from '../../constants/Config';
import { getAssetUrl } from '../../services/aws';
import { sleep } from '../../services/utils';
import WEIcon from '../../components/WE-IconButton';
import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import defaultImage from '../../assets/images/defaultImage.png';
import { getCsrfToken } from '../../services/csrfToken';
import BackLink from '../../components/BackLink';

const AddEditDish = ({
  setLayoutOptions,
  languages,
  DishCategories,
  DishStatuses,
  useLoader,
  useFetchingLoader,
  CurrentLanguage,
  kitchen,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dishId } = useParams();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(async () => {
    let title = dishId ? t('editDish') : t('addDish');
    setLayoutOptions({ title });
  }, [dishId]);
  useEffect(async () => await useLoader(() => sleep()), []);

  const DishStatusMap = DishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});

  const InitialValues = {
    image: null,
    dishCategoryId: 1,
    translations: languages.map((lang) => {
      return { languageId: lang.id };
    }),
    price: 0,
  };

  const makeImage = (imgText) =>
    imgText?.startsWith('http') ? imgText : getAssetUrl(imgText);

  const [values, setValues] = useState(null);
  useEffect(async () => {
    const { data: _tags } = await useFetchingLoader(async () => {
      return await http.get('tags');
    });
    setTags(_tags.filter((tag) => tag.status));

    if (dishId) {
      const {
        image,
        dishCategoryId,
        translations,
        price,
        tags: selectedTag,
      } = await useFetchingLoader(
        async () => await http.get(`dishes/${dishId}`, { language: 'all' })
      );
      setValues({
        image: makeImage(image),
        dishCategoryId,
        translations,
        price,
        tags: selectedTag.map((tag) => tag.id),
      });
      setSelectedTags(selectedTag.map((tag) => tag.id));
    } else {
      setValues(InitialValues);
    }
  }, []);

  const handleSubmit = async (form) => {
    try {
      // if (Array.isArray(form.image)) {
      //   // On upload, form.image is an array of files
      //   form.image = await uploadImage(form.image[0]);
      //   //  form.image = form.image[0];
      // }

      var formData = new FormData();
      Object.keys(form).forEach((item) => {
        if (item === 'translations')
          formData.append(item.toString(), JSON.stringify(form[item]));
        else formData.append(item.toString(), form[item]);
      });
      dishId
        ? await useFetchingLoader(async () => {
            if (form.image) {
              formData.set(
                'image',
                form.image[0].preview ? form.image[0] : form.image
              );
            }
            formData.set('tags', JSON.stringify(selectedTags));

            let dd = await axios.put(
              `${AppConfig.server.url}/dishes/${dishId}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem('token')
                  )}`,
                  'X-Site-Url': window.location.href,
                  'X-Org-Id': localStorage.getItem('companyId') ?? null,
                },
              }
            );

            history.push('/dishManagement');
          })
        : await useFetchingLoader(async () => {
            formData.append('kitchenId', kitchen.id);
            formData.append('tags', JSON.stringify(selectedTags));
            formData.append('dishStatusId', DishStatusMap.active);
            formData.set('image', form.image ? form.image[0] : null);
            let jj = await axios.post(
              `${AppConfig.server.url}/dishes/create`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem('token')
                  )}`,
                  'X-Site-Url': window.location.href,
                  'X-Org-Id': localStorage.getItem('companyId') ?? null,
                },
              }
            );

            history.push('/dishManagement');
          });
    } catch (err) {
      Alert({ error: err });
    }
  };

  return (
    <>
      {/* <WEBackButton text={backText} redirectHistory={backLink} /> */}
      <BackLink text={backText} redirectHistory={backLink} />
      {values && (
        <DishForm
          tags={tags}
          setTags={setTags}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          initialValues={{ ...values }}
          languages={languages}
          DishCategories={DishCategories}
          CurrentLanguage={CurrentLanguage}
          onSubmit={handleSubmit}
          onClose={() => history.push('/dishManagement')}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  languages: state.languages.options,
  CurrentLanguage: state.languages.current,
  DishCategories: state.app.enums.DishCategory,
  DishStatuses: state.app.enums.DishStatus,
  kitchen: state.kitchens.current,
});

const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
export default connect(mapStateToProps, mapDispatchToProps)(AddEditDish);
