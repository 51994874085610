import PaymentMangementForm from '../../components/Forms/PaymentManagementForm';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { showAlert } from '../../store/app/actions';
import {
  getPaymentPreferences,
  setPaymentPreferences,
} from '../../store/kitchens/actions';
import { useCallback, useEffect, useState } from 'react';
import { computePaymentOptions } from '../../store/kitchens/utils';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
//import { useLocation } from 'react-router-dom';
// import ProfileLayout from '../profile/profileLayout';

const PaymentManagement = ({
  setPaymentPreferences,
  kitchen,
  getPaymentPreferences,
  currentPaymentPreferences,
  Currency,
}) => {
  const { t } = useTranslation();
  const [formType, setFormType] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState();
  // const { search } = useLocation();
  useEffect(() => {
    // if (search) {
    //   const queryStrings: Array<string> = search.substring(1).split('&');

    //   if (queryStrings.length) {
    //     const query: {} = {};
    //     queryStrings.forEach((el: string) => {
    //       const [key, value] = el.split('=');
    //       query[key] = value;
    //     });
    //     console.log("query['paymenttype']", query['paymenttype']);
    //     if (query['paymenttype']) {
    setFormType('hyp');
    //     }
    //   }
    // }
  }, [formType, kitchen]);

  useEffect(() => {
    // getPaymentPreferences(kitchen.id, formType).then(console.log);
    const getData = async () => {
      const data = await getPaymentPreferences(kitchen?.id, formType);
      setPaymentMethod(data);
    };
    getData();
  }, [formType, kitchen]);

  const handleSubmit = useCallback(
    async (form) => {
      try {
        console.log({ form }, { computePaymentOptions });
        let kitchenId = kitchen.id || localStorage.getItem('kitchen');
        if (form.selectedPaymentOptions === 5 && form.currencyId == '') {
          Alert({ error: t('chooseCurrency') });
        } else {
          await setPaymentPreferences(
            {
              ...form,
              paymentOptionId: form.selectedPaymentOptions,
              currencyId: form.currencyId,
              paymentType: formType,
              referenceId: form.referenceId,
              posId: form.posId,
              applicationId: form.applicationId,
              authorization: form.authorization,
              companyCard: form.companyCard,
              companyId: form.companyId,
              vatFreePrice: form.vatFreePrice,
              addOrderUrl: form.addOrderUrl,
              paymentUrl: form.paymentUrl,
              host: form.host,
            },
            kitchenId
          );

          SwalDialogs.Dialog({
            title: t('paymentPreferncesUpdated'),
            text: '',
            type: 'success',
            buttons: {
              showCancelButton: false,
              showConfirmButton: true,
            },
            onConfirm: () => {
              window.location.reload();
            },
          });
        }
      } catch (err) {
        Alert({ error: err, buttons: {} });
      }
    },
    [formType]
  );

  return (
    <div className="payment-management-page">
      <div className="container">
        {currentPaymentPreferences.loaded && (
          <div className="payment-management-grid">
            <PaymentMangementForm
              onSubmit={handleSubmit}
              initialValues={currentPaymentPreferences}
              formType={formType}
              paymentMethod={paymentMethod}
              Currency={Currency}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    kitchen: state.kitchens.current,
    currentPaymentPreferences: state.kitchens.paymentPreferences,
    Currency: state.app.enums.Currency,
  };
};

const mapDispatchToProps = {
  setPaymentPreferences,
  showAlert,
  getPaymentPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentManagement);
