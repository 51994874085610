import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../store/app/actions';
import { connect } from 'react-redux';
import http from '../services/http';
import { generateMealDishData } from '../components/DishComponents/Dish';
import { DynamicStars } from '../components/RatingStars';
import { ActiveButton, DisabledButton } from '../components/WE-Button';
import { sleep } from '../services/utils';
import WEBackButton from '../components/WE-BackButton';
import { Dialog } from '../components/Dialogs/SwalDialogs';
import { Alert } from '../components/Dialogs/SwalDialogs';
import moment from 'moment';
import chat from '../assets/images/chat.png';

const ReviewDetails = ({
  setLayoutOptions,
  kitchen,
  MealTypes,
  showAlert,
  useLoader,
  user,
  useFetchingLoader,
}) => {
  const { t } = useTranslation();
  const { orderId, dishId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [details, setDetails] = useState(null);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [reviewDetailsData, setReviewDetailsData] = useState();
  const [commentData, setCommentData] = useState('');
  const [hideCommnet, setHideCommnet] = useState('');
  const [currentUser, setCurrentUser] = useState();
  const [dummy, setDummy] = useState(null);
  const [allRating, setAllRating] = useState();
  const { backText, backLink } = location.state ?? {};
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});
  const Roles = {
    1: 'admin',
    2: 'siteManager',
    3: 'kitchenManager',
    4: 'client',
    9: 'kitchenWorker',
  };
  useEffect(async () => await useLoader(() => sleep()), []);

  useEffect(async () => {
    const reviewData = await useFetchingLoader(
      async () => await http.get(`review/getRatingByDish/${dishId}`)
    );
    setAllRating(reviewData);
  }, []);

  useEffect(async () => {
    setLayoutOptions({ title: t('addReviewTitle') });
    if (!kitchen) return;
    const reviewData = await useFetchingLoader(
      async () => await http.get(`review/${orderId}`)
    );
    let aa = typeof reviewData;

    setReviewDetailsData(reviewData);
    setRating(
      (reviewData && reviewData[0]?.order?.rating) ||
        (reviewData && reviewData[0]?.rating)
    );
    // setCurrentUser(location?.state?.userdata);
    let currUser = localStorage.getItem('userId');
    setCurrentUser(currUser);
  }, [kitchen, dummy]);

  const currentRole = Roles[currentUser?.roleId];

  let linearProgressClasses = LinearProgress;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));
  /******* add New comment */
  const handleSubmit = async () => {
    try {
      let details = {
        userId: currentUser,
        orderId: reviewDetailsData[0]?.order?.id || reviewDetailsData[0].id,
        comment: commentData,
      };
      let comment;
      await useFetchingLoader(async () => {
        comment = await http.post(`review/create`, {
          ...details,
        });
      });
      Dialog({
        title: 'Commented Successfully',
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setDummy(true);
          setCommentData('');
        },
      });
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handleShowCommnet = () => {
    setHideCommnet(true);
  };

  // console.log('reviewDetailsData', reviewDetailsData);
  return (
    <>
      <WEBackButton text={backText} redirectHistory={backLink} />
      <div className="review-page">
        <div className="review review-details-section">
          <img
            src={
              (reviewDetailsData &&
                reviewDetailsData[0]?.order?.mealDish?.dish?.image) ||
              (reviewDetailsData &&
                reviewDetailsData[0]?.mealDish?.[0]?.mealDish?.dish?.image)
            }
            alt="orderdish"
          />
          <div className="review-details-name">
            <h4>
              {(reviewDetailsData &&
                reviewDetailsData[0]?.order?.mealDish?.[0]?.mealDish?.dish
                  ?.name) ||
                (reviewDetailsData &&
                  reviewDetailsData[0]?.mealDish?.[0]?.mealDish?.dish?.name)}
            </h4>
            <p>
              {(reviewDetailsData &&
                reviewDetailsData[0]?.order?.mealDish?.[0]?.mealDish?.dish
                  ?.kitchen.name) ||
                (reviewDetailsData &&
                  reviewDetailsData[0]?.mealDish?.[0]?.mealDish?.dish?.kitchen
                    ?.name)}
            </p>
            <p>
              {' '}
              {(reviewDetailsData &&
                reviewDetailsData[0]?.order?.mealDish?.[0]?.mealDish?.meal
                  ?.mealType?.value) ||
                (reviewDetailsData &&
                  reviewDetailsData[0]?.mealDish?.[0]?.mealDish?.meal?.mealType
                    ?.value)}
            </p>
            <div className="review-comment-btn">
              <div className="review-rating">
                <DynamicStars
                  value={rating}
                  // cb={(value) => setRating(value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="select-add-review">
          <div className="left-review-section">
            <h6>{t('allRatingDish')}</h6>
            <div className="rating-total review-five">
              <div className="review-count ">
                <p>
                  {(
                    (allRating?.['5'] /
                      (allRating?.['5'] +
                        allRating?.['4'] +
                        allRating?.['3'] +
                        allRating?.['2'] +
                        allRating?.['1'])) *
                    100
                  ).toFixed(0) !== NaN
                    ? '0'
                    : (
                        (allRating?.['5'] /
                          (allRating?.['5'] +
                            allRating?.['4'] +
                            allRating?.['3'] +
                            allRating?.['2'] +
                            allRating?.['1'])) *
                        100
                      ).toFixed(0)}
                  %{' '}
                  <span>
                    {allRating?.['5']} {t('reviews')}
                  </span>
                </p>
                <DynamicStars
                  value={5}
                  // cb={(value) => setRating(value)}
                />
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={(
                  (allRating?.['5'] /
                    (allRating?.['5'] +
                      allRating?.['4'] +
                      allRating?.['3'] +
                      allRating?.['2'] +
                      allRating?.['1'])) *
                  100
                ).toFixed(0)}
              />
            </div>
            <div className="rating-total review-four">
              <div className="review-count">
                <p>
                  {(
                    (allRating?.['5'] /
                      (allRating?.['5'] +
                        allRating?.['4'] +
                        allRating?.['3'] +
                        allRating?.['2'] +
                        allRating?.['1'])) *
                    100
                  ).toFixed(0) !== NaN
                    ? '0'
                    : (
                        (allRating?.['5'] /
                          (allRating?.['5'] +
                            allRating?.['4'] +
                            allRating?.['3'] +
                            allRating?.['2'] +
                            allRating?.['1'])) *
                        100
                      ).toFixed(0)}
                  %{' '}
                  <span>
                    {allRating?.['4']} {t('reviews')}
                  </span>
                </p>
                <DynamicStars
                  value={4}
                  // cb={(value) => setRating(value)}
                />
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={(
                  (allRating?.['4'] /
                    (allRating?.['5'] +
                      allRating?.['4'] +
                      allRating?.['3'] +
                      allRating?.['2'] +
                      allRating?.['1'])) *
                  100
                ).toFixed(0)}
              />
            </div>
            <div className="rating-total review-three">
              <div className="review-count">
                <p>
                  {(
                    (allRating?.['5'] /
                      (allRating?.['5'] +
                        allRating?.['4'] +
                        allRating?.['3'] +
                        allRating?.['2'] +
                        allRating?.['1'])) *
                    100
                  ).toFixed(0) !== NaN
                    ? '0'
                    : (
                        (allRating?.['5'] /
                          (allRating?.['5'] +
                            allRating?.['4'] +
                            allRating?.['3'] +
                            allRating?.['2'] +
                            allRating?.['1'])) *
                        100
                      ).toFixed(0)}
                  %{' '}
                  <span>
                    {allRating?.['3']} {t('reviews')}
                  </span>
                </p>
                <DynamicStars
                  value={3}
                  // cb={(value) => setRating(value)}
                />
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={(
                  (allRating?.['3'] /
                    (allRating?.['5'] +
                      allRating?.['4'] +
                      allRating?.['3'] +
                      allRating?.['2'] +
                      allRating?.['1'])) *
                  100
                ).toFixed(0)}
              />
            </div>
            <div className="rating-total review-two">
              <div className="review-count">
                <p>
                  {(
                    (allRating?.['5'] /
                      (allRating?.['5'] +
                        allRating?.['4'] +
                        allRating?.['3'] +
                        allRating?.['2'] +
                        allRating?.['1'])) *
                    100
                  ).toFixed(0) !== NaN
                    ? '0'
                    : (
                        (allRating?.['5'] /
                          (allRating?.['5'] +
                            allRating?.['4'] +
                            allRating?.['3'] +
                            allRating?.['2'] +
                            allRating?.['1'])) *
                        100
                      ).toFixed(0)}
                  %{' '}
                  <span>
                    {allRating?.['2']} {t('reviews')}
                  </span>
                </p>
                <DynamicStars
                  value={2}
                  // cb={(value) => setRating(value)}
                />
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={(
                  (allRating?.['2'] /
                    (allRating?.['5'] +
                      allRating?.['4'] +
                      allRating?.['3'] +
                      allRating?.['2'] +
                      allRating?.['1'])) *
                  100
                ).toFixed(0)}
              />
            </div>
            <div className="rating-total review-one">
              <div className="review-count">
                <p>
                  {(
                    (allRating?.['5'] /
                      (allRating?.['5'] +
                        allRating?.['4'] +
                        allRating?.['3'] +
                        allRating?.['2'] +
                        allRating?.['1'])) *
                    100
                  ).toFixed(0) !== NaN
                    ? '0'
                    : (
                        (allRating?.['5'] /
                          (allRating?.['5'] +
                            allRating?.['4'] +
                            allRating?.['3'] +
                            allRating?.['2'] +
                            allRating?.['1'])) *
                        100
                      ).toFixed(0)}
                  %{' '}
                  <span>
                    {allRating?.['1']} {t('reviews')}
                  </span>
                </p>
                <DynamicStars value={1} />
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={(
                  (allRating?.['1'] /
                    (allRating?.['5'] +
                      allRating?.['4'] +
                      allRating?.['3'] +
                      allRating?.['2'] +
                      allRating?.['1'])) *
                  100
                ).toFixed(0)}
              />
            </div>
          </div>
          <div className="right-review-section">
            {/* <div className="new-comment review-heading">
              <h6 className="">{t('comments')}</h6>
            </div> */}

            <div className="add-review">
              <h6>{t('addComment')}</h6>
              <textarea
                cols="30"
                rows="10"
                placeholder={t('writeDishOpinion')}
                onChange={(event) => setCommentData(event.target.value)}
                value={commentData}
                disabled={user?.roleId === 9}
              />
              <div className="submit-review-btn">
                <ActiveButton
                  label={t('comment')}
                  cb={handleSubmit}
                  disableBtn={user?.roleId === 9}
                />
              </div>
            </div>
            {reviewDetailsData &&
              reviewDetailsData.map((el, i) => (
                <div className="user-comments-box">
                  <div className="user-comment">
                    <div className="user-name">
                      <h6>
                        {[el?.user?.firstName, el?.user?.lastName].join(' ')}{' '}
                        <span className="time">
                          {new Date(el?.createdAt).toISOString().slice(0, 10)}{' '}
                          <span>
                            {moment(el?.createdAt).format('hh: mm A')}
                          </span>
                        </span>
                      </h6>
                    </div>
                    <p>{el?.ratingText || el?.comment}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  user: state.user,
});
const mapDispatchToProps = {
  setLayoutOptions,
  showAlert,
  useLoader,
  useFetchingLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetails);
