import React from 'react';
import { StaticStars } from '../RatingStars';
import DishImage from '../../screens/client/DishImage';
import Chip from '@material-ui/core/Chip';
import { ActiveButton } from '../WE-Button';
import ExtrasDish from './extrasDish';
import PaymentMethod from './paymentMethod';
import DatesMethods from '../../services/dates.js';

export default function CafeteriaDetails({
  details,
  mealSlotData,
  confirmBtn,
  extrasPopup,
  setExtrasPopup,
  t,
  extrsData,
  addedExtras,
  setAddedExtras,
  setIsSkip,
  AddOrderNew,
  skipAddOrder,
  paymentMethods,
  paymentPopup,
  setPaymentPopup,
  skipAddOrderPayment,
  handlePayment,
  MealTypes,
}) {
  const handleextrasPopup = () => {
    setExtrasPopup(!extrasPopup);
  };
  const handleCloseExtras = () => {
    setExtrasPopup(false);
  };
  const handleClose = () => {
    setPaymentPopup(false);
  };
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});
  console.log('details', details);
  return (
    <>
      <div className="cafeteria-order-card">
        <div className="dishImage">
          <DishImage
            fileName={details?.dish?.image}
            size={{ height: '100%', width: '100%' }}
          />
        </div>

        <div className="order-details">
          <section className="section1 dash-bottom">
            <div className="dishname">
              <h4>{details?.dish?.name}</h4>

              <p>Qty: {details?.quantity}</p>
              {details?.dish?.price ? (
                <p style={{ fontSize: '22px' }}>
                  {' '}
                  ₪{details?.quantity * details?.dish?.price}
                </p>
              ) : null}
            </div>
            {details?.dishDescription && (
              <p className="order-description">{details?.dishDescription}</p>
            )}
            {details?.dishTags && (
              <div style={{ marginBottom: '5px' }}>
                {details?.dishTags
                  ?.filter((tag) => tag?.isPublic)
                  .map((tag) => (
                    <Chip size="small" label={tag?.displayName} />
                  ))}
              </div>
            )}
          </section>

          {/* <p className="order-time">
              {DatesMethods.format(details?.meal?.date)} /{' '}
              {MealTypesMap_Id[details?.meal?.mealTypeId]}
              {`${
                mealSlotData && mealSlotData?.mealTypesSlot?.from ? '/' : ''
              }`}
              {mealSlotData && mealSlotData?.mealTypesSlot?.from}{' '}
              {`${
                mealSlotData && mealSlotData?.mealTypesSlot?.from ? '-' : ''
              }`}{' '}
              {mealSlotData && mealSlotData?.mealTypesSlot?.to}
            </p> */}

          <div className="add-extras-order-details">
            {/* <ActiveButton
              label={'Add Extras'}
              arrow={false}
              cb={handleextrasPopup}
            /> */}
            {confirmBtn}
          </div>
        </div>
      </div>
      {extrasPopup && (
        <ExtrasDish
          extrasPopup={extrasPopup}
          handleCloseExtras={handleCloseExtras}
          t={t}
          extrsData={extrsData}
          setAddedExtras={setAddedExtras}
          addedExtras={addedExtras}
          skipAddOrder={skipAddOrder}
          AddOrderNew={AddOrderNew}
          setIsSkip={setIsSkip}
          paymentMethods={paymentMethods}
        />
      )}
      {paymentPopup && (
        <PaymentMethod
          paymentPopup={paymentPopup}
          t={t}
          handleClose={handleClose}
          skipAddOrderPayment={skipAddOrderPayment}
          handlePayment={handlePayment}
          paymentMethods={paymentMethods}
        />
      )}
    </>
  );
}
