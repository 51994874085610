import React, { useState, useEffect } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useTranslation } from 'react-i18next';
import WEIcon from './WE-IconButton';
import LanguageSelection from './Forms/LanguageSelection';
import KitchenDropdown from './Subheaders/KitchenDropdown';
import logo from '../assets/logo/workeat-logo.svg';
import textlogo from '../assets/logo/workeat-text-logo.svg';
import LogoWE from '../assets/logo/logo-we.png';
import arrow from '../assets/images/close-sidebar.svg';
import profile from '../assets/images/profile.svg';
import { Link, NavLink } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import arrowIcon from '../assets/icons/arrowIcon.svg';
import { KitchenMangamentSVG, ReportsSVG, SettingSVG, UserSVG } from './Header';
import http from '../services/http';
import packageJson from '../../package.json';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import axios from 'axios';
import AppConfig from '../constants/Config';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import { makeStyles } from '@mui/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { grey } from '@mui/material/colors';
export default function Sidebar({
  user,
  items,
  isOpen,
  logout,
  toggleSidebar,
  languages,
  currentLanguage,
  setLanguage,
  kitchens,
  kitchen,
  showKitchen,
  setKitchen,
  userKitchen,
  companyLogo,
}) {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [ver, setVer] = useState(0);
  const { t } = useTranslation();
  const anchor = 'right';
  const resetErrConfig = { noTitle: false, noFile: false, tooLarge: false };
  const [menuActive, toggleMenu] = useState(false);
  const [openModalState, setOpenModalState] = useState(false);
  const [fileTitle, setFileTitle] = useState('');
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [displayedFileTitle, setDisplayedFileTitle] = useState('');
  const [errors, setErrors] = useState(resetErrConfig);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [openEditBlock, setOpenEditBlock] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [fileWasDeleted, setFileWasDeleted] = useState(false);

  const useStyles = makeStyles((theme) => ({
    '& label': {
      transformOrigin: 'right !important',
      left: 'inherit !important',
      right: '1.75rem !important',
      fontSize: 'small',
      color: '#807D7B',
      fontWeight: 400,
      overflow: 'unset',
    },
  }));

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    toggleSidebar(open);
  };

  const uploadFileFn = (e) => {
    setFile(e.target.files[0]);
    setErrors((prev) => {
      return { ...prev, noFile: false };
    });
  };
  const getFile = async () => {
    const data = await http.get(`users/getSiteManagerFile`);
    setDisplayedFileTitle(data.urlTitle);
    setFileUrl(data.url);
    setFileTitle(data.urlTitle);
    return data.url;
  };
  useEffect(async () => {
    await getFile();
  }, []);
  const deleteFileFn = async () => {
    try {
      await http.delete(`users/deleteSiteManagerFile`);
      await setDisplayedFileTitle('');
      await setFileUrl('');
      await setFile(null);
      await setFileTitle('');
      await setDeleteDialog(false);
      await setOpenEditBlock(false);
    } catch (error) {
      console.log(error);
    }
  };

  const addFileTitle = (e) => {
    setFileTitle(e.target.value);
    if (e.target.value.trim() === '' || e.target.value.length > 20) {
      setErrors((prev) => {
        return {
          ...prev,
          noTitle: !e.target.value || e.target.value.trim() === '',
          tooLarge: e.target.value.length > 20,
        };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, noTitle: false, tooLarge: false };
      });
    }
  };

  const handleSubmit = async () => {
    let file = null;
    let title = '';
    const formData = new FormData();
    setFile((prev) => {
      file = prev;
      !file ? {} : formData.append('file', prev, prev?.name);
      return prev;
    });
    setFileTitle((prev) => {
      title = prev;
      !title || title === '' ? {} : formData.append('title', prev);
      return prev;
    });
    if (!file || !title || title === '' || title.length > 20) {
      setErrors((prev) => {
        return {
          noTitle: !title || title === '',
          noFile: !file,
          tooLarge: title && title.length > 20,
        };
      });
    } else {
      setErrors(resetErrConfig);
      const res = await axios.post(
        `${AppConfig.server.url}/users/setSiteManagerFile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('token')
            )}`,
            'X-Site-Url': window.location.href,
            'X-Org-Id': localStorage.getItem('companyId') ?? null,
          },
        }
      );
      setFileUrl(res.data.url);
      setDisplayedFileTitle(res.data.title);
    }
  };
  useEffect(async () => {
    setVer(packageJson.version);
  }, []);

  const changeLanguage = async (language) => {
    setIsSelectOpen(false);
    await setLanguage(language);
    //window.location.reload();
  };

  const getFlagUrl = (shortCode) => {
    return `${process.env.PUBLIC_URL}/flags/${shortCode}.svg`;
  };

  const clearFileInput = () => {
    setFile(null);
    setDisplayedFileTitle(null);
  };
  const editFileTitle = async () => {
    let title = '';
    setFileTitle((prev) => {
      title = prev;
      return prev;
    });
    if (!title || title === '' || title.length > 20) {
      setErrors((prev) => {
        return {
          ...prev,
          noTitle: !title || title === '',
          tooLarge: title.length > 20,
        };
      });
    } else {
      setErrors(resetErrConfig);
      const res = await http.post(`users/setSiteManagerTitle`, {
        title: title,
      });
      setOpenEditBlock(false);
      setDisplayedFileTitle(res.title);
    }
  };
  const classes = useStyles();

  const downloadFile = async () => {
    const fileUrl = await getFile();
    if (fileUrl) {
      await window.open(fileUrl);
    } else {
      setFileWasDeleted(true);
    }
  };
  return (
    <SwipeableDrawer
      anchor={anchor}
      open={isOpen}
      onClose={toggleDrawer(anchor, false)}
      onOpen={toggleDrawer(anchor, true)}
      BackdropProps={{ invisible: true }}
      elevation={0}
      classes={{ paper: 'sidebar' }}
      anchor={currentLanguage?.isRTL ? 'right' : 'left'}
    >
      <nav className={`nav sidebar-menu ${isOpen ? 'active' : ''}`}>
        <section className="logo-section">
          <img src={logo} alt="logo" className="desk-logo" />

          <NavLink
            to="/"
            onClick={() => toggleSidebar(!isOpen)}
            className="mobile-logo"
          >
            <img src={LogoWE} alt={''} />
            <img src={textlogo} alt="logo" />
          </NavLink>
          <img src={arrow} alt="arrow" onClick={toggleDrawer(anchor, false)} />
        </section>
        <section className="user-profile">
          {/* <WEIcon
            icon={'close'}
            additionalClassname={'icon'}
            text={''}
            cb={toggleDrawer(anchor, false)}
          /> */}
          <div className="user-name">
            <img src={profile} alt="profile" />
            <span className="">{t('hello') + ' ' + user.firstName}</span>
          </div>
          <div className="logout">
            {/* <p onClick={() => {
                toggleDrawer(anchor, false)();
                logout();
              }}>{t('logout')}</p>   */}
            {/*Logout*/}
            <WEIcon
              // icon={false}
              iconSize={'small'}
              text={t('logout')}
              cb={() => {
                toggleDrawer(anchor, false)();
                logout();
              }}
            />
          </div>
        </section>
        <div className="main-wrapper">
          <section className="items">
            <ul>
              {items?.map((link, index) => (
                <>
                  {link?.user?.length ? (
                    <NavDropdown
                      title={
                        <>
                          <UserSVG />
                          {t('user')}
                        </>
                      }
                      key={index}
                      active={
                        [
                          '#/userManagement',
                          '#/contractorManagement',
                          '#/walletManagement',
                        ].find((ele) => window.location.hash.startsWith(ele))
                          ? true
                          : false
                      }
                    >
                      {link?.user?.map((el, i) => (
                        <NavLink
                          onClick={() => toggleSidebar(!isOpen)}
                          className="topbar-link"
                          activeClassName="active-topbar-link"
                          to={el.link}
                          key={i}
                          as={NavLink}
                        >
                          {el.label}
                        </NavLink>
                      ))}
                    </NavDropdown>
                  ) : (
                    ''
                  )}
                  {link?.reports?.length ? (
                    <NavDropdown
                      title={
                        <>
                          <ReportsSVG /> {t('reports')}
                        </>
                      }
                      key={index}
                      active={
                        [
                          '#/reservationManagement',
                          '#/reservationManagement',
                          '#/reservationByMeal',
                          '#/reservationByUser',
                          '#/salaryReport',
                          '#/allReviews',
                        ].find((ele) => window.location.hash.startsWith(ele))
                          ? true
                          : false
                      }
                    >
                      {link?.reports?.map((el, i) => {
                        return (
                          <NavLink
                            onClick={async (el) => {
                              toggleSidebar(!isOpen);
                            }}
                            className="topbar-link"
                            activeClassName="active-topbar-link"
                            to={el.link}
                            key={i}
                            as={NavLink}
                          >
                            {el.label}
                          </NavLink>
                        );
                      })}
                    </NavDropdown>
                  ) : (
                    ''
                  )}
                  {link?.kitchen?.length ? (
                    <NavDropdown
                      title={
                        <>
                          <KitchenMangamentSVG />
                          {t('kitchen')}
                        </>
                      }
                      active={
                        [
                          '#/mealManagement',
                          '#/pastMeals',
                          '#/dishManagement',
                          '#/tagManagement',
                          '#/menuConstruction',
                          '#/addDishToMeal',
                          '#/copyPreviousMeal',
                        ].find((ele) => window.location.hash.startsWith(ele))
                          ? true
                          : false
                      }
                    >
                      {link?.kitchen?.map((el, i) => (
                        <NavLink
                          onClick={() => toggleSidebar(!isOpen)}
                          className="topbar-link"
                          activeClassName="active-topbar-link"
                          to={el.link}
                          key={i}
                        >
                          {el.label}
                        </NavLink>
                      ))}
                    </NavDropdown>
                  ) : (
                    ''
                  )}
                  {link?.setting?.length ? (
                    <NavDropdown
                      title={
                        <>
                          <SettingSVG /> {t('setting')}
                        </>
                      }
                      active={
                        [
                          '#/kitchensManagement',
                          '#/paymentManagement',
                          '#/logViewer',
                          '#/security',
                          '#/mealtypes',
                        ].find((ele) => window.location.hash.startsWith(ele))
                          ? true
                          : false
                      }
                    >
                      {link?.setting?.map((el, i) => (
                        <NavLink
                          onClick={() => toggleSidebar(!isOpen)}
                          className="topbar-link"
                          activeClassName="active-topbar-link"
                          to={el.link}
                          key={i}
                        >
                          {el.label}
                        </NavLink>
                      ))}
                    </NavDropdown>
                  ) : (
                    ''
                  )}
                  {link?.label && (
                    <li className="menu_item">
                      <NavLink
                        onClick={() => toggleSidebar(!isOpen)}
                        className="topbar-link"
                        activeClassName="active-topbar-link"
                        to={link?.link}
                      >
                        {link?.icon}
                        {link?.label}
                      </NavLink>
                    </li>
                  )}
                </>
              ))}
              {user.roleId === 4 && displayedFileTitle && (
                <li className="menu_item" onClick={() => downloadFile()}>
                  <NavLink
                    className="topbar"
                    activeClassName="active-topbar"
                    to={'#'}
                  >
                    <FileDownloadOutlinedIcon className="file-download-icon" />
                    <Tooltip title={displayedFileTitle} placement="bottom">
                      <p className="file-download-elem">{displayedFileTitle}</p>
                    </Tooltip>
                  </NavLink>
                </li>
              )}
            </ul>
            {/* <ul>

            {items.map((link, index) => (
              <li
                key={index}
                className="menu_item"
                onClick={() => handleItemClick(link.link)}
              >
                <NavLink
                  // onClick={link.onClick}
                  className="topbar-link"
                  activeClassName="active-topbar-link"
                  to={link.link}
                >
                  {link.label}
                  precomment >>> <div className={'active-topbar-link-underline'} />
                </NavLink>
              </li>
            ))}
          </ul> */}
          </section>
          <section className="sidebar-footer">
            <div className="sidebar-upload-button-wrapper">
              {(user.roleId === 2 || user.roleId === 1) && (
                <Accordion
                  sx={{
                    border: '1px solid #333951',
                    'border-radius': '8px',
                    width: '100%',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    variant="primary"
                  >
                    <Typography>{t('attachedFile')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {displayedFileTitle && displayedFileTitle !== '' ? (
                      <div className="delete-file-block-column">
                        <div className="file-actions-block">
                          <Tooltip title={t('isDownload')} placement="top">
                            <div
                              onClick={() => window.open(fileUrl)}
                              className="cursor-pointer"
                            >
                              <TermsSVG />
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={displayedFileTitle}
                            placement="bottom"
                          >
                            <p className="upload-file-block">
                              {displayedFileTitle !== ''
                                ? `${t('isfile')}: ${displayedFileTitle}`
                                : `${t('isfile')}:--`}
                            </p>
                          </Tooltip>
                          <div className="cursor-pointer">
                            <i
                              class="las la-pen"
                              onClick={() => setOpenEditBlock((prev) => !prev)}
                            ></i>
                          </div>
                          <div className="cursor-pointer">
                            <i
                              class="las la-trash"
                              onClick={() => setDeleteDialog(true)}
                            ></i>
                          </div>
                        </div>

                        {openEditBlock && (
                          <div className="sidebar-edit-block">
                            <div className="sidebar-edit-block-control">
                              <TextField
                                label={t('fileTitle')}
                                dir={currentLanguage?.isRTL ? 'rtl' : 'ltr'}
                                className={
                                  currentLanguage?.isRTL
                                    ? 'sidebar-upload-input-rtl'
                                    : 'sidebar-upload-input'
                                }
                                onChange={(e) => addFileTitle(e)}
                                value={fileTitle}
                              />
                              <Button
                                variant="contained"
                                color="success"
                                component="label"
                                className="sidebar-upload-button"
                                onClick={() => editFileTitle()}
                              >
                                {t('saveInfo')}
                              </Button>
                            </div>

                            {errors.noTitle && (
                              <p className="errors">{t('TitleEmpty')}</p>
                            )}
                            {errors.tooLarge && (
                              <p className="errors">{t('titleTooLarge')}</p>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <form
                        className="flex-file-upload-wrapper"
                        id="form-file-uploader"
                      >
                        <div className="flex-file-upload">
                          <div className="errorWrapper">
                            {file ? (
                              <div className="flex-file-upload">
                                <i
                                  class="las la-trash cursor-pointer"
                                  onClick={() => clearFileInput()}
                                ></i>
                                <div className="flex-column">
                                  <Tooltip
                                    title={t('isDownload')}
                                    placement="top"
                                  >
                                    <TermsSVG
                                      className="upload-file-button cursor-pointer"
                                      onClick={() => window.open(fileUrl)}
                                    />
                                  </Tooltip>
                                  <Tooltip title={file.name} placement="bottom">
                                    <p className="sidebar-upload-filename">
                                      {file.name}
                                    </p>
                                  </Tooltip>
                                </div>
                              </div>
                            ) : (
                              <Button
                                variant="contained"
                                color="success"
                                component="label"
                                className="sidebar-upload-button"
                                sx={{ height: '100%' }}
                              >
                                <UploadIcon className="cursor-pointer" />
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) => uploadFileFn(e)}
                                />
                              </Button>
                            )}
                            {errors.noFile && (
                              <p className="errors">{t('FileInpEmpty')}</p>
                            )}
                          </div>
                          <div className="errorWrapper">
                            <TextField
                              label={t('fileTitle')}
                              onChange={(e) => addFileTitle(e)}
                              dir={currentLanguage?.isRTL ? 'rtl' : 'ltr'}
                              className={
                                currentLanguage?.isRTL
                                  ? 'sidebar-upload-input-rtl'
                                  : 'sidebar-upload-input'
                              }
                            />
                            {errors.noTitle && (
                              <p className="errors">{t('TitleEmpty')}</p>
                            )}
                            {errors.tooLarge && (
                              <p className="errors">{t('titleTooLarge')}</p>
                            )}
                          </div>
                        </div>
                        <Button
                          variant="contained"
                          color="success"
                          component="label"
                          className="sidebar-upload-button"
                          onClick={handleSubmit}
                        >
                          {t('submitLabel')}
                        </Button>
                      </form>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
            <div className="sub-header ">
              <div
                className={`kitchens-list ${
                  showKitchen ? 'show-kitchen' : ''
                } ${kitchens.length >= 1 ? 'pointer' : ''}`}
                onClick={() => toggleMenu(!menuActive)}
                // onMouseLeave={() => toggleMenu(false)}
              >
                {showKitchen && (
                  <KitchenDropdown
                    curKitchen={kitchen}
                    kitchens={kitchens}
                    menuActive={menuActive}
                    setKitchenCB={setKitchen}
                    user={user}
                    currentLanguage={currentLanguage}
                    userKitchen={userKitchen}
                  />
                )}
              </div>
            </div>

            <div className="language-section">
              <LanguageSelection />
              <p className="version-def-style language-section-version">
                V {ver}
              </p>
            </div>
          </section>
        </div>
      </nav>
      <Dialog
        open={deleteDialog}
        disableEnforceFocus
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('deleteAttachedFile')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteFileFn()}>{t('yes')}</Button>
          <Button onClick={() => setDeleteDialog(false)}>{t('no')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={fileWasDeleted}
        onClose={setFileWasDeleted}
        disableEnforceFocus
      >
        <DialogContent>
          <p>{t('fileDeleted')}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFileWasDeleted(false)}>{t('ok')}</Button>
        </DialogActions>
      </Dialog>
    </SwipeableDrawer>
  );
}
const TermsSVG = () => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 18.9738H5C4.54167 18.9738 4.14945 18.8107 3.82334 18.4846C3.49667 18.158 3.33334 17.7655 3.33334 17.3071V3.9738C3.33334 3.51546 3.49667 3.12296 3.82334 2.7963C4.14945 2.47018 4.54167 2.30713 5 2.30713H11.6667L16.6667 7.30713V17.3071C16.6667 17.7655 16.5036 18.158 16.1775 18.4846C15.8508 18.8107 15.4583 18.9738 15 18.9738ZM10.8333 8.14046V3.9738H5V17.3071H15V8.14046H10.8333ZM5 8.14046V3.9738V17.3071V8.14046ZM6.66667 11.4738H13.3333V9.80713H6.66667V11.4738ZM6.66667 13.9738H13.3333V12.3071H6.66667V13.9738ZM6.66667 16.4738H10.8333V14.8071H6.66667V16.4738Z"
        fill="#6D7287"
      />
    </svg>
  );
};
