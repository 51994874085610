import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useCallback, useState } from 'react';
import { setLayoutOptions, useFetchingLoader } from '../../store/app/actions';
import TagForm from '../../components/Forms/TagForm';
import { useHistory, useParams } from 'react-router';
import http from '../../services/http';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';

const adaptPayload = (data) => {
  const translations: any[] = data.translations;
  const n = Object.keys(translations).length;
  const translationsArray: any[] = [];
  for (let i = 0; i < n; i++) {
    translationsArray.push(translations[i]);
  }

  return { ...data, translations: translationsArray };
};

const AddTag = ({
  setLayoutOptions,
  useFetchingLoader,
  languages,
  CurrentLanguage,
}) => {
  const { t } = useTranslation();
  const { tagId } = useParams<{
    tagId: string;
  }>();
  const history = useHistory();
  const [data, setData] = useState<any>(null);
  const dataToInitialValues = useCallback(() => {
    return {
      isPublic: data?.isPublic || false,
      active: data?.status || data?.active || false,
      displayName:
        data?.translations[CurrentLanguage.languageStatusId].displayName,
      translations:
        data?.translations
          .sort((a, b) => a.languageId - b.languageId)
          .reduce((prev, translation, idx) => {
            prev[idx] = {
              displayName: translation.text,
            };
            return prev;
          }, {}) ?? [],
    };
  }, [data]);

  const onSubmit = useCallback(async (form) => {
    if (!!tagId) {
      await useFetchingLoader(async () => {
        await http.put(`tags/${tagId}`, {
          ...adaptPayload(form),
          displayName:
            form?.translations[CurrentLanguage.languageStatusId].displayName,
        });
      });
      SwalDialogs.Dialog({
        title: t('tagUpdatedSuccessfully'),
        text: '',
        type: 'success',
        buttons: {
          showCancelButton: false,
          showConfirmButton: true,
        },
        onConfirm: () => {
          history.push('/tagManagement');
        },
        confirmButtonText: t('okay'),
      });
    } else {
      try {
        await useFetchingLoader(async () => {
          const res = await http.post('tags/create', {
            ...adaptPayload(form),
            displayName:
              form?.translations[CurrentLanguage.languageStatusId].displayName,
          });
          history.push('/tagManagement');
          console.log('error', res);
        });
      } catch (error) {
        console.log(error, 'jkjjjj');
        showDailog(error);
        //alert(error);
        return error;
      }
    }
  }, []);

  const showDailog = (error: any) => {
    Dialog({
      title: error['msg'],
      type: 'error',
      buttons: {
        showCancelButton: false,
        confirmButtonText: t('confirm'),
      },
      onConfirm: () => {},
    });
  };

  useEffect(() => {
    setLayoutOptions({
      title: t('addTag'),
    });

    if (!!tagId) {
      useFetchingLoader(async () => {
        const _data = await http.get(`tags/${tagId}`);
        setData(_data);
      });
    }
  }, []);

  return (
    <div>
      {' '}
      {((!!tagId && data) || !tagId) && (
        <TagForm
          initialValues={dataToInitialValues()}
          languages={languages}
          CurrentLanguage={CurrentLanguage}
          onSubmit={onSubmit}
          onClose={() => history.push('/tagManagement')}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
  languages: state.languages.options,
  CurrentLanguage: state.languages.current,
});

const mapDispatchToProps = {
  useFetchingLoader,
  setLayoutOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTag);
