import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import {
  validateEmployee,
  validateGuestUsersNew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  borderRadius: '5px',
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        // disabled={!editMode}
        type={type}
      />
    </>
  );
};

const AddGuestUserPopup = ({
  open,
  handleClose,
  handleAddNewUser,
  Roles,
  Kitchens,
  UserStatuses,
  setPayload,
  payload,
  handleUpdateUser,
  setIsEdit,
  isUpdate,
  userData,
  setKitchensIds,
  kitchensIds,
  t,
  errors,
  setErrors,
  user,
  contractorList,
  kitchen,
  getMealTypes,
  mealTypesIds,
  setMealTypesIds,
  setGetMealTypes,
  currentUserKitchens,
  setMealTypesKitchenWise,
  getMealTypesKitchenWise,
}) => {
  const KitchensMap = Kitchens?.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const MealTypesMap = getMealTypes?.reduce((acc, mealType) => {
    return { ...acc, [mealType.id]: mealType.display };
  }, {});

  const RolesMap_ValueId = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});

  const ContractorMap_ValueId = contractorList?.reduce((acc, contractor) => {
    return { ...acc, [contractor.id]: contractor.name };
  }, {});
  const StatusMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.display };
  }, {});

  const [role] = useState(4);
  const [pError, setPError] = useState(false);
  const [status, setStatus] = useState({ value: 3, label: 'Active' });

  useEffect(() => {
    setPayload({
      ...payload,
      contractorsIds: payload?.contractors?.map((contractor) => contractor.id),
    });
  }, [payload?.contractors]);

  useEffect(() => {
    if (
      payload?.allContractors &&
      Array.isArray(payload?.allContractors) &&
      (!payload?.contractorsIds || payload?.contractorsIds.length === 0) &&
      payload?.allContractors.length === 1
    ) {
      const elem = payload?.allContractors[0];
      setPayload((prev) => {
        return {
          ...prev,
          contractorsIds: [elem?.id],
          magneticStripe: elem?.magneticStripe ?? '',
        };
      });
    }
    if (isUpdate && payload.mealTypesIds && payload.mealTypesIds.length !== 0) {
      setPayload((prev) => {
        const result = prev?.mealTypesIds.filter(
          (elem) => MealTypesMap[elem] !== undefined
        );
        return { ...prev, mealTypesIds: result };
      });
    }
    if (
      currentUserKitchens &&
      Array.isArray(currentUserKitchens) &&
      (!payload?.kitchensIds || payload?.kitchensIds.length === 0) &&
      getMealTypes &&
      currentUserKitchens.length === 1
    ) {
      setKitchensIds([currentUserKitchens[0].id]);
      setPayload((prev) => {
        return {
          ...prev,
          kitchensIds: [Kitchens[0].id],
        };
      });
      let newMealTypeDateArray = [];
      if (getMealTypes && Array.isArray(getMealTypes)) {
        let newMealTypeInfo = getMealTypes?.filter(
          (elInfo) => elInfo?.kitchenId === currentUserKitchens[0]?.id
        );
        newMealTypeDateArray = [...newMealTypeDateArray, ...newMealTypeInfo];
        console.log('newMealTypeDateArray', newMealTypeDateArray);
        setMealTypesKitchenWise(newMealTypeDateArray);
        if (newMealTypeDateArray.length === 1) {
          setMealTypesIds([Number(newMealTypeDateArray[0].id)]);
          setPayload((prev) => {
            return {
              ...prev,
              mealTypesIds: [Number(newMealTypeDateArray[0].id)],
              mealTypeId: [Number(newMealTypeDateArray[0].id)],
            };
          });
        }
      }
    }
  }, [currentUserKitchens, payload?.allContractors, open]);

  const handleChange = (name, value) => {
    const { isValid, error } = validateEmployee(value, name);
    setErrors({ ...errors, ...error });
    setPayload({ ...payload, [name]: value });
  };
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      backgroundColor: 'transparent',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
      borderRadius: '5px',
    }),
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log('payload', payload);
    let details = {
      firstName: payload.fName,
      lastName: payload.lName,
      email: payload?.email ? payload?.email : null,
      phone: payload?.phone,
      userStatusId: status,
      kitchensIds: userData
        .find((el) => el.id === payload.userId)
        ?.kitchens.map((ele) => ele.id),
      password: payload.password === '' ? undefined : payload.password,
      cardId: payload.card,
      employeeId: payload.employeeId,
      roleId: RolesMap_ValueId.guestUser,
      kitchensIds,
      magneticStripe: payload.magneticStripe,
      contractorsIds: payload.contractorsIds,
      hireFrom: payload.hireFrom,
      hireTill: payload.hireTill,
      mealTypeId: mealTypesIds,
    };
    const { isValid, errors } = validateGuestUsersNew(details);
    setErrors(errors);
    if (isValid) {
      await handleAddNewUser({ ...details });
      // handleClose();
    }
  };

  const CheckPassword = (value) => {
    if (
      value.label === 'Active' &&
      (payload.password === '' || !payload.password)
    ) {
      /*setPError(true);
      // setErrors({ ...errors, password: 'Password is required' });*/
      setPayload({ ...payload, status: 'Active' });
    } else {
      setPError(false);
      setStatus(value);
      setPayload({ ...payload, status: value.label });
      setErrors({ ...errors, password: '' });
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {isUpdate ? t('updateGuestUser') : t('addGuestUser')}
        </Typography>
        <Typography id="modal-modal-description">
          <form>
            <div className="item">
              <div className="input-field">
                <GetInputField
                  placeholder={t('firstName')}
                  label={t('firstName')}
                  name="fName"
                  type="text"
                  value={payload['fName']}
                  cb={(value) => {
                    handleChange('fName', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {errors.fName || errors.firstName}
                  </p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('lastName')}
                  label={t('lastName')}
                  name="lName"
                  type="text"
                  value={payload['lName']}
                  cb={(value) => {
                    handleChange('lName', value);
                  }}
                  required
                />
                {errors && (
                  <p className="error-msg">{errors.lName || errors.lastName}</p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('phone')}
                  label={t('phone')}
                  name="phone"
                  type="number"
                  value={payload['phone']}
                  cb={(value) => {
                    handleChange('phone', value);
                  }}
                  required
                />
                {errors && (
                  <p className="error-msg">{errors.phone || errors.phone}</p>
                )}
              </div>

              <div className="input-field">
                <GetInputField
                  placeholder={t('email')}
                  label={t('email')}
                  name="email"
                  type="email"
                  value={payload['email']}
                  cb={(value) => {
                    handleChange('email', value);
                  }}
                  required
                />
                {errors && <p className="error-msg">{errors.email}</p>}
              </div>
              {/* <div className="input-field">
                <GetInputField
                  placeholder={t('password')}
                  label={t('password')}
                  name="password"
                  type="password"
                  value={payload['password']}
                  cb={(value) => {
                    handleChange('password', value);
                  }}
                  required
                />
                {pError && <p className="error-msg">Password is required</p>}
                {errors && <p className="error-msg">{errors.password}</p>}
              </div> */}

              <div className="input-field">
                <label>{t('kitchenname')}</label>
                <Select
                  isMulti
                  name={'kitchensIds'}
                  value={kitchensIds?.map((kitchenId) => {
                    return { value: kitchenId, label: KitchensMap[kitchenId] };
                  })}
                  onChange={(newData) => {
                    setPayload({
                      ...payload,
                      kitchensIds: newData.map((k) => k.value),
                    });
                    let newMealTypeDateArray = [];
                    newData?.forEach((elData) => {
                      let newMealTypeInfo = getMealTypes?.filter(
                        (elInfo) => elInfo?.kitchenId === elData?.value
                      );
                      newMealTypeDateArray = [
                        ...newMealTypeDateArray,
                        ...newMealTypeInfo,
                      ];
                    });
                    setMealTypesKitchenWise(newMealTypeDateArray);
                    if (newMealTypeDateArray.length === 1) {
                      setMealTypesIds([newMealTypeDateArray[0].value]);
                      setPayload({
                        ...payload,
                        mealTypesIds: [newMealTypeDateArray[0].id],
                      });
                    }
                    setKitchensIds(newData.map((k) => k.value));
                    if (
                      payload.mealTypesIds &&
                      payload.mealTypesIds.length > 0
                    ) {
                      let newMealTypeDateArrayInfo = [];
                      newMealTypeDateArray?.forEach((elDataInfo) => {
                        let newMealTypeInfo1 = payload.mealTypesIds?.filter(
                          (elInfoID) => elInfoID === elDataInfo?.id
                        );
                        newMealTypeDateArrayInfo = [
                          ...newMealTypeDateArrayInfo,
                          ...newMealTypeInfo1,
                        ];
                      });
                      setPayload({
                        ...payload,
                        mealTypesIds: newMealTypeDateArrayInfo,
                      });
                    }
                  }}
                  options={currentUserKitchens.map((kitchen) => {
                    return { value: kitchen.id, label: kitchen.name };
                  })}
                  placeholder={t('kitchenname')}
                  styles={SelectStyles}
                  required
                />
                {errors && <p className="error-msg">{errors.kitchensIds}</p>}
              </div>
              <div className="input-field">
                <label>{t('mealType')}</label>
                <Select
                  isMulti
                  name={'mealTypeId'}
                  // value={mealTypesIds?.map((el) => {
                  //   return { value: el, label: MealTypesMap[el] };
                  // })}
                  value={payload?.mealTypesIds?.map((el) => {
                    return { value: el, label: MealTypesMap[el] };
                  })}
                  onChange={(newData) => {
                    setMealTypesIds(newData.map((k) => k.value));
                    setPayload({
                      ...payload,
                      mealTypesIds: newData.map((k) => k.value),
                    });
                  }}
                  // options={getMealTypes?.map((el) => {
                  //   return { value: el.id, label: el.display };
                  // })}
                  options={getMealTypesKitchenWise?.map((el) => {
                    return { value: el.id, label: el.display };
                  })}
                  placeholder={t('mealType')}
                  styles={SelectStyles}
                  required
                />
                {errors && <p className="error-msg">{errors.mealTypeId}</p>}
              </div>
              <div className="input-field">
                <label>{t('contractor')}</label>
                <Select
                  name={'contractorsIds'}
                  value={
                    payload.contractorsIds &&
                    payload.contractorsIds.map((elem) => {
                      return {
                        value: elem,
                        label: ContractorMap_ValueId[elem],
                        disableDelete:
                          payload?.allContractors &&
                          payload?.contractors &&
                          !Boolean(
                            payload?.allContractors.find(
                              (el) => el.id === elem
                            ) &&
                              payload?.contractors.find((el) => el.id === elem)
                          ),
                      };
                    })
                  }
                  onChange={(newData, triggeredAction) => {
                    if (
                      triggeredAction.action === 'remove-value' &&
                      triggeredAction.removedValue.disableDelete
                    ) {
                      Dialog({
                        title: t('removeForeignContractor'),
                        type: 'error',
                        buttons: {
                          showCancelButton: false,
                          confirmButtonText: t('confirm'),
                        },
                      });
                      return;
                    } else {
                      setPayload({
                        ...payload,
                        contractorsIds: [newData.value],
                        magneticStripe:
                          contractorList.find((el) => el.id === newData.value)
                            ?.magneticStripe ?? '',
                      });
                    }
                  }}
                  options={payload?.allContractors?.map((contractors) => {
                    return {
                      value: contractors.id,
                      label: [contractors.name],
                      disableDelete: payload?.contractors
                        ? payload?.contractors.length === 0
                          ? false
                          : !payload?.contractors.find(
                              (el) =>
                                el.label ===
                                ContractorMap_ValueId[contractors.id]
                            )
                        : false,
                    };
                  })}
                  placeholder={t('contractor')}
                  styles={SelectStyles}
                  required
                />
                {errors && <p className="error-msg">{errors.contractorsIds}</p>}
              </div>
              {/* <div className="input-field">
                <label>{t('kitchenname')}</label>
                <Select
                  isMulti
                  name={'kitchensIds'}
                  value={kitchensIds?.map((kitchenId) => {
                    return { value: kitchenId, label: KitchensMap[kitchenId] };
                  })}
                  onChange={(newData) => {
                    setKitchensIds(newData.map((k) => k.value));
                    setPayload({
                      ...payload,
                      kitchensIds: newData.map((k) => k.value),
                    });
                  }}
                  options={Kitchens.map((kitchen) => {
                    return { value: kitchen.id, label: kitchen.name };
                  })}
                  placeholder={t('kitchenname')}
                  styles={SelectStyles}
                  required
                />
              </div> */}

              {/* <div className="input-field">
                <label>{t('contractor')}</label>
                <Select
                  // isMulti
                  name={'contractorId'}
                  defaultValue={{
                    value: payload?.contractor,
                    label:
                      ContractorMap_ValueId &&
                      ContractorMap_ValueId[payload?.contractor],
                  }}
                  onChange={(newData) => {
                    // setRolesIds(newData.value);
                    setPayload({ ...payload, contractorId: newData.value });
                  }}
                  options={contractorList?.map((contractors) => {
                    return {
                      value: contractors.id,
                      label: [contractors.name],
                    };
                  })}
                  placeholder={t('contractor')}
                  styles={SelectStyles}
                  required
                /> */}
              {/* {errors.role && <p className="error-msg">Role is required</p>} */}
              {/* </div> */}

              <div className="input-field">
                <GetInputField
                  placeholder={t('startDate')}
                  label={t('startDate')}
                  name="hireFrom"
                  type="date"
                  value={payload['hireFrom']}
                  cb={(value) => {
                    handleChange('hireFrom', value);
                  }}
                  required
                />
                {errors && <p className="error-msg">{errors.hireFrom}</p>}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('endDatee')}
                  label={t('endDate')}
                  name="hireTill"
                  type="date"
                  value={payload['hireTill']}
                  cb={(value) => {
                    handleChange('hireTill', value);
                  }}
                  required
                />
                {errors && <p className="error-msg">{errors.hireTill}</p>}
              </div>
              {/* <div className="input-field">
                <GetInputField
                  placeholder={t('cardNo')}
                  label={t('cardNo')}
                  name="card"
                  type="number"
                  value={payload['card']}
                  cb={(value) => {
                    handleChange('card', value);
                  }}
                  required
                />
              </div> */}
              {user.roleId === 1 || isUpdate ? (
                <div className="input-field">
                  <label>{t('status')}</label>
                  {/* <Select
                name={'status'}
                value={ { value: 1, label: StatusMap[1] }}
                placeholder={t('status')}
                styles={SelectStyles}
                isMulti
                required
              /> */}
                  <Select
                    name={'status'}
                    defaultValue={
                      UserStatuses.find((el) => el.display === payload.status)
                        ? {
                            value: UserStatuses.find(
                              (el) => el.display === payload.status
                            )?.id,
                            label: UserStatuses.find(
                              (el) => el.display === payload.status
                            )?.display,
                          }
                        : { value: 1, label: StatusMap[1] }
                    }
                    onChange={(newData) => {
                      CheckPassword(newData);
                    }}
                    options={UserStatuses.map((kitchen) => {
                      return { value: kitchen.id, label: kitchen.display };
                    })}
                    placeholder={t('status')}
                    styles={SelectStyles}
                    // isMulti
                    required
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </form>
          {isUpdate ? (
            <div className="add-employee-btn">
              <div onClick={handleUpdateUser}>
                <ActiveButton label={t('update')} arrow={true} />
              </div>
            </div>
          ) : (
            <div className="add-employee-btn">
              <div onClick={handleFormSubmit}>
                <ActiveButton label={t('add')} arrow={true} />
              </div>
            </div>
          )}
        </Typography>
      </Box>
    </Modal>
  );
};
export default AddGuestUserPopup;
