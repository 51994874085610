import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewContractorDetails = ({
  open,
  details,
  handleClose,
  UserStatuses,
  setKitchensIds,
  setPayload,
  Kitchens,
  Roles,
  handleOpen,
  handleConfirmation,
  ResendVerification,
  CopyDataUrl,
  handleWallet,
  handleOrderLink,
  setMealTypesIds,
  cibusEnabled,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    console.log('details', details);
  }, [details]);
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="view-more-popup"
      >
        <Box sx={style}>
          <div className="view-detail-head">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {t('userManagement')}
            </Typography>
            <div className="close-btn-view">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('name')}:</h6>
                  <p>
                    {details?.firstName ? details?.firstName : '--'}{' '}
                    {details?.lastName ? details?.lastName : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('email')}:</h6>
                  <p>{details?.email ? details?.email : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('employeeNo')}:</h6>
                  <p>{details?.employeeId ? details?.employeeId : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('cardId')}:</h6>
                  <p>{details?.cardId ? details?.cardId : '--'}</p>
                </div>

                <div className="user-details-block">
                  <h6>{t('userStatus')}:</h6>
                  <p>{details?.Status ? details?.Status : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('kitchenname')}:</h6>
                  <p>{details?.Kitchens ? details?.Kitchens : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('contractors')}:</h6>
                  <p>
                    {details?.Contractors
                      ? details?.Contractors.map((el) => el.name).join(', ')
                      : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('registrationDate')}:</h6>
                  <p>{details?.createdAt ? details?.createdAt : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('phone')}:</h6>
                  <p>{details?.phone ? details?.phone : '--'}</p>
                </div>
                {cibusEnabled && (
                  <div className="user-details-block">
                    <h6>{t('magneticStripe')}:</h6>
                    <p>
                      {details?.magneticStripe ? details?.magneticStripe : '--'}
                    </p>
                  </div>
                )}
                <div className="user-details-block">
                  <h6>{t('action')}:</h6>
                  <p>
                    <div className="actions-btns">
                      <i
                        class="las la-pen"
                        title="edit"
                        onClick={() => {
                          let ids = [];
                          if (details['Kitchens']?.length) {
                            Kitchens?.forEach((el) => {
                              if (details['Kitchens']?.includes(el?.name)) {
                                ids?.push(el?.id);
                              }
                            });
                          }
                          setKitchensIds(ids);
                          if (details['RoleId'] === 6) {
                            setShowContractor(true);
                          }
                          setPayload({
                            userId: details['userId'],
                            card: details['cardId'],
                            email: details['email'],
                            fName: details['firstName'],
                            lName: details['lastName'],
                            phone: details['phone']
                              ? details['phone'].split('-').join('')
                              : details['phone'],
                            kitchensIds: ids,
                            employeeId: details['employeeId'],
                            allContractors: details['allContractors'],
                            contractors: details['Contractors'],
                            role: [
                              Roles.find((el) => el.display === details['Role'])
                                ?.id,
                            ],
                            status: details?.Status,
                            contractorsIds: [],
                            magneticStripe: details?.magneticStripe,
                          });
                          handleOpen();
                        }}
                      ></i>
                      <i
                        class="las la-trash"
                        title="delete"
                        onClick={() => handleConfirmation(details['userId'])}
                      ></i>
                      {UserStatuses?.find(
                        (el) => el.display === details?.Status
                      )?.value === 'created' ||
                      UserStatuses?.find((el) => el.display === details?.Status)
                        ?.value === 'pending' ? (
                        <i
                          class="las la-copy"
                          title="Copy"
                          onClick={() => ResendVerification(details)}
                        ></i>
                      ) : (
                        ''
                      )}
                      {UserStatuses?.find(
                        (el) => el.display === details?.Status
                      )?.value === 'created' ||
                      UserStatuses?.find((el) => el.display === details?.Status)
                        ?.value === 'pending' ? (
                        <i
                          class="las la-share-square"
                          title="Resend"
                          onClick={() => CopyDataUrl(details)}
                        ></i>
                      ) : (
                        ''
                      )}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </Typography>
          {/* <div className="view-popup-btn">
            <button className="close-modal-btn" onClick={handleClose}>
              {t('close')}
            </button>
          </div> */}
        </Box>
      </Modal>
    </>
  );
};
export default ViewContractorDetails;
