import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from '../../services/http';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import { isTabletOrMobile } from '../../services/mediaQueries';
import {
  Alert,
  Dialog,
  SuccessDailog,
} from '../../components/Dialogs/SwalDialogs';
import Employee from './Employee';
import EmployeeToAdd from './EmployeeToAdd';
import Select from 'react-select';
import useDebounce from '../../hooks/UseDebounce';
import WEIcon from '../../components/WE-IconButton';
import { Button } from '@material-ui/core';
import { ActiveButton } from '../../components/WE-Button';
import AddEmployeePopup from './AddEmployee';
import UpdateWalletPopup from './UpdateWalletPopup';
import {
  validateEmployeenew,
  validateUserWallet,
  validateUserWalletNew,
} from '../../services/validators';
import CopyUrlPopup from './copyUrl';
import ConfirmationPopup from './confirmation';
import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import search from '../../assets/images/Search.svg';
import file from '../../assets/images/file-export.svg';
import adduser from '../../assets/images/add-user.svg';
import more from '../../assets/images/more.svg';
import next from '../../assets/images/next.png';
import moment from 'moment';
import agGridLocales from '../../translations/ag-grid';
const initialiState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: 4,
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'created',
};
const initialiWalletState = {
  id: null,
  amount: '',
};

const WalletManagement = ({
  user,
  kitchen,
  setLayoutOptions,
  UserStatuses,
  Kitchens,
  Roles,
  useLoader,
  useFetchingLoader,
  currentLanguage,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [kitchensIds, setKitchensIds] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [errors, setErrors] = useState({});
  const [details, setDetails] = useState();

  const [isWalletUpdate, setIsWalletUpdate] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const [walletPayload, setWalletPayload] = useState(initialiWalletState);
  const [rowCount, setRowCount] = useState();

  const handleClose = () => {
    setOpen(false);
    setKitchensIds([]);
    setErrors({});
  };

  const handleWalletClose = () => {
    setOpenWallet(false);
  };

  const Limit = 2;
  const UserStatusesMap_Status = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status };
  }, {});
  const UserStatusesMap_Id = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(async () => {
    const { data, total } = await useFetchingLoader(
      async () => await http.get(`users/getAllwallet`)
    );
    let serverUsers = data.map((u, i) => ({
      walletId: u.id,
      cardNumber: u.cardId,
      amount: u.amount,
      name: [u?.user?.firstName, u?.user?.lastName].join(' '),
    }));
    setUsersToDisplay(serverUsers);
    setRowCount(serverUsers?.length);
  }, [dummy]);

  // ag grid data
  const columnDefs = [
    {
      field: 'name',
      headerName: t('userName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'NameCellRender',
      resizable: true,
    },
    {
      field: 'cardNumber',
      headerName: t('cardNo'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'CardNumber',
    },
    {
      field: 'amount',
      headerName: t('credit'),
      cellRenderer: 'PaymentMethod',
      sortable: ['asc', 'desc', null],
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        let check1 = nodeA.data.amount;
        let check2 = nodeB.data.amount;
        if (check1 === check2) return 0;
        else if (check1 > check2) return 1;
        return -1;
      },
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'action',
      headerName: t('action'),
      cellRenderer: 'UpdateWalletMore',
      filter: true,
      suppressMovable: true,
    },
  ];

  const defaultColDef = {
    sortable: true,
    width: 'auto',
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  // const gridOptions = {
  //   rowHeight: 60,
  //   groupHeaderHeight: 60,
  //   headerHeight: 60,
  //   rowStyle: {
  //     'border-bottom': 'white 10px solid',
  //     'border-top': 'white 0px solid',
  //     'border-radius': '5px',
  //     padding: '0px 5px',
  //   },
  // };

  // const onFilterTextBoxChanged = (e) => {
  //   const value = e.target.value;
  //   gridApi.setQuickFilter(value);
  // };

  const handleFilterChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
    const rowCount = gridApi.getModel().rowsToDisplay.length;
    setRowCount(rowCount);
  };

  const gridOptions = {
    onFilterChanged: handleFilterChanged,
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  /******** update wallet details */
  const handleWalletUpdateUser = async (userData) => {
    let details = {
      id: userData.id,
      amount: userData.amount,
    };
    try {
      const response = await useFetchingLoader(
        async () => await http.post(`users/updateUserWallet`, details)
      );
      Dialog({
        title: t('updatedMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setDummy(true);
          handleWalletClose();
          window.location.reload();
        },
      });

      setKitchensIds([]);
    } catch (err) {
      Alert({ error: err });
    }
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  function skipColumn(params) {
    return params.column.colId === 'action'; // Skip the 'model' column
  }
  const onBtnExport = () => {
    // const params = {
    //   skipColumn: skipColumn
    // };

    // gridApi.exportDataAsCsv({
    //   fileName:
    //     'Wallet' + '-' + moment(new Date()).format('YYYY-MM-DD hh:mm A'),
    // });
    const params = {
      columnKeys: ['name', 'cardNumber', 'amount'], // Specify the column keys to include in the CSV
      fileName:
        'Wallet' + '-' + moment(new Date()).format('YYYY-MM-DD hh:mm A'),
    };
    // const gridApi = gridRef.current.api;
    gridApi.exportDataAsCsv(params);
  };

  const handleWallet = (userId) => {
    history.push(`/credit?${userId}`);
  };
  const lang = localStorage.getItem('language');

  const handleUpdateUserWallet = (data) => {
    setWalletPayload(data);
    setOpenWallet(true);
  };
  return (
    <div className="user-management employee-management-page global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-input">
            <input
              type="text"
              id="filter-text-box"
              placeholder={`${t('freeTextSearch')}`}
              onInput={handleFilterChanged}
            />
            <img src={search} alt="search" />
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{rowCount}</span>
              </p>
            </div>
            <div className="actions">
              <img src={file} alt="add" onClick={() => onBtnExport()} />
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            frameworkComponents={{
              NameCellRender: (e) => <NameCellRender e={e} />,
              CardNumber: (e) => <CardNumber e={e} />,
              PaymentMethod: (e) => (
                <PaymentMethod e={e} handleWallet={handleWallet} t={t} />
              ),
              UpdateWalletMore: (e) => (
                <UpdateWallet
                  e={e}
                  t={t}
                  handleUpdateUserWallet={handleUpdateUserWallet}
                />
              ),
            }}
            columnDefs={columnDefs}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            debug={false}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            pagination={true}
            paginationPageSize={50}
            overlayLoadingTemplate={'<p></p>'}
            paginationNumberFormatter={function (params) {
              return '[' + params.value.toLocaleString() + ']';
            }}
          ></AgGridReact>
        </div>
        {/* <div className="pagination-header">
          {t('pageSize')}:
          <select onChange={() => onPageSizeChanged()} id="page-size">
            <option value="10" selected={true}>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>{' '} */}
        <UpdateWalletPopup
          open={openWallet}
          handleClose={handleWalletClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          newUserFlag={true}
          isEdit={true}
          walletPayload={walletPayload}
          setWalletPayload={setWalletPayload}
          handleWalletUpdateUser={handleWalletUpdateUser}
          isUpdate={isUpdate}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          t={t}
          errors={errors}
          setErrors={setErrors}
          user={user}
        />
      </div>
    </div>
  );
};

const UpdateWallet = ({ e, t, handleUpdateUserWallet }) => {
  return (
    <div class="review-btn" bis_skin_checked="1">
      <button
        type="button"
        onClick={() =>
          handleUpdateUserWallet({
            id: e?.data.walletId,
            amount: e?.data.amount,
          })
        }
        class="edit-btn btn btn-primary"
      >
        <span className="wallet-icon">
          <i class="las la-plus" title="edit"></i>
        </span>
        <span className="wallet-text">{t('UpdateWalletAMount')}</span>
      </button>
    </div>
  );
};

const PaymentMethod = ({ e, handleWallet, t }) => {
  return (
    <div className="payment-method">
      <a role="presentation" onClick={() => handleWallet(e.data['walletId'])}>
        {e.data.amount ? e.data.amount : '0'}
      </a>
    </div>
  );
};

const NameCellRender = ({ e }) => {
  return <p>{e.data.name && e.data.name != ' ' ? e.data.name : '--'}</p>;
};

const CardNumber = ({ e }) => {
  return <p>{e.data.cardNumber ? e.data.cardNumber : '--'}</p>;
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  languages: state.languages.options,
  currentLanguage: state.languages.current,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(WalletManagement);
