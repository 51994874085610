import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import moment from 'moment';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewDetails = ({
  open,
  details,
  handleClose,
  UserStatuses,
  setKitchensIds,
  setPayload,
  Kitchens,
  Roles,
  handleOpen,
  handleConfirmation,
  ResendVerification,
  CopyDataUrl,
  handleWallet,
  handleOrderLink,
  setShowContractor,
  setMealTypesIds,
  setMealTypesKitchenWise,
  getMealTypesKitchenWise,
  getMealTypes,
  cibusEnabled,
  attendanceEnabled,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [paymentMethodsForCA, setPaymentMethodsForCA] = useState('');
  useEffect(() => {
    if (details?.RoleId === 7) {
      if (
        details.allContractors &&
        details.allContractors.length !== 0 &&
        Boolean(details.allContractors.find((elem) => elem?.isInvoice === 1))
      ) {
        const methods = details.paymentMethod;
        methods.push('invoice');
        const uniqueArray = Array.from(new Set(methods));
        setPaymentMethodsForCA(uniqueArray.map((el) => t(el)).join(', '));
      } else {
        setPaymentMethodsForCA(
          details.paymentMethod.map((el) => t(el)).join(', ')
        );
      }
    }
  }, [details]);
  return (
    <>
      <Modal
        open={open}
        fullScreen={false}
        disableEnforceFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="view-more-popup"
      >
        <Box sx={style}>
          <div className="view-detail-head">
            {/* <Typography id="modal-modal-title" variant="h5" component="h2">
              {t('user')}
            </Typography> */}
            <div className="reservation-title">
              <h5>
                {t('user')}
                {t('reservationNumber')}
                {details?.userId}
              </h5>
              <h6>{details?.Name ? details?.Name : ''}</h6>
            </div>
            <div className="close-btn-view">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <div className="more-detail-popup">
              <div className="user-detail-container scrollable-popup">
                <div className="user-details-row">
                  {/* <div className="user-details-block">
                  <h6>{t('name')}:</h6>
                  <p>{details?.Name ? details?.Name : '--'}</p>
                </div> */}

                  <div className="user-details-block">
                    <h6>{t('email')}:</h6>
                    <p>{details?.Email ? details?.Email : '--'}</p>
                  </div>
                  {details?.RoleId !== 8 ? (
                    <>
                      <div className="user-details-block">
                        <h6>{t('employeeNo')}:</h6>
                        <p>
                          {details?.employeeId ? details?.employeeId : '--'}
                        </p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('cardId')}:</h6>
                        <p>
                          {details?.['Card Number']
                            ? details?.['Card Number']
                            : '--'}
                        </p>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  <div className="user-details-block">
                    <h6>{t('resetPassword')}:</h6>
                    <p>
                      <input
                        type="checkbox"
                        checked={details?.isPasswordTemporary}
                      />
                    </p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('userStatus')}:</h6>
                    <p>{details?.Status ? details?.Status : '--'}</p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('role')}:</h6>
                    <p>
                      {details?.Role === 'Client' ? 'Employee' : details?.Role}
                    </p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('kitchenname')}:</h6>
                    <p>{details?.Kitchens ? details?.Kitchens : '--'}</p>
                  </div>

                  <div className="user-details-block">
                    <h6>{t('phone')}:</h6>
                    <p>{details?.phone ? details?.phone : '--'}</p>
                  </div>
                  {details?.RoleId !== 8 ? (
                    <>
                      <div className="user-details-block">
                        <h6>{t('contractorName')}:</h6>
                        <p>
                          {details?.contractors &&
                          details?.contractors.length !== 0
                            ? details?.contractors
                                .map((elem) => elem.name)
                                .join(', ')
                            : '--'}
                        </p>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <div className="user-details-block">
                    <h6>{t('paymentMethod')}:</h6>
                    <p
                      className={`payment-method ${
                        details?.walletId != undefined
                          ? 'add-link'
                          : 'without-link'
                      }`}
                    >
                      {(details?.RoleId !== 6 &&
                        details?.RoleId !== 7 &&
                        details?.RoleId !== 8) ||
                      (details?.RoleId === 8 &&
                        details?.allContractorInfo &&
                        details?.allContractorInfo?.isInvoice === 0) ||
                      (details?.RoleId === 6 &&
                        details?.allContractorInfo &&
                        details?.allContractorInfo?.isInvoice === 0)
                        ? details?.paymentMethod?.map((el, i) =>
                            el === 'Wallet' &&
                            details?.walletId != undefined ? (
                              <p
                                role="presentation"
                                onClick={() => handleWallet(details?.walletId)}
                              >
                                {t('wallet')}
                                <>({details?.amount ? details?.amount : 0})</>
                                <>
                                  {' '}
                                  {details.paymentMethod.length === 1 ||
                                  details.paymentMethod.length - 1 === i
                                    ? ' '
                                    : ', '}
                                </>
                              </p>
                            ) : el === 'Salary' ? (
                              t('paymentOption-salary') +
                              (details.paymentMethod.length === 1 ||
                              details.paymentMethod.length - 1 === i
                                ? ' '
                                : ', ')
                            ) : el === 'Cibus' ? (
                              t('paymentOption-cibus') +
                              (details.paymentMethod.length === 1 ||
                              details.paymentMethod.length - 1 === i
                                ? ' '
                                : ', ')
                            ) : el === 'Credit Card' ? (
                              t('paymentOption-creditCard') +
                              (details.paymentMethod.length === 1 ||
                              details.paymentMethod.length - 1 === i
                                ? ' '
                                : ', ')
                            ) : el === 'Invoice' ? (
                              t('invoice') +
                              (details.paymentMethod.length === 1 ||
                              details.paymentMethod.length - 1 === i
                                ? ' '
                                : ', ')
                            ) : el === 'Wallet' ? (
                              t('wallet') +
                              '(0)' +
                              (details.paymentMethod.length === 1 ||
                              details.paymentMethod.length - 1 === i
                                ? ' '
                                : ', ')
                            ) : (
                              '--'
                            )
                          )
                        : details.allContractors &&
                          details.allContractors.length !== 0 &&
                          Boolean(
                            details.allContractors.find(
                              (elem) => elem?.isInvoice === 0
                            )
                          )
                        ? paymentMethodsForCA
                        : t('invoice')}
                    </p>
                  </div>
                  {details?.RoleId === 8 ? (
                    <>
                      <div className="user-details-block">
                        <h6>{t('startDate')}:</h6>
                        <p>{details?.hireFrom ? details?.hireFrom : '--'}</p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('endDate')}:</h6>
                        <p>{details?.hireTill ? details?.hireTill : '--'}</p>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {details?.RoleId !== 8 ? (
                    <>
                      <div className="user-details-block">
                        <h6> {t('paymentOption-creditCard')}:</h6>
                        <p>
                          <input
                            type="checkbox"
                            checked={details?.creditCard}
                          />
                        </p>
                      </div>
                      {cibusEnabled && (
                        <div className="user-details-block">
                          <h6>{t('magneticStripe')}:</h6>
                          <p>
                            {details?.['magneticStripe']
                              ? details?.['magneticStripe']
                              : '--'}
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    ''
                  )}

                  {details?.RoleId === 8 ? (
                    <>
                      <div className="user-details-block">
                        <h6>{t('guestLink')}:</h6>
                        <p>
                          {details?.generatedLink
                            ? details?.generatedLink
                            : '--'}
                        </p>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <div className="user-details-block">
                    <h6>{t('reservations')}:</h6>
                    <div
                      className={`payment-method ${
                        details?.userId != undefined
                          ? 'add-link'
                          : 'without-link'
                      }`}
                    >
                      <p
                        role="presentation"
                        onClick={() =>
                          handleOrderLink(
                            details?.userId,
                            details?.['Registration Date']
                          )
                        }
                      >
                        {details?.totalReservation}
                      </p>
                    </div>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('registrationDate')}:</h6>
                    <p>
                      {details?.['Registration Date']
                        ? details?.['Registration Date']
                        : '--'}
                    </p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('updatedOn')}:</h6>
                    <p>{details?.updatedAt ? details?.updatedAt : '--'}</p>
                  </div>
                </div>
              </div>
              {details?.RoleId === 8 ? (
                <div className="modal-footer-btn">
                  <Button
                    onClick={() => {
                      let ids = [];
                      let newMealTypeDateArray = [];
                      if (details['Kitchens']?.length) {
                        Kitchens?.forEach((el) => {
                          if (details['Kitchens']?.includes(el?.name)) {
                            ids?.push(el?.id);
                            let newMealTypeInfo = getMealTypes?.filter(
                              (elInfo) => elInfo?.kitchenId === el?.id
                            );
                            newMealTypeDateArray = [
                              ...newMealTypeDateArray,
                              ...newMealTypeInfo,
                            ];
                          }
                        });
                      }
                      setMealTypesKitchenWise(newMealTypeDateArray);
                      setKitchensIds(ids);
                      if (details['RoleId'] === 6) {
                        setShowContractor(true);
                      }
                      console.log('details', details);
                      setPayload({
                        userId: details['userId'],
                        card: details['Card Number'],
                        email: details['Email'],
                        fName: details['firstName'],
                        lName: details['lastName'],
                        phone: details['phone']
                          ? details['phone'].split('-').join('')
                          : details['phone'],
                        kitchensIds: ids,
                        employeeId: details['employeeId'],
                        role: [
                          Roles.find((el) => el.display === details['Role'])
                            ?.id,
                        ],
                        contractors: details['contractors'],
                        status: details?.Status,
                        magneticStripe: details?.magneticStripe,
                        hireFrom: details['hireFrom'],
                        hireTill: details['hireTill'],
                        mealTypeId: details['mealTypeId'] || [],
                        isPasswordTemporary: false,
                        mealTypesIds: details['mealTypeId'] || [],
                      });
                      let mealTypeIdList = details['mealTypeId'] || [];
                      let array = [];
                      if (mealTypeIdList?.length) {
                        let aa = mealTypeIdList?.map((el) => array.push(el));
                      }
                      setMealTypesIds(array);
                      handleOpen();
                    }}
                  >
                    <i class="las la-pen" title="edit"></i>
                    {t('edit')}
                  </Button>
                  <Button onClick={() => handleConfirmation(details['userId'])}>
                    <i class="las la-trash" title="delete"></i>
                    {t('delete')}
                  </Button>

                  {UserStatuses?.find((el) => el.display === details?.Status)
                    ?.value === 'created' ||
                  UserStatuses?.find((el) => el.display === details?.Status)
                    ?.value === 'pending' ? (
                    <Button>
                      <i
                        class="las la-copy"
                        title="Resend"
                        onClick={() => CopyDataUrl(details)}
                      ></i>
                      {t('copyUrl')}
                    </Button>
                  ) : (
                    <Button>
                      <i
                        class="las la-copy"
                        title="Resend"
                        onClick={() => CopyDataUrl(details)}
                      ></i>
                      {t('copyUrl')}
                    </Button>
                  )}
                </div>
              ) : (
                <div className="modal-footer-btn">
                  <Button
                    onClick={() => {
                      let ids = [];
                      if (details['Kitchens']?.length) {
                        Kitchens?.forEach((el) => {
                          if (details['Kitchens']?.includes(el?.name)) {
                            ids?.push(el?.id);
                          }
                        });
                      }
                      setKitchensIds(ids);
                      if (details['RoleId'] === 6) {
                        setShowContractor(true);
                      }
                      setPayload({
                        userId: details['userId'],
                        card: details['Card Number'],
                        email: details['Email'],
                        fName: details['firstName'],
                        lName: details['lastName'],
                        phone: details['phone']
                          ? details['phone'].split('-').join('')
                          : details['phone'],
                        kitchensIds: ids,
                        employeeId: details['employeeId'],
                        role: [
                          Roles.find((el) => el.display === details['Role'])
                            ?.id,
                        ],
                        contractors: details['contractors'],
                        status: details?.Status,
                        magneticStripe: details?.magneticStripe,
                        mealTypeId: details?.mealTypeId,
                        isPasswordTemporary: details?.isPasswordTemporary,
                      });
                      handleOpen();
                    }}
                  >
                    <i class="las la-pen" title="edit"></i>
                    {t('edit')}
                  </Button>
                  <Button onClick={() => handleConfirmation(details['userId'])}>
                    <i class="las la-trash" title="delete"></i>
                    {t('delete')}
                  </Button>

                  {UserStatuses?.find((el) => el.display === details?.Status)
                    ?.value === 'created' ||
                  UserStatuses?.find((el) => el.display === details?.Status)
                    ?.value === 'pending' ? (
                    <Button>
                      <i
                        class="las la-share-square"
                        title="Copy"
                        onClick={() => ResendVerification(details)}
                      ></i>
                      {t('resend')}
                    </Button>
                  ) : (
                    <Button>
                      <i
                        class="las la-share-square"
                        title="Copy"
                        onClick={() => ResendVerification(details)}
                      ></i>
                      {t('resend')}
                    </Button>
                  )}

                  {UserStatuses?.find((el) => el.display === details?.Status)
                    ?.value === 'created' ||
                  UserStatuses?.find((el) => el.display === details?.Status)
                    ?.value === 'pending' ? (
                    <Button>
                      <i
                        class="las la-copy"
                        title="Resend"
                        onClick={() => CopyDataUrl(details)}
                      ></i>
                      {t('copyUrl')}
                    </Button>
                  ) : (
                    <Button>
                      <i
                        class="las la-copy"
                        title="Resend"
                        onClick={() => CopyDataUrl(details)}
                      ></i>
                      {t('copyUrl')}
                    </Button>
                  )}
                  {attendanceEnabled ? (
                    <Button>
                      <i
                        class="las la-address-card"
                        title="Resend"
                        onClick={() =>
                          history.push(
                            `/attendance?user=${
                              details?.userId
                            }&fromDate=${moment(
                              new Date(details['Registration Date'])
                            ).format('YYYY-MM-DD')}&toDate=${moment(
                              new Date()
                            ).format('YYYY-MM-DD')}`
                          )
                        }
                      ></i>
                      {t('attendance')}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </Typography>
          {/* <div className="view-popup-btn">
            <button className="close-modal-btn" onClick={handleClose}>
              {t('close')}
            </button>
          </div> */}
        </Box>
      </Modal>
    </>
  );
};
export default ViewDetails;
