import { AgGridReact } from 'ag-grid-react';
import { useFetchingLoader } from '../../store/app/actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getReservations,
  getReportTypeReservations,
  getMealTypeReservations,
} from '../../store/kitchens/actions';
import agGridLocales from '../../translations/ag-grid';
import { setLayoutOptions } from '../../store/app/actions';
import { getSalaryConfigByCompanyId } from '../../store/companyConfig/actions';
import { DisabledButton } from '../../components/WE-Button';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from '../../services/http';
import search from '../../assets/icons/search.png';
import next from '../../assets/images/next.png';
import ViewSalaryReportdetails from './ViewSalaryReportDetails';
import MuiDialog from '@mui/material/Dialog';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SalaryReport = ({
  kitchen,
  //reservations,
  setLayoutOptions,
  useFetchingLoader,
  currentUser,
  options,
  getSalaryConfigByCompanyId,
  companyId,
  isManualEnabledForSalary,
  codeUrlForSalary,
  fileTypeForSalary,
}) => {
  const { t, i18n } = useTranslation();
  const [gridApi, setGridApi] = useState<any>();
  const [tableColumn, setTableColumn] = useState<any>();
  const [viewDetails, setViewDetails] = useState<any>();
  const [details, setDetails] = useState<any>();
  const [reserData, setreserData] = useState([]);
  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>(new Date());
  const [companyName] = useState<string | undefined>('VPG');
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [selectedKitchen, setSelectedKitchen] = useState<string | number>();
  const [fromDateForSalary, setFromDateForSalary] = useState<any>(
    new Date().setDate(new Date().getDate() - 29)
  );
  const [toDateForSalary, setToDateForSalary] = useState<any>(
    new Date().setDate(new Date().getDate())
  );
  const [kitchensForDisplay, setKitchensForDisplay] = useState<any>([]);
  const [errors, setErrors] = useState({
    dateError: false,
    kitchensErrors: false,
  });
  const [, setFileTypeForSalaryState] = useState('');
  const history = useHistory();
  useEffect(() => {
    const fetch = async () => {
      await getSalaryConfigByCompanyId(companyId);
    };
    fetch();
  }, []);
  useEffect(() => {
    console.log('isManualEnabledForSalary', isManualEnabledForSalary);
  }, [isManualEnabledForSalary]);
  useEffect(() => {
    setFileTypeForSalaryState(fileTypeForSalary);
  }, [fileTypeForSalary]);

  useEffect(() => {
    setErrors({
      dateError: false,
      kitchensErrors: false,
    });
    setFromDateForSalary(new Date().setDate(new Date().getDate() - 29));
    setToDateForSalary(new Date().setDate(new Date().getDate()));
    setSelectedKitchen('');
  }, [openPopup]);
  useEffect(() => {
    if (fileTypeForSalary) {
      let url = window.location.href;
      let split = url.split('?');
      setLayoutOptions({
        title: t('salaryFileReport'),
      });

      let endDate = new Date();
      let startDate = new Date(new Date().setDate(endDate.getDate() - 29));
      setToDate(endDate);
      setFromDate(startDate);
      let postBodyData: {
        startDate: string;
        endDate: string;
        date?: string;
        company?: string;
      } = {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      };

      if (split[1]?.split('=')[0] === 'FromDate') {
        postBodyData.date = split[1]?.split('=')[1];
      }
      console.log('fileTypeForSalary', fileTypeForSalary);

      if (fileTypeForSalary == 1) {
        postBodyData.company = 'VPG';
      } else {
        postBodyData.company = 'WSHYCLCK';
      }
      useFetchingLoader(async () => {
        if (kitchensForDisplay.length === 0) {
          const { data } = await http.get('kitchens', {
            filter: { companyId: currentUser.companyId, kitchenStatusId: 1 },
          });
          let res = data.filter(
            (el) => el.kitchenPayment.paymentOptionId === 4
          );
          console.log('currentUser res', res);

          console.log('currentUser', currentUser.kitchens);

          /*setKitchensForDisplay(
            currentUser?.roleId === 1 || fileTypeForSalary == 2
              ? res
              : currentUser.kitchens
          );*/
          setKitchensForDisplay(res);
        }
        const getSalaryReports = await http.get(
          `kitchens/${kitchen?.id}/getSalaryReports`,
          postBodyData
        );
        /*const filteredSalaryReports =
          currentUser?.roleId === 1 || fileTypeForSalary == 2
            ? getSalaryReports
            : getSalaryReports?.data?.filter((el) =>
                currentUser.KitchenId.includes(el.kitchenId)
              );*/
        let getSalaryReportsInfo = getSalaryReports?.data?.map((el) => ({
          id: el?.id,
          fileName: el?.fileName,
          downloadFile: 'Download',
          getReservation: t('getReservation'),
          kitchenId: el?.kitchenId,
          amount: el.amount,
          date: el?.date,
          totalReservations: el?.totalReservations,
          getReservationDate: el?.getReservationDate,
          createdAt: moment(el?.createdAt).format('YYYY-MM-DD HH:mm'),
          updatedAt: el?.updatedAt,
        }));
        setreserData(getSalaryReportsInfo);
      });
      if (window.innerWidth <= 480) {
        if (fileTypeForSalary == 1) {
          setTableColumn(mobcolumnDefsVPG);
        } else {
          setTableColumn(mobcolumnDefsDefault);
        }
      } else {
        if (fileTypeForSalary == 1) {
          setTableColumn(columnDefsVPG);
        } else {
          setTableColumn(columnDefsDefault);
        }
      }
    }
  }, [fileTypeForSalary]);

  const lang = localStorage.getItem('language');

  const columnDefsVPG = [
    {
      field: 'createdAt',
      headerName: `${t('date')}`,
      headerClass: '<resizable>-header',
      suppressMovable: true,
      sortable: true,
      hide: false,
      width: 70,
    },
    {
      field: 'fileName',
      headerName: `${t('isfile')}`,
      sortable: true,
      cellRenderer: 'SalaryFileName',
      width: 100,
    },
    {
      field: 'kitchenId',
      headerName: `${t('kitchen')}`,
      cellRenderer: 'KitchenCellRender',
      suppressMovable: true,
      width: 70,
    },
    {
      field: 'getReservationDate',
      headerName: `${t('dateRange')}`,
      cellRenderer: (params) => {
        return params.data.getReservationDate.join(' - ');
      },
      suppressMovable: true,
      width: 70,
    },
    {
      //field: 'getReservation',
      headerName: `#${t('isEntries')}`,
      cellRenderer: 'GetReservationsName',
      suppressMovable: true,
      width: 40,
    },
  ];
  const columnDefsDefault = [
    {
      field: 'createdAt',
      headerName: `${t('date')}`,
      headerClass: '<resizable>-header',
      suppressMovable: true,
      sortable: true,
      hide: false,
      width: 70,
    },
    {
      field: 'fileName',
      headerName: `${t('isfile')}`,
      sortable: true,
      cellRenderer: 'SalaryFileName',
      width: 100,
    },
    {
      //field: 'getReservation',
      headerName: `#${t('isEntries')}`,
      cellRenderer: 'GetReservationsName',
      suppressMovable: true,
      width: 40,
    },
  ];
  const mobcolumnDefsDefault = [
    {
      field: 'createdAt',
      headerName: `${t('date')}`,
      headerClass: 'resizable-header',
      suppressMovable: true,
      sortable: true,
      hide: false,
    },
    {
      //field: 'getReservation',
      headerName: `#${t('isEntries')}`,
      cellRenderer: 'GetReservationsName',
      suppressMovable: true,
    },
    // {
    //   field: 'fileName',
    //   headerName: `${t('isfile')}`,
    //   sortable: true,
    //   cellRenderer: 'SalaryFileName',
    //   suppressMovable: true,
    // },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      sortable: true,
      width: 50,
    },
  ];
  const mobcolumnDefsVPG = [
    {
      field: 'createdAt',
      headerName: `${t('date')}`,
      headerClass: 'resizable-header',
      suppressMovable: true,
      sortable: true,
      hide: false,
    },
    {
      //field: 'getReservation',
      headerName: `#${t('isEntries')}`,
      cellRenderer: 'GetReservationsName',
      suppressMovable: true,
      width: 50,
    },
    // {
    //   field: 'fileName',
    //   headerName: `${t('isfile')}`,
    //   sortable: true,
    //   cellRenderer: 'SalaryFileName',
    //   suppressMovable: true,
    // },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      sortable: true,
      width: 50,
    },
  ];

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  /*const handleOpen = async (hash: any, mealId: any, id: any) => {
    //const getMealData = await http.get(`meals/${mealId}`);
    console.log(hash, mealId, id);
  };*/

  /************global filter funcitionm */
  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };
  const handleChangeDatepicker = async (name: any, value: any) => {
    if (name === 'fromdate') {
      if (!value) {
        setFromDateForSalary(null);
      }
      setFromDateForSalary(new Date(value));
      setErrors({
        kitchensErrors: !selectedKitchen || selectedKitchen === 'undefined',
        dateError: toDateForSalary < new Date(value),
      });
    } else if (name === 'todate') {
      if (!value) {
        setToDateForSalary(null);
      }
      const tempToDate = new Date(value);
      setToDateForSalary(tempToDate);
      setErrors({
        kitchensErrors: !selectedKitchen || selectedKitchen === 'undefined',
        dateError: tempToDate < fromDateForSalary,
      });
    }
  };

  const downloadSalaryReport = async ({ e }) => {
    let fromURL = e?.data?.fileName;
    const filename = fromURL.split('com/').reverse()[0];

    let postData = {
      id: e?.data?.id,
    };
    const response = await http.post(`kitchens/downloadSalary`, postData);

    const file = new Blob([response.join('\n').replace(/\r?\n/g, '\r\n')], {
      type: 'text/plain',
    });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = filename ? filename : '';
    document.body.appendChild(element);
    element.click();
  };

  const compareDates = (dateStr1, dateStr2) => {
    const date1: any = new Date(dateStr1);
    const date2: any = new Date(dateStr2);

    return date1 - date2;
  };

  const goToReservationsPage = async ({ e }) => {
    setFileTypeForSalaryState((prev) => {
      if (Number(prev) === 1) {
        history.push({
          pathname: `/reservationByUser`,
          state: {
            kitchenId: e.data.kitchenId,
            fromDate: e.data.getReservationDate[0],
            toDate: e.data.getReservationDate[1],
            userType: '4',
          },
        });
      } else {
        let fromURL = e?.data?.fileName;
        const filename = fromURL?.split('com/').reverse()[0];
        const sortedDatesArray = [...e?.data?.getReservationDate].sort(
          compareDates
        );
        history.push({
          pathname: `/reservationManagement`,
          state: {
            backText: t('salaryReportFromDate'),
            FromDate: sortedDatesArray.map((el) => String(el)),
            FromFile: filename,
          },
        });
      }
      return prev;
    });
  };

  const handleviewmore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  const handleApplyfilter = async (fromDate: any, toDate: any) => {
    let postBodyData: {
      startDate: string;
      endDate: string;
      company?: string;
    } = {
      startDate: moment(fromDate).format('YYYY-MM-DD'),
      endDate: moment(toDate).format('YYYY-MM-DD'),
    };
    if (fileTypeForSalary === 1) {
      postBodyData.company = 'VPG';
    } else {
      postBodyData.company = 'WSHYCLCK';
    }
    console.log('handleApplyfilter', handleApplyfilter);

    useFetchingLoader(async () => {
      const getSalaryReports = await http.get(
        `kitchens/${kitchen?.id}/getSalaryReports`,
        postBodyData
      );
      let getSalaryReportsInfo = getSalaryReports?.data?.map((el) => ({
        id: el?.id,
        fileName: el?.fileName,
        downloadFile: 'Download',
        getReservation: t('getReservation'),
        kitchenId: el?.kitchenId,
        amount: el.amount,
        date: el?.date,
        totalReservations: el?.totalReservations,
        getReservationDate: el?.getReservationDate,
        createdAt: moment(el?.createdAt).format('YYYY-MM-DD HH:mm'),
        updatedAt: el?.updatedAt,
      }));
      setreserData(getSalaryReportsInfo);
    });
  };

  const handleChange = async (name: any, value: any) => {
    if (name === 'fromdate') {
      if (!value) {
        setFromDate(null);
      }
      setFromDate(value);
      history.push('/salaryReport');
      handleApplyfilter(value, toDate);
    }
    if (name === 'toDate') {
      if (!value) {
        setToDate(null);
      }
      setToDate(value);
      history.push('/salaryReport');
      handleApplyfilter(fromDate, value);
    }
  };

  const handleGenerateSalaryFile = async () => {
    if (!selectedKitchen || toDateForSalary < fromDateForSalary) {
      setErrors({
        kitchensErrors: !selectedKitchen || selectedKitchen === 'undefined',
        dateError: toDateForSalary < fromDateForSalary,
      });
    } else {
      try {
        const getSalaryfile = await http.get(codeUrlForSalary, {
          fromDate: moment(fromDateForSalary).format('YYYY-MM-DD'),
          toDate: moment(toDateForSalary).format('YYYY-MM-DD'),
          kitchenId: selectedKitchen,
        });
        console.log(getSalaryfile);

        const selectedKitchenForName = kitchensForDisplay.find(
          (el) => el.id == selectedKitchen
        );

        const file = new Blob(
          [getSalaryfile.join('\n').replace(/\r?\n/g, '\r\n')],
          {
            type: 'text/plain',
          }
        );

        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = `salaryFile-${selectedKitchenForName.name}`;
        document.body.appendChild(element);
        element.click();
        setOpenPopup(false);
        Dialog({
          title: t('salaryGenerated'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            handleApplyfilter(fromDate, toDate);
          },
        });
      } catch (err) {
        Dialog({
          title: t('genericError'),
          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
        });
      }
    }
  };
  const paperStyle = {
    overflow: 'visible',
  };
  return (
    <>
      <div className="reservation-management global-data-table reservation-updated-page">
        <div className="container">
          <div className="add-employee-btn">
            <div className="left-filter">
              <div className="filter-section">
                <div className="search-filter">
                  <div className="xyz">
                    <div className="from-date">
                      <label>{t('fromDate')}</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={fromDate ? fromDate : new Date()}
                        name="fromDate"
                        onChange={(event) => handleChange('fromdate', event)}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                    <div className="from-date">
                      <label>{t('toDate')}</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={toDate ? toDate : new Date()}
                        name="toDate"
                        onChange={(event) => handleChange('toDate', event)}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="search-input searchBtn">
                <label>{t('filter')}</label>
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={t('freeTextSearch')}
                  onInput={onFilterTextBoxChanged}
                />
                <img src={search} alt="search" />
              </div>
              {isManualEnabledForSalary && currentUser.roleId !== 9 && (
                <button
                  onClick={() => setOpenPopup(true)}
                  className="search-button"
                >
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9999 17.3334H1.99992C1.54159 17.3334 1.14936 17.1704 0.823252 16.8442C0.496585 16.5176 0.333252 16.1251 0.333252 15.6667V2.33341C0.333252 1.87508 0.496585 1.48258 0.823252 1.15591C1.14936 0.829803 1.54159 0.666748 1.99992 0.666748H8.66658L13.6666 5.66675V15.6667C13.6666 16.1251 13.5035 16.5176 13.1774 16.8442C12.8508 17.1704 12.4583 17.3334 11.9999 17.3334ZM7.83325 6.50008V2.33341H1.99992V15.6667H11.9999V6.50008H7.83325ZM1.99992 6.50008V2.33341V15.6667V6.50008ZM3.66659 9.83341H10.3333V8.16675H3.66659V9.83341ZM3.66659 12.3334H10.3333V10.6667H3.66659V12.3334ZM3.66659 14.8334H7.83325V13.1667H3.66659V14.8334Z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={reserData?.length ? reserData : []}
              frameworkComponents={{
                ActionButton: (e) => (
                  <ActionButton e={e} t={t} handleOpen={() => {}} />
                ),
                ViewMore: (e) => (
                  <ViewMore handleviewmore={handleviewmore} e={e} />
                ),

                // Download: (e) => (
                //   <Download e={e} downloadSalaryReport={downloadSalaryReport} />
                // ),
                SalaryFileName: (e) => (
                  <SalaryFileName
                    e={e}
                    downloadSalaryReport={downloadSalaryReport}
                  />
                ),
                KitchenCellRender: (e) => (
                  <KitchenCellRender e={e} kitchensForDisplay={options} />
                ),
                GetReservationsName: (e) => (
                  <GetReservationsName
                    e={e}
                    goToReservationsPage={goToReservationsPage}
                  />
                ),
              }}
              columnDefs={tableColumn}
              localeText={agGridLocales[i18n.language]}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              onGridReady={(params) => {
                // setGridApi(params.api);
                // setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
                // document
                //   .querySelector('.ag-pinned-left-cols-container')
                //   .remove();
              }}
              onGridColumnsChanged={(params) => {
                setGridApi(params.api);
                //setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              reactUi={true}
              overlayLoadingTemplate={'<p></p>'}
              enableRtl={lang === '"he"' ? true : false}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ViewSalaryReportdetails
        open={viewDetails}
        details={details}
        handleClose={handleviewmore}
        reportType={false}
        handleOpen={() => {}}
        kitchensForDisplay={kitchensForDisplay}
        reserData={reserData}
        setDetails={setDetails}
        companyName={companyName}
      />
      <MuiDialog
        open={openPopup}
        PaperProps={{ style: paperStyle }}
        disableEnforceFocus
        onClose={() => setOpenPopup(false)}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '22px',
            }}
          >
            {t('generateSalaryFile')}
          </Box>
          <Box
            sx={{
              margin: '0 0 0 auto',
            }}
          >
            <IconButton onClick={() => setOpenPopup(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '10px 25px',
            alignItems: 'center',
          }}
        >
          <DialogContentText
            sx={{ color: '#000', margin: '0 auto', textAlign: 'center' }}
          >
            {t('generateSalaryText')}
          </DialogContentText>
          <Box>
            <DialogContent>
              <div className="reservation-management global-data-table reservation-updated-page">
                <div className="container">
                  <div className="add-employee-btn margin-bottom-10">
                    <div className="left-filter width-100 left-filter-salary-small">
                      <div className="filter-section">
                        <div className="search-filter">
                          <div className="xyz">
                            <div className="from-date from-date-small z-index-1500">
                              <label>{t('fromDate')}</label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  fromDateForSalary
                                    ? fromDateForSalary
                                    : new Date()
                                }
                                name="fromDate"
                                onChange={(event) =>
                                  handleChangeDatepicker('fromdate', event)
                                }
                                placeholder="DD/MM/YYYY"
                              />
                            </div>
                          </div>
                          <div className="xyz">
                            <div className="from-date from-date-small">
                              <label>{t('toDate')}</label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  toDateForSalary ? toDateForSalary : new Date()
                                }
                                name="toDate"
                                onChange={(event) =>
                                  handleChangeDatepicker('todate', event)
                                }
                                placeholder="DD/MM/YYYY"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.dateError && (
                  <p className="error-datepicker">{t('dateError')} </p>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '10px auto',
                    width: 150,
                  }}
                >
                  <div className="from-date">
                    <label className="dropdown-label">{t('kitchens')}</label>
                    <select
                      value={selectedKitchen}
                      onChange={(e) => {
                        setErrors({
                          kitchensErrors: false,
                          dateError: toDateForSalary < fromDateForSalary,
                        });
                        setSelectedKitchen(e.target.value);
                      }}
                    >
                      <option value={'undefined'}>{t('selectKitchen')}</option>
                      {kitchensForDisplay.map((el) => (
                        <option value={el.id}>{el.name}</option>
                      ))}
                    </select>
                    {errors.kitchensErrors && (
                      <p className="error-datepicker">{t('requiredField')} </p>
                    )}
                  </div>
                </Box>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="success"
                sx={{
                  margin: '0 auto',
                  width: 150,
                  backgroundColor: '#14cd30',
                }}
                onClick={() => handleGenerateSalaryFile()}
              >
                {t('submitLabel')}
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </MuiDialog>
    </>
  );
};
// const defaultColDef = {
//   sortable: true,
//   resizable: true,
//   filter: true,
//   suppressMovable: true,
//   // sizeColumnsToFit: true,
//   suppressSizeToFit: true,
//   width: 'auto',
// };

const ViewMore = ({ e, handleviewmore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleviewmore(e.data)} />
    </div>
  );
};
// const Download = ({ e, downloadSalaryReport }) => {
//   return (
//     <div className="download-btn">
//       <Button onClick={() => downloadSalaryReport({ e })}>Download</Button>
//     </div>
//   );
// };

const SalaryFileName = ({ e, downloadSalaryReport }) => {
  let fromURL = e?.data?.fileName;
  const filename = fromURL.split('com/').reverse()[0];
  return (
    <div className="file-name-section">
      {/* <Button onClick={() => downloadSalaryReport({ e })}>Download</Button> */}
      <p onClick={() => downloadSalaryReport({ e })} className="link-css">
        {filename ? filename : '--'}
      </p>
    </div>
  );
};
const KitchenCellRender = ({ e, kitchensForDisplay }) => {
  const findedElem = kitchensForDisplay.find(
    (el) => el.id === e.data.kitchenId
  );
  return (
    <div className="file-name-section">
      <p>{findedElem?.name || '--'}</p>
    </div>
  );
};
const GetReservationsName = ({ e, goToReservationsPage }) => {
  return (
    <div className="download-btn">
      <p onClick={() => goToReservationsPage({ e })} className="link-css">
        {e?.data?.totalReservations}
      </p>
    </div>
  );
};

const ActionButton = ({ e, t, handleOpen }) => {
  return (
    <div className="order-with-status">
      <div className="orderId">
        <p>{e?.data?.point ? '' : e?.data?.id}</p>
      </div>
      <div className="action-btn realized-button">
        {e.data.orderStatusId === 2 ? (
          <DisabledButton label={t('realizedOrders')} />
        ) : e.data.orderStatusId === 1 ? (
          <button
            className="edit-btn"
            onClick={() => {
              handleOpen(
                e.data.hash,
                e.data.mealDish[0].mealDish.mealId,
                e.data.id
              );
            }}
          >
            {t('realizeOrder')}
          </button>
        ) : (
          <DisabledButton label={t('pendingCollectedPoint')} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  //reservations: state.kitchens.reservations,
  options: state.kitchens.options,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
  currentLanguage: state.languages.current,
  currentUser: state.user,
  companyId: state.companyConfig.companyId,
  isManualEnabledForSalary: state.companyConfig.isManualEnabledForSalary,
  fileTypeForSalary: state.companyConfig.fileTypeForSalary,
  codeUrlForSalary: state.companyConfig.codeUrlForSalary,
});

const mapDispatchToProps = {
  getReservations,
  getReportTypeReservations,
  getMealTypeReservations,
  setLayoutOptions,
  useFetchingLoader,
  getSalaryConfigByCompanyId,
};

// export default ReservationManagement;
export default connect(mapStateToProps, mapDispatchToProps)(SalaryReport);
