import React from 'react';
const iconsPath = `${process.env.PUBLIC_URL}/icons/`;

const InputField = ({
  icon,
  input,
  style,
  type,
  label,
  placeholder,
  labelIcon,
  required,
  disabled,
  dataOg,
  containerClass = '',
  meta: { touched, error, submitFailed },
}) => {
  const errorClass = touched && error ? 'error-color' : '';
  const containerClasses = ['input-group', containerClass, errorClass];

  return (
    <>
      <div className={containerClasses.join(' ')}>
        <div
          className={`label-input ${
            error && submitFailed ? 'required-field' : ''
          }`}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div>
            {input.value !== '' && (
              <p className={'upper-placeholder'}>{placeholder}</p>
            )}
          </div>

          <section className={'input-section'}>
            {label && (
              <div className="label-wrapper">
                {labelIcon && <img src={labelIcon} alt="" />}
                <label htmlFor={input.name}>
                  {required ? '*' : ''}
                  {label}
                </label>
              </div>
            )}

            {icon && (
              <img className="icon" src={`${iconsPath}${icon}.svg`} alt="" />
            )}
            {required && <p>{'*'}</p>}
            <input
              className="input-field"
              {...input}
              value={input.value.trim()}
              data-og={dataOg}
              style={style}
              type={type}
              value={input?.value.trim()}
              placeholder={placeholder}
              disabled={disabled}
            />
          </section>

          {error && submitFailed && (
            <div className="error-message">{error}</div>
          )}
        </div>
      </div>
    </>
  );
};
export default InputField;
