import React, { useEffect, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../services/mediaQueries';
import { StaticStars } from '../../components/RatingStars';
import { ActiveButton } from '../../components/WE-Button';
import WEIcon from '../../components/WE-IconButton';
import DishImage from '../../screens/client/DishImage';
import { useHistory, useLocation, useParams } from 'react-router';
import enums from '../../services/enums';
import { sleep } from '../../services/utils';
import dates from '../../services/dates';
import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import Chip from '@material-ui/core/Chip';
import BackLink from '../../components/BackLink';
import add from '../../assets/images/add.svg';
import searchimg from '../../assets/images/Search.svg';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import next from '../../assets/images/next.png';
import ViewMealDishDetail from './ViewMealDishDetail';

const AddDishToMeal = ({
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  user,
  kitchen,
  MealStatuses,
  DishStatuses,
  MealDishStatuses,
  MealTypes,
  DishCategories,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dishes, setDishes] = useState(null);
  const { date, mealTypeId } = useParams();
  const [tableColumn, setTableColumn] = useState();
  const [gridApi, setGridApi] = useState();
  const [dishesToDisplay, setDishesToDisplay] = useState(null);
  const [columnApi, setColumnApi] = useState();
  const [viewDetails, setViewDetails] = useState();
  const [details, setDetails] = useState();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  const lang = localStorage.getItem('language');

  useEffect(async () => await useLoader(() => sleep()), []);

  const MealDishStatusMap = MealDishStatuses.reduce((acc, mealDish) => {
    return { ...acc, [mealDish.value]: mealDish.id };
  }, {});
  const MealStatusMap = MealStatuses.reduce((acc, meal) => {
    return { ...acc, [meal.value]: meal.id };
  }, {});
  const DishStatusMap = DishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});
  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type.display };
  }, {});

  useEffect(async () => {
    setLayoutOptions({
      title: `${t('addDishToMeal')}`,
      showDatePicker: true,
      isDatePickerClickable: false,
    });
    if (!kitchen) return;

    // Fetch both 'all dishes' and 'only in meal' dishes
    const { data: allDishes } = await useFetchingLoader(
      async () =>
        await http.get(`dishes`, {
          filter: {
            kitchenId: kitchen.id,
            dishStatusId: DishStatusMap.active,
            mealTypeId: mealTypeId,
          },
        })
    );
    const { data: mealDishes } = await useFetchingLoader(
      async () =>
        await http.get(`mealsDishes`, {
          filter: {
            kitchenId: kitchen.id,
            date,
            mealTypeId,
            mealDishStatusId: [
              MealDishStatusMap.active,
              MealDishStatusMap.blocked,
            ],
            dishStatusId: DishStatusMap.active,
          },
        })
    );

    // Create a mixed collection of 'Include and not include' dishes
    const mealDishesIds = mealDishes.map((md) => md.dish.id);
    const data = allDishes.map((dish) => ({
      ...dish,
      isIncluded: mealDishesIds.includes(dish.id),
    }));
    setDishes(data);

    let serverDishes = data
      ?.sort((d1, d2) => d1?.name?.localeCompare(d2?.name))
      .map((u, i) => ({
        dishId: u.id,
        dishName: u.name,
        //dishImage: u.image,
        dishImage: u?.thumbnail ? u?.thumbnail : u?.image,
        dishPrice: u.price,
        dishRating: u.rating,
        numberOfRaters: u.numOfRaters,
        dishCategory: enums.display('DishCategory', u.dishCategoryId),
        dishTag: u.tags,
        dishDescription: u.description,
        isIncluded: u.isIncluded,
      }));
    setDishesToDisplay(serverDishes);
  }, [kitchen]);

  const [search, setSearch] = useState('');
  // const handleSearchChange = (value) => setSearch(value);
  const handleSearchChange = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };

  const handleAddDish = async (dishId) => {
    let requestValues = {
      dishId,
      date,
      mealTypeId: Number.parseInt(mealTypeId),
      kitchenId: kitchen.id,
    };
    try {
      await useFetchingLoader(
        async () => await http.post(`mealsDishes/create`, requestValues)
      );
      // Update dishes, set the added dish as included
      setDishesToDisplay((dishesToDisplay) =>
        dishesToDisplay.map((dish) =>
          dish.dishId === dishId ? { ...dish, isIncluded: true } : dish
        )
      );
      setViewDetails(false);
    } catch (err) {
      Alert({ error: err });
    }
  };
  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);
  // useEffect(() => {
  //   let filteredDishes = dishes?.filter(
  //     (dish) =>
  //       (dish.name && dish.name.indexOf(search) > -1) ||
  //       (dish.description && dish.description.indexOf(search) > -1) ||
  //       dish.tags
  //         .map((tag) => tag.displayName.indexOf(search) > -1)
  //         .reduce((prev, curr) => prev || curr, false)
  //   );
  //   setDishesToDisplay(filteredDishes);
  // }, [dishes, search]);
  `c`;
  const columnDefs = [
    {
      field: 'dishName',
      headerName: t('name'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
      autoHeight: true,
      wrapText: true,
      flex: 3,
    },
    {
      field: 'dishDescription',
      headerName: t('description'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      autoHeight: true,
      wrapText: true,
      flex: 5,
    },
    {
      field: 'dishCategory',
      headerName: t('category'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      flex: 1,
    },
    {
      field: 'dishPrice',
      headerName: t('price'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      width: 100,
    },
    {
      field: 'dishRating',
      headerName: t('rating'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'RatingStar',
      width: 150,
    },
    {
      // field: 'Action',
      headerName: t('action'),
      headerClass: 'resizable-header',
      // width: 100,
      suppressMovable: true,
      cellRenderer: 'EditDeleteCellRender',
      width: 100,
    },
  ];

  /**** Tab view column */
  const columnDefsTab = [
    {
      field: 'dishName',
      headerName: t('name'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
      flex: 3,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'dishDescription',
      headerName: t('description'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      flex: 5,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'dishCategory',
      headerName: t('category'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      flex: 2,
    },
    {
      field: 'dishPrice',
      headerName: t('price'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      flex: 1,
    },
    {
      // field: 'Action',
      headerName: t('action'),
      headerClass: 'resizable-header',
      // width: 100,
      suppressMovable: true,
      cellRenderer: 'EditDeleteCellRender',
      width: 100,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'dishName',
      headerName: t('name'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
      flex: 2,
      autoHeight: true,
      wrapText: true,
    },
    {
      // field: 'Action',
      headerName: t('action'),
      headerClass: 'resizable-header',
      suppressMovable: true,
      cellRenderer: 'EditDeleteCellRender',
      flex: 1,
    },
    {
      field: 'hghgh',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    // rowHeight: 60,
    // groupHeaderHeight: 60,
    // headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      'padding-top': '10px',
      'padding-bottom': '10px',
    },
  };
  return (
    <div className={'add-dish-to-meal global-data-table'}>
      <div className="container">
        <div className="search-section">
          <BackLink redirectHistory={backLink} />
          <div className="search-input-section">
            <input
              className={`input`}
              type={'text'}
              onInput={(e) => handleSearchChange(e)}
              placeholder={`${t('search')}`}
            />
            <img src={searchimg} alt="search" />
          </div>
        </div>
        <AgGridReact
          className="ag-theme-alpine"
          rowData={dishesToDisplay}
          columnDefs={tableColumn}
          reactUi={lang === '"he"' ? false : true}
          enableRtl={lang === '"he"' ? true : false}
          frameworkComponents={{
            NameCellRender: (e) => <NameCellRender e={e} />,
            EditDeleteCellRender: (e) => (
              <EditDeleteCellRender e={e} handleAddDish={handleAddDish} t={t} />
            ),
            ViewMore: (e) => <ViewMore e={e} handleViewMore={handleViewMore} />,
            RatingStar: (e) => <RatingStar e={e} />,
          }}
          domLayout={'autoHeight'}
          gridOptions={gridOptions}
          onGridReady={(params) => {
            setGridApi(params.api);
            setColumnApi(params.columnApi);
          }}
          debug={false}
          rowSelection={'multiple'}
          rowGroupPanelShow={'always'}
          pivotPanelShow={'always'}
          enableRangeSelection={true}
          overlayLoadingTemplate={'<p></p>'}
          pagination={true}
          paginationPageSize={25}
          // paginationNumberFormatter={function (params) {
          //   return '[' + params.value.toLocaleString() + ']';
          // }}
        ></AgGridReact>
        {/* <div className="add-dishes-table">
          <div className="dishes-header">
            <h6>{t('name')}</h6>
            <h6>{t('description')}</h6>
            <h6>{t('category')}</h6>
            <h6>{t('price')}</h6>
            <h6>{t('rating')}</h6>
            <h6></h6>
          </div>
          {dishesToDisplay && dishesToDisplay.length ? (
            <div className={'dishes'}>
              {dishesToDisplay
                .sort((d1, d2) => d1?.name?.localeCompare(d2.name))
                .map((dishData) => (
                  <Dish
                    key={dishData.id}
                    dishData={dishData}
                    cb={handleAddDish}
                    DishCategories={DishCategories}
                  />
                ))}
            </div>
          ) : (
            <div className="no-dishes">
              <p>{t('noDishes')}</p>
            </div>
          )}
        </div> */}
      </div>
      <ViewMealDishDetail
        open={viewDetails}
        details={details}
        handleClose={handleViewMore}
        handleAddDish={handleAddDish}
      />
    </div>
  );
};

const NameCellRender = ({ e }) => {
  return (
    <section className={'image'}>
      <DishImage fileName={e.data.dishImage} size={{ height: 100 }} />
      <div className="dish-name-with-tag">
        <p>{e.data.dishName}</p>
        {e.data.dishTag.map((tag) => (
          <Chip label={tag.displayName} size="small" />
        ))}
      </div>
    </section>
  );
};
const EditDeleteCellRender = ({ e, handleAddDish, t = { t } }) => {
  return (
    <section>
      <div className={'actions'}>
        <div className={'include-add'}>
          {e.data.isIncluded ? (
            <p>{t('added')}</p>
          ) : (
            <img
              src={add}
              alt="add"
              onClick={() => handleAddDish(e.data.dishId)}
            ></img>
          )}
        </div>
      </div>
    </section>
  );
};

const RatingStar = ({ e }) => {
  return (
    <section className={'section2'}>
      <div className={'rating'}>
        <StaticStars
          rating={e.data.dishRating || -1}
          numOfRaters={e.data.numberOfRaters}
        />
      </div>
    </section>
  );
};
const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

// const Dish = ({ dishData, DishCategories, cb = () => {} }) => {
//   const { t } = useTranslation();
//   const DishCategoriesMap = DishCategories.reduce((acc, cat) => {
//     return { ...acc, [cat.id]: cat.display };
//   }, {});

//   return (
//     <div className={'dish dish-content'}>
//       <section className="dish-image">
//         <DishImage fileName={dishData.image} size={{ height: 100 }} />
//         <div className="dish-name-with-tag">
//           <p>{dishData.name}</p>
//           {dishData.tags.map((tag) => (
//             <Chip label={tag.displayName} size="small" />
//           ))}
//         </div>
//       </section>

//       <section className="dish-description">
//         <p>{dishData.description}</p>
//       </section>

//       <section>
//         <p className={'type'}>{DishCategoriesMap[dishData.dishCategoryId]}</p>
//       </section>
//       <section className="dish-price">
//         <p className={'type'}>₪ {dishData.price}</p>
//       </section>
//       <section className={'section2'}>
//         <div className={'rating'}>
//           <StaticStars
//             rating={dishData.rating || -1}
//             numOfRaters={dishData.numOfRaters}
//           />
//         </div>
//       </section>
//       <section>
//         <div className={'include-add'}>
//           {dishData.isIncluded ? (
//             <p>Added</p>
//           ) : (
//             // <WEIcon
//             //   icon={'plus'}
//             //   text={t('addDish')}
//             //   backgroundColor={'green'}
//             //   iconSize={'small'}
//             //   additionalWrapperClassname={'mobile-flex-end'}
//             //   cb={() => cb(dishData.id)}
//             // />
//             <img src={add} alt="add" onClick={() => cb(dishData.id)}></img>
//           )}
//         </div>
//       </section>
//     </div>
//   );
// };

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  DishCategories: state.app.enums.DishCategory,
  MealDishStatuses: state.app.enums.MealDishStatus,
  MealStatuses: state.app.enums.MealStatus,
  DishStatuses: state.app.enums.DishStatus,
  MealTypes: state.app.enums.MealType,
});
const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
export default connect(mapStateToProps, mapDispatchToProps)(AddDishToMeal);
