import AppConfig from '../../constants/Config';
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import http from '../../services/http';
import dates from '../../services/dates';
import { useTranslation } from 'react-i18next';
import { getAssetUrl } from '../../services/aws';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { StaticStars } from '../../components/RatingStars';
import enums from '../../services/enums';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DishImage from './DishImage';
import { sleep } from '../../services/utils';
import WEIcon from '../../components/WE-IconButton';
import WEBackButton from '../../components/WE-BackButton';
import Layout from '../../components/Layout';

// Img
import arrow from '../../assets/icons/eva_arrow-back-fill.svg';

import { Button } from 'react-bootstrap';
import { getPaymentPreferences } from '../../store/kitchens/actions';

// @ts-ignore
const OrderVoucher = ({
  user,
  kitchen,
  kitchens,
  MealTypes,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
}) => {
  const [details, setDetails] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  useEffect(() => {
    console.log('details', details);
  }, [details]);
  const { t } = useTranslation();
  useEffect(async () => await useLoader(() => sleep()), []);

  // setLayoutOptions({ title: t('orderVoucher') });
  const { orderId, authoTokenURL } = useParams();
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});
  useEffect(async () => {
    const order = await useFetchingLoader(async () => {
      if (authoTokenURL) {
        return http.get(`orders/anonymous/voucher/${authoTokenURL}`);
      } else {
        return http.get(`orders/${orderId}`);
      }
    });
    console.log('order', order);
    if (order?.mealDish?.length > 1) {
      const paymentdata = await getPaymentPreferences(
        order?.mealDish[0]?.meal?.mealType?.kitchenId,
        ''
      );
      setDetails({
        orderHash: order?.hash,
        orderId: order?.id,
        mealExtraName: order?.extras?.text,
        mealExtraPrice: order?.extras?.price,
        date: order?.mealDish?.[0]?.meal.date,
        mealType: order?.mealDish?.[0]?.meal.mealType.id,
        price: order?.orderPrice,
        authToken: order?.authToken,
        from: order?.mealSlot?.mealTypesSlot.from,
        to: order?.mealSlot?.mealTypesSlot.to,
        qrImage: order?.qrImage,
        orderStatus: order?.orderStatusId,
        realizeDate: order?.realizeDate,
        source: order?.platformId,
        mealDish: order?.mealDish,
        userName: order?.user?.firstName + ' ' + order?.user?.lastName,
        creditPoints: order?.mealDish[0]?.meal?.mealType?.creditPoints,
        paymentOption: paymentdata?.selectedPaymentOptions,
      });
    } else {
      const { meal, dish } = order?.mealDish?.[0];

      const paymentdata = await getPaymentPreferences(
        meal?.mealType?.kitchenId,
        ''
      );
      setDetails({
        orderHash: order?.hash,
        orderId: order?.id,
        dishId: dish?.id,
        dishName: dish?.name,
        dishPrice: dish?.price,
        mealExtraName: order?.extras?.text,
        mealExtraPrice: order?.extras?.price,
        image: dish?.image,
        rating: dish?.rating,
        numOfRaters: dish?.numOfRaters,
        date: meal?.date,
        mealDish: order?.mealDish,
        mealType: meal?.mealType.id,
        description: dish?.description,
        price: order?.orderPrice,
        authToken: order?.authToken,
        from: order?.mealSlot?.mealTypesSlot.from,
        to: order?.mealSlot?.mealTypesSlot.to,
        qrImage: order?.qrImage,
        orderStatus: order?.orderStatusId,
        realizeDate: order?.realizeDate,
        source: order?.platformId,
        userName: order?.user?.firstName + ' ' + order?.user?.lastName,
        creditPoints: meal?.mealType?.creditPoints,
        paymentOption: paymentdata?.selectedPaymentOptions,
      });
    }
  }, [kitchen]);
  // let url = window.location.href;
  // let get = url.split('=');
  // let getAuthToken = get[1];

  return (
    <Layout>
      {/* <div className="order-voucher web-voucher voucher-page"> */}
      <div className="voucher-page">
        <div className="voucher-heading-section">
          <Button className="back-section" onClick={() => history.goBack()}>
            <img src={arrow} alt="icon" />
            <span>{t('back')}</span>
          </Button>
          <h2>
            {t('order')} #{details?.orderId}
            <span>
              {dates.format(details?.date)} |{' '}
              {MealTypesMap_Id[details?.mealType]}
            </span>
          </h2>
        </div>
        {authoTokenURL && details ? (
          <>
            {/* {/ <WEBackButton text={backText} redirectHistory={backLink} /> /} */}
            {details.orderStatus === 2 ? (
              <div className="voucher-section">
                <h4 className="userName desktopSection">{details.userName}</h4>
                <span className="voucher-realized">
                  {t('realizedMessage')}{' '}
                  {dates.formatVoucher(details.realizeDate)}
                </span>
                <div className="order-container">
                  <div className="qr-code">
                    <img
                      src={`data:image;base64,${details.qrImage}`}
                      alt="QR_CODE"
                    />
                  </div>
                  <div className="voucher-section-right">
                    <h4 className="userName mobSection">{details.userName}</h4>
                    <div className="order-details">
                      {/* Order Table Starts */}
                      <div class="my-orders-table">
                        <div class="my-orders-table-head">
                          <div class="table-text-section">
                            <h5>{t('dish')}</h5>
                            <h5>{t('quantity')}</h5>
                            {user?.roleId == 6 ||
                            user?.roleId == 7 ||
                            user?.roleId == 8 ? (
                              ''
                            ) : (
                              <h5>{t('price')}</h5>
                            )}
                          </div>
                        </div>
                        <div class="my-orders-table-body">
                          <div class="my-orders-table-item">
                            <div class="table-text-section">
                              <p>
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>{el.dish.name}</span>
                                    ))
                                  ) : (
                                    <span> {details.dishName}</span>
                                  )}
                                </div>
                              </p>
                              <p>
                                {' '}
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>
                                        {el.quantity}
                                        <br />
                                      </span>
                                    ))
                                  ) : (
                                    <span> {1}</span>
                                  )}
                                </div>
                              </p>
                              {user?.roleId == 6 ||
                              user?.roleId == 7 ||
                              user?.roleId == 8 ? (
                                ''
                              ) : (
                                <p>
                                  {' '}
                                  <div className="text-section-dishes">
                                    {details?.mealDish
                                      ? details?.mealDish?.map((el, i) => (
                                          <span>
                                            {details.paymentOption == 5
                                              ? ''
                                              : '₪' +
                                                ' ' +
                                                el.dish.price * el.quantity}
                                          </span>
                                        ))
                                      : details.paymentOption == 5
                                      ? ''
                                      : '₪' + ' ' + details.dishPrice}
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {details?.mealExtraName ? (
                        <div className="total-price-section">
                          <h5>{t('extra')}</h5>
                          <p>
                            <span> {details?.mealExtraName}</span>
                          </p>
                          {user?.roleId == 6 ||
                          user?.roleId == 7 ||
                          user?.roleId == 8 ? (
                            ''
                          ) : (
                            <p>
                              {' '}
                              <span>
                                {' '}
                                {details.paymentOption == 5
                                  ? ''
                                  : '₪' + ' ' + details?.mealExtraPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* Order Table Ends */}
                      {user?.roleId == 8 ||
                      user?.roleId == 7 ||
                      user?.roleId == 6 ? (
                        ''
                      ) : (
                        <div className="total-price-section">
                          <h5>{t('totalAmount')}</h5>
                          <p>
                            <span>{details.paymentOption == 5 ? '' : '₪'}</span>
                            <span>{details.price}</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="voucher-section">
                <h4 className="userName desktopSection">{details.userName}</h4>
                <div className="order-container">
                  <div className="qr-code">
                    <img
                      src={`data:image;base64,${details.qrImage}`}
                      alt="QR_CODE"
                    />
                  </div>
                  <div className="voucher-section-right">
                    <h4 className="userName mobSection">{details.userName}</h4>
                    <div className="order-details">
                      {/* Order Table Starts */}
                      <div class="my-orders-table">
                        <div class="my-orders-table-head">
                          <div class="table-text-section">
                            <h5>{t('dish')}</h5>
                            <h5>{t('quantity')}</h5>
                            {user?.roleId == 6 ||
                            user?.roleId == 7 ||
                            user?.roleId == 8 ? (
                              ''
                            ) : (
                              <h5>{t('price')}</h5>
                            )}
                          </div>
                        </div>
                        <div class="my-orders-table-body">
                          <div class="my-orders-table-item">
                            <div class="table-text-section">
                              <p>
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>{el.dish.name}</span>
                                    ))
                                  ) : (
                                    <span> {details.dishName}</span>
                                  )}
                                </div>
                              </p>
                              <p>
                                {' '}
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>
                                        {el.quantity}
                                        <br />
                                      </span>
                                    ))
                                  ) : (
                                    <span> {1}</span>
                                  )}
                                </div>
                              </p>
                              {user?.roleId == 6 ||
                              user?.roleId == 7 ||
                              user?.roleId == 8 ? (
                                ''
                              ) : (
                                <p>
                                  {' '}
                                  <div className="text-section-dishes">
                                    {details?.mealDish
                                      ? details?.mealDish?.map((el, i) => (
                                          <span>
                                            {details.paymentOption == 5
                                              ? ''
                                              : '₪' +
                                                ' ' +
                                                el.dish.price * el.quantity}
                                          </span>
                                        ))
                                      : details.paymentOption == 5
                                      ? ''
                                      : '₪' + ' ' + details.dishPrice}
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {details?.mealExtraName ? (
                        <div className="total-price-section">
                          <h5>{t('extra')}</h5>
                          <p>
                            <span> {details?.mealExtraName}</span>
                          </p>
                          {user?.roleId == 6 ||
                          user?.roleId == 7 ||
                          user?.roleId == 8 ? (
                            ''
                          ) : (
                            <p>
                              {' '}
                              <span>
                                {' '}
                                {details.paymentOption == 5
                                  ? ''
                                  : '₪' + ' ' + details?.mealExtraPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}

                      {/* Order Table Ends */}
                      {user?.roleId == 8 ||
                      user?.roleId == 7 ||
                      user?.roleId == 6 ? (
                        ''
                      ) : (
                        <div className="total-price-section">
                          <h5>{t('totalAmount')}</h5>
                          <p>
                            <span>{details.paymentOption == 5 ? '' : '₪'}</span>
                            <span>{details.price}</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : details ? (
          <>
            {/* {/ <WEBackButton text={backText} redirectHistory={backLink} /> /} */}
            {details?.orderStatus === 2 ? (
              <div className="voucher-section">
                <h4 className="userName desktopSection">{details.userName}</h4>
                <span className="voucher-realized">
                  {t('realizedMessage')}{' '}
                  {dates.formatVoucher(details.realizeDate)}
                </span>
                <div className="order-container">
                  <div className="qr-code">
                    <img
                      src={`data:image;base64,${details.qrImage}`}
                      alt="QR_CODE"
                    />
                  </div>
                  <div className="voucher-section-right">
                    <h4 className="userName mobSection">{details.userName}</h4>
                    <div className="order-details">
                      {/* Order Table Starts */}
                      <div class="my-orders-table">
                        <div class="my-orders-table-head">
                          <div class="table-text-section">
                            <h5>{t('dish')}</h5>
                            <h5>{t('quantity')}</h5>
                            {user?.roleId == 6 ||
                            user?.roleId == 7 ||
                            user?.roleId == 8 ? (
                              ''
                            ) : (
                              <h5>{t('price')}</h5>
                            )}
                          </div>
                        </div>
                        <div class="my-orders-table-body">
                          <div class="my-orders-table-item">
                            <div class="table-text-section">
                              <p>
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>{el.dish.name}</span>
                                    ))
                                  ) : (
                                    <span> {details.dishName}</span>
                                  )}
                                </div>
                              </p>
                              <p>
                                {' '}
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => {
                                      console.log('Elem', el);
                                      return (
                                        <span>
                                          {el.quantity}
                                          <br />
                                        </span>
                                      );
                                    })
                                  ) : (
                                    <span> {1}</span>
                                  )}
                                </div>
                              </p>
                              {user?.roleId == 6 ||
                              user?.roleId == 7 ||
                              user?.roleId == 8 ? (
                                ''
                              ) : (
                                <p>
                                  {' '}
                                  <div className="text-section-dishes">
                                    {details?.mealDish
                                      ? details?.mealDish?.map((el, i) => (
                                          <span>
                                            {details.paymentOption == 5
                                              ? ''
                                              : '₪' +
                                                ' ' +
                                                el.dish.price * el.quantity}
                                          </span>
                                        ))
                                      : details.paymentOption == 5
                                      ? ''
                                      : '₪' + ' ' + details.dishPrice}
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {details?.mealExtraName ? (
                        <div className="total-price-section">
                          <h5>{t('extra')}</h5>
                          <p>
                            <span> {details?.mealExtraName}</span>
                          </p>
                          {user?.roleId == 6 ||
                          user?.roleId == 7 ||
                          user?.roleId == 8 ? (
                            ''
                          ) : (
                            <p>
                              {' '}
                              <span>
                                {' '}
                                {details.paymentOption == 5
                                  ? ''
                                  : '₪' + ' ' + details?.mealExtraPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* Order Table Ends */}
                      {user?.roleId == 8 ||
                      user?.roleId == 7 ||
                      user?.roleId == 6 ? (
                        ''
                      ) : (
                        <div className="total-price-section">
                          <h5>{t('totalAmount')}</h5>
                          <p>
                            <span>{details.paymentOption == 5 ? '' : '₪'}</span>
                            <span>{details.price}</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // <div className="green-border shadow-card">
              <div className="voucher-section">
                <h4 className="userName desktopSection">{details.userName}</h4>
                <div className="order-container">
                  {/* <DishImage fileName={details?.image} size={{ height: '' }} /> */}
                  <div className="qr-code">
                    <img
                      src={`data:image;base64,${details?.qrImage}`}
                      alt="QR_CODE"
                    />
                  </div>
                  <div className="voucher-section-right">
                    <h4 className="userName mobSection">{details.userName}</h4>
                    <div className="order-details">
                      {/* Order Table Starts */}
                      <div class="my-orders-table">
                        <div class="my-orders-table-head">
                          <div class="table-text-section">
                            <h5>{t('dish')}</h5>
                            <h5>{t('quantity')}</h5>
                            {user?.roleId == 6 ||
                            user?.roleId == 7 ||
                            user?.roleId == 8 ? (
                              ''
                            ) : (
                              <h5>{t('price')}</h5>
                            )}
                          </div>
                        </div>
                        <div class="my-orders-table-body">
                          <div class="my-orders-table-item">
                            <div class="table-text-section">
                              <p>
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>{el.dish.name}</span>
                                    ))
                                  ) : (
                                    <span> {details.dishName}</span>
                                  )}
                                </div>
                              </p>
                              <p>
                                {' '}
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>
                                        {el.quantity}
                                        <br />
                                      </span>
                                    ))
                                  ) : (
                                    <span> {1}</span>
                                  )}
                                </div>
                              </p>
                              {user?.roleId == 6 ||
                              user?.roleId == 7 ||
                              user?.roleId == 8 ? (
                                ''
                              ) : (
                                <p>
                                  {' '}
                                  <div className="text-section-dishes">
                                    {details?.mealDish
                                      ? details?.mealDish?.map((el, i) => (
                                          <span>
                                            {details.paymentOption == 5
                                              ? ''
                                              : '₪' +
                                                ' ' +
                                                el.dish.price * el.quantity}
                                          </span>
                                        ))
                                      : details.paymentOption == 5
                                      ? ''
                                      : '₪' + ' ' + details.dishPrice}
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {details?.mealExtraName ? (
                        <div className="total-price-section extra-div">
                          <h5>
                            {t('extra')}{' '}
                            <p>
                              <span> {details?.mealExtraName}</span>
                            </p>
                          </h5>

                          {user?.roleId == 6 ||
                          user?.roleId == 7 ||
                          user?.roleId == 8 ? (
                            ''
                          ) : (
                            <p>
                              {' '}
                              <span>
                                {' '}
                                {details.paymentOption == 5
                                  ? ''
                                  : '₪' + ' ' + details?.mealExtraPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* Order Table Ends */}
                      {user?.roleId == 8 ||
                      user?.roleId == 7 ||
                      user?.roleId == 6 ? (
                        ''
                      ) : (
                        <div className="total-price-section">
                          <h5>{t('totalAmount')}</h5>
                          <p>
                            <span>{details.paymentOption == 5 ? '' : '₪'}</span>
                            <span>{details.price}</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="green-border shadow-card">
            <div className="scan-voucher">
              {' '}
              <p>{t('scanVoucher')}</p>
              <iframe
                src={`${AppConfig.server.url}/orders/voucher/${orderId}`}
                sandbox=""
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens.options,
  MealTypes: state.app.enums.MealType,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderVoucher);
