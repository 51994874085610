import AppConfig from '../constants/Config';
// import AppConfig from "../config";
// import {setSessionIdToStorage, getSessionIdFromStorage} from '../store/user/actions';

class HttpRequest {
  defaults = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  options = {
    mock: {
      sleep: 0,
    },
  };

  url;
  endpoint;
  callback;
  $state = 'uninitialized';

  extractRequestOptions(options) {
    if (!options) {
      options = { saveSession: true };
    }
    let { mock, saveSession = true } = options;
    this.options = { mock, saveSession };
    delete options.mock;
    delete options.saveSession;
  }

  constructor(obj) {
    let {
      method,
      url,
      language,
      headers,
      endpoint,
      options: opts,
      data,
      callback,
    } = obj;
    const options = { language, ...opts };
    this.extractRequestOptions(options);

    this.url = `${url}/${endpoint}${this.serializedOptions(options)}`;
    this.endpoint = endpoint;
    this.settings = {
      method,
      credentials: this.options.saveSession ? 'include' : null,
      headers: { ...this.defaults.headers, ...headers },
      body: data ? JSON.stringify(data) : null,
    };
    this.callback = callback ? callback : null;

    this.$state = 'initialized';
  }

  // For using token in header request
  setAuthorizationHeader = (token) => {
    this.defaults.headers['Authorization'] = `Bearer ${token}`;
  };

  save() {}

  async sleep(timeout, toResolve) {
    return await new Promise((resolve) => {
      setTimeout(() => {
        resolve(toResolve || 0);
      }, timeout || 0);
    });
  }

  async process() {
    this.$state = 'pending';
    try {
      // this.settings.headers['x-session-id'] = await getSessionIdFromStorage();

      if (this.options.mock) {
        return await this.sleep(
          this.options.mock.sleep,
          this.options.mock.resolve
        );
      }
      let resp = await window.fetch(this.url, this.settings);
      let results = await this.evaluateResponse(resp, this.options);
      this.$state = 'resolved';

      this.results = results;
      return this.results;
    } catch (e) {
      this.$state = 'rejected';
      throw e;
    }
  }

  async evaluateResponse(response, options) {
    if (options.saveSession) {
      // let session = response.headers.get('set-cookie');
      // await setSessionIdToStorage({id: session})
    }
    // const contentLength = parseInt(response.headers.get("content-length")); // temporary solution while logout r body is empty
    const results = {
      // temporary solution while logout r body is empty
      results: {
        message: 'OK',
      },
    };

    if (response.ok) {
      try {
        return await response.json();
      } catch (e) {
        return results;
      }
      // return contentLength ? await response.json() : results; // temporary solution while logout r body is empty
    } else if (response.status.toString().startsWith('4')) {
      let res = await response.json();

      throw {
        forceDisplay: true,
        message: res.message || res,
        redirect: '/login',
      };
    } else {
      let res = await response.json();
      throw res || 'התרחשה שגיאה. אנא נסו שנית';
      // throw new Error(`Error Communicating With Server.
      // Error Code:  ${res.code || response.code}
      // Error Message: ${res.message || 'Generic Error'}`);
    }
  }

  serializedOptions(obj) {
    /* obj = {filters, project, search, populate, paging, sort} */
    const parts = localStorage.getItem('language').split('"');
    const stringWithoutQuotes = parts.join('');

    if (!obj || Object.keys(obj).length === 0) return '';
    return (
      '?' +
      Object.entries(obj)
        .map((e, i) => {
          switch (e[0]) {
            case 'search':
              return `${e[0]}=${e[1]}`;
            case 'paging':
              return `page=${Number(e[1].page) + 1}&pageSize=${e[1].pageSize}`;
            case 'sort':
              return `sort=${e[1].field}&order=${e[1].order}`;
            case 'language':
              return `lang=${e[1] ?? stringWithoutQuotes}`;
            default:
              return `${e[0]}=${
                typeof e[1] === 'string' ? e[1] : JSON.stringify(e[1])
              }`;
          }
        })
        .join('&')
    );
  }

  showSpinner() {
    return this.$state === 'pending';
  }
}

class HTTPConnection {
  config = {
    modes: { single: 'single', sequence: 'sequence', parallel: 'parallel' },
    set: function (options) {
      for (let option in Object.keys(options)) {
        if (option in this) {
          this[option] = options[option];
        }
      }
    },
  };

  mode = this.config.modes.single;
  locals = {};
  requests = [];
  resolved = [];

  constructor(url) {
    this.url = url ? url : AppConfig.server.url;
  }

  load(endpoint, data) {
    this.locals[endpoint] = data;
    return this;
  }

  clear() {
    this.requests = [];
    this.resolved = [];
    this.resolved = [];
  }

  setMode(mode) {
    this.mode = mode;
  }

  get sequence() {
    this.setMode(this.config.modes.sequence);
    return this;
  }

  get parallel() {
    this.setMode(this.config.modes.parallel);
    return this;
  }

  async runOnce() {
    let request = this.requests.pop();

    this.resolved.push(await request.process());

    if (request.callback)
      request.callback.call(this, this.resolved, this.locals);

    return this.resolved.pop();
  }

  async runParallel() {
    let callbacks = [];
    this.resolved = await Promise.all(
      this.requests.map((request) => {
        if (request.callback) callbacks.push(request.callback);
        return request.process();
      })
    );

    for (let cb of callbacks) {
      cb.call(this, this.resolved, this.locals);
    }
  }

  async runSequence() {
    for (let request of this.requests) {
      this.resolved.push(await request.process());

      if (request.callback)
        request.callback.call(this, this.resolved, this.locals);
    }
  }

  async run() {
    let { single, parallel, sequence } = this.config.modes;

    let results;

    switch (this.mode) {
      case single:
        results = JSON.parse(JSON.stringify(this.resolved));
        break;
      case sequence:
        await this.runSequence();
        results = JSON.parse(JSON.stringify(this.resolved));
        break;
      case parallel:
        await this.runParallel();
        results = JSON.parse(JSON.stringify(this.resolved));
        break;
    }

    this.clear();

    return results;
  }

  request(request) {
    if (!request.url) request.url = this.url;
    if (!request.method) request.method = 'GET';
    if (this.language) request.language = this.language;
    let httpRequest = new HttpRequest(request);
    this.requests.push(httpRequest);
    return httpRequest;
  }

  next(request) {
    let { modes } = this.config;

    if (request) this.request(request);

    if (this.mode === modes.single && this.requests.length >= 1) {
      return this.runOnce();
    }

    return this;
  }
}

class HTTPClient extends HTTPConnection {
  static instance;

  get getInstance() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  constructor(url) {
    super(url);
    return this.getInstance;
  }

  // For using token in header request
  setAuthorizationHeader = (token) => {
    this.Authorization = `Bearer ${token}`;
  };

  setLanguage(lang) {
    super.language = lang;
  }

  get(endpoint, options, callback) {
    const headers = this.Authorization
      ? { Authorization: this.Authorization }
      : {};
    return this.next({ endpoint, headers, options, callback });
  }

  post(endpoint, data, options, callback) {
    const headers = this.Authorization
      ? { Authorization: this.Authorization }
      : {};
    return this.next({
      method: 'POST',
      endpoint,
      headers,
      options,
      data,
      callback,
    });
  }

  put(endpoint, data, options, callback) {
    const headers = this.Authorization
      ? { Authorization: this.Authorization }
      : {};
    return this.next({
      method: 'PUT',
      endpoint,
      headers,
      options,
      data,
      callback,
    });
  }

  delete(endpoint, data, options, callback) {
    const headers = this.Authorization
      ? { Authorization: this.Authorization }
      : {};
    return this.next({
      method: 'DELETE',
      endpoint,
      headers,
      options,
      data,
      callback,
    });
  }
}

export default new HTTPClient().getInstance;

const connection = (url) => new HTTPClient(url);

export { connection };
