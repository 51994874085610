import { useTranslation } from 'react-i18next';
import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

// import { ActiveButton } from '../../components/WE-Button';
import http from '../../services/http';
import { frontendUrl } from '../../services/utils';
import { useLoader } from '../../store/app/actions';
import VisaCard from '../../assets/icons/visa.svg';
import MasterCard from '../../assets/icons/mastercard.svg';
import GenericCard from '../../assets/icons/generic_card.svg';
// import WEIcon from '../../components/WE-IconButton';
import { useLocation } from 'react-router-dom';
import ProfileLayout from '../profile/profileLayout';
import { Button } from 'react-bootstrap';
const getPaymentMethodImg = (provider: string) => {
  switch (provider) {
    case 'Visa':
      return VisaCard;
    case 'Mastercard':
      return MasterCard;
    default:
      return GenericCard;
  }
};

const EmployeePaymentManagement = ({ user, useLoader }) => {
  const { t } = useTranslation();
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(0);
  // const [openDropdown, setOpenDropdown] = useState(false);
  const [userpaymentType, setUserpaymentType] = useState<any>();
  //  const [curUrl, setCurUrl] = useState();
  const { search } = useLocation();

  const refreshPaymentMethods = useCallback(() => {
    useLoader(async () => {
      setPaymentMethods(await http.get('users/paymentMethods'));
      setDefaultPaymentMethod(
        (await http.get('users/defaultPaymentMethod'))?.id
      );
    });
  }, []);

  useEffect(() => {
    refreshPaymentMethods();
  }, []);

  const deletePaymentMethod = useCallback(
    (paymentMethod) => {
      useLoader(async () => {
        //@ts-ignore
        await http.delete(`users/paymentMethod/${paymentMethod.id}`, {
          userId: user.id,
        });
        refreshPaymentMethods();
      });
    },
    [paymentMethods]
  );

  const updateDefaultPaymentMethod = useCallback(
    (paymentMethodId) => {
      console.log('Dsdsds =>', paymentMethodId);
      useLoader(async () => {
        await http.post('users/defaultPaymentMethod', {
          defaultPaymentMethodId: paymentMethodId,
        });
      });
    },
    [paymentMethods]
  );

  // const handleDropdownClick = () => {
  //   setOpenDropdown(!openDropdown);
  // };

  // const useOutsideAlerter = (ref) => {
  //   useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         setOpenDropdown(false);
  //       }
  //     };
  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside);
  //     };
  //   }, [ref]);
  // };
  // const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (search) {
      const queryStrings: Array<string> = search.substring(1).split('&');
      if (queryStrings.length) {
        const query: {} = {};
        queryStrings.forEach((el: string) => {
          const [key, value] = el.split('=');
          query[key] = value;
        });
        if (query['type']) {
          setUserpaymentType(() => query['type']);
        }
      }
    }
  }, [search, userpaymentType]);

  const handlePayment = async () => {
    console.log('cl');
    let url = window.location.href;
    let current = url.split('#');
    let paymentUrl = current[1];
    const res = await http.post('users/paymentUrl', {
      url_redirect: frontendUrl('successfullySavedPaymentMethod'),
      paymentType: userpaymentType,
      currentUrl: paymentUrl,
    });
    window.location.href = res.url;
  };

  return (
    <ProfileLayout>
      <div className="employee-payment-management">
        <div className="add-apyment-btn">
          <Button onClick={handlePayment}>{t('addNewPaymentMethod')}</Button>
        </div>
        {/* <div className="custom-dropdown">
          <div className="dropdown-menu" onClick={handleDropdownClick}>
            Select Payment option
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </div>
          {openDropdown && (
            <div className="dropdwon-list" ref={wrapperRef}>
              <div
                className="dropdown-item"
                onClick={async () => {
                  const res = await http.post('users/paymentUrl', {
                    url_redirect: frontendUrl('successfullySavedPaymentMethod'),
                    paymentType: 'nayax',
                  });
                  window.location.href = res.url;
                }}
              >
                <p className={'label'}>Nayax</p>
              </div>
              <div
                className="dropdown-item"
                onClick={async () => {
                  const res = await http.post('users/paymentUrl', {
                    url_redirect: frontendUrl('successfullySavedPaymentMethod'),
                    paymentType: 'hyp',
                  });
                  window.location.href = res.url;
                }}
              >
                <p className={'label'}>Hyp (Caspit)</p>
              </div>
            </div>
          )}
        </div> */}
        <div>
          {paymentMethods.map((paymentMethod) => (
            <div className="payment-details">
              <p>
                <span>
                  <img
                    src={getPaymentMethodImg(paymentMethod.provider)}
                    style={{
                      lineHeight: '1.5rem',
                      height: '1.5rem',
                      margin: '10px',
                    }}
                  />
                  <span>{paymentMethod.paymentDisplay}</span>
                  <i
                    className="las la-trash"
                    onClick={() => {
                      deletePaymentMethod(paymentMethod);
                    }}
                  ></i>
                  <i
                    className={`lar la-check-circle ${
                      defaultPaymentMethod === paymentMethod.id ? 'ghgh' : ''
                    }`}
                    onClick={async () => {
                      updateDefaultPaymentMethod(paymentMethod.id);
                    }}
                  ></i>
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </ProfileLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = {
  useLoader,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeePaymentManagement);
